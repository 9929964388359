import { observable, makeObservable } from "mobx";

export default class CategoryItem {
  id = null;
  title = null;
  urlSlug = null;
  subcategories = null;
  selected = false;
  deepLinked = false;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      title: observable,
      urlSlug: observable,
      subcategories: observable,
      selected: observable,
      deepLinked: observable
    });

    this.id = props.id;
    this.title = props.title;
    this.urlSlug = props.urlSlug;
    this.subcategories = props.subcategories;
  }
}
