import { observable, makeObservable } from 'mobx';

export default class ImageGridVersion2 {

  id = null;
  type = null;
  alt = null;
  leftSquareImage = null;
  rightUpperRectangularImage = null;
  rightLowerRectangularImage = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      alt: observable,
      leftSquareImage: observable,
      rightUpperRectangularImage: observable,
      rightLowerRectangularImage: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id
    this.type = props.type
    this.alt = props.alt
    this.leftSquareImage = props.leftSquareImage
    this.rightUpperRectangularImage = props.rightUpperRectangularImage
    this.rightLowerRectangularImage = props.rightLowerRectangularImage
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
  }
} 