import { observable, makeObservable } from 'mobx';

export default class HomePage {

  id = "";
  header = "";
  subhead = "";
  masterbrandGuidelineLink = null;
  topics = [];
  guidelineLinks = [];
  mainGuidelines = [];
  seeMoreLabel = "";
  seeLessLabel = "";

  constructor(props) {
    makeObservable(this, {
      id: observable,
      header: observable,
      subhead: observable,
      masterbrandGuidelineLink: observable,
      topics: observable,
      guidelineLinks: observable,
      mainGuidelines: observable,
      seeMoreLabel: observable,
      seeLessLabel: observable
    });

    this.id = props.id
    this.header = props.header
    this.subhead = props.subhead
    this.masterbrandGuidelineLink = props.masterbrandGuidelineLink
    this.topics.replace(props.topics)
    this.guidelineLinks.replace(props.guidelineLinks)
    this.mainGuidelines.replace(props.mainGuidelines)
    this.seeMoreLabel = props.seeMoreLabel
    this.seeLessLabel = props.seeLessLabel
  }
}