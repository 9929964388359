import { observable, makeObservable } from "mobx";

export default class VideoPlayer {
  id = null;
  type = null;
  video = null;
  poster = null;
  paddingTop = null;
  hasBorder = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      video: observable,
      poster: observable,
      paddingTop: observable,
      hasBorder: observable
    });

    this.id = props.id;
    this.type = props.type;
    this.video = props.video;
    this.poster = props.poster;
    this.paddingTop = props.paddingTop;
    this.hasBorder = props.hasBorder;
  }
}
