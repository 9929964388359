export const API_URL: string = process.env.REACT_APP_API || "";

export const BASE_URL = process.env.hasOwnProperty("REACT_APP_BASE_URL")
  ? process.env.REACT_APP_BASE_URL
  : "https://brandcentral.verizonwireless.com.qa.epqa.us";

export const COGNITO_REGION = process.env.hasOwnProperty(
  "REACT_APP_COGNITO_REGION"
)
  ? process.env.REACT_APP_COGNITO_REGION
  : "";
export const COGNITO_USER_POOL_ID = process.env.hasOwnProperty(
  "REACT_APP_COGNITO_USER_POOL_ID"
)
  ? process.env.REACT_APP_COGNITO_USER_POOL_ID
  : "";

export const COGNITO_USER_POOL_CLIENT_ID = process.env.hasOwnProperty(
  "REACT_APP_COGNITO_USER_POOL_CLIENT_ID"
)
  ? process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
  : "";

export const COGNITO_AUTH_FLOW_TYPE = process.env.hasOwnProperty(
  "REACT_APP_COGNITO_AUTH_FLOW_TYPE"
)
  ? process.env.REACT_APP_COGNITO_AUTH_FLOW_TYPE
  : "";

export const COGNITO_OAUTH_DOMAIN = process.env.hasOwnProperty(
  "REACT_APP_COGNITO_OAUTH_DOMAIN"
)
  ? process.env.REACT_APP_COGNITO_OAUTH_DOMAIN
  : "";

export const COGNITO_OAUTH_SCOPE = process.env.hasOwnProperty(
  "REACT_APP_COGNITO_OAUTH_SCOPE"
)
  ? process.env.REACT_APP_COGNITO_OAUTH_SCOPE!.split(",")
  : [];

export const COGNITO_OAUTH_REDIRECT_SIGN_IN = process.env.hasOwnProperty(
  "REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN"
)
  ? process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN
  : "";

export const COGNITO_OAUTH_REDIRECT_SIGN_OUT = process.env.hasOwnProperty(
  "REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT"
)
  ? process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT
  : "";

export const COGNITO_OAUTH_RESPONSE_TYPE = process.env.hasOwnProperty(
  "REACT_APP_COGNITO_OAUTH_RESPONSE_TYPE"
)
  ? process.env.REACT_APP_COGNITO_OAUTH_RESPONSE_TYPE
  : "";

export const SSO_HOST_UX_URL_REDIRECT = process.env.hasOwnProperty(
  "REACT_APP_SSO_HOST_UX_URL_REDIRECT"
)
  ? process.env.REACT_APP_SSO_HOST_UX_URL_REDIRECT
  : "";

export const RECAPTCHA_API_KEY = process.env.REACT_APP_RECAPTCHA_API_KEY || "";


export const GUIDELINES_CONTENTFUL_DELIVERY_API_KEY = process.env.REACT_APP_GUIDELINES_CONTENTFUL_DELIVERY_API_KEY || "";

export const GUIDELINES_CONTENTFUL_SPACEID = process.env.REACT_APP_GUIDELINES_CONTENTFUL_SPACEID || "";

export const GUIDELINES_CONTENTFUL_ENVIRONMENT = process.env.REACT_APP_GUIDELINES_CONTENTFUL_ENVIRONMENT || "";
