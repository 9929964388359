const CONSTANTS = {
  CONTENT: {
    TYPES: {
      HOME_PAGE: "homePage",
      FOOTER: "footer",
      FOOTER_LINK: "footerLink",
      FOOTER_TEXT: "footerText",
      GUIDELINES: "guidelines",
      DECISION_TREE: "decisionTree",
    },
  },
};

export default CONSTANTS;
