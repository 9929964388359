import { observable, makeObservable } from 'mobx';

export default class LegacyGuideline {

  id = "";
  title = "";
  url = "";
  searchDescription = "";

  constructor(props) {
    makeObservable(this, {
      id: observable,
      title: observable,
      url: observable,
      searchDescription: observable
    });

    this.id = props.id
    this.title = props.title
    this.url = props.url
    this.searchDescription = props.searchDescription
  }
}