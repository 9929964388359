import { useState } from "react";
import MdToHtml from "../MdToHtml/MdToHtml";
import TextButton from "../../components/buttons/TextButton/TextButton";
import FlexBox from "../layouts/FlexBox/FlexBox";

interface LineClampingProps {
  content: string;
  maxLength: number;
  pSize?: string;
  linkSize?: string;
  color?: string;
  pMargin?: string;
  linkMargin?: string;
  padding?: string;
  linksInNewTab?: boolean;
  hideSeeMore?: boolean;
}

const LineClamping = ({
  content,
  color,
  pSize,
  linkSize,
  pMargin,
  linksInNewTab,
  linkMargin,
  maxLength,
  padding,
  hideSeeMore,
}: LineClampingProps) => {
  const [clipped, setClipped] = useState<boolean>(true),
    toggleClipped = () => {
    
      setClipped(!clipped);
    };
  
  const getContent = (): string => {
    let tempContent = content;
    // regex lifted from https://davidwells.io/snippets/regex-match-markdown-links
    const regexMdLinks = /\[([^\[]+)\](\(.*|\n\))/gm;

    const matches = tempContent.matchAll(regexMdLinks);

    let visibleLinkLengthTotal = 0;
    let overallLinkLengthTotal = 0;
    let extraCharactersDueToLinkMarkup = 0;

    // For all visible links (those would fall within the maxLength)
    // count the total visible characters and overall total characters including the markdown syntax
    // @ts-ignore
    for (const match of matches) {
      // check to see if the current match's index would be pass limit of visible characters (maxLength)
      // we subtract out the extra characters from the seen markdown links at this point to offset the index
      // into the visible limit.
      //
      // match.index + 1 accounts of the left bracket '[' that the link text starts with
      if (
        (match.index || 0) + 1 - extraCharactersDueToLinkMarkup >=
        maxLength
      ) {
        break;
      }

      visibleLinkLengthTotal += match[1]?.length;
      overallLinkLengthTotal += match[0]?.length;
      extraCharactersDueToLinkMarkup =
        overallLinkLengthTotal - visibleLinkLengthTotal;
    }

    // subtract out the extra characters in markdown link, leaving only the total "visible" character
    // length - if the visible length is greater than max then clip.
    //
    // NOTE: This does not handle an edge case where the link text crosses the maxLength boundary.
    //       e.g, only part of the text is within the boundary. This should be modified to detect a
    //       link is on the cut off boundary and extend that cutoff point to include the visible link text vs
    //       breaking it up and showing an ellipses.
    const adjustedLength = tempContent?.length - extraCharactersDueToLinkMarkup;
    if (adjustedLength > maxLength && clipped) {
      // extend the clip point to accommodate the links found in the visible region.
      return `${tempContent.slice(
        0,
        maxLength + extraCharactersDueToLinkMarkup
      )}...`;
    }

    return tempContent;
  };
  
  

  return (
    <FlexBox pd={padding} data-testid="line-clamping">
      {clipped ? (
        <MdToHtml
          linksInNewTab={linksInNewTab}
          color={color}
          pSize={pSize}
          pMargin={pMargin}
          linkMargin={linkMargin}
          linkSize={linkSize}
          content={getContent()}
        />
      ) : (
        <>
          <MdToHtml
            linksInNewTab={linksInNewTab}
            color={color}
            pSize={pSize}
            pMargin={pMargin}
            linkMargin={linkMargin}
            linkSize={linkSize}
            content={content}
          />
        </>
        
      )}
      
      {(content?.length > maxLength && !hideSeeMore) && (
        <TextButton onClick={toggleClipped} pd="0 0 2.5rem">
          {clipped ? "See more" : "See less"}
        </TextButton>
      )}
    </FlexBox>
  );
};

export default LineClamping;
