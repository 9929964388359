import { observable, makeObservable } from "mobx";

export default class SubcategoryItem {
  id = null;
  title = null;
  urlSlug = null;
  selected = false;
  sidenavSubnavLinks = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      title: observable,
      urlSlug: observable,
      selected: observable,
      sidenavSubnavLinks: observable
    });

    this.id = props.id;
    this.title = props.title;
    this.urlSlug = props.urlSlug;
    this.sidenavSubnavLinks = props.sidenavSubnavLinks;
  }
}
