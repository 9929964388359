import { observable, makeObservable } from 'mobx';

export default class VZButton {

  id = null;
  type = null;
  label = null;
  action = null;
  buttonType = null;
  style = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      label: observable,
      action: observable,
      buttonType: observable,
      style: observable
    });

    this.id = props.id
    this.type = props.type
    this.label = props.label
    this.action = props.action
    this.buttonType = props.buttonType
    this.style = props.style
  }
}