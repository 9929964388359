import { observable, makeObservable } from 'mobx';

export default class InterSectionHeader {
  id = null;
  type = null;
  text = null;
  textSize = null;
  additionalPaddingBelowComponent = null;
  removePadding = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      text: observable,
      textSize: observable,
      additionalPaddingBelowComponent: observable,
      removePadding: observable
    });

    this.id = props.id
    this.type = props.type
    this.text = props.text
    this.textSize = props.textSize
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
    this.removePadding = props.removePadding
  }
}
