import { observable, makeObservable } from 'mobx';

export default class FlexGridColumn {
  id = null;
  type = null;
  content = null;
  textAlignment = null;
  dynamicWidth = null;
  fullWidth = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      content: observable,
      textAlignment: observable,
      dynamicWidth: observable,
      fullWidth: observable
    });

    this.id = props.id
    this.type = props.type
    this.content = props.content
    this.textAlignment = props.textAlignment
    this.dynamicWidth = props.dynamicWidth
    this.fullWidth = props.fullWidth
  }
}
