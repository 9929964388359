import { observable, makeObservable } from "mobx";

export default class Category {
  id = "";
  title = "";
  urlSlug = "";
  subcategories = [];

  constructor(props) {
    makeObservable(this, {
      id: observable,
      title: observable,
      urlSlug: observable,
      subcategories: observable
    });

    this.id = props.id;
    this.title = props.title;
    this.urlSlug = props.urlSlug;
    this.subcategories = props.subcategories;
  }
}
