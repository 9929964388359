import { observable, makeObservable } from 'mobx';

export default class SubSectionHeader {
  id = "";
  type = "";
  title = "";
  updatedCopy = "";
  buttonsCopy = null;
  CTAButtons = [];
  theme = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      title: observable,
      updatedCopy: observable,
      buttonsCopy: observable,
      CTAButtons: observable,
      theme: observable
    });

    this.id = props.id
    this.type = props.type
    this.title = props.title
    this.updatedCopy = props.updatedCopy
    this.buttonsCopy = props.buttonsCopy
    this.CTAButtons = props.CTAButtons
    this.theme = props.theme
  }
}
