const CONSTANTS = {
  TYPES: {
    SECTION_HEADER: 'componentSectionHeader',
    INTER_SECTION_HEADER: 'componentInterSectionHeader',
    CAUTION_HEADER: 'componentCautionHeader',
    COLOR_SWATCH: 'componentColorSwatch',
    DO_HEADER: 'componentDoHeader',
    DONT_HEADER: 'componentDontHeader',
    IMAGE: 'componentImage',
    SECTION_FOOTER: 'componentSectionFooter',
    GRID_50_50: 'componentLayoutColumns2',
    GRID_25_75: 'grid2575',
    GRID_75_25: 'grid7525',
    GRID_33_33_33: 'componentLayoutColumns3',
    GRID_5UP: 'componentLayoutColumns5',
    LINK_IMAGE: 'componentLinkImage',
    TEXT_BLOCK: 'componentTextBlock',
    CONTAINER_INVERT_THEME_TO_DARK: 'componentContainerInvertThemeToDark',
    PADDING_INTER_SECTION: 'componentPaddingInterSection',
    IMAGE_GRID_VERSION_1: 'componentImageGridVersion1',
    IMAGE_GRID_VERSION_2: 'componentImageGridVersion2',
    IMAGE_GRID_VERSION_3: 'componentImageGridVersion3',
    IMAGE_GRID_VERSION_4: 'componentImageGridVersion4',
    IMAGE_GRID_VERSION_5: 'componentImageGridVersion5',
    TABLE: 'componentTable',
    ANIMATION: 'componentAnimation',
    ANIMATION_MOBILE_AND_DESKTOP_COMPARE:
      'componentAnimationMobileAndDesktopCompare',
    HERO: 'componentHero',
    VIDEO_PLAYER: 'componentVideoPlayer',
    VZBUTTON: 'componentVZButton',
    CONTENT_BLOCK: 'componentContentBlock',
    BEFORE_AND_AFTER: 'componentBeforeAndAfter',
    FLEX_GRID_COLUMN: 'componentFlexGridColumn',
    FLEX_GRID: 'componentFlexGrid',
  },
}

export default CONSTANTS
