import { observable, makeObservable } from 'mobx';

export default class TableHeader {

  id = null;
  cells = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      cells: observable
    });

    this.id = props.id
    this.cells = props.cells
  }
} 