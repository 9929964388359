import { useEffect, useState, useRef, KeyboardEvent } from "react";
import { verizonCheckmark } from "assets";
import Image from "../Image/Image";
import {
  StyledNavBar,
  StyledMenuWrapper,
  UserMenu,
  StyledSignOutButton,
} from "./NavBar.module";
import { Icon } from "@vds/icons";
import { Body, FlexBox, LinkButton, Loader } from "components";
import paths from "routing/paths";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "hooks/redux";
import { Auth } from "aws-amplify";
import {logout, setNotification, useGetGlobalQuery, useLazyGetHasPackagingManagerAccessQuery} from "services";
import IconButton from "../../buttons/IconButton/IconButton";
import { debounce } from "../../../utils/debounce";
import { ParsedUserJWT } from "../../../interfaces/Cognito";
import { withVDSManager } from "@vds/utilities";
import { withVDSManagerProps } from "interfaces";
import { GlobalSearch } from "features";
import { externalRoute, privilegedUser } from "utils";
import { selectCurrentUserDetails } from "services/auth/auth.slice";
import Button from "../../buttons/Button/Button";
import { supportsStorage } from "utils";

interface NavigationProps {
  active?: boolean;
  mobileOnly?: boolean;
  title: string;
  url?: string;
  external?: boolean;
  roles?: Array<string>;
  children?: Array<NavigationProps>;
}

interface HeaderScroll {
  headerIsActive: boolean;
  headerIsFixed: boolean;
  lastScrollPosition: number;
}

const NavBar = ({ viewport = "" }: withVDSManagerProps) => {
  const location = useLocation();
  const userDetails: ParsedUserJWT | null | undefined = useSelector(
    selectCurrentUserDetails
  );
  
  const initialHeaderScroll: HeaderScroll = {
    headerIsActive: false,
    headerIsFixed: false,
    lastScrollPosition: 0
  }
  
  const [checkPermissions, {data: userHasPmAccess}] = useLazyGetHasPackagingManagerAccessQuery();
  
  const callCheckPermissions = () => {
    checkPermissions()
    .unwrap()
    .catch((error: any) => {
      console.error(error);
    })
  };
  
  const {
    data: global,
    // isFetching: fetchingGlobal,
    // isError: globalError,
  } = useGetGlobalQuery();

  const [navigation, _navigation] = useState<any[]>([]);
  
  useEffect(() => {
    callCheckPermissions();
  }, []);

  useEffect(() => {
    if (global?.navigation?.items) {
      _navigation(global.navigation.items);
    }
  }, [global]);

  let hideSubNavTimeout: ReturnType<typeof setTimeout>;
  let showSubNavTimeout: NodeJS.Timeout;

  const isMobile: boolean = ["mobile", "mobileLarge", "tablet"].includes(
    viewport as string
  );

  const [menuOpen, setMenuOpen] = useState<boolean>(false),
    showMenu = () => {
      setMenuOpen(true);
      enableOverlay();
    },
    hideMenu = () => {
      setMenuOpen(false);
      disableOverlay();
    };
  
  const [headerScroll, _headerScroll ] = useState<HeaderScroll>(initialHeaderScroll);
  // const [headerIsActive, setHeaderIsActive] = useState<boolean>(false);
  // const [headerIsFixed, setHeaderIsFixed] = useState<boolean>(false);
  // const [lastScrollPosition, setLastScrollPosition] = useState<number>(0);

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false),
    showMobileMenu = () => {
      setMobileMenuOpen(true);
    },
    hideMobileMenu = () => {
      setMobileMenuOpen(false);
    };

  const [overlayIsActive, setOverlayIsActive] = useState<boolean>(false),
    enableOverlay = () => {
      setOverlayIsActive(true);
    },
    disableOverlay = () => {
      setOverlayIsActive(false);
    };

  const [mobileKeyDown, setMobileKeyDown] = useState<boolean>(false);
  const [lastFocusableEl, setLastFocusableEl] = useState<HTMLElement | null>(
    null
  );
  const [hoverNavItem, setHoverNavItem] = useState<HTMLElement | null>(null);
  const [hoverNavItemIndex, setHoverNavItemIndex] = useState<number | null>(
    null
  );
  const [hoverNavItemTitle, setHoverNavItemTitle] = useState<string | null>(
    null
  );
  const [hoverSubNavItem, setHoverSubNavItem] = useState<HTMLElement | null>(
    null
  );

  // const mobileCloseButton = useRef<HTMLButtonElement>(null);
  const mobileCloseButton = document.getElementById("close-mobile-nav-button");
  const [userNavigation, setUserNavigation] =
    useState<NavigationProps[]>(navigation);
  const [mainNavigation, setMainNavigation] = useState<NavigationProps[]>([]);
  const navigate = useNavigate(),
    dispatch = useDispatch();

  const [signingOut, _signingOut] = useState(false);

  async function handleSignOut() {
    hideMenu();
    _signingOut(true);
    try {
      if (supportsStorage("localStorage")) {
        localStorage.setItem("prevRoute", location.pathname);
        if (localStorage.getItem("pageRefreshed")) {
          localStorage.removeItem("pageRefreshed");
        }
        
      }
      await Auth.signOut();
      dispatch(logout());
      navigate(paths.SIGNIN);
    } catch (err) {
      _signingOut(false);
      dispatch(
        setNotification({
          type: "error",
          message: "An error has occurred. Please try again.",
        })
      );

      console.log("Error on logout:", err);
    }
  }

  const getLastFocusableEl = (): void => {
    setTimeout(() => {
      const query: string =
        "a[href]:not([tab-index='-1']), button:not([disabled]), textarea:not([disabled]), input[type='email']:not([disabled]), input[type='text']:not([disabled]), input[type='radio']:not([disabled]), input[type='checkbox']:not([disabled]), select:not([disabled]), [tabindex='0']";
      const navList: HTMLElement | null =
        document.getElementById("header-main-nav");
      const focusableEls = navList?.querySelectorAll(
        query
      ) as NodeListOf<HTMLElement>;
      let finalFocusableList: HTMLElement[] = [];
      focusableEls.forEach((element) => {
        if (!element.classList.contains("hidden-link")) {
          finalFocusableList.push(element);
        }
      });
      if (finalFocusableList.length > 0 && finalFocusableList !== undefined) {
        const lastFocusableElement = finalFocusableList[
          finalFocusableList.length - 1
        ] as HTMLElement;
        setLastFocusableEl(lastFocusableElement);
      }
    }, 200);
  };

  useEffect(() => {
    getLastFocusableEl();
  }, [mobileKeyDown]);

  const isSubNavOpen = (itemIndex: number): boolean => {
    let isSubNavOpen: boolean = true;
    const navItem = document.getElementById(`main-nav-item-${itemIndex}`);
    if (navItem !== null) {
      if (!navItem.classList.contains("sub-nav-active")) {
        isSubNavOpen = false;
      }
    }

    return isSubNavOpen;
  };

  const onLinkHover = (title: string) => {
    const subNavItem = document.getElementById(
      `header-sub-nav-item-link-${title}`
    );
    if (!subNavItem) {
      return;
    }
    subNavItem.classList.add("hover");
  };

  const onLinkHoverOut = (title: string) => {
    const subNavItem = document.getElementById(
      `header-sub-nav-item-link-${title}`
    );
    if (!subNavItem) {
      return;
    }
    subNavItem.classList.remove("hover");
  };

  const collapseSubNav = (itemIndex: number, itemTitle: string): void => {
    clearTimeout(hideSubNavTimeout);
    clearTimeout(showSubNavTimeout);
    const navItem = document.getElementById(`main-nav-item-${itemIndex}`);
    if (!navItem) {
      return;
    }

    const subNav = document.getElementById(`subnav-${itemTitle}`);
    if (!subNav) {
      return;
    }

    navItem.classList.remove("sub-nav-active");
    subNav.style.height = "";

    setHoverNavItem(null);
    setHoverSubNavItem(null);

    disableOverlay();
  };

  const expandSubNav = (itemIndex: number, itemTitle: string): void => {
    clearTimeout(hideSubNavTimeout);
    clearTimeout(showSubNavTimeout);
    if (hoverNavItemIndex && hoverNavItemTitle && isMobile) {
      collapseSubNav(hoverNavItemIndex, hoverNavItemTitle);
    }

    const prevSubNav = document.getElementById(`subnav-${itemTitle}`);
    if (!prevSubNav) {
      return;
    }

    const navItem = document.getElementById(`main-nav-item-${itemIndex}`);
    if (!navItem) {
      return;
    }

    const subNav = document.getElementById(`subnav-${itemTitle}`);
    if (!subNav) {
      return;
    }
    navItem.classList.remove("hidden-subnav");
    if (!navItem.classList.contains("hidden-subnav")) {
      subNav.classList.remove("hidden-subnav");
      setHoverNavItem(navItem);
      setHoverSubNavItem(subNav);
      setHoverNavItemIndex(itemIndex);
      setHoverNavItemTitle(itemTitle);
    }
  };

  const showSubNav = (navItem: HTMLElement, subNav: HTMLElement): void => {
    clearTimeout(hideSubNavTimeout);
    clearTimeout(showSubNavTimeout);
    navItem.classList.add("sub-nav-active");
    if (!subNav) {
      return;
    }
    if (!isMobile) {
      const ul = subNav.children[0] as HTMLElement;
      ul.style.paddingLeft =
        window.innerWidth > 896
          ? `${(subNav.parentElement as HTMLElement).offsetLeft + 8}px`
          : "";
    }
    subNav.style.height = `${subNav.scrollHeight}px`;
    enableOverlay();
  };

  const hideSubNav = (itemIndex: number, itemTitle: string): void => {
    clearTimeout(hideSubNavTimeout);
    clearTimeout(showSubNavTimeout);
    const navItem = document.getElementById(`main-nav-item-${itemIndex}`);
    if (!navItem) {
      return;
    }

    const subNav = document.getElementById(`subnav-${itemTitle}`);
    if (!subNav) {
      return;
    }

    navItem.classList.add("hidden-subnav");

    if (navItem.classList.contains("hidden-subnav")) {
      subNav.classList.add("hidden-subnav");
    }
    setHoverNavItemIndex(null);
    setHoverNavItemTitle(null);
  };

  const toggleMobileSubNav = (itemIndex: number, itemTitle: string): void => {
    const navItem = document.getElementById(`main-nav-item-${itemIndex}`);
    if (!navItem) {
      return;
    }

    if (navItem.classList.contains("sub-nav-active")) {
      collapseSubNav(itemIndex, itemTitle);
    } else {
      expandSubNav(itemIndex, itemTitle);
    }
  };

  const expandMobileNav = (): void => {
    document.body.style.overflow = "hidden";

    showMobileMenu();
    getLastFocusableEl();
  };

  const collapseMobileNav = (): void => {
    document.body.style.overflow = "";

    hideMobileMenu();
    disableOverlay();
  };

  // tab to first item in nav from the open menu button, set event listener for navlist so escape closes nav, set event listener on first link to focus on close button if shift and tab are pressed
  const tabToNav = (event: KeyboardEvent): void => {
    const firstNavItem: HTMLElement | null =
      document.getElementById("main-nav-item-0");

    if (event.key === "Tab" && event.shiftKey) {
      setMobileKeyDown(true);
      event.preventDefault();
      if (lastFocusableEl !== null) {
        lastFocusableEl.focus();
      }
    } else if (event.key === "Tab" && !event.shiftKey) {
      setMobileKeyDown(true);
      event.preventDefault();
      if (firstNavItem !== undefined && firstNavItem !== null) {
        firstNavItem.focus();
      }
    }
  };

  const escCloseMenu = (event: KeyboardEvent): void => {
    if (event.key === "Tab") {
      setMobileKeyDown(!mobileKeyDown);
    }
    if (event.key === "Enter") {
      setTimeout(() => {
        setMobileKeyDown(!mobileKeyDown);
      }, 400);
    }
    if (event.key === "Escape") {
      toggleMobileNav();
    }
  };

  const subNavMobileKeypressHandler = (
    event: KeyboardEvent<HTMLAnchorElement>,
    title: string
  ): void => {
    if (isMobile) {
      const closeButton: HTMLElement | null = document.getElementById(
        "close-mobile-nav-button"
      );
      if (
        event.key === "Tab" &&
        !event.shiftKey &&
        lastFocusableEl?.id === `header-sub-nav-item-link-${title}`
      ) {
        event.preventDefault();
        closeButton?.focus();
      }
    }
  };

  const mainNavMobileKeypressHandler = (
    event: KeyboardEvent<HTMLAnchorElement>,
    index: number,
    title: string
  ): void => {
    if (isMobile) {
      const closeButton: HTMLElement | null = document.getElementById(
        "close-mobile-nav-button"
      );
      if (
        event.target === document.getElementById(`mobile-toggle-${title}`) &&
        event.key === "Enter"
      ) {
        event.preventDefault();
        toggleMobileSubNav(index, title);
      }
      if (event.shiftKey && event.key === "Tab" && index === 0) {
        event.preventDefault();
        closeButton?.focus();
      }
      // const lastNavItem: HTMLElement | null = getLastFocusableEl();
      if (
        event.key === "Tab" &&
        !event.shiftKey &&
        lastFocusableEl?.id === `main-nav-item-${index}`
      ) {
        event.preventDefault();
        closeButton?.focus();
      }
      if (
        event.key === "Tab" &&
        !event.shiftKey &&
        lastFocusableEl?.id === `mobile-toggle-${title}`
      ) {
        event.preventDefault();
        closeButton?.focus();
      }
    }
  };

  const onNavItemClick = (itemIndex: number, itemTitle: string): void => {
    collapseSubNav(itemIndex, itemTitle);
    collapseMobileNav();
  };

  const toggleMobileNav = (): void => {
    if (mobileMenuOpen) {
      collapseMobileNav();
      if (hoverNavItemIndex && hoverNavItemTitle) {
        collapseSubNav(hoverNavItemIndex, hoverNavItemTitle);
      }
      const openButton = document.getElementById("open-mobile-nav-button");
      if (openButton) {
        setTimeout(() => {
          openButton.focus();
        }, 200);
      }
    } else {
      expandMobileNav();
      mobileCloseButton?.focus();
    }
  };

  const navHeader = useRef<HTMLDivElement>(null);

  const scrollListener = debounce((): void => {
    if (navHeader.current) {
      const currentScrollPosition = window.scrollY;
      const offsetTop = navHeader.current?.offsetTop;
      const navHeight = navHeader.current?.clientHeight;
      if ((offsetTop || offsetTop === 0) && (navHeight || navHeight === 0)) {
        _headerScroll((prevState) => {
          const newHeaderScroll: HeaderScroll = {
            headerIsFixed: currentScrollPosition > (navHeight + offsetTop + 75),
            headerIsActive: prevState.lastScrollPosition > currentScrollPosition && currentScrollPosition > (navHeight + offsetTop + 150),
            lastScrollPosition: currentScrollPosition
          };
          return newHeaderScroll;
        });
      }
      
      // setHeaderIsFixed(
      //   currentScrollPosition >
      //     navHeader.current?.clientHeight + navHeader.current?.offsetTop + 75
      // );
      // setHeaderIsActive(
      //   lastScrollPosition > currentScrollPosition &&
      //     currentScrollPosition >
      //       navHeader.current?.clientHeight + navHeader.current?.offsetTop + 150
      // );
      // setLastScrollPosition(currentScrollPosition);
    }
  }, 50);

  const getUserNav = () => {
    const userNavItems: NavigationProps[] = [
      {
        title: `${userDetails?.firstName} ${userDetails?.lastName[0]}.`,
        children: [
          {
            title: "My account",
            url: paths.MY_ACCOUNT,
          },
          {
            title: "My downloads",
            url: `${paths.ASSETS}${paths.MY_DOWNLOADS}`,
          },
          {
            title: "My uploads",
            url: "/assets/my-uploads",
          },
          {
            title: "My favorites",
            url: "/assets/my-favorites",
          },
          {
            title: "Saved searches",
            url: "/assets/saved-searches",
          },
          {
            title: "Restricted assets",
            url: "/assets/restricted-assets/my-requests",
          },
        ],
        external: true,
      },
    ];
    if (userDetails?.role.includes("administrator")) {
      userNavItems.splice(1, 0, {
        title: "Admin",
        children: [
          {
            title: "Assets",
            url: paths.ADMIN_ASSET_SEARCH,
          },
          {
            title: "General",
            children: [
              {
                title: "Emails",
                url: paths.ADMIN_EMAIL_TEMPLATES,
                external: false,
              },
              {
                title: "Filter",
                url: paths.ADMIN_FILTER_MANAGEMENT,
                external: false,
              },
              {
                title: "User",
                url: paths.ADMIN_USER_MANAGEMENT,
                external: false,
              },
            ],
            external: true,
          },
        ],
      });
    }
    setUserNavigation(userNavItems);
  };

  useEffect(() => {
    getUserNav();
  }, [userDetails]);

  useEffect(() => {
    let tempNav: any[] = JSON.parse(
      JSON.stringify(
        navigation.filter((nav) => nav.roles?.includes(userDetails?.role || ""))
      )
    );
    if (!privilegedUser(userDetails?.role)) {
      const index = tempNav.findIndex((nav) => nav.url === paths.ASSETS);
      if (index !== -1) {
        tempNav[index].children = [];
      }
    }
    if (!userHasPmAccess) {
      const toolsIndex = tempNav.findIndex((nav) => nav.title === "Tools");
      if (toolsIndex !== -1) {
        tempNav[toolsIndex].children = tempNav[toolsIndex].children.filter((childLink: NavigationProps) => childLink.title !== "Packaging Manager");
      }
    }
    if (
      tempNav.length &&
      userNavigation.length > 0 &&
      !tempNav.find((nav) => nav.title === "My Account")
    ) {
      tempNav.map((navItem) => mapNavigationItem(navItem));
      setMainNavigation(
        tempNav.concat([
          {
            mobileOnly: true,
            title: "My Account",
            children: userNavigation,
          },
        ])
      );
    }
  }, [navigation, userNavigation, userHasPmAccess]);

  function mapNavigationItem(item: NavigationProps): NavigationProps {
    if (!location.pathname) {
      return item;
    }

    item.active =
      location.pathname === item.url ||
      (item.url && location.pathname.startsWith(item.url)) ||
      item.children?.some((child) => child.url === location.pathname) ||
      false;

    item.children = item.children?.map((child) => {
      child.active = location.pathname + location.hash === child.url;
      return child;
    });

    return item;
  }

  useEffect(() => {
    if (
      location.pathname &&
      mainNavigation.length &&
      userNavigation.length > 0 &&
      mainNavigation.find((nav) => nav.title === "My Account")
    ) {
      let tempNav: NavigationProps[] = [...mainNavigation];
      tempNav.map((navItem) => mapNavigationItem(navItem));
      setMainNavigation(tempNav);
    }
  }, [location.pathname, location.hash]);

  useEffect(() => {
    window.addEventListener("scroll", scrollListener); // add event listener
    return () => {
      window.removeEventListener("scroll", scrollListener); // clean up
    };
  }, []);

  useEffect(() => {
    if (hoverNavItem === null || hoverSubNavItem === null) {
      return;
    }
    showSubNavTimeout = setTimeout(
      showSubNav,
      200,
      hoverNavItem,
      hoverSubNavItem
    );

    return () => clearTimeout(showSubNavTimeout);
  }, [hoverNavItem, hoverSubNavItem]);

  useEffect(() => {
    if (hoverNavItemIndex === null || hoverNavItemTitle === null) {
      return;
    }
    if (hoverNavItem === null || hoverSubNavItem === null) {
      hideSubNavTimeout = setTimeout(
        hideSubNav,
        500,
        hoverNavItemIndex,
        hoverNavItemTitle
      );
    }

    return () => clearTimeout(hideSubNavTimeout);
  }, [hoverNavItem, hoverSubNavItem, hoverNavItemIndex, hoverNavItemTitle]);

  const [searchOpen, _setSearchOpen] = useState(false),
    toggleSearch = () => {
      _setSearchOpen(!searchOpen);
    };

  return (
    <>
      <StyledNavBar id="brand-central-nav-bar">
        <div className={`overlay ${overlayIsActive ? "active" : ""}`}></div>
        <div className={`header-spacer ${headerScroll.headerIsFixed ? "active" : ""}`}></div>
        <div
          ref={navHeader}
          className={`nav-header ${headerScroll.headerIsFixed ? "fixed" : ""} ${
            headerScroll.headerIsActive ? "active" : ""
          }`}
        >
          <FlexBox row justify="center" className="nav" gap="1.25rem">
            <FlexBox>
              <Link to={paths.HOME} className="home-icon">
                <Image
                  src={verizonCheckmark}
                  className="logo"
                  width={33}
                  height={40}
                />
              </Link>
            </FlexBox>

            <FlexBox
              row
              flex="2"
              gap="2ch"
              className={`header-main-nav ${
                mobileMenuOpen && isMobile ? "mobile-nav-active" : ""
              } ${
                !mobileMenuOpen && isMobile ? "hidden-mobile-nav-active" : ""
              }`}
              id="header-main-nav"
              role={mobileMenuOpen ? "dialog" : ""}
              aria-modal={mobileMenuOpen ? "true" : null}
              aria-label={mobileMenuOpen ? "menu" : null}
            >
              {navigation.length > 0 && (
                <ul className="header-main-nav-list" onKeyDown={escCloseMenu}>
                  {mainNavigation.map((item, index) => (
                    <li
                      className={`header-main-nav-item ${
                        item.active ? "active" : ""
                      } ${item.mobileOnly ? "mobile-only" : ""}`}
                      onMouseEnter={
                        item.children?.length
                          ? () => !isMobile && expandSubNav(index, item.title)
                          : undefined
                      }
                      onMouseLeave={
                        item.children?.length
                          ? () => !isMobile && collapseSubNav(index, item.title)
                          : undefined
                      }
                      onFocus={
                        item.children?.length
                          ? () => !isMobile && expandSubNav(index, item.title)
                          : undefined
                      }
                      onBlur={
                        item.children?.length
                          ? () => !isMobile && collapseSubNav(index, item.title)
                          : undefined
                      }
                      key={item.title}
                    >
                      <div className="header-main-nav-item-container">
                        <FlexBox row justify="space-between">
                          {!item.url ? (
                            <Body
                              className="header-main-nav-item-link not-link"
                              id={`main-nav-item-${index}`}
                              tabIndex={!isMobile ? 0 : undefined}
                              onFocus={
                                item.children?.length
                                  ? () =>
                                      !isMobile &&
                                      expandSubNav(index, item.title)
                                  : undefined
                              }
                              onBlur={
                                item.children?.length
                                  ? () =>
                                      !isMobile &&
                                      collapseSubNav(index, item.title)
                                  : undefined
                              }
                              size="large"
                              color="#000000"
                            >
                              {item.title}
                            </Body>
                          ) : item.external ? (
                            <a
                              key={item.title}
                              id={`main-nav-item-${index}`}
                              onKeyDown={(event) =>
                                isMobile &&
                                mainNavMobileKeypressHandler(
                                  event,
                                  index,
                                  item.title
                                )
                              }
                              href={externalRoute(item.url)}
                              rel="external noopener noreferrer"
                              style={{ textDecoration: "none" }}
                              className="header-main-nav-item-link"
                              onClick={() => onNavItemClick(index, item.title)}
                              onFocus={
                                item.children?.length
                                  ? () =>
                                      !isMobile &&
                                      expandSubNav(index, item.title)
                                  : undefined
                              }
                              onBlur={
                                item.children?.length
                                  ? () =>
                                      !isMobile &&
                                      collapseSubNav(index, item.title)
                                  : undefined
                              }
                            >
                              <Body size="large" color="#000000">
                                {item.title}
                              </Body>
                            </a>
                          ) : (
                            <Link
                              key={item.title}
                              id={`main-nav-item-${index}`}
                              onKeyDown={(event) =>
                                isMobile &&
                                mainNavMobileKeypressHandler(
                                  event,
                                  index,
                                  item.title
                                )
                              }
                              to={item.url}
                              style={{ textDecoration: "none" }}
                              className="header-main-nav-item-link"
                              onClick={() => onNavItemClick(index, item.title)}
                              onFocus={
                                item.children?.length
                                  ? () =>
                                      !isMobile &&
                                      expandSubNav(index, item.title)
                                  : undefined
                              }
                              onBlur={
                                item.children?.length
                                  ? () =>
                                      !isMobile &&
                                      collapseSubNav(index, item.title)
                                  : undefined
                              }
                            >
                              <Body size="large" color="#000000">
                                {item.title}
                              </Body>
                            </Link>
                          )}
                          {/* {!item.url && } */}
                          {isMobile && item.children && (
                            <IconButton
                              name="down-caret"
                              surface="light"
                              color="#000000"
                              id={`mobile-toggle-${item.title}`}
                              // className="mobile-toggle"
                              onKeyDown={(event: any) =>
                                isMobile &&
                                mainNavMobileKeypressHandler(
                                  event,
                                  index,
                                  item.title
                                )
                              }
                              onClick={() =>
                                toggleMobileSubNav(index, item.title)
                              }
                              aria-label={
                                isSubNavOpen(index)
                                  ? `open sub menu for ${item.title}`
                                  : `close sub menu for ${item.title}`
                              }
                            />
                          )}
                        </FlexBox>

                        {item.children && (
                          <FlexBox
                            className="header-sub-nav hidden-subnav"
                            id={`subnav-${item.title}`}
                          >
                            <ul
                              className="header-sub-nav-list"
                              onMouseEnter={() =>
                                !isMobile && expandSubNav(index, item.title)
                              }
                              onMouseLeave={() =>
                                !isMobile && collapseSubNav(index, item.title)
                              }
                            >
                              {item.children?.map((child) => (
                                <li
                                  className={`header-sub-nav-item ${
                                    child.active ? "active" : ""
                                  }`}
                                  key={child.title}
                                  onFocus={() =>
                                    !isMobile && expandSubNav(index, item.title)
                                  }
                                  onBlur={() =>
                                    !isMobile &&
                                    collapseSubNav(index, item.title)
                                  }
                                  onMouseEnter={() => onLinkHover(child.title)}
                                  onMouseLeave={() =>
                                    onLinkHoverOut(child.title)
                                  }
                                >
                                  {child.url && (
                                    <Link
                                      to={child.url ? child.url : ""}
                                      id={`header-sub-nav-item-link-${child.title}`}
                                      className={`header-sub-nav-item-link ${
                                        isMobile && !isSubNavOpen(index)
                                          ? "hidden-link"
                                          : ""
                                      }`}
                                      style={{ textDecoration: "none" }}
                                      onKeyDown={(event) =>
                                        isMobile &&
                                        subNavMobileKeypressHandler(
                                          event,
                                          child.title
                                        )
                                      }
                                      tab-index={
                                        isMobile && !isSubNavOpen(index)
                                          ? "-1"
                                          : null
                                      }
                                      onClick={() =>
                                        onNavItemClick(index, item.title)
                                      }
                                      onFocus={() =>
                                        !isMobile &&
                                        expandSubNav(index, item.title)
                                      }
                                      onBlur={() =>
                                        !isMobile &&
                                        collapseSubNav(index, item.title)
                                      }
                                    >
                                      <Body
                                        id={`header-sub-nav-item-link-${child.title} `}
                                        className={`header-sub-nav-item-link ${
                                          isMobile && !isSubNavOpen(index)
                                            ? "hidden-link"
                                            : ""
                                        } ${child.active ? "active" : ""}`}
                                        size="large"
                                        color="#000000"
                                        // bold={child.active ? true : false}
                                      >
                                        {child.title}
                                      </Body>
                                    </Link>
                                  )}

                                  {!child.url && (
                                    <Body
                                      size="large"
                                      color="#000000"
                                      bold={child.active ? true : false}
                                    >
                                      {child.title}
                                    </Body>
                                  )}

                                  {child.children && (
                                    <FlexBox id={`subnav-${child.title}`}>
                                      <ul className="header-sub-nav-list">
                                        {child.children?.map((grandchild) => (
                                          <li
                                            className={`header-sub-nav-item ${
                                              grandchild.active ? "active" : ""
                                            }`}
                                            key={grandchild.title}
                                            onFocus={() =>
                                              !isMobile &&
                                              expandSubNav(index, child.title)
                                            }
                                            onBlur={() =>
                                              !isMobile &&
                                              collapseSubNav(index, child.title)
                                            }
                                            onMouseEnter={() =>
                                              onLinkHover(grandchild.title)
                                            }
                                            onMouseLeave={() =>
                                              onLinkHoverOut(grandchild.title)
                                            }
                                          >
                                            <Link
                                              to={
                                                grandchild.url
                                                  ? grandchild.url
                                                  : ""
                                              }
                                              id={`header-sub-nav-item-link-${grandchild.title}`}
                                              className={`header-sub-nav-item-link ${
                                                isMobile && !isSubNavOpen(index)
                                                  ? "hidden-link"
                                                  : ""
                                              }`}
                                              style={{ textDecoration: "none" }}
                                              onKeyDown={(event) =>
                                                isMobile &&
                                                subNavMobileKeypressHandler(
                                                  event,
                                                  grandchild.title
                                                )
                                              }
                                              tab-index={
                                                isMobile && !isSubNavOpen(index)
                                                  ? "-1"
                                                  : null
                                              }
                                              onClick={() =>
                                                onNavItemClick(
                                                  index,
                                                  child.title
                                                )
                                              }
                                              onFocus={() =>
                                                !isMobile &&
                                                expandSubNav(index, child.title)
                                              }
                                              onBlur={() =>
                                                !isMobile &&
                                                collapseSubNav(
                                                  index,
                                                  child.title
                                                )
                                              }
                                            >
                                              <Body
                                                size="large"
                                                color="#000000"
                                                bold={
                                                  grandchild.active
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {grandchild.title}
                                              </Body>
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </FlexBox>
                                  )}
                                </li>
                              ))}
                              {isMobile && item.title === "My Account" && (
                                <li
                                  id="sign-out"
                                  className="header-sub-nav-item"
                                >
                                  <StyledSignOutButton onClick={handleSignOut}>
                                    <Body
                                      color="#000000"
                                      className="sign-out-button-text"
                                      size="large"
                                    >
                                      Sign out
                                    </Body>
                                  </StyledSignOutButton>
                                </li>
                              )}
                            </ul>
                          </FlexBox>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </FlexBox>

            <FlexBox
              className="nav-utilities"
              row
              flex="0.95"
              align="center"
              gap="1rem"
              justify="flex-end"
              height="100%"
            >
              
              {privilegedUser(userDetails?.role) && (
                <span className="search-button-desktop-container">
                  <Button
                    size="small"
                    surface="light"
                    onClick={toggleSearch}
                    use="primary"
                    color="#ffffff"
                  ><FlexBox row gap="1rem" justify="center" align="center" className="search-icon-container">
                    <Body color="#ffffff" size="small">Search Brand Central</Body>
                    <Icon
                      name="search"
                      size="small"
                      color="#ffffff"
                      ariaLabel="Search"
                    />
                  </FlexBox>
                  
                  </Button>
                </span>
              )}
              
              <span className="submit-work">
                <LinkButton
                  external
                  className="submit-work"
                  to="https://www.verizonwireless.com/brandcompliance/"
                  label="Submit work"
                  surface="light"
                />
              </span>
              
              <StyledMenuWrapper
                onMouseEnter={showMenu}
                onMouseLeave={hideMenu}
              >
                <Icon
                  name="my-account"
                  size="large"
                  color="#000000"
                  ariaLabel="User Account"
                />
                <UserMenu className={`user-menu ${menuOpen ? "open" : ""}`}>
                  <ul
                    className="menu-list"
                    onFocus={showMenu}
                    onBlur={hideMenu}
                  >
                    {userNavigation?.map((item) => {
                      return (
                        <li key={item.title} className="item">
                          {item.url && (
                            <Link to={item.url} onClick={hideMenu}>
                              <Body color="#000000" size="large">
                                {item.title}
                              </Body>
                            </Link>
                          )}
                          {!item.url && (
                            <Body
                              color="#000000"
                              className="header"
                              size="large"
                            >
                              {item.title}
                            </Body>
                          )}
                          {item.children && (
                            <ul className="nested-menu">
                              {item.children?.map((child) => {
                                return (
                                  <li key={child.title} className="item nested">
                                    {child.url && (
                                      <Link to={child.url} onClick={hideMenu}>
                                        <Body color="#000000" size="large">
                                          {child.title}
                                        </Body>
                                      </Link>
                                    )}
                                    {!child.url && (
                                      <Body
                                        color="#000000"
                                        className="header nested"
                                        size="large"
                                      >
                                        {child.title}
                                      </Body>
                                    )}
                                    {child.children && (
                                      <ul className="nested-menu double-nested">
                                        {child.children?.map((child) => {
                                          return (
                                            <li
                                              key={child.title}
                                              className="item nested"
                                            >
                                              {child.url && (
                                                <Link
                                                  to={child.url}
                                                  onClick={hideMenu}
                                                >
                                                  <Body
                                                    color="#000000"
                                                    size="large"
                                                  >
                                                    {child.title}
                                                  </Body>
                                                </Link>
                                              )}
                                              {!child.url && (
                                                <Body
                                                  color="#000000"
                                                  className="header nested"
                                                  size="large"
                                                >
                                                  {child.title}
                                                </Body>
                                              )}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                    <li id="sign-out" className="item">
                      <StyledSignOutButton onClick={handleSignOut}>
                        <Body
                          color="#000000"
                          className="sign-out-button-text"
                          size="large"
                        >
                          Sign out
                        </Body>
                      </StyledSignOutButton>
                    </li>
                  </ul>
                </UserMenu>
              </StyledMenuWrapper>
            </FlexBox>

            {mobileMenuOpen ? (
              <FlexBox
                className="header-nav-button"
                row
                align="center"
                justify="flex-end"
                height="100%"
              >
                <IconButton
                  name="close"
                  surface="light"
                  color="#000000"
                  onClick={toggleMobileNav}
                  onKeyDown={tabToNav}
                  id="close-mobile-nav-button"
                  // ref={mobileCloseButton}
                  ariaLabel={mobileMenuOpen ? "close nav" : ""}
                  size="large"
                />
              </FlexBox>
            ) : (
              <FlexBox
                className="header-nav-button"
                row
                align="center"
                justify="flex-end"
                height="100%"
                gap="1rem"
              >
                {privilegedUser(userDetails?.role) && (
                  <IconButton
                    name="search"
                    size="large"
                    color="#000000"
                    surface="light"
                    onClick={toggleSearch}
                    ariaHidden
                  />
                )}
                <IconButton
                  name="menu"
                  surface="light"
                  color="#000000"
                  onClick={toggleMobileNav}
                  id="open-mobile-nav-button"
                  ariaLabel={!mobileMenuOpen ? "open nav" : ""}
                  size="large"
                />
              </FlexBox>
            )}
          </FlexBox>
        </div>
      </StyledNavBar>
      <GlobalSearch open={searchOpen} onClose={() => _setSearchOpen(false)} />
      {signingOut && <Loader active />}
    </>
  );
};

export default withVDSManager(NavBar);
