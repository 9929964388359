import Image from "components/layouts/Image/Image";
import Title from "components/typography/Title/Title";
import { verizonLogo } from "assets";
import { HeaderWrapper } from "./UnprotectedHeader.module";

const UnprotectedHeader = () => {
  
  return (
    <HeaderWrapper>
      <Image src={verizonLogo} width="13.5rem" />
      <Title size="XLarge" bold pd="3rem 0">
        Brand Central
      </Title>
    </HeaderWrapper>
  );
};

export default UnprotectedHeader;
