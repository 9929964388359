export default {
  EMPTY: "/",
  SIGNIN: "/signin",
  SIGNOUT: "/signout",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/resetPassword",
  AUTH: "/auth",
  HOME: "/home",
  GUIDELINES: "/guidelines/*",
  GUIDELINES_HOME: "/guidelines",
  PRINT_INDEX: "/guidelines/print-index",
  GUIDELINE_PAGE: "/guidelines/details/:guidelineSlug/:categorySlug/:subcategorySlug",
  MASTERBRAND_ELEMENTS: "/guidelines/details/masterbrand-elements/",
  PARENTBRAND_ELEMENTS: "/guidelines/details/parent-brand-elements/",
  COMPONENT: "/guidelines/component/:componentId",
  NOT_FOUND: "/guidelines/not-found",
  SERVICE: "/guidelines/error",
  PRINT_GUIDELINE: "/guidelines/print/:guidelineSlug",
  PRINT_CATEGORY: "/guidelines/print/:guidelineSlug/:categorySlug",
  ASSETS: "/assets",
  ASSET_DETAILS: "/details",
  ASSET_EDIT: "/edit",
  BULK_EDIT: "/bulk-edit",
  ASSET_UPLOAD: "/upload",
  ASSET_SEARCH: "/assets/search",
  ASSET_SEARCH_REDIRECT: "/assets/search/category/:categoryId",
  TEMPLATE_SAVED_SEARCH_REDIRECT: "/templates/search",
  TEMPLATES: "/templates",
  CONTACT_US: "/contact-us",
  BRAND_UPDATES: "/brand-updates",
  TRAINING: "/training",
  TRAINING_VIDEOS: "/training/videos",
  ADMIN: "/admin",
  ADMIN_ASSET_SEARCH: "/admin/asset-search",
  ADMIN_EMAIL_TEMPLATES: "/admin/email-templates",
  ADMIN_FILTER_MANAGEMENT: "/admin/filter-management",
  ADMIN_USER_MANAGEMENT: "/admin/user-management",
  USER_MANAGEMENT: "/user-management",
  USER_MANAGEMENT_EDIT: "/user-management-edit",
  PENDING_USER_MANAGEMENT: "/pending-user-management",
  PENDING_USER_MANAGEMENT_EDIT: "/pending-user-management-edit",
  GROUP_MANAGEMENT: "/group-management",
  GROUP_MANAGEMENT_EDIT: "/group-management-edit",
  MY_UPLOADS: "/my-uploads",
  MY_DOWNLOADS: "/my-downloads",
  MY_FAVORITES: "/my-favorites",
  SAVED_SEARCHES: "/saved-searches",
  RESTRICTED_ASSETS: "/restricted-assets",
  MY_REQUESTS: "/my-requests",
  MANAGE_REQUESTS: "/manage-requests",
  REQUEST_DETAILS: "/request-details",
  MY_ACCOUNT: "/account/profile",
  FEEDBACK: "/feedback",
  COMPLIANCE_OVERVIEW: "/compliance",
  COMPLIANCE_PROCESS: "/compliance/process",
  COMPLIANCE_SCORING: "/compliance/scoring",
  COMPLIANCE_RESOURCES: "/compliance/resources",
  COMPLIANCE_FAQS: "/compliance/faqs",
  FILE_COURIER: "/file-courier",
  FILE_COURIER_SENT: "/file-courier/sent",
  FILE_COURIER_RECEIVED: "/file-courier/received",
  EMAIL_TEMPLATES: "/email-templates",
  RETAIL: "/retail/*",
  RETAIL_CENTER: "/retail",
  RETAIL_DESIGN_GUIDE: "/retail/branded-environments",
  RETAIL_DESIGN_ADVISORIES: "/retail/branded-environments/design-advisories",
  RETAIL_GOVERNANCE: "/retail-governance",
  RETAIL_SUPPORT: "/support",
  RETAIL_TEMPLATES_TOOLS: "/retail/templates-and-tools",
  RETAIL_GOVERNANCE_AUDITS_REPORTS: "/retail/retail-governance/reports",
  RETAIL_CONTACT_US: "/retail/support/contact-us",
  RETAIL_TOOLS: "/retail/templates-and-tools/tools",
  DOWNLOAD_REQUESTS: "/vzso-bc-access-requests-test-0/*",
  PACKAGING_MANAGER: "/packaging-manager/*",
  PACKAGING_MANAGER_PROJECTS: "/packaging-manager/dashboard",
  PACKAGING_MANAGER_REPORTING: "/packaging-manager/reporting",
  PACKAGING_MANAGER_PROJECT: "/packaging-manager/project/*",
  PACKAGING_MANAGER_PROJECT_HOME: "/packaging-manager/project/home",
  PACKAGING_MANAGER_PROJECT_TASKS: "/packaging-manager/project/tasks",
  PACKAGING_MANAGER_PROJECT_BRIEFS: "/packaging-manager/project/briefs",
  PACKAGING_MANAGER_PROJECT_FILES: "/packaging-manager/project/files",
  PACKAGING_MANAGER_PROJECT_TEAMS: "/packaging-manager/project/teams",
  PACKAGING_MANAGER_PROJECT_TEAMS_EDIT: "/edit-teams",
  PACKAGING_MANAGER_PROJECT_TASK_DETAILS: "/task-details",
  PACKAGING_MANAGER_PROJECT_TASK_EDIT: "/task-edit",
  PACKAGING_MANAGER_PROJECT_TASK_CREATE: "/task-create",
  PACKAGING_MANAGER_PROJECT_FILES_EDIT: "/file-edit",
  PACKAGING_MANAGER_PROJECT_BRIEF_EDIT: "/brief-edit",
  PACKAGING_MANAGER_PROJECT_BRIEF_PREVIEW: "/brief-preview",
};
