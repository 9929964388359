import { observable, makeObservable } from "mobx";

export default class SubSectionHeader {
  id = null;
  type = null;
  titleSize = null;
  typographyID = null;
  title = null;
  description = null;
  CTAButtons = null;
  additionalPaddingBelowComponent = null;
  anchorLinkTarget = null;
  headerStyle = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      titleSize: observable,
      typographyID: observable,
      title: observable,
      description: observable,
      CTAButtons: observable,
      additionalPaddingBelowComponent: observable,
      anchorLinkTarget: observable,
      headerStyle: observable
    });

    this.id = props.id;
    this.type = props.type;
    this.titleSize = props.titleSize;
    this.typographyID = props.typographyID;
    this.title = props.title;
    this.description = props.description;
    this.CTAButtons = props.CTAButtons;
    this.additionalPaddingBelowComponent =
      props.additionalPaddingBelowComponent;
    this.anchorLinkTarget = props.anchorLinkTarget;
    this.headerStyle = props.headerStyle;
  }
}
