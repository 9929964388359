import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Icon } from "@vds/icons";
import {
  Body, Button,
  Checkbox,
  FlexBox,
  Loader,
  Title,
} from "components";
import { useEffect, useRef, useState } from "react";
import {useLazyGetAllProjectsQuery} from "services/api/api.slice";
import {PackagingManagerGroupTypeFilterResponse, PackagingManagerProjectSearchResults, PackagingManagerProjectTypeFilterResponse, PackagingManagerStatusFilterResponse, ProjectsSearchForm} from "interfaces";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import {PackagingManagerGroupFilterResponse} from "../../interfaces/PackagingManager";
import {Sort} from "../../views/PackagingManagerProjects/PackagingManagerProjects";
import Modal from "../../components/modals/Modal/Modal";


export interface MobileFilterSettingsProps {
  selectedStatus: Array<string>;
  selectedGroups: Array<string>;
  selectedProjects: Array<string>;
}
export interface MobilePackagingFilterProps {
  mobileFilters: MobileFilterSettingsProps;
  search: string;
  page: number;
  pageSize: number;
  sort: Sort;
  setResponse: Function;
  onClose: Function;
  initialResponse: PackagingManagerProjectSearchResults;
  setPage: Function;
}

export type CategoryOption = {
  label: string;
  value: number;
}

export type SelectedTagProps = {
  id: string;
  value: string;
}

const MobilePackagingFilter = ({
  pageSize,
  sort,
  search,
  setResponse,
  page,
  onClose,
  mobileFilters,
  initialResponse,
  setPage,
}: MobilePackagingFilterProps) => {
  
  const[selectedStatus, _selectedStatus] = useState<Array<string>>(mobileFilters.selectedStatus.map((status) => status));
  const [selectedGroups, _selectedGroups] = useState<Array<string>>(mobileFilters.selectedGroups.map((group) => group));
  const [selectedProjects, _selectedProjects] = useState<Array<string>>(mobileFilters.selectedProjects.map((project) => project));
  const [pageNumber, _pageNumber] = useState<number>(page);
  const [isLoading, _isLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  
  const [projectsResponse, _projectsResponse] = useState<PackagingManagerProjectSearchResults>(initialResponse);
  const [searchProjects, {data: projectsResponseData, isFetching: projectsFetching, isLoading: projectsLoading}] = useLazyGetAllProjectsQuery();
  
  const setProjectsResponse = (newResponse: PackagingManagerProjectSearchResults) => {
    _projectsResponse(
      {
        results: newResponse.results.map((result) => result),
        filters: {
          unfilteredTotal: newResponse.filters.unfilteredTotal,
          statusFilterResponse: newResponse.filters.statusFilterResponse.map((filter) => filter),
          groupTypeFilterResponse: newResponse.filters.groupTypeFilterResponse.map((filter) => filter),
          projectTypeFilterResponse: newResponse.filters.projectTypeFilterResponse.map((filter) => filter),
        },
        total: newResponse.total,
        totalPages: newResponse.totalPages,
      });
  };
  
  const callSearchProjects = () => {
    _isLoading(true);
    const query: ProjectsSearchForm = {
      page: pageNumber,
      pageSize: pageSize,
      sortOrder: sort.sortOrder,
      sortOn: sort.sortOn,
      q: search,
      statusFilters: selectedStatus.length > 0 ? selectedStatus.map((status) => status) : [],
      projectTypeFilters: selectedProjects.length > 0 ? selectedProjects.map((project) => project) : [],
      groupFilters: selectedGroups.length > 0 ? selectedGroups.map((group) => group) : [],
    };
    searchProjects(query)
    .unwrap()
    .then((response: any) => {
    })
    .catch((error: any) => {
      _isLoading(false);
      dispatch(
        setNotification({
          type: "error",
          message: "Error fetching projects.",
        })
      );
      console.error(error);
    });
  };
  
  useEffect(() => {
    if (projectsResponse) {
      setResponse(projectsResponse);
      _isLoading(false);
    }
  }, [projectsResponse]);
  
  
  useEffect(() => {
    if (projectsResponseData) {
      setProjectsResponse(projectsResponseData);
    }
    
  }, [projectsResponseData]);
  
  
  const [statusExpanded, _statusExpanded] = useState<boolean>(false);
  const [projectExpanded, _projectExpanded] = useState<boolean>(false);
  const [expandedGroupTypeIds, _expandedGroupTypeIds] = useState<Array<string>>([]);
  
  const handleExpandGroupTypes = (groupId: string) => {
    if (expandedGroupTypeIds.includes(groupId)) {
      _expandedGroupTypeIds((prevState) => prevState.filter((groupTypeId) => groupTypeId !== groupId));
    } else {
      _expandedGroupTypeIds((prevState) => {
        return [...prevState.map((groupTypeId) => groupTypeId), groupId]
      });
    }
  };
  const handleExpandProjects = () => {
    _projectExpanded((prevState) => !prevState);
  };
  
  const handleExpandStatus = () => {
    _statusExpanded((prevState) => !prevState);
  };
  
  const handleGroupSelectionChange = (option: PackagingManagerGroupFilterResponse) => {
    if (selectedGroups.length > 0 && selectedGroups.find((selected) => selected === option.id)) {
      _selectedGroups((prevOptions) => {
        return prevOptions.filter((prevOption) => prevOption !== option.id);
      });
    } else {
      _selectedGroups((prevOptions) => {
        return [...prevOptions.map((prevOption) => prevOption), option.id];
      });
    }
  };
  
  const handleProjectSelectionChange = (option: PackagingManagerProjectTypeFilterResponse) => {
    if (selectedProjects.length > 0 && selectedProjects.find((selected) => selected === option.id)) {
      _selectedProjects((prevOptions) => {
        return prevOptions.filter((prevOption) => prevOption !== option.id);
      });
    } else {
      _selectedProjects((prevOptions) => {
        return [...prevOptions.map((prevOption) => prevOption), option.id];
      });
    }
  };
  
  // // need to console log here and test to figure out when or if this gets called - tested but I do not think it can happen because the only time the page can change is if the pageNumber changes first due to changing a filter while on page 2, then the page gets set to the same thing which is 1
  // useEffect(() => {
  //   if (page !== pageNumber) {
  //     console.log('page does not equal pageNumber');
  //     _pageNumber(page);
  //   }
  // }, [page]);
  
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (pageNumber !== 1) {
      _pageNumber(1);
      setPage(1);
    } else {
      callSearchProjects();
    }
  }, [selectedStatus, selectedGroups, selectedProjects]);
  
  const firstLoad = useRef(true);
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    callSearchProjects();
  }, [pageNumber]);
  const handleStatusSelectionChange = (option: PackagingManagerStatusFilterResponse) => {
    if (selectedStatus.length > 0 && selectedStatus.find((selected) => selected === option.name)) {
      
      _selectedStatus((prevOptions) => {
        return prevOptions.filter((prevOption) => prevOption !== option.name);
      });
    } else {
      _selectedStatus((prevOptions) => {
        return [...prevOptions.map((prevOption) => prevOption), option.name];
      });
    }
  };
  
  const handleCLoseFilters = () => {
    onClose({
      selectedStatus: selectedStatus.map((status) => status),
      selectedGroups: selectedGroups.map((group) => group),
      selectedProjects: selectedProjects.map((project) => project),
    });
  };
  
  return (
    projectsResponse  ? (
      
      <Modal testId="mobile-packaging-filter-modal" open onClose={handleCLoseFilters}>
          <Title pd="4.5rem 0 2.5rem" size="large">
            Filters
          </Title>
        
        {isLoading ? (
            <Loader containerHeight="20rem" active />
          ) : (
          <FlexBox minWidth="100%">
          {(projectsResponse.filters.statusFilterResponse && projectsResponse.filters.statusFilterResponse.length > 0) && (
            <Accordion
              onChange={handleExpandStatus}
              expanded={statusExpanded && !(projectsLoading || projectsFetching)}
              disableGutters
              square
              data-testid="status-filters"
              className="first"
            >
              <AccordionSummary
                expandIcon={<Icon name="down-caret" />}
              >
                <Body size="large">
                  {`Status${selectedStatus.length > 0 ? ` (${selectedStatus.length})` : ''}`}
                </Body>
              </AccordionSummary>
              <AccordionDetails>
                <FlexBox>
                  {(!projectsFetching && !projectsLoading) && (
                    <FlexBox row gap="0.5rem" wrap>
                      {projectsResponse.filters.statusFilterResponse.map((status: PackagingManagerStatusFilterResponse) => (
                        <Checkbox
                          key={status.name}
                          onChange={() =>
                            handleStatusSelectionChange(status)
                          }
                          selected={selectedStatus.includes(status.name)}
                          required={false}
                        >
                          <Body bold>{status.name === "in-progress" ? "In progress" : status.name === "completed" ? "Completed" : "New"}</Body>
                        </Checkbox>
                      ))}
                    
                    </FlexBox>
                  )}
                </FlexBox>
              </AccordionDetails>
            </Accordion>
          )}
          {(!projectsFetching && !projectsLoading) && (
            <>
              {projectsResponse.filters.groupTypeFilterResponse && projectsResponse.filters.groupTypeFilterResponse.length > 0 && projectsResponse.filters.groupTypeFilterResponse.map((groupType: PackagingManagerGroupTypeFilterResponse) => (
                groupType.groups.length > 0 ? (
                  <Accordion
                    key={groupType.id}
                    onChange={() => handleExpandGroupTypes(groupType.id)}
                    expanded={expandedGroupTypeIds.includes(groupType.id) && !(projectsLoading || projectsFetching)}
                    disableGutters
                    square
                    data-testid="group-filters-accordion"
                  >
                    <AccordionSummary
                      expandIcon={<Icon name="down-caret" />}
                    >
                      <Body size="large">
                        {`${groupType.name}${selectedGroups.filter((group) => {
                          if (groupType.groups.find((g) => g.id === group)) {
                            const matchingGroup = groupType.groups.find((g) => g.id === group);
                            return matchingGroup;
                        }
                        }).length > 0 ? ` (${selectedGroups.filter((group) => {
                          if (groupType.groups.find((g) => g.id === group)) {
                            const matchingGroup = groupType.groups.find((g) => g.id === group);
                            return matchingGroup;
                          }
                        }).length})` : ''}`}
                      </Body>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FlexBox gap="1rem">
                        {groupType.groups.map((group: PackagingManagerGroupFilterResponse) => (
                          <Checkbox
                            key={group.id}
                            onChange={() =>
                              handleGroupSelectionChange(group)
                            }
                            selected={selectedGroups.includes(group.id)}
                            required={false}
                          >
                            <Body bold>{group.name}</Body>
                          </Checkbox>
                        ))}
                      </FlexBox>
                    </AccordionDetails>
                  </Accordion>
                ) : <></>
              ))}
            </>
          )}
          
          {projectsResponse.filters.projectTypeFilterResponse && projectsResponse.filters.projectTypeFilterResponse.length > 0 ? (
            <Accordion
              disableGutters
              onChange={handleExpandProjects}
              expanded={projectExpanded && !(projectsLoading || projectsFetching)}
              square
              data-testid="project-filters-accordion"
            >
              <AccordionSummary
                expandIcon={<Icon name="down-caret" />}
              >
                <Body size="large">
                  {`Projects${selectedProjects.length > 0 ? ` (${selectedProjects.length})` : ''}`}
                </Body>
              </AccordionSummary>
              <AccordionDetails>
                {(!projectsFetching && !projectsLoading) && (
                  <FlexBox gap="1rem">
                    {projectsResponse.filters.projectTypeFilterResponse.map((project: PackagingManagerProjectTypeFilterResponse) => (
                      <Checkbox
                        key={project.id}
                        onChange={() =>
                          handleProjectSelectionChange(project)
                        }
                        selected={selectedProjects.includes(project.id)}
                        required={false}
                      >
                        <Body bold>{project.name}</Body>
                      </Checkbox>
                    ))}
                  </FlexBox>
                )}
              </AccordionDetails>
            </Accordion>
          ) : <></>
          }
          </FlexBox>
          )}
        <div style={{width: "100%", paddingTop: "4rem"}}>
          <Button width="100%" use="primary" disabled={isLoading || projectsLoading || projectsFetching} onClick={handleCLoseFilters}>Done</Button>
        </div>
    </Modal>
  ) : <></>
  );
};

export default MobilePackagingFilter;
