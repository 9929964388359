import { observable, makeObservable } from "mobx";

export default class ContentBlock {
  id = null;
  type = null;
  defaultTypography = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      defaultTypography: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id;
    this.type = props.type;
    this.content = props.content;
    this.defaultTypography = props.defaultTypography;
    this.additionalPaddingBelowComponent =
      props.additionalPaddingBelowComponent;
  }
}
