import { observable, makeObservable } from 'mobx';

export default class FooterText {

  id = "";
  title = "";

  constructor(props) {
    makeObservable(this, {
      id: observable,
      title: observable
    });

    this.id = props.id
    this.title = props.title
  }
}