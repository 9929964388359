import { observable, makeObservable } from 'mobx';

export default class BeforeAndAfter {
  id = null;
  type = null;
  isFullWidth = null;
  beforeToggleLabel = null;
  beforeDescription = null;
  beforeImage = null;
  beforeImageAltText = null;
  afterToggleLabel = null;
  afterDescription = null;
  afterImage = null;
  afterImageAltText = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      isFullWidth: observable,
      beforeToggleLabel: observable,
      beforeDescription: observable,
      beforeImage: observable,
      beforeImageAltText: observable,
      afterToggleLabel: observable,
      afterDescription: observable,
      afterImage: observable,
      afterImageAltText: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id
    this.type = props.type
    this.isFullWidth = props.isFullWidth
    this.beforeToggleLabel = props.beforeToggleLabel
    this.beforeDescription = props.beforeDescription
    this.beforeImage = props.beforeImage
    this.beforeImageAltText = props.beforeImageAltText
    this.afterToggleLabel = props.afterToggleLabel
    this.afterDescription = props.afterDescription
    this.afterImage = props.afterImage
    this.afterImageAltText = props.afterImageAltText
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
  }
}
