import { observable, makeObservable } from 'mobx';

export default class DoHeader {
  id = null;
  type = null;
  title = null;
  removeBottomPadding = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      title: observable,
      removeBottomPadding: observable
    });

    this.id = props.id
    this.type = props.type
    this.title = props.title
    this.removeBottomPadding = props.removeBottomPadding
  }
}
