import { observable, makeObservable } from 'mobx';

export default class SectionFooter {

  id = null;
  type = null;
  text = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      text: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id
    this.type = props.type
    this.text = `&copy; ${new Date().getFullYear()} Verizon`
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
  }
}