import { observable, makeObservable } from 'mobx';

export default class Table {

  id = null;
  type = null;
  header = null;
  rows = null;
  description = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      header: observable,
      rows: observable,
      description: observable
    });

    this.id = props.id
    this.type = props.type
    this.header = props.header
    this.rows = props.rows
    this.description = props.description
  }
} 