import { observable, makeObservable } from 'mobx';

export default class Grid25_75 {

  id = null;
  type = null;
  leftSide = null;
  rightSide = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      leftSide: observable,
      rightSide: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id
    this.type = props.type
    this.leftSide = props.leftSide
    this.rightSide = props.rightSide
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
  }
}