import _ from 'lodash'

import COMPONENT_CONSTANTS from './COMPONENT_CONSTANTS'
import SectionHeader from './SectionHeader'
import InterSectionHeader from './InterSectionHeader'
import CautionHeader from './CautionHeader'
import DoHeader from './DoHeader'
import DontHeader from './DontHeader'
import SectionFooter from './SectionFooter'
import Image from './Image'
import Grid50_50 from './Grid50_50'
import Grid25_75 from './Grid25_75'
import Grid75_25 from './Grid75_25'
import Grid33_33_33 from './Grid33_33_33'
import Grid_5UP from './Grid_5UP'
import LinkImage from './LinkImage'
import TextBlock from './TextBlock'
import ContainerInvertThemeToDark from './ContainerInvertThemeToDark'
import ImageGridVersion1 from './ImageGridVersion1'
import ImageGridVersion2 from './ImageGridVersion2'
import ImageGridVersion3 from './ImageGridVersion3'
import ImageGridVersion4 from './ImageGridVersion4'
import ImageGridVersion5 from './ImageGridVersion5'
import Table from './Table'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import TableCell from './TableCell'
import Animation from './Animation'
import AnimationMobileAndDesktopCompare from './AnimationMobileAndDesktopCompare'
import Hero from './Hero'
import VideoPlayer from './VideoPlayer'
import VZButton from './VZButton'
import ContentBlock from './ContentBlock'
import BeforeAndAfter from './BeforeAndAfter'
import ColorSwatch from './ColorSwatch'
import FlexGrid from './FlexGrid'
import FlexGridColumn from './FlexGridColumn'

const ComponentDataFactory = {
  parseComponentData(componentData) {
    switch (componentData.type) {
      case COMPONENT_CONSTANTS.TYPES.HERO:
        return new Hero({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.CONTAINER_INVERT_THEME_TO_DARK:
        return new ContainerInvertThemeToDark({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.SECTION_HEADER:
        return new SectionHeader({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.IMAGE:
        return new Image({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.INTER_SECTION_HEADER:
        return new InterSectionHeader({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.CAUTION_HEADER:
        return new CautionHeader({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.DO_HEADER:
        return new DoHeader({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.DONT_HEADER:
        return new DontHeader({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.SECTION_FOOTER:
        return new SectionFooter({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.GRID_50_50:
      case COMPONENT_CONSTANTS.TYPES.GRID_25_75:
      case COMPONENT_CONSTANTS.TYPES.GRID_75_25:
        const gridData = {
          id: componentData.id,
          type: componentData.type,
          leftSide: [],
          rightSide: [],
          additionalPaddingBelowComponent:
            componentData.additionalPaddingBelowComponent,
        }

        _.each(componentData.leftSide, leftSideData => {
          if (!leftSideData) return

          gridData.leftSide.push(
            ComponentDataFactory.parseComponentData(leftSideData),
          )
        })

        _.each(componentData.rightSide, rightSideData => {
          if (!rightSideData) return

          gridData.rightSide.push(
            ComponentDataFactory.parseComponentData(rightSideData),
          )
        })

        if (componentData.type === COMPONENT_CONSTANTS.TYPES.GRID_50_50)
          return new Grid50_50({
            ...gridData,
          })
        else if (componentData.type === COMPONENT_CONSTANTS.TYPES.GRID_25_75)
          return new Grid25_75({
            ...gridData,
          })
        else
          return new Grid75_25({
            ...gridData,
          })

      case COMPONENT_CONSTANTS.TYPES.GRID_33_33_33:
        const componentDataObject = {
          id: componentData.id,
          type: componentData.type,
          leftSide: [],
          middle: [],
          rightSide: [],
          additionalPaddingBelowComponent:
            componentData.additionalPaddingBelowComponent,
        }

        _.each(componentData.leftSide, leftSideData => {
          if (!leftSideData) return

          componentDataObject.leftSide.push(
            ComponentDataFactory.parseComponentData(leftSideData),
          )
        })

        _.each(componentData.middle, middleData => {
          if (!middleData) return

          componentDataObject.middle.push(
            ComponentDataFactory.parseComponentData(middleData),
          )
        })

        _.each(componentData.rightSide, rightSideData => {
          if (!rightSideData) return

          componentDataObject.rightSide.push(
            ComponentDataFactory.parseComponentData(rightSideData),
          )
        })

        return new Grid33_33_33({
          ...componentDataObject,
        })

      case COMPONENT_CONSTANTS.TYPES.GRID_5UP:
        const layoutData = {
          id: componentData.id,
          type: componentData.type,
          column1: [],
          column2: [],
          column3: [],
          column4: [],
          column5: [],
          additionalPaddingBelowComponent:
            componentData.additionalPaddingBelowComponent,
        }

        _.each(componentData.column1, column1Data => {
          if (!column1Data) return

          layoutData.column1.push(
            ComponentDataFactory.parseComponentData(column1Data),
          )
        })

        _.each(componentData.column2, column2Data => {
          if (!column2Data) return

          layoutData.column2.push(
            ComponentDataFactory.parseComponentData(column2Data),
          )
        })

        _.each(componentData.column3, column3Data => {
          if (!column3Data) return

          layoutData.column3.push(
            ComponentDataFactory.parseComponentData(column3Data),
          )
        })

        _.each(componentData.column4, column4Data => {
          if (!column4Data) return

          layoutData.column4.push(
            ComponentDataFactory.parseComponentData(column4Data),
          )
        })

        _.each(componentData.column5, column5Data => {
          if (!column5Data) return

          layoutData.column5.push(
            ComponentDataFactory.parseComponentData(column5Data),
          )
        })

        return new Grid_5UP({
          ...layoutData,
        })

      case COMPONENT_CONSTANTS.TYPES.LINK_IMAGE:
        const linkImageData = {
          id: componentData.id,
          type: componentData.type,
          image: componentData.image
            ? ComponentDataFactory.parseComponentData(componentData.image)
            : null,
          title: componentData.title,
          titleSize: componentData.titleSize,
          description: componentData.description,
          url: componentData.url,
          linkType: componentData.linkType,
          additionalPaddingBelowComponent:
            componentData.additionalPaddingBelowComponent,
        }

        return new LinkImage({
          ...linkImageData,
        })

      case COMPONENT_CONSTANTS.TYPES.TEXT_BLOCK:
        return new TextBlock({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.IMAGE_GRID_VERSION_1:
        return new ImageGridVersion1({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.IMAGE_GRID_VERSION_2:
        return new ImageGridVersion2({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.IMAGE_GRID_VERSION_3:
        return new ImageGridVersion3({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.IMAGE_GRID_VERSION_4:
        return new ImageGridVersion4({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.IMAGE_GRID_VERSION_5:
        return new ImageGridVersion5({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.TABLE:
        const headerCells = []
        const rows = []

        _.each(componentData.header.cells, cell => {
          headerCells.push(
            new TableCell({
              id: cell.id,
              content: cell.content,
            }),
          )
        })

        _.each(componentData.tableRows, row => {
          const cells = []

          _.each(row.cells, cell => {
            cells.push(
              new TableCell({
                id: cell.id,
                content: cell.content,
              }),
            )
          })

          rows.push(
            new TableRow({
              id: row.id,
              cells,
            }),
          )
        })

        return new Table({
          id: componentData.id,
          type: componentData.type,
          header: new TableHeader({
            id: componentData.header.id,
            cells: headerCells,
          }),
          rows,
          description: componentData.description,
        })

      case COMPONENT_CONSTANTS.TYPES.ANIMATION:
        return new Animation({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.ANIMATION_MOBILE_AND_DESKTOP_COMPARE:
        return new AnimationMobileAndDesktopCompare({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.VIDEO_PLAYER:
        return new VideoPlayer({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.VZBUTTON:
        return new VZButton({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.CONTENT_BLOCK:
        return new ContentBlock({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.BEFORE_AND_AFTER:
        return new BeforeAndAfter({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.COLOR_SWATCH:
        return new ColorSwatch({
          ...componentData,
        })

      case COMPONENT_CONSTANTS.TYPES.FLEX_GRID_COLUMN:
        const columnData = {
          id: componentData.id,
          type: componentData.type,
          content: [],
          textAlignment: componentData.textAlignment,
          dynamicWidth: componentData.dynamicWidth,
          fullWidth: componentData.fullWidth,
        }

        _.each(componentData.content, contentData => {
          if (!contentData) return

          columnData.content.push(
            ComponentDataFactory.parseComponentData(contentData),
          )
        })

        return new FlexGridColumn({
          ...columnData,
        })

      case COMPONENT_CONSTANTS.TYPES.FLEX_GRID:
        const flexGridData = {
          id: componentData.id,
          type: componentData.type,
          header: [],
          columns: [],
        }

        _.each(componentData.header, headerData => {
          if (!headerData) return

          flexGridData.header.push(
            ComponentDataFactory.parseComponentData(headerData),
          )
        })

        _.each(componentData.columns, columnData => {
          if (!columnData) return

          flexGridData.columns.push(
            ComponentDataFactory.parseComponentData(columnData),
          )
        })

        return new FlexGrid({
          ...flexGridData,
        })

      default:
        return
    }
  },
}

export default ComponentDataFactory
