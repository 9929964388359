import {useState} from "react";
import {Button, FlexBox, Modal, Title, Loader} from "components";

import {FileCreateForm} from "../../interfaces/PackagingManager";
import {useS3WorkflowUpload} from "../../hooks";
import Body from "../../components/typography/Body/Body";
import FileUploadButton from "../../components/buttons/FileUploadButton/FileUploadButton";



export interface UploadNewPackagingManagerFileModalProps {
  onClose: Function;
  onComplete: Function;
}


const UploadNewPackagingManagerFileModal = ({onClose, onComplete}: UploadNewPackagingManagerFileModalProps) => {
  
  
  const [fileToUpload, _fileToUpload] = useState<FileCreateForm | null>(null);
  
  const { uploadS3, loading } = useS3WorkflowUpload();
  
  const handleClose = () => {
    onClose();
  };
  
  const handleSuccess = (file: FileCreateForm) => {
    _fileToUpload({
      fileId: file.fileId,
      name: file.name,
      extension: file.extension,
      mimeType: file.mimeType,
      sizeInBytes: file.sizeInBytes,
    });
  };
  
  const handleUpload = (file: File) => {
    if (fileToUpload) {
      _fileToUpload(null);
    }
    uploadS3(file, handleSuccess);
  };
  
  const handleComplete = () => {
    if (fileToUpload) {
      onComplete(fileToUpload);
    }
  };
  
  return (
    <Modal testId="upload-new-file-modal" open onClose={handleClose}>
      
      <FlexBox minWidth="100%">
        <Title size="large" bold pd="0 0 2rem">
          Upload a new file
        </Title>
        
        {loading ? (
            <Loader containerHeight="12rem" active />
          ) :
          <>
          
          {fileToUpload && (
            <>
              <Title pd="1.25rem 0 0" size="medium" bold>
                Selected file:
              </Title>
              <FlexBox pd="0 0 3.75rem" gap="1rem">
                <Body size="large" pd="0.75rem 0 1.25rem">{fileToUpload.name}</Body>
                <FileUploadButton testId="select-different-button" size="medium" caretPosition="right" caret onClick={(newFile: File) => handleUpload(newFile)}>Select a different file</FileUploadButton>
              </FlexBox>
            </>
            )}
            
            <FlexBox row gap="1.5rem" align="center" justify="flex-start">
              {!fileToUpload && (
                <FileUploadButton
                  use="primary"
                  testId="upload-button"
                  onClick={(newFile: File) => handleUpload(newFile)}
                  disabled={ loading }
                >
                  Choose file
                </FileUploadButton>
              )}
              
              {fileToUpload && (
                <Button
                  use="primary"
                  data-testid="submit-button"
                  onClick={handleComplete}
                  disabled={ loading || !fileToUpload}
                >
                  Confirm upload file
                </Button>
              )}
              
              <Button use="secondary" data-testid="cancel-button" onClick={handleClose} disabled={loading}>
                Cancel
              </Button>
            </FlexBox>
          </>
        }
      
      </FlexBox>
    
    
    </Modal>
  );
};

export default UploadNewPackagingManagerFileModal;
