import { observable, makeObservable } from "mobx";

export default class Grid_5UP {
  id = null;
  type = null;
  column1 = null;
  column2 = null;
  column3 = null;
  column4 = null;
  column5 = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      column1: observable,
      column2: observable,
      column3: observable,
      column4: observable,
      column5: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id;
    this.type = props.type;
    this.column1 = props.column1;
    this.column2 = props.column2;
    this.column3 = props.column3;
    this.column4 = props.column4;
    this.column5 = props.column5;
    this.additionalPaddingBelowComponent =
      props.additionalPaddingBelowComponent;
  }
}
