import { observable, makeObservable } from 'mobx';

export default class FlexGrid {
  id = null;
  type = null;
  header = null;
  columns = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      header: observable,
      columns: observable
    });

    this.id = props.id
    this.type = props.type
    this.header = props.header
    this.columns = props.columns
  }
}
