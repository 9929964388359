import queryString from 'query-string'
import { createClient } from 'contentful'
import _ from 'lodash'

import DataParser from './DataParser'

import STORE_CONSTANTS from './../store/STORE_CONSTANTS'
import { GUIDELINES_CONTENTFUL_DELIVERY_API_KEY, GUIDELINES_CONTENTFUL_ENVIRONMENT, GUIDELINES_CONTENTFUL_SPACEID } from '../../utils/environmentVariables';

const DATA_CACHE = {}

//eslint-disable-next-line
const preview = queryString.parse(location.search).preview ? true : false

let contentfulClient

const DataService = {
  setupContentful: () => {
    return new Promise((resolve, reject) => {

      const spaceID = GUIDELINES_CONTENTFUL_SPACEID;
      const deliveryAPIKey = GUIDELINES_CONTENTFUL_DELIVERY_API_KEY;

      contentfulClient = createClient({
        space: spaceID,
        environment: GUIDELINES_CONTENTFUL_ENVIRONMENT,
        accessToken: deliveryAPIKey,
        host: 'cdn.contentful.com'
      })

      resolve()

    })
  },
  getData: type => {
    return new Promise((resolve, reject) => {

      const promises = []
      
      switch(type) {

        case STORE_CONSTANTS.CONTENT.TYPES.HOME_PAGE:

          if(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.HOME_PAGE])
            resolve(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.HOME_PAGE])

          else {
            promises.push(DataService.getAllEntries({
              content_type: 'homePage',
              include: 10
            }))
            
            Promise.all(promises)
            .then(responses => {

              DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.HOME_PAGE] = {
                homePage: DataParser.parseHomeContent(responses[0][0])
              }

              resolve(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.HOME_PAGE])
            })
            .catch(error => {
              reject(error)
            })
          }
        
          break

        case STORE_CONSTANTS.CONTENT.TYPES.FOOTER:

          if(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.FOOTER])
            resolve(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.FOOTER])

          else {
            promises.push(DataService.getAllEntries({
              content_type: 'footer',
              include: 10
            }))
            
            Promise.all(promises)
            .then(responses => {

              DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.FOOTER] = {
                footer: DataParser.parseFooterContent(responses[0])
              }

              resolve(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.FOOTER])
            })
            .catch(error => {
              reject(error)
            })
          }

          break

        case STORE_CONSTANTS.CONTENT.TYPES.DECISION_TREE:

          if(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.DECISION_TREE])
            resolve(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.DECISION_TREE])

          else {
            promises.push(DataService.getAllEntries({
              content_type: 'decisionRoot',
              include: 1
            }))

            promises.push(DataService.getAllEntries({
              content_type: 'decisionOption',
              include: 10
            }))

            promises.push(DataService.getAllEntries({
              content_type: 'decisionPanelSimple',
              include: 2
            }))

            promises.push(DataService.getAllEntries({
              content_type: 'decisionPanelMultipleChoice',
              include: 2
            }))

            promises.push(DataService.getAllEntries({
              content_type: 'decisionPanelResult',
              include: 2
            }))

            Promise.all(promises)
            .then(responses => {
              DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.DECISION_TREE] = {
                decisionTree: DataParser.parseDecisionTreeContent(
                  responses[0][0], 
                  responses[1],
                  _.concat([], responses[2], responses[3], responses[4])
                )
              }

              resolve(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.DECISION_TREE])
            })
          }

          break

        case STORE_CONSTANTS.CONTENT.TYPES.GUIDELINES:

          if(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.GUIDELINES])
            resolve(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.GUIDELINES])

          else {
            promises.push(DataService.getAllGuidelineEntries())
            promises.push(DataService.getAllEntries({
              content_type: 'externalGuideline',
              include: 10
            }))

            Promise.all(promises)
            .then(responses => {

              DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.GUIDELINES] = {
                guidelines: DataParser.parseGuidelineEntries(responses[0]),
                externalGuidelines: DataParser.parseExternalGuidelines(responses[1])
              }

              resolve(DATA_CACHE[STORE_CONSTANTS.CONTENT.TYPES.GUIDELINES])
            })
            .catch(error => {
              reject(error)
            })
          }

          break
        
        default:
          break
      }

    })
  },
  getComponent: componentID => {

    return new Promise((resolve, reject) => {

      contentfulClient.getEntries({
        'sys.id': componentID,
        include: 10
      })
      .then(response => {
        resolve(DataParser.parseComponent(
          response.items[0]
        ))
      })
      .catch(error => {
        reject(error)
      })

    })
  },
  getAllEntries: initialQuery => {
    return new Promise((resolve, reject) => {
      let allEntries = []
      let query = _.assign({}, initialQuery)

      const getEntries = queryProp => {
        contentfulClient.getEntries(queryProp)
        .then(entries => {
          allEntries = _.concat([], allEntries, entries.items)

          if(allEntries.length === entries.total) {
            resolve(allEntries)
          } else {
            if(!query.skip)
              query.skip = 0

            query.skip += entries.limit

            getEntries(query)
          }
        })
        .catch(error => {
          reject(error)
        })
      }

      getEntries(query)
    })
  },
  getAllGuidelineEntries: () => {
    return new Promise((resolve, reject) => {

      const promises = [];

      promises.push(contentfulClient.getEntries({
        content_type: 'guideline',
        include: 1
      }))

      promises.push(DataService.getAllEntries({
        content_type: 'subcategory',
        include: 10,
        limit: 20
      }))

      Promise.all(promises)
      .then(responses => {
        const guidelines = responses[0].items;

        guidelines.forEach(guideline => {
          guideline.fields.categories.forEach(category => {
            category.fields.subcategories.forEach(subcategory => {
              subcategory.fields = (_.filter(responses[1], entry => entry.sys.id === subcategory.sys.id)[0]).fields
            })
          })
        })

        resolve(guidelines)
      })

      // contentfulClient.getEntries({
      //   content_type: 'guideline',
      //   include: 1
      // })
      // .then(guidelineEntries => {
      //   const guidelines = guidelineEntries.items;
        
      //   DataService.getAllEntries({
      //     content_type: 'subcategory',
      //     include: 10,
      //     limit: 20
      //   })
      //   .then(subcategoryEntries => {
          
      //     guidelines.forEach(guideline => {
      //       guideline.fields.categories.forEach(category => {
      //         category.fields.subcategories.forEach(subcategory => {
      //           subcategory.fields = (_.filter(subcategoryEntries, entry => entry.sys.id === subcategory.sys.id)[0]).fields
      //         })
      //       })
      //     })

      //     resolve(guidelines)

      //   })
      // })
    })
  },
  getEnvironment: () => {
    let environment = 'development'

    if(window.location.href.indexOf('brandcentral.verizonwireless.com/') > -1) {
      environment = 'production'
    } 
    
    else if(window.location.href.indexOf('brandcentral.verizonwireless.com.qa.epqa.us') > -1 || window.location.href.indexOf('brandcentral.verizonwireless.com.uat.epqa.us') > -1) {
      environment = 'qa'
    }

    return environment
  }
}

window.DataService = DataService
export default DataService