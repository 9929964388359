import { observable, makeObservable } from 'mobx';

export default class Topic {

  id = "";
  name = "";
  slug = "";

  constructor(props) {
    makeObservable(this, {
      id: observable,
      name: observable,
      slug: observable
    });

    this.id = props.id
    this.name = props.name
    this.slug = props.slug
  }
}