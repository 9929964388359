import { Grid } from "@mui/material";
import {Body, Button, FlexBox, Loader, Pagination, Select, TextButton, Title} from "components";
import {useEffect, useRef, useState} from "react";
import {Category, CategoryArray, SavedSearchResponse, SubCategory, SuggestedSearchResponse, Tag, UnifiedSearchResultProps, withVDSManagerProps} from "interfaces";
import {AssetCardLarge, NoResults, SaveSearchModal} from "features";
import SearchFilter from "./SearchFilter";
import { zIndex } from "theme/variables";
import {
  StyledClose,
  StyledFeedback,
  StyledFeedbackButtons,
  StyledPagination,
  StyledFilterDropdowns,
  StyledAssetResults,
  StyledResultsContainer,
  StyledResultsTitle, StyledAssetResultsContainer, StyledFiltersGrid, MobileSortDropdowns, MobileFilterButtonContainer, MobileFilterSortContainer, StyledRelatedSearches, MobileStyledRelatedSearches,
} from "./Search.module";
import {
  useGetFiltersQuery,
  useLazyGetRelatedSearchesQuery,
  useLazyGetSavedSearchQuery,
  useLazyGetSuggestedSearchQuery,
  useLazySearchAssetsQuery,
  useTrackFeedbackMutation,
} from "services/api/api.slice";
import { withVDSManager } from "@vds/utilities";
import {TotalWrapper} from "../../views/UnifiedSearch/UnifiedSearch.module";
import MobileSearchFilter, {CategoryOption} from "./MobileSearchFilter";
import {useLocation, useSearchParams} from "react-router-dom";
import {useDispatch} from "../../hooks/redux";
import {setNotification} from "../../services";
import {getLocalDate} from "../../utils";
import MultipleSelectedBar from "../multipleSelectedBar/MultipleSelectedBar";

interface DropdownOption {
  id: number;
  value: string;
}
export interface SelectedTagProps {
  id: string;
  value: string;
}

interface FiltersProps {
  restricted: boolean;
  expired: boolean;
  expiresAfter: boolean;
  expiresOn: Date | null;
  selectedTags: Array<SelectedTagProps>;
  submit: boolean;
}

export interface MobileSearchSettings {
  subcategories: Array<SubCategory>;
  selectedSearchTypes: Array<string>;
  selectedCategories: Array<string>;
  selectedCategory: number;
  selectedSearchType: number;
  selectedCategoryId: string | null;
  categoryOptions: Array<CategoryOption>;
  categories: Array<Category>;
  correlationId: string;
  savedSearch: SavedSearchResponse | null;
  savedSearchApplied: boolean;
  filters: FiltersProps;
}

interface SearchResultsProps {
  setResponse: Function;
  search: string | null;
  setSearch: Function;
  setSearchTypes: Function;
  setCategory: Function;
  searchResponse: {
    total: number;
    search: any;
    results?: UnifiedSearchResultProps[];
  } | null;
  setIsError: Function;
  setIsFetching: Function;
}

const SearchResults = ({
  setResponse,
  search,
  setSearch,
  viewport,
  setSearchTypes,
  setCategory,
  searchResponse,
  setIsError,
  setIsFetching,
}: SearchResultsProps & withVDSManagerProps) => {
  const isMobile: boolean = ["mobile", "mobileLarge"].includes(
    viewport as string
  );
  const {
    data: initialCategories = { assets: [], templates: [], designAdvisories: [] },
    isFetching,
    isError,
  } = useGetFiltersQuery();
  
  const emptyFilter = {
    restricted: true,
    expired: false,
    expiresAfter: false,
    expiresOn: null,
    selectedTags: [],
    submit: false,
  };
  
  const emptyMobileSearch = {
    subcategories: [],
    selectedSearchTypes: ["All"],
    selectedCategories: [],
    selectedCategory: 0,
    selectedSearchType: 0,
    selectedCategoryId: null,
    categoryOptions: [],
    categories: [],
    correlationId: "",
    savedSearch: null,
    savedSearchApplied: false,
    filters: emptyFilter,
  }
  
  const searchTypeOptions = [
    { value: 0, label: "All" },
    { value: 1, label: "Assets" },
    { value: 2, label: "Guidelines" },
    { value: 3, label: "Templates" },
    { value: 4, label: "Compliance" },
    { value: 5, label: "Retail" },
    { value: 6, label: "Training" },
  ];
  
  const [allCategories, _setAllCategories] = useState<CategoryArray>([]);
  const [categories, _categories] = useState<Array<Category>>([]);
  const [resultsResponse, _setResultsResponse] = useState<any>(null);
  const [isLoading, _setIsLoading] = useState<boolean>(true);
  const savedSearchApplied = useRef(false);
  
  const [currentMobileSearch, _currentMobileSearch] = useState<MobileSearchSettings>(emptyMobileSearch);
  const [selected, _setSelected] = useState<Array<UnifiedSearchResultProps>>([]),
    setSelected = (response: Array<UnifiedSearchResultProps>) => {
      _setSelected(response);
    };
  const updateSearchHistory = useRef(false);
  
  const [searchParams] = useSearchParams();
  const [getSavedSearch, {data: savedSearchResponseData}] = useLazyGetSavedSearchQuery();
  // const { savedSearch } = useContext<any>(SavedSearchContext);
  const [searchAssets] = useLazySearchAssetsQuery();
  const [didYouMeanSearch] = useLazyGetSuggestedSearchQuery();
  const dispatch = useDispatch();
  const { state } = useLocation();
  
  const [didYouMeanSearches, _didYouMeanSearches] = useState<Array<string>>([]);
  
  
  const callDidYouMeanSearch = () => {
    didYouMeanSearch({search, correlationId: undefined})
    .unwrap()
    .then((response: SuggestedSearchResponse) => {
      _didYouMeanSearches(response.results);
    });
  };
  
  
  useEffect(() => {
    if (!isLoading && !resultsResponse.length && search && search?.length > 0) {
      callDidYouMeanSearch();
    }
  }, [isLoading, resultsResponse, search]);
  
  const [savedSearchResponse, _savedSearchResponse] = useState<SavedSearchResponse | null>(null);
  
  const [categoryOptions, _categoryOptions] = useState<Array<CategoryOption>>([]);
  
  const [selectedSearchTypes, _selectedSearchTypes] = useState<Array<string>>([]);
  
  useEffect(() => {
    if (isMobile) {
      if (isError) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    }
  }, [isError, isMobile]);
  
  useEffect(() => {
    if (isFetching && isMobile) {
      setIsFetching(true);
    } else if (!isFetching && isMobile) {
      _setAllCategories([
        ...initialCategories.assets,
        ...initialCategories.templates,
        ...initialCategories.designAdvisories,
      ]);
      setIsFetching(false);
    }
  }, [isFetching, isMobile]);
  
  useEffect(() => {
    if (isMobile && allCategories.length > 0 && selectedSearchTypes.length > 0) {
      let tempCategories: Category[] = [];
      if (selectedSearchTypes.includes("asset") && selectedSearchTypes.length === 1) {
        tempCategories = initialCategories.assets.map((category) => category);
      } else if (selectedSearchTypes.includes("template") && selectedSearchTypes.length === 1) {
        tempCategories = initialCategories.templates.map((category) => category);
      } else if (selectedSearchTypes.includes("retail") && selectedSearchTypes.length === 1) {
        tempCategories = initialCategories.designAdvisories.map((category) => category);
      }
      _categories(tempCategories);
    }
  }, [allCategories, selectedSearchTypes]);
  const getCategoryOptions = () => {
    if (categories && categories.length > 0) {
      const tempCategories: CategoryOption[] = categories.map((category, index) => {
        let option = {
          label: category.value,
          value: index
        };
        // add 1 to the value because for assets, first choice has to be all
        if (selectedSearchTypes.includes("asset")) {
          option = {
            label: category.value,
            value: index + 1
          };
        }
        return option;
      });
      // add All as the first option
      if (selectedSearchTypes.includes("asset")) {
        // unshift adds the all option at the beginning of the array
        tempCategories.unshift({label: "All", value: 0});
      }
      
      _categoryOptions(tempCategories);
      _currentMobileSearch((prevState) => { return { ...prevState, categories: categories, categoryOptions: tempCategories}});
      
    }
  };
  
  useEffect(() => {
    if (isMobile && ((searchParams.get("saved") && !savedSearchApplied.current) || state?.saved?.subCategoryId)) {
      if (categories && categories.length > 0) {
        getCategoryOptions();
      }
    }
  }, [categories]);

  const sortByOptions: DropdownOption[] = [
      {
        id: 0,
        value: "Most relevant",
      },
      {
        id: 1,
        value: "Most popular",
      },
      {
        id: 2,
        value: "Recently updated",
      },
      {
        id: 3,
        value: "Oldest to newest",
      },
      {
        id: 4,
        value: "Newest to oldest",
      },
      {
        id: 5,
        value: "A to Z",
      },
      {
        id: 6,
        value: "Z to A",
      },
    ],
    pageSizeOptions = [{ value: 20 }, { value: 50 }, { value: 100 }];

  const [pageNumber, _setPageNumber] = useState(1),
    [pageSize, _setPageSize] = useState(20),
    [sortBy, _setSortBy] = useState<number>(sortByOptions[0].id),
    handleSelectPage = (newPage: number) => {
      _setPageNumber(newPage);
    },
    handlePageSize = (e: any) => {
      _setPageNumber(1);
      _setPageSize(parseInt(e.target.value));
    },
    handleSortBy = (e: any) => {
      _setPageNumber(1);
      _setSortBy(parseInt(e.target.value));
    };

  const handleSetResults = (response: {
    search: any;
    results: any;
    total: number;
    totalPages: number;
    searchTypes?: Array<string>;
    pageNumber?: number;
    pageSize?: number;
    sortBy?: string;
    updateSearchHistory?: boolean;
    correlationId?: string | null;
    includeRestricted?: boolean;
    includeExpired?: boolean;
    expiresOn?: string | undefined;
    categoryId: string | null,
    selectedTags?: Array<SelectedTagProps>;
  }) => {
    _setResultsResponse(response);
    setResponse(response);
  };
  
  const handleOnError = () => {
    dispatch(
      setNotification({
        type: "error",
        message: "An error has occurred. Please try again.",
      })
    );
    _setIsLoading(false);
  };
  
  const handleSubmit = () => {
    _setIsLoading(true);
    searchAssets({
      search,
      searchTypes: currentMobileSearch.selectedSearchType === 0 ? ["all"] : currentMobileSearch.selectedSearchType === 1 ? ["asset"] : currentMobileSearch.selectedSearchType === 2 ? ["guideline"] : currentMobileSearch.selectedSearchType === 3 ? ["template"] : currentMobileSearch.selectedSearchType === 4 ? ["compliance"] : currentMobileSearch.selectedSearchType === 5 ? ["retail"] : ["training"],
      pageNumber,
      pageSize,
      sortBy: sortBy === 1 ? "popularity" : sortBy === 2 ? "last-modified" : sortBy === 3 ? "old-to-new" : sortBy === 4 ? "new-to-old" : sortBy === 5 ? "a-to-z" : sortBy === 6 ? "z-to-a" : "relevance",
      updateSearchHistory: updateSearchHistory.current,
      correlationId: resultsResponse?.correlationId,
      includeRestricted: currentMobileSearch.filters.restricted,
      includeExpired: currentMobileSearch.filters.expired,
      expiresOn: currentMobileSearch.filters.expiresOn
        ? currentMobileSearch.filters.expiresOn.toISOString()
        : undefined,
      categoryId: currentMobileSearch.selectedCategoryId === "All" || currentMobileSearch.selectedCategoryId === null ? undefined : currentMobileSearch.selectedCategoryId,
      selectedTags: currentMobileSearch.filters.selectedTags,
    })
    .unwrap()
    .then((response) => {
      handleSetResults({
        search,
        searchTypes: currentMobileSearch.selectedSearchType === 0 ? ["all"] : currentMobileSearch.selectedSearchType === 1 ? ["asset"] : currentMobileSearch.selectedSearchType === 2 ? ["guideline"] : currentMobileSearch.selectedSearchType === 3 ? ["template"] : currentMobileSearch.selectedSearchType === 4 ? ["compliance"] : currentMobileSearch.selectedSearchType === 5 ? ["retail"] : ["training"],
        pageNumber,
        pageSize,
        sortBy: sortBy === 1 ? "popularity" : sortBy === 2 ? "last-modified" : sortBy === 3 ? "old-to-new" : sortBy === 4 ? "new-to-old" : sortBy === 5 ? "a-to-z" : sortBy === 6 ? "z-to-a" : "relevance",
        updateSearchHistory: updateSearchHistory.current,
        correlationId: response.correlationId,
        includeRestricted: currentMobileSearch.filters.restricted,
        includeExpired: currentMobileSearch.filters.expired,
        expiresOn: currentMobileSearch.filters.expiresOn
          ? currentMobileSearch.filters.expiresOn.toISOString()
          : undefined,
        categoryId: currentMobileSearch.selectedCategoryId,
        selectedTags: currentMobileSearch.filters.selectedTags,
        results: response.results,
        total: response.total,
        totalPages: response.totalPages,
      });
      
      if (categories && categories.length > 0) {
        // start with all subcategories under the selected category
        // do the minus 1 if it is asset, just use selected category if retail or template
        if (currentMobileSearch.selectedSearchTypes.includes("asset")) {
          if (categories[currentMobileSearch.selectedCategory - 1]) {
            const newSubCategories = categories[currentMobileSearch.selectedCategory - 1].subCategories.map((subcat) => subcat);
            
            // filter out any subcategories that don't contain the category ids from the response
            let filteredCategories = JSON.parse(JSON.stringify(newSubCategories));
            filteredCategories = filteredCategories.filter(
              (subCategory: SubCategory) => {
                return Object.keys(response.filters.categories).includes(
                  subCategory.categoryId
                );
              }
            );
            
            // filter out any subcategories whose id doesn't match the response list of subcategories
            filteredCategories = filteredCategories.filter(
              (subCategory: SubCategory) =>
                Object.keys(response.filters.subCategories).includes(
                  subCategory.id
                )
            );
            
            // filter out any tags that are not part of the response
            filteredCategories = filteredCategories.map(
              (subCategory: SubCategory) => {
                return {
                  ...subCategory,
                  tags: subCategory.tags.filter((tag: Tag) =>
                    Object.keys(response.filters.tags).includes(tag.id)
                  ),
                };
              }
            );
            
            _currentMobileSearch((prevState) => { return {
              ...prevState,
              subcategories: filteredCategories
            };});
            
            
            
          }
        } else if (categories[currentMobileSearch.selectedCategory] && (currentMobileSearch.selectedSearchTypes.includes("template") ||currentMobileSearch.selectedSearchTypes.includes("retail"))) {
          const newSubCategories = categories[currentMobileSearch.selectedCategory].subCategories.map((subcat) => subcat);
          
          if (response.filters.categories) {
            // filter out any subcategories that don't contain the category ids from the response
            let filteredCategories = JSON.parse(JSON.stringify(newSubCategories));
            filteredCategories = filteredCategories.filter(
              (subCategory: SubCategory) => {
                return Object.keys(response.filters.categories).includes(
                  subCategory.categoryId
                );
              }
            );
            
            if (response.filters.subCategories) {
              // filter out any subcategories whose id doesn't match the response list of subcategories
              filteredCategories = filteredCategories.filter(
                (subCategory: SubCategory) =>
                  Object.keys(response.filters.subCategories).includes(
                    subCategory.id
                  )
              );
              
              // filter out any tags that are not part of the response
              filteredCategories = filteredCategories.map(
                (subCategory: SubCategory) => {
                  return {
                    ...subCategory,
                    tags: subCategory.tags.filter((tag: Tag) =>
                      Object.keys(response.filters.tags).includes(tag.id)
                    ),
                  };
                }
              );
              
              _currentMobileSearch((prevState) => { return {
                ...prevState,
                subcategories: filteredCategories
              };});
            }
          }
          
        } else {
          // set subcategories to empty
          _currentMobileSearch((prevState) => { return {
            ...prevState,
            subcategories: []
          };});
        }
      }
      _setIsLoading(false);
      updateSearchHistory.current = false;
    })
    .catch((error: any) => {
      console.error(error);
      handleOnError();
    });
    
  };
  
  const handleSearchTypeChange = (selected: number) => {
    let tempSelectedSearchTypes = ["all"];
    if (selected === 1) {
      tempSelectedSearchTypes = ["asset"];
    } else if (selected === 2) {
      tempSelectedSearchTypes = ["guideline"];
    } else if (selected === 3) {
      tempSelectedSearchTypes = ["template"];
    } else if (selected === 4) {
      tempSelectedSearchTypes = ["compliance"];
    } else if (selected === 5) {
      tempSelectedSearchTypes = ["retail"];
    } else if (selected === 6) {
      tempSelectedSearchTypes = ["training"];
    }
    setSearchTypes(tempSelectedSearchTypes);
    _selectedSearchTypes(tempSelectedSearchTypes);
    _currentMobileSearch((prevState) => { return { ...prevState, selectedSearchType: selected,  selectedSearchTypes: tempSelectedSearchTypes}});
  };
  
  // this gets the searchtype and saves the saved search response in a state to use later as search settings are systematically handled
  const initiateSavedSearch = (response: SavedSearchResponse) => {
    const searchTypeOptionIndex = searchTypeOptions.findIndex((option) => option.label.toLowerCase() === response.searchType || (option.label.toLowerCase() === "assets" && response.searchType === "asset") || (option.label.toLowerCase() === "templates" && response.searchType === "template") || (option.label.toLowerCase() === "guidelines" && response.searchType === "guideline"));
    // store saved search response for future loading steps
    _savedSearchResponse(response);
    // need to set the search type first so can get the categories based on which search type is selected
    handleSearchTypeChange(searchTypeOptionIndex);
  };
  
  // if there's a saved search and it hasn't been applied yet, get the saved search based on the params and set the search type
  useEffect(() => {
    if (isMobile && searchParams.get("saved") && !savedSearchApplied.current && !savedSearchResponse) {
      getSavedSearch(searchParams.get("saved") || "")
      .unwrap()
      .then((response: SavedSearchResponse) => {
      })
      .catch(() => {
        handleSearchTypeChange(0);
        dispatch(
          setNotification({
            type: "error",
            message:
              "An error has occurred, unable to load saved search. Please try again.",
          })
        );
      });
    }
  }, [searchParams, isMobile]);
  
  useEffect(() => {
    if (savedSearchResponseData) {
      initiateSavedSearch(savedSearchResponseData);
    }
  }, [savedSearchResponseData]);
  
  async function handleCategoryChange(selected: number): Promise<void> {
    if (categories && categories.length > 0) {
      // if asset search type, must account for the all as the first option, so move the index back one, but only if the selected options is not all, whose index is 0
      if (selectedSearchTypes.includes("asset") && selected > 0) {
        setCategory(categories[selected - 1].id);
        _currentMobileSearch((prevState) => { return { ...prevState, selectedCategory: selected,  selectedCategories: [categories[selected - 1].id], selectedCategoryId: categories[selected - 1].id, subcategories: (categories[selected - 1].subCategories.length > 0 ? categories[selected - 1].subCategories.map((subcat) => subcat) : [])}});
      } else if (selectedSearchTypes.includes("asset") && selected === 0) {
        // if asset search type and user selects all, set selected category id to null and do not call the setCategory function that is passed in
        _currentMobileSearch((prevState) => { return { ...prevState, selectedCategory: selected,  selectedCategories: ["All"]}});
      } else {
        // for everything else, set the selected option as normal
        setCategory(categories[selected].id);
        _currentMobileSearch((prevState) => { return { ...prevState, selectedCategory: selected,  selectedCategories: [categories[selected].id], selectedCategoryId: categories[selected].id, subcategories: (categories[selected].subCategories.length > 0 ? categories[selected].subCategories.map((subcat) => subcat) : [])}});
      }
    }
  };
  
  async function setAssetRetailTemplateSavedSearch(categoryOptionIndex: number): Promise<void> {
    // make sure there are category options and that the selected category index is not larger than 1 more than the total number of options. the plus one is for the added all for asset categories. templates and retail do not have an all option.
    if (categoryOptionIndex !== undefined && (categoryOptionIndex >= 0 && categoryOptions.length > 0 && categoryOptionIndex <= (categoryOptions.length + 1))) {
      // set the category
      await handleCategoryChange(categoryOptionIndex);
    }
  };
  
  const setInitialFilters = (tempFilters: FiltersProps) => {
    _currentMobileSearch((prevState) => { return { ...prevState, filters: tempFilters}});
  };
  
  // once the saved search response and search type have been set, start working through all the possible saved search combinations
  useEffect(() => {
    // there must be a selected search type. this is the only setting that is mandatory
    if (isMobile && savedSearchResponse && !savedSearchApplied.current && selectedSearchTypes.length > 0) {
      const query = savedSearchResponse.searchObject
        ? JSON.parse(savedSearchResponse.searchObject)
        : null;
      if (query?.Query && query?.Query.length > 0) {
        setSearch(query?.Query);
      }
      
      // the following logic is only for assets, templates, and retail because they can have categories, subcategories and tags, but guidelines, compliance, training and all do not have them
      if (selectedSearchTypes.includes("asset") || selectedSearchTypes.includes("template") || selectedSearchTypes.includes("retail")) {
        // look for category id
        const category = query?.Filters.find(
          (filter: any) => filter.Type === "category"
        )?.Value;
        let categoryOptionIndex = 0;
        
        // look for subcategory id
        const subCategoryId = query?.Filters.find(
          (filter: any) => filter.Type === "subcategory"
        )?.Value;
        
        // look for tags. this is a list of ids as strings that will later need to be remapped to a list of objects
        const tagIds = query?.Filters.filter(
          (filter: any) => filter.Type === "tag"
        ).reduce((prev: any, curr: any) => [...prev, curr.Value], []);
        
        // before trying to set the selected category, must get categories from the selected search type and assign them to the category options
        if (categoryOptions.length > 0 && categories && categories.length) {
          
          let tags: SelectedTagProps[] = [];
          
          // if there is a category id in the saved search, look through the possible categories to match on the id
          if (category !== undefined) {
            const categoryName = categories?.find((fullCategory) => fullCategory.id === category);
            // if the category is found, search through the category options to match on the name and store that index
            if (categoryName) {
              categoryOptionIndex = categoryOptions.findIndex((option) => option.label === categoryName.value);
              
              // if there are tag ids, need to look at each subcategory under that category and its tags and match on the id, then push to a list the full tag object which is the required format for the search query
              if (tagIds.length > 0) {
                categoryName.subCategories.forEach((subCategory) => {
                  tagIds.forEach((tagId: string) => {
                    subCategory.tags.find((tag) => {
                      if (tag.id === tagId) {
                        tags.push(tag);
                      }
                    });
                  });
                });
              }
            } else {
              // if there is no category found, set it to the default which is all for assets and the first and possibly only option for templates and retail
              categoryOptionIndex = 0;
              // add the availability filters and tags
              let tempFilters: FiltersProps = {...emptyFilter};
              tempFilters.submit = true;
              tempFilters.selectedTags = tags;
              
              tempFilters.restricted =
                query?.IsRestricted === undefined
                  ? true
                  : query?.IsRestricted;
              
              tempFilters.expired =
                query?.IsExpired === undefined
                  ? false
                  : query?.IsExpired;
              
              if (!!query?.ExcludeWithinExpiredDate) {
                tempFilters.expiresAfter = true;
                tempFilters.expiresOn = getLocalDate(query?.ExcludeWithinExpiredDate);
              }
              setAssetRetailTemplateSavedSearch(categoryOptionIndex)
              .then(() => {
                setInitialFilters(tempFilters);
              });
            }
            
          } else if (!category && (subCategoryId || tags.length > 0)) {
            
            // if there are subcats and tags but no category, find matching category in the search type's category list, based on finding the category which has a subcategory that matches on the saved search subcategory's id
            if (subCategoryId) {
              const inferredCategory = categories?.find((category) => {
                if (category.subCategories.find((tempSubCategory) => tempSubCategory.id === subCategoryId)) {
                  return category;
                }
              });
              
              if (inferredCategory) {
                // if there are is an inferred category, find matching category index in category options
                categoryOptionIndex = categoryOptions.findIndex((option) => option.label === inferredCategory.value);
                if (tagIds.length > 0) {
                  // if there are tag ids, need to look at each subcategory under that category and its tags and match on the id, then push to a list the full tag object which is the required format for the search query
                  inferredCategory.subCategories.forEach((subCategory) => {
                    tagIds.forEach((tagId: string) => {
                      subCategory.tags.find((tag) => {
                        if (tag.id === tagId) {
                          tags.push(tag);
                        }
                      });
                    });
                  });
                }
              } else {
                // for categories previously saved as subcategories, if the subcategory id cannot be found within any of the categories' subcategories check for the id within categories
                const categoryName = categories?.find((fullCategory) => fullCategory.id === subCategoryId);
                // if the category is found, search through the category options to match on the name and store that index
                if (categoryName) {
                  categoryOptionIndex = categoryOptions.findIndex((option) => option.label === categoryName.value);
                  // if there are tag ids, need to look at each subcategory under that category and its tags and match on the id, then push to a list the full tag object which is the required format for the search query
                  if (tagIds.length > 0) {
                    categoryName.subCategories.forEach((subCategory) => {
                      tagIds.forEach((tagId: string) => {
                        subCategory.tags.find((tag) => {
                          if (tag.id === tagId) {
                            tags.push(tag);
                          }
                        });
                      });
                    });
                  }
                }
              }
            }
            // else if (!subCategoryId && !category || (!category && subCategoryId === "all")) {
            //   // if there are tags but no subcategory and no category, or if there is not category and the subcategory is all, set the category to all
            //   _currentMobileSearch((prevState) => { return { ...prevState, selectedCategories: ["All"]}});
            // }
            
            
          }
          // else if ((!category && !subCategoryId && tags.length === 0) || (!category && subCategoryId === "all" && tags.length === 0)) {
          //   // if there is no category, subcategory, or tags, or if there is no category, no tags, and the subcategory is all, set the category to all
          //   _currentMobileSearch((prevState) => { return { ...prevState, selectedCategories: ["All"]}});
          // }
          
          // add the availability filters and tags
          let tempFilters: FiltersProps = {...emptyFilter};
          tempFilters.submit = true;
          tempFilters.selectedTags = tags;
          
          tempFilters.restricted =
            query?.IsRestricted === undefined
              ? true
              : query?.IsRestricted;
          
          tempFilters.expired =
            query?.IsExpired === undefined
              ? false
              : query?.IsExpired;
          
          if (!!query?.ExcludeWithinExpiredDate) {
            tempFilters.expiresAfter = true;
            tempFilters.expiresOn = getLocalDate(query?.ExcludeWithinExpiredDate);
          }
          
          // call the function where the category, subcategory, and filters states get set
          setAssetRetailTemplateSavedSearch(categoryOptionIndex)
          .then(() => {
            setInitialFilters(tempFilters);
          });
          
        } else if (!category && !subCategoryId && tagIds.length === 0) {
          
          // if there are no category options, no category, no subcategory, and no tags, add the availability filters only
          let tempFilters: FiltersProps = {...emptyFilter};
          tempFilters.submit = true;
          
          tempFilters.restricted =
            query?.IsRestricted === undefined
              ? true
              : query?.IsRestricted;
          
          tempFilters.expired =
            query?.IsExpired === undefined
              ? false
              : query?.IsExpired;
          
          if (!!query?.ExcludeWithinExpiredDate) {
            tempFilters.expiresAfter = true;
            tempFilters.expiresOn = getLocalDate(query?.ExcludeWithinExpiredDate);
          }
          setAssetRetailTemplateSavedSearch(categoryOptionIndex)
          .then(() => {
            setInitialFilters(tempFilters);
          });
        }
        
      } else if (!selectedSearchTypes.includes("asset") && !selectedSearchTypes.includes("retail") && !selectedSearchTypes.includes("template")) {
        
        // if the search type is all, compliance, training, or guideline, just set the availability filters
        let tempFilters: FiltersProps = {...emptyFilter};
        tempFilters.submit = true;
        
        tempFilters.restricted =
          query?.IsRestricted === undefined
            ? true
            : query?.IsRestricted;
        
        tempFilters.expired =
          query?.IsExpired === undefined
            ? false
            : query?.IsExpired;
        
        if (!!query?.ExcludeWithinExpiredDate) {
          tempFilters.expiresAfter = true;
          tempFilters.expiresOn = getLocalDate(query?.ExcludeWithinExpiredDate);
        }
        if (tempFilters) {
          setInitialFilters(tempFilters);
        }
      }
      
    }
    
  }, [savedSearchResponse, categories, selectedSearchTypes, categoryOptions]);
  
  useEffect(() => {
    if (isMobile && searchParams.get("saved") && !savedSearchApplied.current) {
      // only submit the search if the filters have not been cleared or reset
      if (currentMobileSearch.filters.submit) {
        savedSearchApplied.current = true;
        handleSubmit();
      }
    } else if (isMobile && state?.saved?.subCategoryId && !savedSearchApplied.current) {
      if (currentMobileSearch.filters.submit && (currentMobileSearch.selectedCategoryId !== "All" || currentMobileSearch.selectedCategoryId !== null)) {
        savedSearchApplied.current = true;
        state?.saved && window.history.replaceState({}, document.title);
        handleSubmit();
      }
    }
  }, [currentMobileSearch]);
  
  // prevent looping on initial load once all states have been set
  const initialSearch = useRef(true);
  useEffect(() => {
    if (initialSearch.current) {
      initialSearch.current = false;
      return;
    }
    // only proceed if there is not a saved search or if there is a saved search and it has been applied and user has interacted with sort, page size, or pagination
    if (isMobile && (!(searchParams.get("saved")) || ((searchParams.get("saved")) && savedSearchApplied.current === true)) && (!state?.saved?.subCategoryId || (state?.saved?.subCategoryId && savedSearchApplied.current === true))) {
      handleSubmit();
    }
  }, [pageNumber, pageSize, sortBy, isMobile]);
  
  // for submitting search
  const firstUpdateSearch = useRef(true);
  
  useEffect(() => {
    if (isMobile) {
      if (firstUpdateSearch.current) {
        firstUpdateSearch.current = false;
        return;
      }
      if (pageNumber !== 1) {
        _setPageNumber(1);
        updateSearchHistory.current = true;
      } else {
        // only proceed if there is not a saved search or if there is a saved search and it has been applied and the search term has changed
        if ((!(searchParams.get("saved")) || ((searchParams.get("saved")) && savedSearchApplied.current === true)) && (!state?.saved?.subCategoryId || (state?.saved?.subCategoryId && savedSearchApplied.current === true))) {
          updateSearchHistory.current = true;
          handleSubmit();
        }
      }
    }
  }, [search, isMobile]);
  
  useEffect(() => {
    if (state?.saved?.subCategoryId && isMobile) {
      if (!selectedSearchTypes.includes("asset")) {
      
        // need to set the search type first so component can get the categories based on which search type is selected
        handleSearchTypeChange(1);
      } else {
        if (categoryOptions.length > 0 && categories && categories.length > 0) {
          
          // if there is a category id in the saved search, look through the possible categories to match on the id
          const categoryName = categories?.find((fullCategory) => fullCategory.id === state?.saved?.subCategoryId);
          // if the category is found, search through the category options to match on the name and store that index
          if (categoryName) {
            const categoryOptionIndex = categoryOptions.findIndex((option) => option.label === categoryName.value);
            let tempFilters: FiltersProps = {...emptyFilter};
            tempFilters.submit = true;
            _currentMobileSearch((prevState) => { return { ...prevState, selectedCategory: categoryOptionIndex,  selectedCategories: [categories[categoryOptionIndex - 1].id], selectedCategoryId: categories[categoryOptionIndex - 1].id, subcategories: (categories[categoryOptionIndex - 1].subCategories.length > 0 ? categories[categoryOptionIndex - 1].subCategories.map((subcat) => subcat) : []), filters: tempFilters}});
          }
        }
      }
    }
  }, [state, isMobile, categories, categoryOptions]);
  
  const [showMobileSort, _showMobileSort] = useState<boolean>(false);
  
  const toggleMobileSort = () =>
  {
    _showMobileSort((prevState) => !prevState);
  };
  
  const [showMobileFilters, _showMobileFilters] = useState<boolean>(false);
  
  const toggleMobileFilters = () =>
  {
    _showMobileFilters((prevState) => !prevState);
  };
  
  
  const [showFeedback, _setShowFeedback] = useState(true),
    [showSuccessful, _setShowSuccessful] = useState(false),
    closeFeedback = () => {
      _setShowFeedback(false);
      _setShowSuccessful(false);
    },
    handleSuccessfulFeedback = () => {
      _setShowSuccessful(true);
    },
    [trackFeedback] = useTrackFeedbackMutation(),
    submitFeedback = (feedback: string) => () => {
      trackFeedback({
        correlationId: resultsResponse?.correlationId,
        details: [],
        response: feedback,
        source: "search-results",
      })
        .unwrap()
        .then(handleSuccessfulFeedback);
    };

  const [relatedSearches, _setRelatedSearches] = useState<Array<string>>([]),
    [callRelatedSearches, { data: relatedSearchResponse }] =
      useLazyGetRelatedSearchesQuery(),
    handleUpdateSearch = (search: string) => () => {
      setSearch(search);
    };
  
  const [openSave, _openSaveModal] = useState(false),
    toggleSaveModal = () => {
      _openSaveModal((prevState) => !prevState);
    };
  
  useEffect(() => {
    _setShowFeedback(true);
    _setShowSuccessful(false);
    
    if (resultsResponse?.search) {
      callRelatedSearches({
        search: resultsResponse.search,
      });
    }
  }, [resultsResponse]);
  
  useEffect(() => {
    if (relatedSearchResponse && relatedSearchResponse.results && relatedSearchResponse.results.length > 0) {
      _setRelatedSearches(relatedSearchResponse.results);
    }
  }, [relatedSearchResponse]);

  const handleSelected = (id: string) => {
    selected.length && selected.find((item) => item.id === id)
      ? setSelected(selected.filter((item: UnifiedSearchResultProps) => item.id !== id))
      : setSelected([
          ...selected,
          resultsResponse.results.find((asset: UnifiedSearchResultProps) => asset.id === id),
        ]);
  };
   
  const handleRemoveItem = (id: string) => {
      setSelected(selected.filter((item: UnifiedSearchResultProps) => item.id !== id));
    };
  const handleUpdateUsageRights = (id: string) => {
    if (resultsResponse) {
      let tempArray = JSON.parse(JSON.stringify(resultsResponse)),
        index = tempArray.results.findIndex((item: UnifiedSearchResultProps) => item.assetId === id);
      if (tempArray.results[index] && tempArray.results[index].userActions) {
        tempArray.results[index].userActions.hasAcceptedUsageRights = true;
      }
        let tempSelected = JSON.parse(JSON.stringify(selected));
        index = tempSelected.findIndex((item: UnifiedSearchResultProps) => item.assetId === id);
        if (tempSelected[index] && tempSelected[index].userActions) {
          tempSelected[index].userActions.hasAcceptedUsageRights = true;
        }
      setSelected(tempSelected);
      _setResultsResponse(tempArray);
      
    }
    
  };
  
  const handleClearBySearchType = (searchType: string) => {
    const newSelected = selected.filter((selectedItem) => selectedItem.searchType !== searchType);
    setSelected(newSelected);
  };
  
  
  
  const handleSetSearchTypes = (searchTypes: Array<string>) => {
    setSearchTypes(searchTypes);
  };
  
  const handleSetCategory = (categoryId: string) => {
    setCategory(categoryId);
  };
  
  const handleSetIsError = (isCategoryError: boolean) => {
    setIsError(isCategoryError);
  };
  const handleSetIsFetching = (isFetching: boolean) => {
    setIsFetching(isFetching);
  };
  

  return (
    <>
      <Grid container>
        {!isMobile && (
          <StyledFiltersGrid item xs={12} md={3}>
            <SearchFilter
              setResults={(response: any) => handleSetResults(response)}
              setLoading={_setIsLoading}
              search={search}
              setSearch={setSearch}
              pageNumber={pageNumber}
              setPageNumber={(page: number) => _setPageNumber(page)}
              pageSize={pageSize}
              sortBy={sortBy}
              setCategory={(categoryId: string) => handleSetCategory(categoryId)}
              setSearchTypes={(searchTypes: Array<string>) => handleSetSearchTypes(searchTypes)}
              setIsError={(isCategoryError: boolean) => handleSetIsError(isCategoryError)}
              setIsFetching={(isFetching: boolean) => handleSetIsFetching(isFetching)}
            />
          </StyledFiltersGrid>
        )}
        

        <Grid
          item
          xs={12}
          md={9}
          style={{ position: "relative", zIndex: zIndex.notBottom }}
        >
          {isLoading ? (
            <Loader containerHeight="20rem" active />
          ) : resultsResponse?.results?.length ? (
            <StyledResultsContainer data-testid="results-container">
              <MobileFilterSortContainer data-testid="mobile-filter-sort-container">
                <MobileFilterButtonContainer>
                  <Button width="100%" use="secondary" testId="mobile-filter-toggle" onClick={toggleMobileFilters}>Filter</Button>
                  <Button width="100%" testId="mobile-sort-toggle" use="secondary" onClick={toggleMobileSort}>Sort</Button>
                </MobileFilterButtonContainer>
                <MobileSortDropdowns className={showMobileSort ? 'visible' : ''} data-testid="mobile-sort-dropdowns">
                  <Select
                    width="fit-content"
                    items={pageSizeOptions}
                    onChange={(e: Event) => handlePageSize(e)}
                    optionLabels={["value"]}
                    valueKey="value"
                    name="pageSize"
                    label="Display"
                    value={pageSize.toString()}
                    testId="mobile-page-size"
                  />
                  <Select
                    width="fit-content"
                    items={sortByOptions}
                    onChange={(e: Event) => handleSortBy(e)}
                    optionLabels={["value"]}
                    name="sortBy"
                    label="Sort by"
                    value={sortBy.toString()}
                    testId="mobile-sort-by"
                  />
                </MobileSortDropdowns>
              </MobileFilterSortContainer>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={7}
                  >
                  <StyledResultsTitle>
                    <Title bold size="large">Search Results</Title>
                    {searchResponse && (
                      <TotalWrapper data-testid="total-results">
                        <Body>
                          {searchResponse.total} result
                          {searchResponse.total > 1 ? "s" : ""}
                          {searchResponse.search?.length > 0 ? ` for "${searchResponse.search}"` : ""}
                        </Body>
                        <TextButton
                          onClick={toggleSaveModal}
                          testId="save-search-button"
                        >
                          <Body bold>Save search</Body>
                        </TextButton>
                      </TotalWrapper>
                    )}
                  </StyledResultsTitle>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={5}
                >
                  <StyledFilterDropdowns>
                    <Select
                      width="fit-content"
                      items={pageSizeOptions}
                      onChange={(e: Event) => handlePageSize(e)}
                      optionLabels={["value"]}
                      valueKey="value"
                      name="pageSize"
                      label="Display"
                      value={pageSize.toString()}
                      testId="page-size"
                    />
                    <Select
                      width="fit-content"
                      items={sortByOptions}
                      onChange={(e: Event) => handleSortBy(e)}
                      optionLabels={["value"]}
                      name="sortBy"
                      label="Sort by"
                      value={sortBy.toString()}
                      testId="sort-by"
                    />
                  </StyledFilterDropdowns>
                </Grid>
              </Grid>
              <StyledAssetResultsContainer bottomMargin={resultsResponse.totalPages === 1}>
                <StyledAssetResults id="search-response-results" searchGrid>
                  {resultsResponse.results.map((result: UnifiedSearchResultProps, index: number) => (
                    <AssetCardLarge
                      key={result.id}
                      item={result}
                      selected={!!selected.find((item) => item.id === result.id)}
                      onSelectChange={(selected: string) =>
                        handleSelected(selected)
                      }
                      correlationId={resultsResponse?.correlationId}
                      isMobile={isMobile}
                      tracking-data={{
                        parentComponent: "FourUp",
                        parentId: "search-response-results",
                        cardIndex: index,
                        totalCards: resultsResponse.results.length,
                        cardPage: pageNumber ? pageNumber : 1,
                      }}
                    />
                  ))}
                </StyledAssetResults>
              </StyledAssetResultsContainer>
              
              {resultsResponse.totalPages !== 1 && (
                <StyledPagination
                  showFeedback={showFeedback}
                  data-testid="pagination"
                >
                  <Pagination
                    total={resultsResponse.totalPages}
                    selectedPage={pageNumber}
                    selectPage={(newPage: number) => handleSelectPage(newPage)}
                  />
                </StyledPagination>
              )}
              
              {!!relatedSearches && relatedSearches.length > 0 && (
                <FlexBox
                  pd={
                    !showFeedback && resultsResponse.totalPages === 1
                      ? "2rem 1rem 0"
                      : "0 1rem"
                  }
                  data-testid="related-searches"
                >
                  <Title bold>People also searched for</Title>
                  <MobileStyledRelatedSearches data-testid="mobile-related-searches" gap="1.5rem" pd="1rem 0 2rem">
                    {relatedSearches.map((search: string, index: number) => (
                      <TextButton
                        size="small"
                        key={search + index}
                        use="primary"
                        onClick={handleUpdateSearch(search)}
                      >
                        {search}
                      </TextButton>
                    ))}
                  </MobileStyledRelatedSearches>
                  <StyledRelatedSearches data-testid="desktop-related-searches" row gap="1rem" wrap pd="1rem 0 2rem">
                    {relatedSearches.map((search: string, index: number) => (
                      <Button
                        key={search + index}
                        use="primary"
                        onClick={handleUpdateSearch(search)}
                      >
                        {search}
                      </Button>
                    ))}
                  </StyledRelatedSearches>
                </FlexBox>
              )}
              
              {showFeedback && (
                <StyledFeedback data-testid="show-feedback">
                  <StyledClose
                    name="close"
                    size="medium"
                    onClick={closeFeedback}
                    testId="feedback-close"
                  />
                  {showSuccessful ? (
                    <Title size="large" bold testId="feedback-success">
                      Thank you for your feedback.
                    </Title>
                  ) : (
                    <>
                      <Title size="large" bold>
                        How accurate are these search results?
                      </Title>
                      <StyledFeedbackButtons>
                        <Button
                          use="secondary"
                          onClick={submitFeedback("positive")}
                          data-testid="positive-button"
                        >
                          Very accurate
                        </Button>
                        <Button
                          use="secondary"
                          onClick={submitFeedback("neutral")}
                          data-testid="neutral-button"
                        >
                          Slightly accurate
                        </Button>
                        <Button
                          use="secondary"
                          onClick={submitFeedback("negative")}
                          data-testid="negative-button"
                        >
                          Not accurate
                        </Button>
                      </StyledFeedbackButtons>
                    </>
                  )}
                </StyledFeedback>
              )}
            </StyledResultsContainer>
          ) : (
            <StyledResultsContainer data-testid="no-results">
              <NoResults
                search={search}
                suggestedSearch={didYouMeanSearches}
                updateSearch={handleUpdateSearch}
              />
            </StyledResultsContainer>
          )}
        </Grid>
      </Grid>

      {selected.length > 0 && (
        <MultipleSelectedBar
          clearBySearchType={(searchType: string) => handleClearBySearchType(searchType)}
          selectedItems={selected}
          clearSelected={() => setSelected([])}
          removeAsset={handleRemoveItem}
          updateUsageRights={handleUpdateUsageRights}
        />
      )}

      {showMobileFilters && (
        <MobileSearchFilter
          categories={categories}
          setResults={(response: any) => handleSetResults(response)}
          setLoading={_setIsLoading}
          search={search}
          updateSearchHistory={updateSearchHistory.current}
          pageNumber={pageNumber}
          setPageNumber={(page: number) => _setPageNumber(page)}
          pageSize={pageSize}
          sortBy={sortBy}
          setCategory={(categoryId: string) => handleSetCategory(categoryId)}
          setSelectedSearchType={(selectedSearchType: number) => handleSearchTypeChange(selectedSearchType)}
          onClose={toggleMobileFilters}
          currentSearch={currentMobileSearch}
          setCurrentSearch={(newSearch: MobileSearchSettings) => _currentMobileSearch(newSearch)}
        />
      )}
      {openSave && (
        <SaveSearchModal
          onClose={toggleSaveModal}
          searchResponse={searchResponse}
        />
      )}
    </>
  );
};

export default withVDSManager(SearchResults);
