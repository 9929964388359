import { formatDate } from "utils/dateFunctions";
import {ApprovalStatus} from "../../interfaces/Asset";


export const mockComplianceOverview = {
  pageTitle: "page title",
  pageDescription: "page description",
  introHeading: "intro heading",
  introVideoAssetUrl: "videourl",
  introVideoPosterAssetUrl: "posterurl",
  howComplianceWorksCopy: "copy",
  howComplianceWorksLinkText: "link text",
  howComplianceWorksLinkUrl: "linkurl",
  howComplianceWorksSteps: [
    {
      title: "step 1",
      imageAssetUrl: "imageurl1",
      body: "body1",
      shortDescription: "description1",
      link: {
        linkText: "link 1 text",
        linkUrl: "linkurl1",
        linkExternal: true,
      },
    },
    {
      title: "step 2",
      imageAssetUrl: "imageurl2",
      body: "bod2",
      shortDescription: "description2",
      link: {
        linkText: "link 2 text",
        linkUrl: "linkurl2",
        linkExternal: true,
      },
    },
  ],
  whoShouldUseItCopy: "copy",
  whoShouldISubmitCopy: "copy",
  whenShouldISubmitTimelineAssetUrl: "timelineurl",
  whatShouldBeSubmittedCopy: "copy",
  whatShouldBeSubmittedDoList: "do list",
  whatShouldBeSubmittedDoNotList: "do not list",
  brandComplianceIsList: "is list",
  brandComplianceIsNotList: "is not list",
};

export const mockComplianceProcess = {
  pageTitle: "page title",
  pageDescription: "page description",
  steps: [
    {
      title: "step 1",
      imageAssetUrl: "imageurl1",
      body: "body1",
      shortDescription: "description1",
      link: {
        linkText: "link 1 text",
        linkUrl: "linkurl1",
        linkExternal: true,
      },
    },
    {
      title: "step 2",
      imageAssetUrl: "imageurl2",
      body: "bod2",
      shortDescription: "description2",
      link: {
        linkText: "link 2 text",
        linkUrl: "linkurl2",
        linkExternal: true,
      },
    },
  ],
};

export const mockComplianceScoring = {
  pageTitle: "page title",
  pageDescription: "page description",
  scorecardAssetUrl: "",
  scoringElements: [
    {
      title: "title 1",
      iconAssetUrl: "asseturl1",
      description: "description 1",
      links: [
        {
          linkText: "link 1 text",
          linkUrl: "linkurl1",
          linkExternal: true,
        },
        {
          linkText: "link 2 text",
          linkUrl: "linkurl2",
          linkExternal: true,
        },
      ],
    },
    {
      title: "title 2",
      iconAssetUrl: "asseturl2",
      description: "description 2",
      links: [
        {
          linkText: "link 1 text",
          linkUrl: "linkurl1",
          linkExternal: true,
        },
        {
          linkText: "link 2 text",
          linkUrl: "linkurl2",
          linkExternal: true,
        },
      ],
    },
  ],
};

export const mockComplianceResources = {
  pageTitle: "page title",
  pageDescription: "page description",
  resources: [
    {
      title: "title 1",
      description: "description 1",
      link: {
        linkText: "link 1 text",
        linkUrl: "linkurl1",
        linkExternal: true,
      },
      external: true,
      imageAssetUrl: "imageurl1",
    },
    {
      title: "title 2",
      description: "description 2",
      link: {
        linkText: "link 2 text",
        linkUrl: "linkurl2",
        linkExternal: true,
      },
      external: true,
      imageAssetUrl: "imageurl2",
    },
  ],
  quickLinks: [
    {
      linkText: "link 1 text",
      linkUrl: "linkurl1",
      linkExternal: true,
    },
    {
      linkText: "link 2 text",
      linkUrl: "linkurl2",
      linkExternal: true,
    },
  ],
};

export const mockComplianceFaqs = {
  pageTitle: "page title",
  pageDescription: "page description",
  sections: [
    {
      title: "title 1",
      items: [
        {
          question: "question 1",
          answer: "answer 1",
        },
        {
          question: "question 2",
          answer: "answer 2",
        },
      ],
    },
    {
      title: "title 2",
      items: [
        {
          question: "question 1",
          answer: "answer 1",
        },
        {
          question: "question 2",
          answer: "answer 2",
        },
      ],
    },
  ],
  hero: {
    heroTitle: "title",
    heroCopy: "copy",
    heroLinkText: "link text",
    heroLinkUrl: "linkurl",
  },
};

export const mockRetailCenter = {
  page: "retail center",
  header: "retail center header",
  hero: {
    headline: "headline",
    subhead: "subhead",
    alerts: [
      {
        title: "alert 1",
        subtitle: "subtitle 1",
        body: "body 1",
        imageDesktop: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        imageMobile: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        cta: {
          label: "label",
          link: "link",
          isExternal: true,
        },
      },
      {
        title: "alert 2",
        subtitle: "subtitle 2",
        body: "body 2",
        imageDesktop: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        imageMobile: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        cta: {
          label: "label",
          link: "link",
          isExternal: true,
        },
      },
    ],
  },
  quickLinks: [
    {
      title: "title 1",
      cta: {
        label: "label",
        link: "link",
        isExternal: true,
      },
      callout: "callout",
    },
    {
      title: "title 2",
      cta: {
        label: "label",
        link: "link",
        isExternal: true,
      },
      callout: "callout",
    },
  ],
};

export const mockRetailBrandedEnvironment = {
  page: "retail branded environment",
  header: "retail branded environment header",
  subheader: "retail branded environment subheader",
  hero: {
    headline: "headline",
    subhead: "subhead",
    alerts: [
      {
        title: "alert 1",
        subtitle: "subtitle 1",
        body: "body 1",
        imageDesktop: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        imageMobile: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        cta: {
          label: "label",
          link: "link",
          isExternal: true,
        },
      },
      {
        title: "alert 2",
        subtitle: "subtitle 2",
        body: "body 2",
        imageDesktop: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        imageMobile: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        cta: {
          label: "label",
          link: "link",
          isExternal: true,
        },
      },
    ],
  },
  assets: [
    {
      imageDesktopUrl: "desktopurl",
      imageMobileUrl: "mobileurl",
      date: new Date(),
      title: "title",
      assetBehavior: "behavior",
      documentUrl: "documentUrl",
      link: "link",
    },
    {
      imageDesktopUrl: "desktopurl2",
      imageMobileUrl: "mobileurl2",
      date: new Date(),
      title: "title2",
      assetBehavior: "behavior2",
      documentUrl: "documentUrl2",
      link: "link2",
    },
  ],
};

export const mockFavorites = [
  {
    id: "0dc754ab-74b9-44af-8fc2-86222b8200b9",
    name: "Favorite Folder Test",
    createdOn: "2023-05-11T00:00:00",
    items: [
      {
        id: "39a3476c-ebf2-4a30-92ad-f66f38a9a361",
        entityId: "asset-with-favorites",
        name: "Test No expiration date",
      },
      {
        id: "993fdc40-2cf1-44dd-8231-ddec04c75f3d",
        entityId: "asset-with-same-favorites",
        name: "Asset test 7 Deny",
      },
      {
        id: "db564667-6f98-4673-925f-37d968bc77c2",
        entityId: "4e8ffaaa-252d-4d8e-b175-1fcba8769f8c",
        name: "Kat Test 2",
      },
      {
        id: "f0b52245-23c7-4887-87b8-df2d88044c69",
        entityId: "596894b5-358e-41f8-b113-377c5b39bd08",
        name: "Multi file test1",
      },
    ],
  },
  {
    id: "11bfb98d-9d7b-4693-a169-ecca989905d0",
    name: "Favorite Folder Test Group",
    createdOn: "2023-06-20T00:00:00",
    items: [
      {
        id: "a6683e95-515f-41da-bec2-86be69f1b578",
        entityId: "0fbb27c2-cf11-476d-a81c-5c660d2e2998",
        name: "Design Advisories Asset",
      },
    ],
  },
  {
    id: "13c970d9-61ab-44cc-a107-fa4dd51ecb4f",
    name: "TEST",
    createdOn: "2023-06-20T00:00:00",
    items: [
      {
        id: "5362c4bb-02f6-4f4c-8dbb-e7a9894ba5f4",
        entityId: "asset-with-favorites",
        name: "Multi file test1",
      },
      {
        id: "7ecfe3ae-7066-492a-a179-484b978fc6c4",
        entityId: "25b217fc-5b53-445d-845f-d82c6cd981fe",
        name: "Asset test 7 Deny",
      },
      {
        id: "d0bb2403-65e8-4202-bd38-689353640d70",
        entityId: "0fbb27c2-cf11-476d-a81c-5c660d2e2998",
        name: "Design Advisories Asset",
      },
    ],
  },
];

export const mockFavoritesPageFolders = [
  {
    id: "0dc754ab-74b9-44af-8fc2-86222b8200b9",
    name: "Favorite Folder Test",
    createdOn: "2023-05-11T00:00:00",
  },
  {
    id: "11bfb98d-9d7b-4693-a169-ecca989905d0",
    name: "Favorite Folder Test Group",
    createdOn: "2023-06-20T00:00:00",
  },
  {
    id: "13c970d9-61ab-44cc-a107-fa4dd51ecb4f",
    name: "TEST",
    createdOn: "2023-06-20T00:00:00",
  },
];

export const mockFavoritesPageItems = [
  {
    id: "39a3476c-ebf2-4a30-92ad-f66f38a9a361",
    title: "Multi file test1",
    url: "url",
    searchType: "compliance",
    isFavorite: true,
    searchId: "search-id-1",
    entityId: "asset-with-favorites",
  },
  {
    "assetId": "dd481174-fc24-4dc4-a366-9f7bae61c0e9",
    "usageRightsCopy": "",
    "expiresOn": "2424-12-31T00:00:00Z",
    "isRestricted": true,
    "altText": "Restricted nonsearchable",
    "files": [
      {
        "id": "27646746-d6cd-4ee4-94e9-0bcd63fe5f12",
        "groupName": null,
        "fileName": "Brand_Central_attachment.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLEXUGJ25P&Expires=1720453662&x-amz-security-token=IQoJb3JpZ2luX2VjENj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDkCNLVBIrTY7g2mR57krNwDozTPZmQfGa1hAiJxC8o9AiEAkP0eE%2FDGHaKCrVvu1mtc7t%2BFjkG%2BeEq0S2QjAu3nhVYqjAQIkf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDEJqcBziXpo9OjsoyirgA0FiJAjXa8H4Y9qjjccIMR6braIF2x3Qi8kM7J%2FcjjEms8AJGnDLFhY4WbqN5O9h6KWFhVjPb1LEcmgwflw73V9tMD14m6hzvsJbK8v0a3Hhl4GqrErtxEs%2Baoa5VEwjXdPaCa7BoAtsA8cd90FAhsXnUx%2BynL%2FN416yQC4JjGPE1Zkykpq34Jd7P3dwMN4J2SdpLSd4PB%2BkQKx9xaycB1tDeIJz9U490e26uJgaJwy2fvALhRRd26pS0XTlIh9xcOG4KzY2FAmiJ%2F3UY5x%2FpFkZWn79XjTpjdCdPs0Kb1eAJjRKQo0%2B3G0xKr9MNpwptGaLXtuETuzI7e04cazRKqhvnyRtmdhWEJP%2Bjfgo4IOTbzT2%2F2yIW4reAUnyHXq3e87vuYZWorxpbko7SbAmFK3V4y4ai1m6Pgx6tfXtyC%2B8xjY61MbReYd34mfb6cICLSwuDOb1XpRRISBpBapOOFvWhMRFEU%2Bv1KKe%2BpFbeQU0LV3QOgBNto1oJ16I1pp2zpWeWU1MyOPubSHRp2OJmtsrHgjjyURE0ttauHpLcUWyS%2F43qiCZzRfqamGuYF%2B%2FEjygPaTqmUoQ9FzGOH09kvD%2B5NG66DqKphREU1iQTi3AlXx2RE5Xgg7wT0sNtEUk%2FDCp4ZW0BjqlASTdPMwUO2CRHfDstEYHrbNGBvsTl95WfyCJbZhzVALfHVsAqc57NgDa0MsDRJdBr0BSs9L26gLu4ma2E7AjGwXRpBNk2dsX3VyusFP116Q%2FnyvAvPO85vRpZotE8Fe6AQxoG3kWMpqW38BtmcLi6txXGHVhYlwGi6swcqi5tIegu3UqoVg7WPK%2FLWdL%2Bs7qWq2D9k5wDI6bJLh3BegHsteK2gFjEw%3D%3D&Signature=n7gyV%2Fji14mmJfEhOOk6MzmUwWQ%3D",
          "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLEXUGJ25P&Expires=1720453663&x-amz-security-token=IQoJb3JpZ2luX2VjENj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDkCNLVBIrTY7g2mR57krNwDozTPZmQfGa1hAiJxC8o9AiEAkP0eE%2FDGHaKCrVvu1mtc7t%2BFjkG%2BeEq0S2QjAu3nhVYqjAQIkf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDEJqcBziXpo9OjsoyirgA0FiJAjXa8H4Y9qjjccIMR6braIF2x3Qi8kM7J%2FcjjEms8AJGnDLFhY4WbqN5O9h6KWFhVjPb1LEcmgwflw73V9tMD14m6hzvsJbK8v0a3Hhl4GqrErtxEs%2Baoa5VEwjXdPaCa7BoAtsA8cd90FAhsXnUx%2BynL%2FN416yQC4JjGPE1Zkykpq34Jd7P3dwMN4J2SdpLSd4PB%2BkQKx9xaycB1tDeIJz9U490e26uJgaJwy2fvALhRRd26pS0XTlIh9xcOG4KzY2FAmiJ%2F3UY5x%2FpFkZWn79XjTpjdCdPs0Kb1eAJjRKQo0%2B3G0xKr9MNpwptGaLXtuETuzI7e04cazRKqhvnyRtmdhWEJP%2Bjfgo4IOTbzT2%2F2yIW4reAUnyHXq3e87vuYZWorxpbko7SbAmFK3V4y4ai1m6Pgx6tfXtyC%2B8xjY61MbReYd34mfb6cICLSwuDOb1XpRRISBpBapOOFvWhMRFEU%2Bv1KKe%2BpFbeQU0LV3QOgBNto1oJ16I1pp2zpWeWU1MyOPubSHRp2OJmtsrHgjjyURE0ttauHpLcUWyS%2F43qiCZzRfqamGuYF%2B%2FEjygPaTqmUoQ9FzGOH09kvD%2B5NG66DqKphREU1iQTi3AlXx2RE5Xgg7wT0sNtEUk%2FDCp4ZW0BjqlASTdPMwUO2CRHfDstEYHrbNGBvsTl95WfyCJbZhzVALfHVsAqc57NgDa0MsDRJdBr0BSs9L26gLu4ma2E7AjGwXRpBNk2dsX3VyusFP116Q%2FnyvAvPO85vRpZotE8Fe6AQxoG3kWMpqW38BtmcLi6txXGHVhYlwGi6swcqi5tIegu3UqoVg7WPK%2FLWdL%2Bs7qWq2D9k5wDI6bJLh3BegHsteK2gFjEw%3D%3D&Signature=AOGqtlor1AIjkH%2B%2BmBA%2BkQ%2BgnNg%3D",
          "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLEXUGJ25P&Expires=1720453663&x-amz-security-token=IQoJb3JpZ2luX2VjENj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDkCNLVBIrTY7g2mR57krNwDozTPZmQfGa1hAiJxC8o9AiEAkP0eE%2FDGHaKCrVvu1mtc7t%2BFjkG%2BeEq0S2QjAu3nhVYqjAQIkf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDEJqcBziXpo9OjsoyirgA0FiJAjXa8H4Y9qjjccIMR6braIF2x3Qi8kM7J%2FcjjEms8AJGnDLFhY4WbqN5O9h6KWFhVjPb1LEcmgwflw73V9tMD14m6hzvsJbK8v0a3Hhl4GqrErtxEs%2Baoa5VEwjXdPaCa7BoAtsA8cd90FAhsXnUx%2BynL%2FN416yQC4JjGPE1Zkykpq34Jd7P3dwMN4J2SdpLSd4PB%2BkQKx9xaycB1tDeIJz9U490e26uJgaJwy2fvALhRRd26pS0XTlIh9xcOG4KzY2FAmiJ%2F3UY5x%2FpFkZWn79XjTpjdCdPs0Kb1eAJjRKQo0%2B3G0xKr9MNpwptGaLXtuETuzI7e04cazRKqhvnyRtmdhWEJP%2Bjfgo4IOTbzT2%2F2yIW4reAUnyHXq3e87vuYZWorxpbko7SbAmFK3V4y4ai1m6Pgx6tfXtyC%2B8xjY61MbReYd34mfb6cICLSwuDOb1XpRRISBpBapOOFvWhMRFEU%2Bv1KKe%2BpFbeQU0LV3QOgBNto1oJ16I1pp2zpWeWU1MyOPubSHRp2OJmtsrHgjjyURE0ttauHpLcUWyS%2F43qiCZzRfqamGuYF%2B%2FEjygPaTqmUoQ9FzGOH09kvD%2B5NG66DqKphREU1iQTi3AlXx2RE5Xgg7wT0sNtEUk%2FDCp4ZW0BjqlASTdPMwUO2CRHfDstEYHrbNGBvsTl95WfyCJbZhzVALfHVsAqc57NgDa0MsDRJdBr0BSs9L26gLu4ma2E7AjGwXRpBNk2dsX3VyusFP116Q%2FnyvAvPO85vRpZotE8Fe6AQxoG3kWMpqW38BtmcLi6txXGHVhYlwGi6swcqi5tIegu3UqoVg7WPK%2FLWdL%2Bs7qWq2D9k5wDI6bJLh3BegHsteK2gFjEw%3D%3D&Signature=3WYedv8Ek8bQi0kyebFkueITTXE%3D",
          "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLb142ed9168eb413bb285a7f80efc0238_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLEXUGJ25P&Expires=1720453663&x-amz-security-token=IQoJb3JpZ2luX2VjENj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIDkCNLVBIrTY7g2mR57krNwDozTPZmQfGa1hAiJxC8o9AiEAkP0eE%2FDGHaKCrVvu1mtc7t%2BFjkG%2BeEq0S2QjAu3nhVYqjAQIkf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDEJqcBziXpo9OjsoyirgA0FiJAjXa8H4Y9qjjccIMR6braIF2x3Qi8kM7J%2FcjjEms8AJGnDLFhY4WbqN5O9h6KWFhVjPb1LEcmgwflw73V9tMD14m6hzvsJbK8v0a3Hhl4GqrErtxEs%2Baoa5VEwjXdPaCa7BoAtsA8cd90FAhsXnUx%2BynL%2FN416yQC4JjGPE1Zkykpq34Jd7P3dwMN4J2SdpLSd4PB%2BkQKx9xaycB1tDeIJz9U490e26uJgaJwy2fvALhRRd26pS0XTlIh9xcOG4KzY2FAmiJ%2F3UY5x%2FpFkZWn79XjTpjdCdPs0Kb1eAJjRKQo0%2B3G0xKr9MNpwptGaLXtuETuzI7e04cazRKqhvnyRtmdhWEJP%2Bjfgo4IOTbzT2%2F2yIW4reAUnyHXq3e87vuYZWorxpbko7SbAmFK3V4y4ai1m6Pgx6tfXtyC%2B8xjY61MbReYd34mfb6cICLSwuDOb1XpRRISBpBapOOFvWhMRFEU%2Bv1KKe%2BpFbeQU0LV3QOgBNto1oJ16I1pp2zpWeWU1MyOPubSHRp2OJmtsrHgjjyURE0ttauHpLcUWyS%2F43qiCZzRfqamGuYF%2B%2FEjygPaTqmUoQ9FzGOH09kvD%2B5NG66DqKphREU1iQTi3AlXx2RE5Xgg7wT0sNtEUk%2FDCp4ZW0BjqlASTdPMwUO2CRHfDstEYHrbNGBvsTl95WfyCJbZhzVALfHVsAqc57NgDa0MsDRJdBr0BSs9L26gLu4ma2E7AjGwXRpBNk2dsX3VyusFP116Q%2FnyvAvPO85vRpZotE8Fe6AQxoG3kWMpqW38BtmcLi6txXGHVhYlwGi6swcqi5tIegu3UqoVg7WPK%2FLWdL%2Bs7qWq2D9k5wDI6bJLh3BegHsteK2gFjEw%3D%3D&Signature=qnqWSXdYAvb44U%2FmX6uYqTinvBM%3D",
          "isImage": true
        }
      }
    ],
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "filterIds": [
      "76007f55-b998-4883-8e53-64c5ecec4a51",
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
      "3d8d9269-63a0-437f-90ec-f70204d5c10f",
      "9bedcce5-f0e5-4b3f-86db-2ee0b810c091"
    ],
    "category": "Photography",
    "id": "dd481174-fc24-4dc4-a366-9f7bae61c0e9",
    "title": "Restricted nonsearchable",
    "url": "assets/details/dd481174-fc24-4dc4-a366-9f7bae61c0e9",
    "searchType": "asset",
    "isFavorite": true,
    "searchId": "dd481174-fc24-4dc4-a366-9f7bae61c0e9"
  },
  {
    id: "d0bb2403-65e8-4202-bd38-689353640d70",
    title: "Design Advisories Asset",
    url: "url",
    searchType: "training",
    isFavorite: true,
    searchId: "search-id-3",
    entityId: "0fbb27c2-cf11-476d-a81c-5c660d2e2998",
  },
];

export const mockFavoritesPageItemsSwitchTabs = [
  {
    id: "39a3476c-ebf2-4a30-92ad-f66f38a9a361",
    title: "Multi file test1",
    url: "url",
    searchType: "compliance",
    isFavorite: true,
    searchId: "search-id-1",
    entityId: "asset-with-favorites",
  },
  {
    id: "7ecfe3ae-7066-492a-a179-484b978fc6c4",
    title: "Asset test 7 Deny",
    url: "url",
    searchType: "asset",
    isFavorite: true,
    searchId: "search-id-2",
    assetId: "25b217fc-5b53-445d-845f-d82c6cd981fe",
  }
];

export const mockActiveAsset = {
  isActive: true,
  isSearchable: true,
  isRestricted: true,
  canDownload: true,
  isFavorite: true,
  name: "Asset 36",
  description: "Asset 36",
  source: "Asset 36",
  altText: "Asset 36",
  keywords: "",
  jobNumber: "",
  guidelinesUrl: "",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  downloadCount: 1,
  thumbnailCountOverride: 0,
  approvalStatus: ApprovalStatus.Approved,
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "1",
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fc679",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  thumbnails: undefined,
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: false,
    canEdit: false,
    canDeactivate: false,
    canExpire: false,
    canUpdateAssetAccessRequest: false,
    isAdmin: false,
  },
  id: "asset-is-active",
  createdOn: "2022-10-05T19:12:25.759419Z",
  modifiedOn: "2023-09-26T16:57:46.174246Z",
  expiresOn: "2024-06-26T14:30:00Z",
  score: 0,
};

export const mockInactiveAsset = {
  isActive: false,
  isSearchable: true,
  isRestricted: true,
  canDownload: true,
  isFavorite: true,
  name: "Asset 36",
  description: "Asset 36",
  source: "Asset 36",
  altText: "Asset 36",
  keywords: "",
  jobNumber: "",
  guidelinesUrl: "",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  downloadCount: 1,
  thumbnailCountOverride: 0,
  approvalStatus: ApprovalStatus.Approved,
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "1",
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fc679",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  thumbnails: undefined,
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  id: "asset-not-active",
  createdOn: "2022-10-05T19:12:25.759419Z",
  modifiedOn: "2023-09-26T16:57:46.174246Z",
  expiresOn: "2024-06-26T14:30:00Z",
  score: 0,
};

// TODO: will need to update once mutliple selected modal changes to include non-assets
export const mockSelectedAssets = [
  {
    isActive: true,
    isSearchable: true,
    isRestricted: true,
    canDownload: true,
    isFavorite: true,
    name: "Asset 36",
    description: "Asset 36",
    source: "Asset 36",
    altText: "Asset 36",
    keywords: "",
    jobNumber: "",
    guidelinesUrl: "",
    copyright: "",
    subtitle: "Asset 36",
    usageRightsCopy: "Usage rights copy test",
    downloadCount: 1,
    thumbnailCountOverride: 0,
    approvalStatus: "Approved",
    files: [
      {
        id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
        groupName: "1",
        fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
        approvalStatus: "Pending",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
          isImage: true,
        },
      },
      {
        id: "dc8bea19-422d-4a75-a297-bdea822fc679",
        groupName: "2",
        fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
          isImage: true,
        },
      },
    ],
    filterIds: [
      "76007f55-b998-4883-8e53-64c5ecec4a51",
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
      "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
    ],
    thumbnails: null,
    userActions: {
      hasAcceptedUsageRights: false,
      hasPendingAccessRequest: false,
      canDownload: true,
      canEdit: true,
      canDeactivate: true,
      canExpire: true,
      canUpdateAssetAccessRequest: true,
      isAdmin: true,
    },
    id: "827e6605-4342-411e-9de3-848d80e0ac19",
    createdOn: "2022-10-05T19:12:25.759419Z",
    modifiedOn: "2023-09-26T16:57:46.174246Z",
    expiresOn: "2024-06-26T14:30:00Z",
    score: 0,
  },
  {
    isActive: true,
    isSearchable: true,
    isRestricted: false,
    canDownload: true,
    isFavorite: true,
    name: "Elaina Test Asset 6",
    description: "test 6",
    source: "internet",
    altText: "test 6",
    keywords: "",
    jobNumber: "",
    guidelinesUrl: "",
    copyright: "",
    subtitle: "",
    usageRightsCopy: "",
    downloadCount: 2,
    thumbnailCountOverride: 0,
    approvalStatus: "Approved",
    files: [
      {
        id: "3307a28c-ffda-46ed-b98e-4446dea2116d",
        groupName: null,
        fileName: "png-transparent-watermark-thumbnail.png",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_110.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=aiSJr9Mz56GbQR3KdjGIP%2FvDrns%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_400.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ODnBZFnJ%2FESYwzlFDjSKe%2F9%2Fi2s%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_500.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=uC2zzHbaqDjoq8E6DMucGLcEb3U%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_700.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=uDfVEDMhyneHle2X8UGWHB0uXIY%3D",
          isImage: true,
        },
      },
    ],
    filterIds: [
      "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
      "c51db643-479b-46a3-b3ea-4d5c23f0db1d",
      "00b2f403-8017-4498-b591-5e7dbac2f47c",
    ],
    thumbnails: null,
    userActions: {
      hasAcceptedUsageRights: true,
      hasPendingAccessRequest: false,
      canDownload: true,
      canEdit: true,
      canDeactivate: true,
      canExpire: true,
      canUpdateAssetAccessRequest: true,
      isAdmin: true,
    },
    id: "6fa19b24-2542-46ab-b932-0fa8b9f71ecb",
    createdOn: "2023-09-25T22:59:05.010847Z",
    modifiedOn: "2023-09-26T14:59:17.760797Z",
    expiresOn: "2026-10-26T04:00:00Z",
    score: 0,
  },
];

export const mockRetailDesignAdvisoriesAssets = [
  {
    isActive: true,
    isSearchable: true,
    isRestricted: true,
    canDownload: true,
    isFavorite: true,
    title: "Asset 36",
    description: "Asset 36",
    source: "Asset 36",
    altText: "Asset 36",
    keywords: "",
    jobNumber: "",
    guidelinesUrl: "",
    copyright: "",
    subtitle: "Asset 36",
    usageRightsCopy: "Usage rights copy test",
    downloadCount: 1,
    thumbnailCountOverride: 0,
    approvalStatus: "Approved",
    files: [
      {
        id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
        groupName: "1",
        fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
        approvalStatus: "Pending",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
          isImage: true,
        },
      },
      {
        id: "dc8bea19-422d-4a75-a297-bdea822fc679",
        groupName: "2",
        fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
          isImage: true,
        },
      },
    ],
    filterIds: [
      "76007f55-b998-4883-8e53-64c5ecec4a51",
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
      "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
    ],
    thumbnails: null,
    userActions: {
      hasAcceptedUsageRights: false,
      hasPendingAccessRequest: false,
      canDownload: true,
      canEdit: true,
      canDeactivate: true,
      canExpire: true,
      canUpdateAssetAccessRequest: true,
      isAdmin: true,
    },
    id: "827e6605-4342-411e-9de3-848d80e0ac19",
    createdOn: "2022-10-05T19:12:25.759419Z",
    modifiedOn: "2023-09-26T16:57:46.174246Z",
    expiresOn: "2024-06-26T14:30:00Z",
    score: 0,
  },
  {
    isActive: true,
    isSearchable: true,
    isRestricted: false,
    canDownload: true,
    isFavorite: true,
    title: "Elaina Test Asset 6",
    description: "test 6",
    source: "internet",
    altText: "test 6",
    keywords: "",
    jobNumber: "",
    guidelinesUrl: "",
    copyright: "",
    subtitle: "",
    usageRightsCopy: "",
    downloadCount: 2,
    thumbnailCountOverride: 0,
    approvalStatus: "Approved",
    files: [
      {
        id: "3307a28c-ffda-46ed-b98e-4446dea2116d",
        groupName: null,
        fileName: "png-transparent-watermark-thumbnail.png",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_110.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=aiSJr9Mz56GbQR3KdjGIP%2FvDrns%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_400.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ODnBZFnJ%2FESYwzlFDjSKe%2F9%2Fi2s%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_500.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=uC2zzHbaqDjoq8E6DMucGLcEb3U%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_700.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=uDfVEDMhyneHle2X8UGWHB0uXIY%3D",
          isImage: true,
        },
      },
    ],
    filterIds: [
      "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
      "c51db643-479b-46a3-b3ea-4d5c23f0db1d",
      "00b2f403-8017-4498-b591-5e7dbac2f47c",
    ],
    thumbnails: null,
    userActions: {
      hasAcceptedUsageRights: true,
      hasPendingAccessRequest: false,
      canDownload: true,
      canEdit: true,
      canDeactivate: true,
      canExpire: true,
      canUpdateAssetAccessRequest: true,
      isAdmin: true,
    },
    id: "6fa19b24-2542-46ab-b932-0fa8b9f71ecb",
    createdOn: "2023-09-25T22:59:05.010847Z",
    modifiedOn: "2023-09-26T14:59:17.760797Z",
    expiresOn: "2026-10-26T04:00:00Z",
    score: 0,
  },
];

// TODO: will need to update once mutliple selected modal changes to include non-assets
export const mockSelectedAssetsWithRightsAccepted = [
  {
    isActive: true,
    isSearchable: true,
    isRestricted: true,
    canDownload: true,
    isFavorite: true,
    name: "Asset 36",
    description: "Asset 36",
    source: "Asset 36",
    altText: "Asset 36",
    keywords: "",
    jobNumber: "",
    guidelinesUrl: "",
    copyright: "",
    subtitle: "Asset 36",
    usageRightsCopy: "Usage rights copy test",
    downloadCount: 1,
    thumbnailCountOverride: 0,
    approvalStatus: "Approved",
    files: [
      {
        id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
        groupName: "1",
        fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
        approvalStatus: "Pending",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
          isImage: true,
        },
      },
      {
        id: "dc8bea19-422d-4a75-a297-bdea822fc679",
        groupName: "2",
        fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
          isImage: true,
        },
      },
    ],
    filterIds: [
      "76007f55-b998-4883-8e53-64c5ecec4a51",
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
      "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
    ],
    thumbnails: null,
    userActions: {
      hasAcceptedUsageRights: true,
      hasPendingAccessRequest: false,
      canDownload: true,
      canEdit: true,
      canDeactivate: true,
      canExpire: true,
      canUpdateAssetAccessRequest: true,
      isAdmin: true,
    },
    id: "827e6605-4342-411e-9de3-848d80e0ac19",
    createdOn: "2022-10-05T19:12:25.759419Z",
    modifiedOn: "2023-09-26T16:57:46.174246Z",
    expiresOn: "2024-06-26T14:30:00Z",
    score: 0,
  },
  {
    isActive: true,
    isSearchable: true,
    isRestricted: false,
    canDownload: true,
    isFavorite: true,
    name: "Elaina Test Asset 6",
    description: "test 6",
    source: "internet",
    altText: "test 6",
    keywords: "",
    jobNumber: "",
    guidelinesUrl: "",
    copyright: "",
    subtitle: "",
    usageRightsCopy: "",
    downloadCount: 2,
    thumbnailCountOverride: 0,
    approvalStatus: "Approved",
    files: [
      {
        id: "3307a28c-ffda-46ed-b98e-4446dea2116d",
        groupName: null,
        fileName: "png-transparent-watermark-thumbnail.png",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_110.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=aiSJr9Mz56GbQR3KdjGIP%2FvDrns%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_400.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ODnBZFnJ%2FESYwzlFDjSKe%2F9%2Fi2s%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_500.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=uC2zzHbaqDjoq8E6DMucGLcEb3U%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLebd57eb3b7fe4488880ba35ec89f3ffe_700.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=uDfVEDMhyneHle2X8UGWHB0uXIY%3D",
          isImage: true,
        },
      },
    ],
    filterIds: [
      "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
      "c51db643-479b-46a3-b3ea-4d5c23f0db1d",
      "00b2f403-8017-4498-b591-5e7dbac2f47c",
    ],
    thumbnails: null,
    userActions: {
      hasAcceptedUsageRights: true,
      hasPendingAccessRequest: false,
      canDownload: true,
      canEdit: true,
      canDeactivate: true,
      canExpire: true,
      canUpdateAssetAccessRequest: true,
      isAdmin: true,
    },
    id: "6fa19b24-2542-46ab-b932-0fa8b9f71ecb",
    createdOn: "2023-09-25T22:59:05.010847Z",
    modifiedOn: "2023-09-26T14:59:17.760797Z",
    expiresOn: "2026-10-26T04:00:00Z",
    score: 0,
  },
];

// TODO: fix the assets array to have the new objects with title
export const mockRetailDesignAdvisories = {
  page: "retail design advisories",
  header: "retail design advisories header",
  subheader: "retail design advisories subheader",
  hero: {
    headline: "headline",
    subhead: "subhead",
    alerts: [
      {
        title: "alert 1",
        subtitle: "subtitle 1",
        body: "body 1",
        imageDesktop: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        imageMobile: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        cta: {
          label: "label",
          link: "link",
          isExternal: true,
        },
      },
      {
        title: "alert 2",
        subtitle: "subtitle 2",
        body: "body 2",
        imageDesktop: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        imageMobile: {
          fields: {
            file: {
              url: "url",
            },
          },
        },
        cta: {
          label: "label",
          link: "link",
          isExternal: true,
        },
      },
    ],
  },
  assets: mockRetailDesignAdvisoriesAssets,
};

let oneYearsTime = new Date();
oneYearsTime.setDate(oneYearsTime.getDate() + 365);

export const assetEmpty = {
  isActive: true,
  isSearchable: true,
  isRestricted: false,
  canDownload: true,
  isFavorite: true,
  name: "Asset 36",
  description: "Asset 36",
  source: "Asset 36",
  altText: "Asset 36",
  keywords: "",
  jobNumber: "",
  guidelinesUrl: "",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  downloadCount: 1,
  thumbnailCountOverride: 0,
  approvalStatus: "Approved",
  files: undefined,
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  thumbnails: null,
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: false,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: false,
  },
  id: "827e6605-4342-411e-9de3-848d80e0ac19",
  createdOn: "2022-10-05T19:12:25.759419Z",
  modifiedOn: "2023-09-26T16:57:46.174246Z",
  lastModifiedDate: "2023-09-26T16:57:46.174246Z",
  expiresOn: formatDate(oneYearsTime),
  score: 0,
};

export const itemEmpty = {
  url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
  searchType: "template",
  isSearchable: true,
  isRestricted: false,
  isFavorite: true,
  title: "Asset 36",
  description: "Asset 36",
  altText: "Asset 36",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  files: [],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: false,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: false,
  },
  id: "827e6605-4342-411e-9de3-848d80e0ac19",
  searchId: "827e6605-4342-411e-9de3-848d80e0ac19",
  assetId: "827e6605-4342-411e-9de3-848d80e0ac19",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  expiresOn: formatDate(oneYearsTime),
};

export const trainingItem = {
  id: "13b4e470-57e8-4c7b-98d9-e40fb27f3499",
  searchId: "13b4e470-57e8-4c7b-98d9-e40fb27f3499",
  entityId: "13b4e470-57e8-4c7b-98d9-e40fb27f3499",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  searchType: "training",
  url: "training/videos/url",
  title: "Verizon business cards & stationery",
  videoSlug: "business-cards-and-stationery",
  thumbnailAssetUrl:
    "https://images.ctfassets.net/ve6konwi38y5/17sTCemA8NAkiD81hN0Xfb/c7b7095e5ebfda4d35d943147735cab8/verizon-business-cards-stationary.png",
  link1Text: "Learn more",
  link1Url:
    "/guidelines/details/questions-and-answers/get-started/business-cards",
  link2Text: "Download stationery",
  link2Url: "/assets/search?saved=7b19c278-9c15-4afd-b5d5-e02b881513bd",
  playlistSlug: "brand-identity-training-video-series",
};

export const assetWithFiles = {
  id: "test-id",
  isActive: true,
  isSearchable: true,
  isRestricted: true,
  canDownload: true,
  isFavorite: true,
  name: "Asset 36",
  description: "Asset 36",
  source: "Asset 36",
  altText: "Asset 36",
  keywords: "test,keyword,asset",
  jobNumber: "",
  guidelinesUrl: "",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  downloadCount: 1,
  thumbnailCountOverride: 0,
  approvalStatus: "Approved",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "1",
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fc679",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  thumbnails: null,
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  createdOn: "2022-10-05T19:12:25.759419Z",
  modifiedOn: "2023-09-26T16:57:46.174246Z",
  lastModifiedDate: "2023-09-26T16:57:46.174246Z",
  expiresOn: formatDate(oneYearsTime),
  score: 0,
};
export const itemWithFiles = {
  url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
  searchType: "asset",
  id: "test-id",
  isSearchable: true,
  isRestricted: true,
  isFavorite: true,
  title: "Asset 36",
  description: "Asset 36",
  altText: "Asset 36",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "1",
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fc679",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  searchId: "test-id",
  assetId: "test-id",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  expiresOn: formatDate(oneYearsTime),
};

export const assetDeactivated = {
  id: "test-id",
  isActive: false,
  isSearchable: true,
  isRestricted: true,
  canDownload: true,
  isFavorite: true,
  name: "Asset 36",
  description: "Asset 36",
  source: "Asset 36",
  altText: "Asset 36",
  keywords: "test,keyword,asset",
  jobNumber: "",
  guidelinesUrl: "",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  downloadCount: 1,
  thumbnailCountOverride: 0,
  approvalStatus: "Approved",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "1",
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fc679",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  thumbnails: null,
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  createdOn: "2022-10-05T19:12:25.759419Z",
  modifiedOn: "2023-09-26T16:57:46.174246Z",
  lastModifiedDate: "2023-09-26T16:57:46.174246Z",
  expiresOn: formatDate(oneYearsTime),
  score: 0,
};

export const itemWithSingleFile = {
  searchId: "test-id",
  assetId: "test-id",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
  searchType: "asset",
  id: "test-id",
  isSearchable: true,
  isRestricted: true,
  isFavorite: true,
  title: "Asset 36",
  description: "Asset 36",
  altText: "Asset 36",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: null,
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  expiresOn: formatDate(oneYearsTime),
};

let nextWeek = new Date();
nextWeek.setDate(nextWeek.getDate() + 7);

export const itemExpiresSoon = {
  searchId: "827e6605-4342-411e-9de3-848d80e0ac19",
  assetId: "827e6605-4342-411e-9de3-848d80e0ac19",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
  searchType: "asset",
  isSearchable: true,
  isRestricted: true,
  isFavorite: false,
  title: "Asset 36",
  description: "Asset 36",
  altText: "Asset 36",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "1",
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  id: "827e6605-4342-411e-9de3-848d80e0ac19",
  expiresOn: formatDate(nextWeek),
};

let yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export const assetExpired = {
  isActive: true,
  isSearchable: true,
  isRestricted: true,
  canDownload: true,
  isFavorite: true,
  name: "Asset 36",
  description: "Asset 36",
  source: "Asset 36",
  altText: "Asset 36",
  keywords: "",
  jobNumber: "",
  guidelinesUrl: "",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  downloadCount: 1,
  thumbnailCountOverride: 0,
  approvalStatus: "Approved",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "1",
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  thumbnails: null,
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  id: "827e6605-4342-411e-9de3-848d80e0ac19",
  createdOn: "2022-10-05T19:12:25.759419Z",
  modifiedOn: "2023-09-26T16:57:46.174246Z",
  lastModifiedDate: "2023-09-26T16:57:46.174246Z",
  expiresOn: formatDate(yesterday),
  score: 0,
};

export const itemExpired = {
  searchId: "827e6605-4342-411e-9de3-848d80e0ac19",
  assetId: "827e6605-4342-411e-9de3-848d80e0ac19",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
  searchType: "asset",
  isSearchable: true,
  isRestricted: true,
  isFavorite: true,
  title: "Asset 36",
  description: "Asset 36",
  altText: "Asset 36",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "1",
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  id: "827e6605-4342-411e-9de3-848d80e0ac19",
  expiresOn: formatDate(yesterday),
};

export const itemWithFilesInSameGroup = {
  searchId: "827e6605-4342-411e-9de3-848d80e0ac19",
  assetId: "827e6605-4342-411e-9de3-848d80e0ac19",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
  searchType: "asset",
  isSearchable: true,
  isRestricted: true,
  isFavorite: true,
  title: "Asset 36 Name Longer an 64 character long to hit the required test there",
  description: "Asset 36",
  altText: "Asset 36",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  files: [
    {
      currentVersionId: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "1",
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fc679",
      groupName: "1",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  userActions: {
    hasAcceptedUsageRights: true,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  id: "827e6605-4342-411e-9de3-848d80e0ac19",
  expiresOn: "2024-06-26T14:30:00Z",
};

export const itemWithFilesNoGroup = {
  searchId: "827e6605-4342-411e-9de3-848d80e0ac19",
  assetId: "827e6605-4342-411e-9de3-848d80e0ac19",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
  searchType: "retail",
  isSearchable: true,
  isRestricted: true,
  canDownload: true,
  isFavorite: true,
  title: "Asset 36 Name Longer an 64 character long to hit the required test there",
  description: "Asset 36",
  altText: "Asset 36",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  files: [
    {
      currentVersionId: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: null,
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: false,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fc679",
      groupName: null,
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: false,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  userActions: {
    hasAcceptedUsageRights: true,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  id: "827e6605-4342-411e-9de3-848d80e0ac19",
  expiresOn: "2024-06-26T14:30:00Z",
};

export const itemRequiresDownloadModal = {
  id: "test-id",
  searchId: "test-id",
  assetId: "test-id",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
  searchType: "asset",
  isSearchable: true,
  isRestricted: true,
  isFavorite: true,
  title: "Asset 36",
  description: "Asset 36",
  altText: "Asset 36",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      currentVersionId: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: null,
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fc679",
      currentVersionId: "dc8bea19-422d-4a75-a297-bdea822fc679",
      groupName: "1",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fcdfe",
      currentVersionId: "dc8bea19-422d-4a75-a297-bdea822fcdfe",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822f33fe",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  expiresOn: formatDate(oneYearsTime),
};

export const assetRequiresAccessModal = {
  id: "test-id",
  isActive: true,
  isSearchable: true,
  isRestricted: true,
  canDownload: true,
  isFavorite: true,
  name: "Asset 36",
  description: "Asset 36",
  source: "Asset 36",
  altText: "Asset 36",
  keywords: "",
  jobNumber: "",
  guidelinesUrl: "",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  downloadCount: 1,
  thumbnailCountOverride: 0,
  approvalStatus: "Approved",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      currentVersionId: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: null,
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fc679",
      currentVersionId: "dc8bea19-422d-4a75-a297-bdea822fc679",
      groupName: "1",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fcdfe",
      currentVersionId: "dc8bea19-422d-4a75-a297-bdea822fcdfe",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822f33fe",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  thumbnails: null,
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: false,
    canEdit: false,
    canDeactivate: false,
    canExpire: false,
    canUpdateAssetAccessRequest: false,
    isAdmin: false,
  },
  createdOn: "2022-10-05T19:12:25.759419Z",
  modifiedOn: "2023-09-26T16:57:46.174246Z",
  lastModifiedDate: "2023-09-26T16:57:46.174246Z",
  expiresOn: formatDate(oneYearsTime),
  score: 0,
};

export const assetWithPendingRequest = {
  id: "test-id",
  isActive: true,
  isSearchable: true,
  isRestricted: true,
  canDownload: true,
  isFavorite: true,
  // name: "Asset 36",
  description: "Asset 36",
  source: "Asset 36",
  altText: "Asset 36",
  keywords: "",
  jobNumber: "",
  guidelinesUrl: "",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  downloadCount: 1,
  thumbnailCountOverride: 0,
  approvalStatus: "Approved",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      currentVersionId: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: null,
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Pending",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fc679",
      currentVersionId: "dc8bea19-422d-4a75-a297-bdea822fc679",
      groupName: "1",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822fcdfe",
      currentVersionId: "dc8bea19-422d-4a75-a297-bdea822fcdfe",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
    {
      id: "dc8bea19-422d-4a75-a297-bdea822f33fe",
      groupName: "2",
      fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  thumbnails: null,
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: true,
    canDownload: false,
    canEdit: false,
    canDeactivate: false,
    canExpire: false,
    canUpdateAssetAccessRequest: false,
    isAdmin: false,
  },
  createdOn: "2022-10-05T19:12:25.759419Z",
  modifiedOn: "2023-09-26T16:57:46.174246Z",
  lastModifiedDate: "2023-09-26T16:57:46.174246Z",
  expiresOn: formatDate(oneYearsTime),
  score: 0,
};

export const itemRequiresUsageRightsModal = {
  id: "test-id",
  searchId: "test-id",
  assetId: "test-id",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
  searchType: "asset",
  isSearchable: true,
  isRestricted: true,
  isFavorite: true,
  title: "Asset 36",
  description: "Asset 36",
  altText: "Asset 36",
  copyright: "",
  subtitle: "Asset 36",
  usageRightsCopy: "Usage rights copy test",
  files: [
    {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "1",
      fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "76007f55-b998-4883-8e53-64c5ecec4a51",
    "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
    "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
  ],
  userActions: {
    hasAcceptedUsageRights: false,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  expiresOn: formatDate(oneYearsTime),
};

export const suggestedUserData = [
  {
    id: "0",
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@gmail.com",
    name: "John Doe",
    company: "Company",
    phone: "555-5555",
    status: "Active",
  },
];
export const mockDeleteUser = {
  AccountType: "AgencyOrSupplier",
  Company: "EP",
  Country: "76d5efb1-82e0-478b-ba75-cda9f40437b1",
  CreatedOn: "2022-05-02T17:45:34.88539Z",
  CustomEmploymentTitle: "Custom title",
  Email: "kat.klauber+usertest21@epandcompany.com",
  EmploymentTitle: "Agency CX/tech",
  FirstName: "Kat",
  GroupIds: [
    "d7b9bad7-6c5d-45c7-b1de-a7b47792073a",
    "6ddb55f5-b664-4018-b66b-919aefd42a45",
  ],
  Id: "e59ec9a0-62d3-469c-9120-6d35a34203fa",
  IsSso: false,
  LastName: "Klauber",
  PermissionIds: [
    "1b7e0bba-3d01-461d-9365-524d965be48f",
    "fcc032ba-f68f-46b6-8923-80b7be7d1a04",
  ],
  PhoneNumber: "8888888",
  RoleIds: ["ee63ca17-5ea2-4ab0-af67-0282915c4b17"],
  Status: "Active",
  VerizonContact: undefined,
};

export const mockDeletedUser = {
  AccountType: "AgencyOrSupplier",
  Company: "EP",
  Country: "76d5efb1-82e0-478b-ba75-cda9f40437b1",
  CreatedOn: "2022-05-02T17:45:34.88539Z",
  CustomEmploymentTitle: "Custom title",
  Email: "kat.klauber+usertest21@epandcompany.com",
  EmploymentTitle: "Agency CX/tech",
  FirstName: "Kat",
  GroupIds: [
    "d7b9bad7-6c5d-45c7-b1de-a7b47792073a",
    "6ddb55f5-b664-4018-b66b-919aefd42a45",
  ],
  Id: "e59ec9a0-62d3-469c-9120-6d35a34203fa",
  IsSso: false,
  LastName: "Klauber",
  PermissionIds: [
    "1b7e0bba-3d01-461d-9365-524d965be48f",
    "fcc032ba-f68f-46b6-8923-80b7be7d1a04",
  ],
  PhoneNumber: "8888888",
  RoleIds: ["ee63ca17-5ea2-4ab0-af67-0282915c4b17"],
  Status: "Deleted",
  VerizonContact: undefined,
};

export const mockDeleteGroup = {
  Company: "EP",
  Id: "6ddb55f5-b664-4018-b66b-919aefd42a45",
  Name: "Test Group 1",
  Status: "Active",
};

export const mockDeletedGroup = {
  Company: "EP",
  Id: "6ddb55f5-b664-4018-b66b-919aefd42a45",
  Name: "Test Group 1",
  Status: "Deleted",
};

export const mockFeedbackOptions = [
  "Not what I searched for",
  "Wrong size or format",
];

export const mockFunctionalAreas = [
  {
    id: "functional-area-1",
    name: "Functional area 1",
    helpText: "Help text for functional area 1",
  },
  {
    id: "functional-area-2",
    name: "Functional area 2",
    helpText: "Help text for functional area 2",
  },
];

export const mockChannels = [
  {
    id: "channel-1",
    name: "Channel 1",
    helpText: "Help text for channel 1",
  },
  {
    id: "channel-2",
    name: "Channel 2",
    helpText: "Help text for channel 2",
  },
];

export const mockSingleFiles = [
  {
    id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
    groupName: "",
    fileName: "File 1",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
      isImage: true,
    },
  },
  {
    id: "dc8bea19-422d-4a75-a297-bdea822fc679",
    groupName: "",
    fileName: "File 2",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
      isImage: true,
    },
  },
];

export const mockFileGroups = [
  {
    name: "group 1",
    default: {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "group 1",
      fileName: "Grouped File 1",
      currentVersionId: "version 1",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    files: [
      {
        id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
        groupName: "group 1",
        fileName: "Grouped File 1",
        currentVersionId: "version 1",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
          isImage: true,
        },
      },
      {
        id: "dc8bea19-422d-4a75-a297-bdea822fc679",
        groupName: "group 1",
        fileName: "Grouped File 2",
        currentVersionId: "version 1",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
          isImage: true,
        },
      },
    ],
  },
  {
    name: "group 2",
    default: {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "group 1",
      fileName: "Grouped File 1",
      currentVersionId: "version 1",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    files: [
      {
        id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
        groupName: "group 2",
        fileName: "Grouped File 1",
        currentVersionId: "version 1",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
          isImage: true,
        },
      },
      {
        id: "dc8bea19-422d-4a75-a297-bdea822fc679",
        groupName: "group 2",
        fileName: "Grouped File 2",
        currentVersionId: "version 1",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
          isImage: true,
        },
      },
    ],
  },
];

export const mockSingleGroupMultipleFiles = [
  {
    name: "group 1",
    default: {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "group 1",
      fileName: "Grouped File 1",
      currentVersionId: "version 1",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    files: [
      {
        id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
        groupName: "group 1",
        fileName: "Grouped File 1",
        currentVersionId: "version 1",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
          isImage: true,
        },
      },
      {
        id: "dc8bea19-422d-4a75-a297-bdea822fc679",
        groupName: "group 1",
        fileName: "Grouped File 2",
        currentVersionId: "version 1",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
          isImage: true,
        },
      },
    ],
  },
];

export const mockSingleFileGroup = [
  {
    name: "group 1",
    default: {
      id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
      groupName: "group 1",
      fileName: "Grouped File 1",
      currentVersionId: "version 1",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
        isImage: true,
      },
    },
    files: [
      {
        id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
        groupName: "group 1",
        fileName: "Grouped File 1",
        currentVersionId: "version 1",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
          isImage: true,
        },
      },
    ],
  },
];

export const mockAssetFiles = [
  {
    id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
    groupName: "",
    fileName: "File 1",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
      isImage: true,
    },
  },
  {
    id: "dc8bea19-422d-4a75-a297-bdea822fc679",
    groupName: "",
    fileName: "File 2",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
      isImage: true,
    },
  },
];

export const mockAssetFilesWithMultipleGroups = [
  {
    id: "File 1",
    groupName: "1",
    fileName: "File 1",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
      isImage: true,
    },
  },
  {
    id: "File 2",
    groupName: "1",
    fileName: "File 2",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
      isImage: true,
    },
  },
  {
    id: "File 3",
    groupName: "2",
    fileName: "File 3",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
      isImage: true,
    },
  },
  {
    id: "File 4",
    groupName: "2",
    currentVersionId: "version 1",
    fileName: "File 4",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
      isImage: true,
    },
  },
];

export const mockAssetFilesWithSingleGroup = [
  {
    id: "File 1",
    groupName: "1",
    fileName: "File 1",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
      isImage: true,
    },
  },
  {
    id: "File 2",
    groupName: "1",
    fileName: "File 2",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
      isImage: true,
    },
  },
];

export const mockSingleAssetFileWithGroup = [
  {
    id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
    groupName: "Group 1",
    fileName: "File 1",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
      isImage: true,
    },
  },
];

export const mockSingleAssetFileNoGroup = [
  {
    id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
    groupName: "",
    fileName: "File 1",
    currentVersionId: "version 1",
    approvalStatus: "Approved",
    thumbnails: {
      small:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
      medium:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
      large:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
      xLarge:
        "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
      isImage: true,
    },
  },
];

export const mockHeroAnnouncementLinks = [
  {
    text: "Link 1",
    url: "Link url 1",
    openInNewTab: true,
    external: true,
  },
  {
    text: "Link 2",
    url: "Link url 2",
  },
  {
    text: "Link 3",
    url: "Link url 3",
    openInNewTab: true,
  },
  {
    text: "Link 4",
    url: "Link url 4",
    external: true,
  },
];

export const mockFCPackageMultipleFiles = [
  {
    id: "mock package 1",
    sentFromEmail: "mock email 1",
    sentFromFirstName: "kat",
    sentFromLastName: "klauber",
    subject: "subject",
    message: "This is the message",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
  {
    id: "mock package 2",
    sentFromEmail: "mock email 2",
    sentFromFirstName: "kat2",
    sentFromLastName: "klauber2",
    subject: "subject2",
    message: "This is the message2",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
  {
    id: "mock package 3",
    sentFromEmail: "mock email 3",
    sentFromFirstName: "kat3",
    sentFromLastName: "klauber3",
    subject: "subject3",
    message: "This is the message3",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
  {
    id: "mock package 4",
    sentFromEmail: "mock email 4",
    sentFromFirstName: "kat4",
    sentFromLastName: "klauber4",
    subject: "subject4",
    message: "This is the message4",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
  {
    id: "mock package 5",
    sentFromEmail: "mock email 5",
    sentFromFirstName: "kat5",
    sentFromLastName: "klauber5",
    subject: "subject5",
    message: "This is the message5",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
  {
    id: "mock package 6",
    sentFromEmail: "mock email 6",
    sentFromFirstName: "kat6",
    sentFromLastName: "klauber6",
    subject: "subject6",
    message: "This is the message6",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
];

export const mockFCPackageSingleFileNew = [
  {
    id: "mock package 1",
    sentFromEmail: "mock email 1",
    sentFromFirstName: "kat",
    sentFromLastName: "klauber",
    subject: "subject",
    message: "This is the message",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: true,
    shareableLink: "shareable link",
  },
];

export const mockFCPackageSingleFileExpiringSoon = [
  {
    id: "mock package 1",
    sentFromEmail: "mock email 1",
    sentFromFirstName: "kat",
    sentFromLastName: "klauber",
    subject: "subject",
    message: "This is the message",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
    ],
    isExpiringSoon: true,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
];

export const mockFCPackageSingleFileExpired = [
  {
    id: "mock package 1",
    sentFromEmail: "mock email 1",
    sentFromFirstName: "kat",
    sentFromLastName: "klauber",
    subject: "subject",
    message: "This is the message",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
    ],
    isExpiringSoon: false,
    isExpired: true,
    isNew: false,
    shareableLink: "shareable link",
  },
];

export const mockSentFCPackageMultipleFiles = [
  {
    id: "mock package 1",
    sentFromId: "sentId1",
    sentToEmails: "sentEmails",
    recipients: [
      {
        userId: "user1",
        userEmail: "userEmail1",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user2",
        userEmail: "userEmail2",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user3",
        userEmail: "userEmail3",
        userFirstName: "kat",
        userLastName: "klauber",
      },
    ],
    sentFromEmail: "mock email 1",
    sentFromFirstName: "kat",
    sentFromLastName: "klauber",
    subject: "subject",
    message: "This is the message",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
  {
    id: "mock package 2",
    sentFromId: "sentId2",
    sentToEmails: "sentEmails",
    recipients: [
      {
        userId: "user1",
        userEmail: "userEmail1",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user2",
        userEmail: "userEmail2",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user3",
        userEmail: "userEmail3",
        userFirstName: "kat",
        userLastName: "klauber",
      },
    ],
    sentFromEmail: "mock email 2",
    sentFromFirstName: "kat2",
    sentFromLastName: "klauber2",
    subject: "subject2",
    message: "This is the message2",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
  {
    id: "mock package 3",
    sentFromId: "sentId3",
    sentToEmails: "sentEmails",
    recipients: [
      {
        userId: "user1",
        userEmail: "userEmail1",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user2",
        userEmail: "userEmail2",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user3",
        userEmail: "userEmail3",
        userFirstName: "kat",
        userLastName: "klauber",
      },
    ],
    sentFromEmail: "mock email 3",
    sentFromFirstName: "kat3",
    sentFromLastName: "klauber3",
    subject: "subject3",
    message: "This is the message3",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
  {
    id: "mock package 4",
    sentFromId: "sentId4",
    sentToEmails: "sentEmails",
    recipients: [
      {
        userId: "user1",
        userEmail: "userEmail1",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user2",
        userEmail: "userEmail2",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user3",
        userEmail: "userEmail3",
        userFirstName: "kat",
        userLastName: "klauber",
      },
    ],
    sentFromEmail: "mock email 4",
    sentFromFirstName: "kat4",
    sentFromLastName: "klauber4",
    subject: "subject4",
    message: "This is the message4",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
  {
    id: "mock package 5",
    sentFromId: "sentId5",
    sentToEmails: "sentEmails",
    recipients: [
      {
        userId: "user1",
        userEmail: "userEmail1",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user2",
        userEmail: "userEmail2",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user3",
        userEmail: "userEmail3",
        userFirstName: "kat",
        userLastName: "klauber",
      },
    ],
    sentFromEmail: "mock email 5",
    sentFromFirstName: "kat5",
    sentFromLastName: "klauber5",
    subject: "subject5",
    message: "This is the message5",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
  {
    id: "mock package 6",
    sentFromId: "sentId6",
    sentToEmails: "sentEmails",
    recipients: [
      {
        userId: "user1",
        userEmail: "userEmail1",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user2",
        userEmail: "userEmail2",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user3",
        userEmail: "userEmail3",
        userFirstName: "kat",
        userLastName: "klauber",
      },
    ],
    sentFromEmail: "mock email 6",
    sentFromFirstName: "kat6",
    sentFromLastName: "klauber6",
    subject: "subject6",
    message: "This is the message6",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
      {
        id: "file 2",
        packageId: "file 2",
        extension: "png",
        name: "file 2",
        size: "22",
      },
      {
        id: "file 3",
        packageId: "file 3",
        extension: "pdf",
        name: "file 3",
        size: "33",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
];

export const mockSentFCPackageSingleFileNew = [
  {
    id: "mock package 1",
    sentFromId: "sentId1",
    sentToEmails: "sentEmails",
    recipients: [],
    sentFromEmail: "mock email 1",
    sentFromFirstName: "kat",
    sentFromLastName: "klauber",
    subject: "subject",
    message: "This is the message",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
    ],
    isExpiringSoon: false,
    isExpired: false,
    isNew: true,
    shareableLink: "shareable link",
  },
];

export const mockSentFCPackageSingleFileExpiringSoon = [
  {
    id: "mock package 1",
    sentFromId: "sentId1",
    sentToEmails: "sentEmails",
    recipients: [
      {
        userId: "user1",
        userEmail: "userEmail1",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user2",
        userEmail: "userEmail2",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user3",
        userEmail: "userEmail3",
        userFirstName: "kat",
        userLastName: "klauber",
      },
    ],
    sentFromEmail: "mock email 1",
    sentFromFirstName: "kat",
    sentFromLastName: "klauber",
    subject: "subject",
    message: "This is the message",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
    ],
    isExpiringSoon: true,
    isExpired: false,
    isNew: false,
    shareableLink: "shareable link",
  },
];

export const mockSentFCPackageSingleFileExpired = [
  {
    id: "mock package 1",
    sentFromId: "sentId1",
    sentToEmails: "sentEmails",
    recipients: [
      {
        userId: "user1",
        userEmail: "userEmail1",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user2",
        userEmail: "userEmail2",
        userFirstName: "kat",
        userLastName: "klauber",
      },
      {
        userId: "user3",
        userEmail: "userEmail3",
        userFirstName: "kat",
        userLastName: "klauber",
      },
    ],
    sentFromEmail: "mock email 1",
    sentFromFirstName: "kat",
    sentFromLastName: "klauber",
    subject: "subject",
    message: "This is the message",
    expirationDate: new Date(),
    sentDate: "1986-11-22T20:42:16.147Z",
    files: [
      {
        id: "file 1",
        packageId: "file 1",
        extension: "jpg",
        name: "file 1",
        size: "11",
      },
    ],
    isExpiringSoon: false,
    isExpired: true,
    isNew: false,
    shareableLink: "shareable link",
  },
];

export const mockReceivedFCPackage = {
  id: "mock package 1",
  sentFromEmail: "mock email 1",
  sentFromFirstName: "kat",
  sentFromLastName: "klauber",
  subject: "subject",
  message: "This is the message",
  expirationDate: new Date(),
  sentDate: "1986-11-22T20:42:16.147Z",
  files: [
    {
      id: "file1",
      packageId: "file1",
      extension: "jpg",
      name: "file 1",
      size: 11,
    },
  ],
  isExpiringSoon: false,
  isExpired: false,
  isNew: true,
  shareableLink: "shareable link",
};

export const mockSentFCPackageResponse = {
  id: "mock package 1",
  sentFromId: "sentId1",
  sentToEmails: "sentEmails",
  recipients: [
    {
      userId: "user1",
      userEmail: "userEmail1",
      userFirstName: "kat",
      userLastName: "klauber",
    },
    {
      userId: "user2",
      userEmail: "userEmail2",
      userFirstName: "kat",
      userLastName: "klauber",
    },
    {
      userId: "user3",
      userEmail: "userEmail3",
      userFirstName: "kat",
      userLastName: "klauber",
    },
  ],
  sentFromEmail: "mock email 1",
  sentFromFirstName: "kat",
  sentFromLastName: "klauber",
  subject: "subject",
  message: "This is the message",
  expirationDate: new Date(),
  sentDate: "1986-11-22T20:42:16.147Z",
  files: [
    {
      id: "file 1",
      packageId: "file 1",
      extension: "jpg",
      name: "file 1",
      size: "11",
    },
  ],
  isExpiringSoon: false,
  isExpired: false,
  isNew: true,
  shareableLink: "shareable link",
  downloadHistory: [
    {
      userEmail: "email 1",
      fileNames: "file names 1",
      date: "2023-10-23T14:38:03.603+00:00",
    },
    {
      userEmail: "email 2",
      fileNames: "file names 2",
      date: "2023-10-23T14:38:03.603+00:00",
    },
  ],
};

export const mockSentFCPackageResponseNoDlHistory = {
  id: "mock package 1",
  sentFromId: "sentId1",
  sentToEmails: "sentEmails",
  recipients: [
    {
      userId: "user1",
      userEmail: "userEmail1",
      userFirstName: "kat",
      userLastName: "klauber",
    },
    {
      userId: "user2",
      userEmail: "userEmail2",
      userFirstName: "kat",
      userLastName: "klauber",
    },
    {
      userId: "user3",
      userEmail: "userEmail3",
      userFirstName: "kat",
      userLastName: "klauber",
    },
  ],
  sentFromEmail: "mock email 1",
  sentFromFirstName: "kat",
  sentFromLastName: "klauber",
  subject: "subject",
  message: "This is the message",
  expirationDate: new Date(),
  sentDate: "1986-11-22T20:42:16.147Z",
  files: [
    {
      id: "file 1",
      packageId: "file 1",
      extension: "jpg",
      name: "file 1",
      size: "11",
    },
  ],
  isExpiringSoon: false,
  isExpired: false,
  isNew: true,
  shareableLink: "shareable link",
  downloadHistory: [],
};

export const mockFCGetPackagesResponse = {
  newFiles: 3,
  receivedPackages: [
    {
      id: "mock package 1",
      sentFromEmail: "mock email 1",
      sentFromFirstName: "kat",
      sentFromLastName: "klauber",
      subject: "subject",
      message: "This is the message",
      expirationDate: new Date(),
      sentDate: "1986-11-22T20:42:16.147Z",
      files: [
        {
          id: "file1",
          packageId: "file1",
          extension: "jpg",
          name: "file 1",
          size: 11,
        },
      ],
      isExpiringSoon: false,
      isExpired: false,
      isNew: true,
      shareableLink: "shareable link",
    },
    {
      id: "mock package 2",
      sentFromEmail: "mock email 2",
      sentFromFirstName: "kat",
      sentFromLastName: "klauber",
      subject: "subject",
      message: "This is the message",
      expirationDate: new Date(),
      sentDate: "1986-11-22T20:42:16.147Z",
      files: [
        {
          id: "file1",
          packageId: "file1",
          extension: "jpg",
          name: "file 1",
          size: 11,
        },
      ],
      isExpiringSoon: false,
      isExpired: false,
      isNew: true,
      shareableLink: "shareable link",
    },
  ],
  sentPackages: [
    {
      id: "mock package 1",
      sentFromId: "sentId1",
      sentToEmails: "sentEmails",
      recipients: [],
      sentFromEmail: "mock email 1",
      sentFromFirstName: "kat",
      sentFromLastName: "klauber",
      subject: "subject",
      message: "This is the message",
      expirationDate: new Date(),
      sentDate: "1986-11-22T20:42:16.147Z",
      files: [
        {
          id: "file 1",
          packageId: "file 1",
          extension: "jpg",
          name: "file 1",
          size: "11",
        },
      ],
      isExpiringSoon: false,
      isExpired: false,
      isNew: true,
      shareableLink: "shareable link",
    },
    {
      id: "mock package 2",
      sentFromId: "sentId2",
      sentToEmails: "sentEmails",
      recipients: [],
      sentFromEmail: "mock email 2",
      sentFromFirstName: "kat",
      sentFromLastName: "klauber",
      subject: "subject",
      message: "This is the message",
      expirationDate: new Date(),
      sentDate: "1986-11-22T20:42:16.147Z",
      files: [
        {
          id: "file 1",
          packageId: "file 1",
          extension: "jpg",
          name: "file 1",
          size: "11",
        },
      ],
      isExpiringSoon: false,
      isExpired: false,
      isNew: true,
      shareableLink: "shareable link",
    },
    {
      id: "mock package 3",
      sentFromId: "sentId3",
      sentToEmails: "sentEmails",
      recipients: [],
      sentFromEmail: "mock email 3",
      sentFromFirstName: "kat",
      sentFromLastName: "klauber",
      subject: "subject",
      message: "This is the message",
      expirationDate: new Date(),
      sentDate: "1986-11-22T20:42:16.147Z",
      files: [
        {
          id: "file 1",
          packageId: "file 1",
          extension: "jpg",
          name: "file 1",
          size: "11",
        },
      ],
      isExpiringSoon: false,
      isExpired: false,
      isNew: true,
      shareableLink: "shareable link",
    },
  ],
};

export const mockFCGetPackagesEmptyResponse = {
  newFiles: 0,
  receivedPackages: [],
  sentPackages: [],
};

export const mockFile = (type: string, size: number): File => {
  const fileName =
    (Math.random() * 1000).toString().replace(".", "") + type.toLowerCase();
  const file = new File([""], fileName);
  Object.defineProperty(file, "size", { value: size });
  return file;
};

export const mockPmFile = (name: string, size: number): File => {
  const file = new File([""], name);
  Object.defineProperty(file, "size", {value: size});
  return file;
};

let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const mockFcFormData = {
  sendToUsers: [
    {
      id: "0",
      firstName: "John",
      lastName: "Doe",
      email: "john.doe@gmail.com",
      name: "John Doe",
      company: "Company",
      phone: "555-5555",
      status: "Active",
    },
    {
      id: "1",
      firstName: "Jane",
      lastName: "Doe",
      email: "jane.doe@gmail.com",
      name: "Jane Doe",
      company: "Company",
      phone: "555-5555",
      status: "Active",
    },
  ],
  subject: "subject",
  message: "This is the message.",
  expirationDate: tomorrow,
  sendCopy: true,
  files: [
    {
      id: "file 1",
      packageId: "file 1",
      extension: "jpg",
      name: "file 1",
      size: "11",
    },
    {
      id: "file 2",
      packageId: "file 2",
      extension: "png",
      name: "file 2",
      size: "22",
    },
    {
      id: "file 3",
      packageId: "file 3",
      extension: "pdf",
      name: "file 3",
      size: "33",
    },
  ],
};

export const filters = {
  assets: [
    {
      id: "316c0651-c5e3-4bd2-b41c-5427484f74bd",
      description: "Devices & Accessories",
      value: "Devices & Accessories",
      subCategories: [
        {
          id: "36b18590-90a5-464e-85b0-a765a47e7e72",
          description: "Accessory Type",
          value: "Accessory Type",
          categoryId: "316c0651-c5e3-4bd2-b41c-5427484f74bd",
          tags: [
            {
              id: "541e31a0-b794-4004-b5d3-2e7ffa5ab2c7",
              description: "Adapters",
              value: "Adapters",
              subCategoryId: "36b18590-90a5-464e-85b0-a765a47e7e72",
            },
            {
              id: "1f1042fe-789f-4997-b1ac-047743c90c57",
              description: "Antenna",
              value: "Antenna",
              subCategoryId: "36b18590-90a5-464e-85b0-a765a47e7e72",
            },
            {
              id: "4a304e58-0e66-4130-9b92-72734694f576",
              description: "Batteries",
              value: "Batteries",
              subCategoryId: "36b18590-90a5-464e-85b0-a765a47e7e72",
            },
          ],
        },
      ],
    },
    {
      id: "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
      description: "Campaigns, Creative & Playbooks",
      value: "Campaigns, Creative & Playbooks",
      subCategories: [
        {
          id: "5fee2f58-eac4-4d3d-8e01-52ad5aed2479",
          description: "Market",
          value: "Market",
          categoryId: "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
          tags: [],
        },
      ],
    },
    {
      id: "18cdbe3d-567f-47eb-9c94-9acdab78547b",
      description: "Employee Communications",
      value: "Employee Communications",
      subCategories: [
        {
          id: "57c0436d-bc92-4b66-b39a-68daed6e80f3",
          description: "Channel",
          value: "Channel",
          categoryId: "18cdbe3d-567f-47eb-9c94-9acdab78547b",
          tags: [
            {
              id: "1db0c5c3-55f4-4471-8d4d-6a7876c82044",
              description: "Digital/Web",
              value: "Digital/Web",
              subCategoryId: "57c0436d-bc92-4b66-b39a-68daed6e80f3",
            },
            {
              id: "1360c2f9-4213-4aa6-81f7-3ebb94ad3972",
              description: "Packaging",
              value: "Packaging",
              subCategoryId: "57c0436d-bc92-4b66-b39a-68daed6e80f3",
            },
            {
              id: "6bae776f-543c-4ed6-ab8a-309965c406e5",
              description: "TV",
              value: "TV",
              subCategoryId: "57c0436d-bc92-4b66-b39a-68daed6e80f3",
            },
          ],
        },
      ],
    },
    {
      id: "e492b86d-3a1d-45a0-b518-3ace937747cf",
      description: "Iconography",
      value: "Iconography",
      subCategories: [
        {
          id: "f19f31e1-54a7-4563-b614-ebb88631107a",
          description: "Business",
          value: "Business",
          categoryId: "e492b86d-3a1d-45a0-b518-3ace937747cf",
          tags: [
            {
              id: "358305a0-dcd2-4002-8041-d5adfe84a734",
              description: "B2B",
              value: "B2B",
              subCategoryId: "f19f31e1-54a7-4563-b614-ebb88631107a",
            },
            {
              id: "8529fdfb-77d9-40f7-aa2e-221cc592b6ae",
              description: "Objects & Industries",
              value: "Objects & Industries",
              subCategoryId: "f19f31e1-54a7-4563-b614-ebb88631107a",
            },
          ],
        },
        {
          id: "39f6460e-7746-4767-94f7-354aa62d5253",
          description: "Interface",
          value: "Interface",
          categoryId: "e492b86d-3a1d-45a0-b518-3ace937747cf",
          tags: [
            {
              id: "0604a980-03fa-484d-8c3b-cbad5262c139",
              description: "Directional",
              value: "Directional",
              subCategoryId: "39f6460e-7746-4767-94f7-354aa62d5253",
            },
            {
              id: "943ac556-7980-4358-9ef2-67c11c8964a5",
              description: "Navigational",
              value: "Navigational",
              subCategoryId: "39f6460e-7746-4767-94f7-354aa62d5253",
            },
            {
              id: "d9d3c77e-a000-4822-9fd8-c6a1f0885b1f",
              description: "Notifications & Feedback",
              value: "Notifications & Feedback",
              subCategoryId: "39f6460e-7746-4767-94f7-354aa62d5253",
            },
          ],
        },
        {
          id: "121ed840-4049-43f3-a3be-ecd0887e5f11",
          description: "Misc.",
          value: "Misc.",
          categoryId: "e492b86d-3a1d-45a0-b518-3ace937747cf",
          tags: [
            {
              id: "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92",
              description: "Other",
              value: "Other",
              subCategoryId: "121ed840-4049-43f3-a3be-ecd0887e5f11",
            },
            {
              id: "e3e1b271-fb37-413a-9cf4-e00cede94536",
              description: "Utilities",
              value: "Utilities",
              subCategoryId: "121ed840-4049-43f3-a3be-ecd0887e5f11",
            },
          ],
        },
        {
          id: "015b170f-c5c0-42eb-ad45-6085b594a2d4",
          description: "Product",
          value: "Product",
          categoryId: "e492b86d-3a1d-45a0-b518-3ace937747cf",
          tags: [
            {
              id: "53882030-b97a-4455-9e5e-2e7de96a226a",
              description: "Calling",
              value: "Calling",
              subCategoryId: "015b170f-c5c0-42eb-ad45-6085b594a2d4",
            },
            {
              id: "192a20d2-27c7-4dfe-a6fb-7ee89c0936fb",
              description: "International",
              value: "International",
              subCategoryId: "015b170f-c5c0-42eb-ad45-6085b594a2d4",
            },
            {
              id: "e61d105f-1833-48fa-b2c6-9264077b518c",
              description: "Orders & Pricing",
              value: "Orders & Pricing",
              subCategoryId: "015b170f-c5c0-42eb-ad45-6085b594a2d4",
            },
            {
              id: "69a0b5fc-4daf-4226-8332-0f8022aeb054",
              description: "Plans & Devices",
              value: "Plans & Devices",
              subCategoryId: "015b170f-c5c0-42eb-ad45-6085b594a2d4",
            },
          ],
        },
      ],
    },
    {
      id: "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
      description: "Logos",
      value: "Logos",
      subCategories: [
        {
          id: "c268f8b4-9834-4947-8271-4c2a07ff3572",
          description: "Others",
          value: "Others",
          categoryId: "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
          tags: [],
        },
        {
          id: "b5598c75-7aac-4c95-8bb6-7bcb525e337b",
          description: "Primary",
          value: "Primary",
          categoryId: "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
          tags: [],
        },
        {
          id: "f677f2bb-7ead-4601-918d-43726ada3387",
          description: "Segment",
          value: "Segment",
          categoryId: "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
          tags: [],
        },
        {
          id: "88113f99-0a09-42f7-8b7b-c7fcbbd401cf",
          description: "Type",
          value: "Type",
          categoryId: "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
          tags: [],
        },
      ],
    },
    {
      id: "76007f55-b998-4883-8e53-64c5ecec4a51",
      description: "Photography",
      value: "Photography",
      subCategories: [
        {
          id: "a22f39d0-4876-496c-93a6-14fe4011dc8d",
          description: "Age",
          value: "Age",
          categoryId: "76007f55-b998-4883-8e53-64c5ecec4a51",
          tags: [
            {
              id: "8e04ac67-b08a-4f88-ad39-194dd6d80f02",
              description: "Adult 32-50",
              value: "Adult 32-50",
              subCategoryId: "a22f39d0-4876-496c-93a6-14fe4011dc8d",
            },
            {
              id: "8464f380-7dd5-478a-adc4-9fe85f123d00",
              description: "Family",
              value: "Family",
              subCategoryId: "a22f39d0-4876-496c-93a6-14fe4011dc8d",
            },
            {
              id: "86de57e7-7615-4d70-a3e0-9f5974ae2345",
              description: "Mature 51 & Older",
              value: "Mature 51 & Older",
              subCategoryId: "a22f39d0-4876-496c-93a6-14fe4011dc8d",
            },
            {
              id: "2e080ec9-e2a5-4806-8b30-0d82bb624007",
              description: "Teen 15-18",
              value: "Teen 15-18",
              subCategoryId: "a22f39d0-4876-496c-93a6-14fe4011dc8d",
            },
            {
              id: "68e58d8c-b1e1-43d5-ac2c-8c1fe20bddcb",
              description: "Young Adult 19-31",
              value: "Young Adult 19-31",
              subCategoryId: "a22f39d0-4876-496c-93a6-14fe4011dc8d",
            },
            {
              id: "f50cd84f-282a-46fb-a97e-61f2119fe56b",
              description: "Youth 14 & Under",
              value: "Youth 14 & Under",
              subCategoryId: "a22f39d0-4876-496c-93a6-14fe4011dc8d",
            },
          ],
        },
        {
          id: "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
          description: "Channel",
          value: "Channel",
          categoryId: "76007f55-b998-4883-8e53-64c5ecec4a51",
          tags: [
            {
              id: "3d8d9269-63a0-437f-90ec-f70204d5c10f",
              description: "Business",
              value: "Business",
              subCategoryId: "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
            },
            {
              id: "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
              description: "Consumer",
              value: "Consumer",
              subCategoryId: "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
            },
            {
              id: "9066ae36-b552-4e82-98af-f5dc2dd2fe10",
              description: "Enterprise",
              value: "Enterprise",
              subCategoryId: "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
            },
            {
              id: "990afd3e-7939-4f41-8cb4-279b1189ce29",
              description: "Government",
              value: "Government",
              subCategoryId: "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
            },
          ],
        },
      ],
    },
  ],
  templates: [
    {
      id: "ccafc402-96da-4547-a02a-0e1551004a59",
      description: "Test",
      value: "Test",
      subCategories: [
        {
          id: "974d55ee-0566-4c21-8e91-8cdfae764bc0",
          description: "By Type",
          value: "By Type",
          categoryId: "ccafc402-96da-4547-a02a-0e1551004a59",
          tags: [],
        },
      ],
    },
    {
      id: "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
      description: "Templates",
      value: "Templates",
      subCategories: [
        {
          id: "6e2909ba-42ba-45e4-b1b4-6f273d440d87",
          description: "By software",
          value: "By software",
          categoryId: "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
          tags: [
            {
              id: "c8f85b01-daee-47f1-a10b-e4b771d71837",
              description: "Email",
              value: "Email",
              subCategoryId: "6e2909ba-42ba-45e4-b1b4-6f273d440d87",
            },
            {
              id: "a3298bea-dbb2-4550-9146-da82f603c9d9",
              description: "Google Slides",
              value: "Google Slides",
              subCategoryId: "6e2909ba-42ba-45e4-b1b4-6f273d440d87",
            },
            {
              id: "e8308d8d-783e-4b49-9df2-50226aed75d5",
              description: "Illustrator",
              value: "Illustrator",
              subCategoryId: "6e2909ba-42ba-45e4-b1b4-6f273d440d87",
            },
          ],
        },
        {
          id: "1491b4e6-5127-4d6d-8329-35831079696a",
          description: "Template Category",
          value: "Template Category",
          categoryId: "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
          tags: [
            {
              id: "32e464d8-a8b8-4497-9d77-6533ff4e7cde",
              description: "Awards",
              value: "Awards",
              subCategoryId: "1491b4e6-5127-4d6d-8329-35831079696a",
            },
            {
              id: "ed9b962f-a376-4626-bfc9-8b1b5c6c9046",
              description: "Email Signature Builder",
              value: "Email Signature Builder",
              subCategoryId: "1491b4e6-5127-4d6d-8329-35831079696a",
            },
            {
              id: "43f2abbd-c9a6-4b67-8f23-67c2dd43bdbf",
              description: "Employee Communications",
              value: "Employee Communications",
              subCategoryId: "1491b4e6-5127-4d6d-8329-35831079696a",
            },
          ],
        },
      ],
    },
  ],
  designAdvisories: [
    {
      id: "ccafc402-96da-4547-a02a-0e1551004a52",
      description: "Test",
      value: "Test",
      subCategories: [
        {
          id: "974d55ee-0566-4c21-8e91-8cdfae764bc3",
          description: "By Type",
          value: "By Type",
          categoryId: "ccafc402-96da-4547-a02a-0e1551004a52",
          tags: [],
        },
      ],
    },
    {
      id: "ccafc402-96da-4547-a02a-0e1551004a55",
      description: "Design Advisories",
      value: "Design Advisories",
      subCategories: [
        {
          id: "974d55ee-0566-4c21-8e91-8cdfae764bc2",
          description: "By Type",
          value: "By Type",
          categoryId: "ccafc402-96da-4547-a02a-0e1551004a55",
          tags: [
            {
              id: "8a14cfe4-4171-436e-8724-ae35afdd422a",
              description: "DAS",
              value: "DAS",
              subCategoryId: "974d55ee-0566-4c21-8e91-8cdfae764bc2",
            },
          ],
        },
      ],
    },
  ],
};

export const renamedSavedSearches = [
  {
    id: "3e12633a-2f0f-4925-8290-869738412287",
    name: "SavedSearch1",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"subcategory","Value":"316c0651-c5e3-4bd2-b41c-5427484f74bd"},{"Type":"tag","Value":"541e31a0-b794-4004-b5d3-2e7ffa5ab2c7"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "asset"
  },
  {
    id: "51d0350f-9904-4a05-b6ea-1dee5fe801e9",
    name: "photography",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"subcategory","Value":"76007f55-b998-4883-8e53-64c5ecec4a51"},{"Type":"tag","Value":"3d8d9269-63a0-437f-90ec-f70204d5c10f"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "asset"
  },
  {
    id: "472e438d-f4aa-4e40-97a9-0a8e43dbd662",
    name: "templates-tag",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"category","Value":"7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1"},{"Type":"tag","Value":"43f2abbd-c9a6-4b67-8f23-67c2dd43bdbf"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "template"
  },
  {
    id: "adc32d65-6b1d-46c3-9188-048046d8ac43",
    name: "asset-test-with-tag",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"subcategory","Value":"316c0651-c5e3-4bd2-b41c-5427484f74bd"},{"Type":"tag","Value":"541e31a0-b794-4004-b5d3-2e7ffa5ab2c7"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "asset"
  },
  {
    id: "bdd43880-f02f-41a6-870f-cd47baf7a10e",
    name: "search with date",
    searchObject:
      '{"Query":"test","Filters":[{"Type":"subcategory","Value":"76007f55-b998-4883-8e53-64c5ecec4a51"}],"IsRestricted":true,"ExcludeWithinExpiredDate":"2023-09-20T14:30:00.000Z"}',
    searchType: "retail"
  },
  {
    id: "6f69e82c-c0b7-4baa-8ed2-550afeb7ba82",
    name: "template-email-tag-1-result",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"category","Value":"7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1"}],"IsRestricted":true}',
    searchType: "template"
  },
  {
    id: "18e7dca5-3bc3-451b-8e2b-3b7112c86889",
    name: "templates-tags-bug-fixed",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"category","Value":"7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1"},{"Type":"tag","Value":"c8f85b01-daee-47f1-a10b-e4b771d71837"}],"IsRestricted":true}',
    searchType: "template"
  },
];

export const deletedFirstSavedSearches = [
  {
    id: "51d0350f-9904-4a05-b6ea-1dee5fe801e9",
    name: "photography",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"subcategory","Value":"76007f55-b998-4883-8e53-64c5ecec4a51"},{"Type":"tag","Value":"3d8d9269-63a0-437f-90ec-f70204d5c10f"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "asset"
  },
  {
    id: "472e438d-f4aa-4e40-97a9-0a8e43dbd662",
    name: "templates-tag",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"category","Value":"7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1"},{"Type":"tag","Value":"43f2abbd-c9a6-4b67-8f23-67c2dd43bdbf"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "template"
  },
  {
    id: "adc32d65-6b1d-46c3-9188-048046d8ac43",
    name: "asset-test-with-tag",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"subcategory","Value":"316c0651-c5e3-4bd2-b41c-5427484f74bd"},{"Type":"tag","Value":"541e31a0-b794-4004-b5d3-2e7ffa5ab2c7"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "asset"
  },
  {
    id: "bdd43880-f02f-41a6-870f-cd47baf7a10e",
    name: "search with date",
    searchObject:
      '{"Query":"test","Filters":[{"Type":"subcategory","Value":"76007f55-b998-4883-8e53-64c5ecec4a51"}],"IsRestricted":true,"ExcludeWithinExpiredDate":"2023-09-20T14:30:00.000Z"}',
    searchType: "retail"
  },
  {
    id: "6f69e82c-c0b7-4baa-8ed2-550afeb7ba82",
    name: "template-email-tag-1-result",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"category","Value":"7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1"}],"IsRestricted":true}',
    searchType: "template"
  },
  {
    id: "18e7dca5-3bc3-451b-8e2b-3b7112c86889",
    name: "templates-tags-bug-fixed",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"category","Value":"7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1"},{"Type":"tag","Value":"c8f85b01-daee-47f1-a10b-e4b771d71837"}],"IsRestricted":true}',
    searchType: "template"
  },
];

export const savedSearches = [
  {
    id: "3e12633a-2f0f-4925-8290-869738412287",
    name: "asset-test",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"subcategory","Value":"316c0651-c5e3-4bd2-b41c-5427484f74bd"},{"Type":"tag","Value":"541e31a0-b794-4004-b5d3-2e7ffa5ab2c7"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "asset"
  },
  {
    id: "51d0350f-9904-4a05-b6ea-1dee5fe801e9",
    name: "photography",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"subcategory","Value":"76007f55-b998-4883-8e53-64c5ecec4a51"},{"Type":"tag","Value":"3d8d9269-63a0-437f-90ec-f70204d5c10f"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "asset"
  },
  {
    id: "472e438d-f4aa-4e40-97a9-0a8e43dbd662",
    name: "templates-tag",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"category","Value":"7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1"},{"Type":"tag","Value":"43f2abbd-c9a6-4b67-8f23-67c2dd43bdbf"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "template"
  },
  {
    id: "adc32d65-6b1d-46c3-9188-048046d8ac43",
    name: "asset-test-with-tag",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"subcategory","Value":"316c0651-c5e3-4bd2-b41c-5427484f74bd"},{"Type":"tag","Value":"541e31a0-b794-4004-b5d3-2e7ffa5ab2c7"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
    searchType: "asset"
  },
  {
    id: "bdd43880-f02f-41a6-870f-cd47baf7a10e",
    name: "search with date",
    searchObject:
      '{"Query":"test","Filters":[{"Type":"subcategory","Value":"76007f55-b998-4883-8e53-64c5ecec4a51"}],"IsRestricted":true,"ExcludeWithinExpiredDate":"2023-09-20T14:30:00.000Z"}',
    searchType: "retail"
  },
  {
    id: "6f69e82c-c0b7-4baa-8ed2-550afeb7ba82",
    name: "template-email-tag-1-result",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"category","Value":"7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1"}],"IsRestricted":true}',
    searchType: "template"
  },
  {
    id: "18e7dca5-3bc3-451b-8e2b-3b7112c86889",
    name: "templates-tags-bug-fixed",
    searchObject:
      '{"Query":null,"Filters":[{"Type":"category","Value":"7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1"},{"Type":"tag","Value":"c8f85b01-daee-47f1-a10b-e4b771d71837"}],"IsRestricted":true}',
    searchType: "template"
  },
];

export const singleSavedSearch = {
  id: "3e12633a-2f0f-4925-8290-869738412287",
  name: "asset-test",
  searchObject:
    '{"Query":null,"Filters":[{"Type":"subcategory","Value":"316c0651-c5e3-4bd2-b41c-5427484f74bd"},{"Type":"tag","Value":"541e31a0-b794-4004-b5d3-2e7ffa5ab2c7"}],"IsRestricted":true,"IsExpired":false,"ExcludeWithinExpiredDate":null}',
  searchType: "asset"
};

export const savedSearchWithAvailabilityFiltersAndTag = {
  id: "3e12633a-2f0f-4925-8290-869738412287",
  name: "asset-test",
  searchObject:
    '{"Query":null,"Filters":[{"Type":"subcategory","Value":"316c0651-c5e3-4bd2-b41c-5427484f74bd"},{"Type":"tag","Value":"541e31a0-b794-4004-b5d3-2e7ffa5ab2c7"}],"IsRestricted":true,"IsExpired":true,"ExcludeWithinExpiredDate":"1986-10-05T19:12:25.759419Z"}',
  searchType: "asset"
};

export const savedSearchGuidelinesWithAvailabilityFilters = {
  id: "3e12633a-2f0f-4925-8290-869738412287",
  name: "guideline-test",
  searchObject:
    '{"Query":null,"IsRestricted":true,"IsExpired":true,"ExcludeWithinExpiredDate":"1986-10-05T19:12:25.759419Z"}',
  searchType: "guideline"
};
export const savedSearchTemplateWithAvailabilityFilters = {
  id: "472e438d-f4aa-4e40-97a9-0a8e43dbd662",
  name: "templates-tag",
  searchObject:
    '{"Query":"test","Filters":[{"Type":"subcategory","Value":"1491b4e6-5127-4d6d-8329-35831079696a"}],"IsRestricted":true,"IsExpired":true,"ExcludeWithinExpiredDate":"1986-10-05T19:12:25.759419Z"}',
  searchType: "template"
};

export const suggestedSearch = {
  form: {},
  results: ["test1", "test2"],
  total: 2,
  totalPages: 1
};

export const relatedSearch = {
  form: {},
  results: ["first related search", "second related search", "third related search"],
  total: 2,
  totalPages: 1
};

export const recentSearches = [
  { searchId: 0, searchTerm: "recent-1" },
  { searchId: 1, searchTerm: "recent-2" },
  { searchId: 2, searchTerm: "recent-3" },
];

export const twoRecentSearches = [
  { searchId: 0, searchTerm: "recent-1" },
  { searchId: 1, searchTerm: "recent-2" }
];

export const singleAsset = {
  searchId: "975551e9-411f-44b9-8762-e07533d7769a",
  assetId: "975551e9-411f-44b9-8762-e07533d7769a",
  createdDate: "2022-10-05T19:12:25.759419Z",
  modifiedDate: "2023-09-26T16:57:46.174246Z",
  url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
  searchType: "asset",
  isSearchable: true,
  isRestricted: true,
  canDownload: true,
  isFavorite: false,
  title: "Danhuri test asset",
  description: "Danhuri test asset",
  altText: "Danhuri test asset",
  copyright: "",
  subtitle: "",
  usageRightsCopy: "",
  files: [
    {
      id: "8fc4d4c8-47e4-4f5b-9c23-3a22b7701537",
      groupName: null,
      fileName: "asset screen shot 2.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=XhcB3t5ksw2Ete3OX%2F%2B0jUoC5nE%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=qJzPPCtqHdNFmHfDBnlFg7dltiY%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=4ZFOqOGZpGj%2FKfwb4fgDyb5eJiw%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=wxppHFfSluEGb%2Fc%2BpIqbsD2lubo%3D",
        isImage: true,
      },
    },
    {
      id: "a284f85f-0e9b-4426-9645-a0364fe82d73",
      groupName: null,
      fileName: "asset screen shot new version 2.png",
      approvalStatus: "Approved",
      thumbnails: {
        small:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=y11%2BcMgNYfsMJwUkP1UK37tzqB8%3D",
        medium:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=YfDO4C82Woh%2F4PEVBfEC8Ym0IT4%3D",
        large:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=6GJXyqO2DNhmGxzqbdAJzy9WsyY%3D",
        xLarge:
          "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=jMJzMUuqrR6FN7MFjogMIJc1laI%3D",
        isImage: true,
      },
    },
  ],
  filterIds: [
    "ccafc402-96da-4547-a02a-0e1551004a55",
    "ca388e7e-c9cd-462a-bd07-1c98e7032762",
    "1c634841-4f9e-4da7-b963-eabe3afa9c2c",
  ],
  userActions: {
    hasAcceptedUsageRights: true,
    hasPendingAccessRequest: false,
    canDownload: true,
    canEdit: true,
    canDeactivate: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    isAdmin: true,
  },
  id: "975551e9-411f-44b9-8762-e07533d7769a",
  expiresOn: "2424-12-31T00:00:00Z",
};

export const unifiedSearchResults = {
  total: 19,
  results: [
    {
      category: "Photography",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isRestricted: true,
      isFavorite: false,
      title: "Danhuri test asset",
      description: "Danhuri test asset",
      altText: "Danhuri test asset",
      usageRightsCopy: "",
      files: [
        {
          id: "8fc4d4c8-47e4-4f5b-9c23-3a22b7701537",
          groupName: null,
          fileName: "asset screen shot 2.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=XhcB3t5ksw2Ete3OX%2F%2B0jUoC5nE%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=qJzPPCtqHdNFmHfDBnlFg7dltiY%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=4ZFOqOGZpGj%2FKfwb4fgDyb5eJiw%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=wxppHFfSluEGb%2Fc%2BpIqbsD2lubo%3D",
            isImage: true,
          },
        },
        {
          id: "a284f85f-0e9b-4426-9645-a0364fe82d73",
          groupName: null,
          fileName: "asset screen shot new version 2.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=y11%2BcMgNYfsMJwUkP1UK37tzqB8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=YfDO4C82Woh%2F4PEVBfEC8Ym0IT4%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=6GJXyqO2DNhmGxzqbdAJzy9WsyY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=jMJzMUuqrR6FN7MFjogMIJc1laI%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "a22f39d0-4876-496c-93a6-14fe4011dc8d",
        "8e04ac67-b08a-4f88-ad39-194dd6d80f02",
      ],
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      searchId: "975551e9-411f-44b9-8762-e07533d7769a",
      id: "975551e9-411f-44b9-8762-e07533d7769a",
      assetId: "975551e9-411f-44b9-8762-e07533d7769a",
      expiresOn: "2424-12-31T00:00:00Z",
    },
    {
      searchId: "7a65ae9e-f296-4269-9e84-62815670bb53",
      assetId: "7a65ae9e-f296-4269-9e84-62815670bb53",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      category: "photography",
      isRestricted: false,
      isFavorite: false,
      title: "Evil Tail Gaze Weasel",
      description: "look at that lil paint brush, all dipped in white paint",
      altText: "dog is looking at you, watch out",
      usageRightsCopy: "you have the right to be cute and scary",
      files: [
        {
          id: "4da2a620-417f-4153-a14b-ec7dcc3531ba",
          groupName: null,
          fileName: "evil_tail.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_110.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=NUwhftqGhJNMRcLq0i0mJfVRYZ8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_400.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=D4SVllmjV2Vk41RI0p9aK1SEZ34%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_500.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=3xb5CQKci9rDMRTyHtxi3tZRdeY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_700.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=3V5HtK5ZL1TNUuz0hWSm3Z6Fn7w%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "a22f39d0-4876-496c-93a6-14fe4011dc8d",
        "8e04ac67-b08a-4f88-ad39-194dd6d80f02",
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "7a65ae9e-f296-4269-9e84-62815670bb53",
      expiresOn: "2222-11-22T05:00:00Z",
    },
    {
      searchId: "5f578d08-75a0-41dc-87e0-556b3e35c14f",
      assetId: "5f578d08-75a0-41dc-87e0-556b3e35c14f",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      category: "photography",
      isRestricted: false,
      isFavorite: false,
      title: "Louise loves her chippies",
      description: "yum. yum chipmunks",
      altText: "dog eats chipmunk",
      copyright: "",
      usageRightsCopy: "",
      files: [
        {
          id: "3f27aff3-327b-42cb-9342-21c59b5d98ad",
          groupName: null,
          fileName: "lick_chippie_sneaky.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_110.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=xI%2Fvvh8gATTcWaB%2FIJoYjkwSqfw%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_400.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=v2qF7X%2FpACSXLY8uvoAsoPkndMw%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_500.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=RIN7ABT0WDfEGGytzswHziwe7VM%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_700.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=E2JNceVjrxFQggxvd%2BTWXmUvv0c%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "a22f39d0-4876-496c-93a6-14fe4011dc8d",
        "8464f380-7dd5-478a-adc4-9fe85f123d00",
      ],
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "5f578d08-75a0-41dc-87e0-556b3e35c14f",
      expiresOn: "2050-05-09T04:00:00Z",
    },
    {
      searchId: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      assetId: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      category: "photography",
      isRestricted: false,
      isFavorite: false,
      title: "Floppy Mouth",
      description: "she lost her dang mind",
      altText: "upside down dog face",
      usageRightsCopy: "",
      files: [
        {
          id: "c6740fb7-ca93-4430-bad6-a7f9796740eb",
          groupName: null,
          fileName: "upside_down_floppy_gums.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_110.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=fkJCkO89CWLFnRYHWn79KIEu3OE%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_400.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=X%2BUfvHmZ9JwoBPUHltuYavHtyOU%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_500.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=navH48KIYITfyrN4kB1G0f84u%2BI%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_700.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=FVXQe1OdCkw%2F%2FS12abCqNTSMl18%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
      ],
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      expiresOn: "2121-11-22T05:00:00Z",
    },
    {
      id: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchId: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      entityId: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      isFavorite: false,
      searchType: "compliance",
      title: "Scoring",
      // copy: "The goal of Brand Compliance is to ensure all communications are 100% on-brand once they go into market. In this section you’ll learn how a submission is scored.",
      url: "/compliance/scoring",
      complianceType: "page",
    },
    {
      id: "557ZAO7oaUXnYiVFjfM3Y1",
      searchId: "557ZAO7oaUXnYiVFjfM3Y1",
      entityId: "557ZAO7oaUXnYiVFjfM3Y1",
      searchType: "guideline",
      isFavorite: false,
      url: "https://brandcentral.verizonwireless.com.test.epqa.us/guidelines/details/retail/digital/logo",
      title: "Retail: Digital Logo",
    },
    {
      id: "7oO2yYCaOsqKDF3upJfJG1",
      searchId: "7oO2yYCaOsqKDF3upJfJG1",
      entityId: "7oO2yYCaOsqKDF3upJfJG1",
      searchType: "guideline",
      isFavorite: false,
      url: "https://brandcentral.verizonwireless.com.test.epqa.us/guidelines/details/verizon-business/get-started/logo-attributes",
      title: "Verizon Business: Logo attributes",
    },
    {
      id: "4yLTjfgXLc8cLLKfSJnFKR",
      searchId: "4yLTjfgXLc8cLLKfSJnFKR",
      entityId: "4yLTjfgXLc8cLLKfSJnFKR",
      isFavorite: false,
      searchType: "guideline",
      url: "https://brandcentral.verizonwireless.com.test.epqa.us/guidelines/details/alliance-branding/Verizon-endorsed/logo-only",
      title: "Alliance branding: Verizon-endorsed Logo only",
    },
    {
      id: "13b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchId: "13b4e470-57e8-4c7b-98d9-e40fb27f3499",
      entityId: "13b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchType: "training",
      url: "training/videos/url",
      title: "Verizon business cards & stationery",
      isFavorite: false,
      videoSlug: "business-cards-and-stationery",
      thumbnailAssetUrl:
        "https://images.ctfassets.net/ve6konwi38y5/17sTCemA8NAkiD81hN0Xfb/c7b7095e5ebfda4d35d943147735cab8/verizon-business-cards-stationary.png",
      link1Text: "Learn more",
      link1Url:
        "/guidelines/details/questions-and-answers/get-started/business-cards",
      link2Text: "Download stationery",
      link2Url: "/assets/search?saved=7b19c278-9c15-4afd-b5d5-e02b881513bd",
      playlistSlug: "brand-identity-training-video-series",
    },
    {
      id: "23b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchId: "23b4e470-57e8-4c7b-98d9-e40fb27f3499",
      entityId: "23b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchType: "training",
      url: "training/videos/url",
      title: "Verizon email signature",
      isFavorite: false,
      videoSlug: "email-signature",
      thumbnailAssetUrl:
        "https://images.ctfassets.net/ve6konwi38y5/44TnKVKAlcwSqS167ZIurm/bafd6cc1af3b9a9fa01cdf8d03f983ba/verizon-email-signature-1.png",
      link1Text: "Learn more",
      link1Url:
        "/guidelines/details/questions-and-answers/get-started/email-signature",
      playlistSlug: "brand-identity-training-video-series",
    },
    {
      id: "33b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchId: "33b4e470-57e8-4c7b-98d9-e40fb27f3499",
      entityId: "33b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchType: "training",
      url: "training/videos/url",
      title: "Verizon NHG",
      videoSlug: "verizon-nhg",
      isFavorite: false,
      thumbnailAssetUrl:
        "https://images.ctfassets.net/ve6konwi38y5/1U01rVo1xWS380gjD7MDFQ/0ab5fe3b35529a27023a8f12be91a8bf/verizon-nhg.png",
      link1Text: "Learn more",
      link1Url:
        "/guidelines/details/masterbrand-elements/typography/overview",
      link2Text: "Download Verizon NHG",
      link2Url: "/assets/details/5043f09c8c4b47ac88d36232e03891be",
      playlistSlug: "brand-identity-training-video-series",
    },
    {
      id: "43b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchId: "43b4e470-57e8-4c7b-98d9-e40fb27f3499",
      entityId: "43b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchType: "training",
      url: "training/videos/url",
      title: "Verizon brand voice",
      videoSlug: "brand-voice",
      isFavorite: false,
      thumbnailAssetUrl:
        "https://images.ctfassets.net/ve6konwi38y5/26WUrhq78k5KWeOSBeVrmd/e5dea62d31ec993bb04711abf00908c9/verizon-brand-voice-1.png",
      link1Text: "Learn more",
      link1Url: "/guidelines/details/masterbrand-elements/voice/overview",
      playlistSlug: "brand-identity-training-video-series",
    },
    {
      "assetId": "418ab717-3ed1-4ace-94cd-e703697e7f0e",
      "description": "bed hog with a serious case of grumpies",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2100-05-09T04:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "dog hogging the bed",
      "files": [
        {
          "id": "4122c3ba-1856-42cb-a8f9-f3244717432c",
          "groupName": null,
          "fileName": "grumpy_tucked_in_bed.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0e84467826574b98a7b9906015031d6f_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=%2BnDfbCjMP7xlDVIvSkdv1xUjxA4%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0e84467826574b98a7b9906015031d6f_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=hDZ8FiFL7x%2BcD6htDmFJz3N%2By5U%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0e84467826574b98a7b9906015031d6f_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=Ir62QEPPSab%2FK4Hu4yKVkxcu5zU%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0e84467826574b98a7b9906015031d6f_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=YbEXYv%2F2%2FNKSUn1rWRk%2Bsif2Slo%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
        "1491b4e6-5127-4d6d-8329-35831079696a",
        "32e464d8-a8b8-4497-9d77-6533ff4e7cde"
      ],
      "category": null,
      "downloadCount": 45,
      "id": "418ab717-3ed1-4ace-94cd-e703697e7f0e",
      "url": "templates/details/418ab717-3ed1-4ace-94cd-e703697e7f0e",
      "searchType": "template",
      "isFavorite": false,
      "title": "Sleepy Weasel",
      "score": 0,
      "createdOn": "2023-10-04T19:10:27.181207Z",
      "modifiedOn": "2023-10-04T19:10:27.170476Z"
    },
    {
      "assetId": "b3af393c-36c0-433d-bb34-9e16d84cabb4",
      "description": "squishy face",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2716-05-14T04:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "smushy house hippo",
      "files": [
        {
          "id": "b77511c6-9b94-418d-9f07-a908f3ea5762",
          "groupName": null,
          "fileName": "Image_20240322_171556_376.jpeg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2b3af9e6b0034e239a63da6d46352751_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=5%2BecLwVwHWCFAthUmOY%2Fev9IRIk%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2b3af9e6b0034e239a63da6d46352751_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=5ADaJ9g2D%2F7buAeqpgTGn%2BiHcPQ%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2b3af9e6b0034e239a63da6d46352751_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=CPQTiTcuqT3R8RLy0MNjdXLCYt0%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2b3af9e6b0034e239a63da6d46352751_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=CQxwwFkauLlIVwYN73OQcXUXiLY%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
        "1491b4e6-5127-4d6d-8329-35831079696a",
        "32e464d8-a8b8-4497-9d77-6533ff4e7cde"
      ],
      "category": null,
      "downloadCount": 31,
      "id": "b3af393c-36c0-433d-bb34-9e16d84cabb4",
      "url": "templates/details/b3af393c-36c0-433d-bb34-9e16d84cabb4",
      "searchType": "template",
      "isFavorite": false,
      "title": "Mighty Meaty Meatball",
      "score": 0,
      "createdOn": "2024-05-14T19:48:06.996386Z",
      "modifiedOn": "2024-05-14T19:48:06.984939Z"
    },
    {
      "assetId": "b3244dfc-f650-4012-a359-02c87806c5ab",
      "description": "meatball napping and showing his teefs",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2517-05-14T04:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "toofy toofs",
      "files": [
        {
          "id": "f52fb6e1-58f4-475f-a63b-2cc1931662d2",
          "groupName": null,
          "fileName": "Image_20240313_093730_963.jpeg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd5eed4b33c6045aa8cf17b5a66e05abd_110.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=OtomM14S95h8knXqP21wfxU%2BjR8%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd5eed4b33c6045aa8cf17b5a66e05abd_400.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=QwGSccTKz9ztZ5UJtK3Ui02LAHw%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd5eed4b33c6045aa8cf17b5a66e05abd_500.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=f4%2FphxoKmVREJadFbhshCBOwyg0%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd5eed4b33c6045aa8cf17b5a66e05abd_700.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=VrhMW6JFMCbrzmbOk14buj79gfE%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
        "1491b4e6-5127-4d6d-8329-35831079696a",
        "ed9b962f-a376-4626-bfc9-8b1b5c6c9046"
      ],
      "category": null,
      "downloadCount": 46,
      "id": "b3244dfc-f650-4012-a359-02c87806c5ab",
      "url": "templates/details/b3244dfc-f650-4012-a359-02c87806c5ab",
      "searchType": "template",
      "isFavorite": true,
      "title": "Teefs",
      "score": 0,
      "createdOn": "2024-05-14T19:52:03.388724Z",
      "modifiedOn": "2024-05-14T20:28:14.687879Z"
    },
    {
      "assetId": "d2bb51a9-bf04-4079-8f45-1fc0a9507db6",
      "description": "majestic af",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "you have the right to bow down",
      "expiresOn": "2626-11-22T05:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "glowing dog",
      "files": [
        {
          "id": "2001e7ac-3a48-4865-9ce2-71030f87d7d4",
          "groupName": null,
          "fileName": "sunshine_side_eye.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3a624121b3a145278ba6bf5e5771d6d3_110.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=3S97SK5qeieZD0fCTqSv9BlzPMg%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3a624121b3a145278ba6bf5e5771d6d3_400.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=76YgB04%2FpFbCtmUi%2FcejFDMs8aU%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3a624121b3a145278ba6bf5e5771d6d3_500.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=EQpmnNSkY5d%2BnrO6VULlZRDoM0s%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3a624121b3a145278ba6bf5e5771d6d3_700.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=HFZMiRITZoRMmHwoIKzVjnK%2FO88%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
        "1491b4e6-5127-4d6d-8329-35831079696a",
        "a5d33e9f-13bb-4ed6-9a74-d63f768a7533"
      ],
      "category": null,
      "downloadCount": 48,
      "id": "d2bb51a9-bf04-4079-8f45-1fc0a9507db6",
      "url": "templates/details/d2bb51a9-bf04-4079-8f45-1fc0a9507db6",
      "searchType": "template",
      "isFavorite": false,
      "title": "Majestic Weasel",
      "score": 0,
      "createdOn": "2023-10-04T19:13:12.4048Z",
      "modifiedOn": "2023-10-04T19:13:12.3968Z"
    },
    {
      "assetId": "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      "description": "she lost her dang mind",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2121-11-22T05:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "upside down dog face",
      "files": [
        {
          "id": "c6740fb7-ca93-4430-bad6-a7f9796740eb",
          "groupName": null,
          "fileName": "upside_down_floppy_gums.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_110.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=kieV3%2F%2BJf8353%2FkfYOkiroRweFk%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_400.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=AsB7aIpc%2Bx6BWne1hDJuvCb1Anc%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_500.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=LUU7c4P1S5gHMpZzWSgpVOa2FPM%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_700.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=dKe3YFmLz1Yf2umaYmmnqosD80E%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "ccafc402-96da-4547-a02a-0e1551004a55",
        "974d55ee-0566-4c21-8e91-8cdfae764bc2",
        "8a14cfe4-4171-436e-8724-ae35afdd422a"
      ],
      "category": null,
      "downloadCount": 4,
      "id": "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      "url": "retail/branded-environments/design-advisories/details/63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      "searchType": "retail",
      "isFavorite": true,
      "title": "Floppy Mouth",
      "score": 0,
      "createdOn": "2023-10-04T15:39:22.365061Z",
      "modifiedOn": "2023-10-04T15:39:22.356624Z"
    },
    {
      "assetId": "7a65ae9e-f296-4269-9e84-62815670bb53",
      "description": "look at that lil paint brush, all dipped in white paint",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "you have the right to be cute and scary",
      "expiresOn": "2222-11-22T05:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "dog is looking at you, watch out",
      "files": [
        {
          "id": "4da2a620-417f-4153-a14b-ec7dcc3531ba",
          "groupName": null,
          "fileName": "evil_tail.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_110.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=FpKpuM8VDItIAxALhcoeNftsYw4%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_400.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=XuaTh5c0AQlZ01PT18pPRogjw78%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_500.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=h4hS3DAWN7AyvdduY3PHvGbRmD0%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_700.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=ork4%2FVHmgXI9%2B6xI3vDgJpMPgdg%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "ccafc402-96da-4547-a02a-0e1551004a55",
        "974d55ee-0566-4c21-8e91-8cdfae764bc2",
        "8a14cfe4-4171-436e-8724-ae35afdd422a"
      ],
      "category": null,
      "downloadCount": 2,
      "id": "7a65ae9e-f296-4269-9e84-62815670bb53",
      "url": "retail/branded-environments/design-advisories/details/7a65ae9e-f296-4269-9e84-62815670bb53",
      "searchType": "retail",
      "isFavorite": true,
      "title": "Evil Tail Gaze Weasel",
      "score": 0,
      "createdOn": "2023-10-04T15:00:59.72001Z",
      "modifiedOn": "2023-10-04T15:00:59.682499Z"
    },
    {
      "assetId": "5f578d08-75a0-41dc-87e0-556b3e35c14f",
      "description": "yum. yum chipmunks",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2050-05-09T04:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "dog eats chipmunk",
      "files": [
        {
          "id": "3f27aff3-327b-42cb-9342-21c59b5d98ad",
          "groupName": null,
          "fileName": "lick_chippie_sneaky.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_110.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=msdCQNnQoESDJJ8KrGMuZDjB4i0%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_400.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=g8tk1m99CrcuAgoFS1LgD0N6j6c%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_500.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=oSRfAZ2RJ%2FZr2dtODyEhKTgeZok%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_700.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=XbfhbPaUk1%2FLMzwuXc3B1Rza8wc%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "ccafc402-96da-4547-a02a-0e1551004a55",
        "974d55ee-0566-4c21-8e91-8cdfae764bc2",
        "8a14cfe4-4171-436e-8724-ae35afdd422a"
      ],
      "category": null,
      "downloadCount": 1,
      "id": "5f578d08-75a0-41dc-87e0-556b3e35c14f",
      "url": "retail/branded-environments/design-advisories/details/5f578d08-75a0-41dc-87e0-556b3e35c14f",
      "searchType": "retail",
      "isFavorite": false,
      "title": "Louise loves her chippies",
      "score": 0,
      "createdOn": "2023-10-04T15:56:30.198513Z",
      "modifiedOn": "2023-10-04T15:56:30.187993Z"
    },
  ],
  filters: null,
  totalPages: 2,
  correlationId: "U0lEOjAyNDQ2YzNiLWNkNTYtNGNkNS04NjA4LTQ0NTYzOTUwY2IyN3xQSDo2MjI1MDU3MzZ8UklEOmZjMDI0Mjg2LTg5NDgtNGY2OS05MTUxLTY2OWJkZDUzNGRiMQ=="
};

export const assetSearchResults = {
  total: 4,
  results: [
    {
      category: "Photography",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isRestricted: true,
      isFavorite: false,
      title: "Danhuri test asset",
      description: "Danhuri test asset",
      altText: "Danhuri test asset",
      usageRightsCopy: "",
      files: [
        {
          id: "8fc4d4c8-47e4-4f5b-9c23-3a22b7701537",
          groupName: null,
          fileName: "asset screen shot 2.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=XhcB3t5ksw2Ete3OX%2F%2B0jUoC5nE%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=qJzPPCtqHdNFmHfDBnlFg7dltiY%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=4ZFOqOGZpGj%2FKfwb4fgDyb5eJiw%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL04c98ef7a7cd4156867c5d6e22a739f6_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=wxppHFfSluEGb%2Fc%2BpIqbsD2lubo%3D",
            isImage: true,
          },
        },
        {
          id: "a284f85f-0e9b-4426-9645-a0364fe82d73",
          groupName: null,
          fileName: "asset screen shot new version 2.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=y11%2BcMgNYfsMJwUkP1UK37tzqB8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=YfDO4C82Woh%2F4PEVBfEC8Ym0IT4%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=6GJXyqO2DNhmGxzqbdAJzy9WsyY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLab6e9b49ca0c4e8dbc6af794cf1c42ff_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=jMJzMUuqrR6FN7MFjogMIJc1laI%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "a22f39d0-4876-496c-93a6-14fe4011dc8d",
        "8e04ac67-b08a-4f88-ad39-194dd6d80f02",
      ],
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      searchId: "975551e9-411f-44b9-8762-e07533d7769a",
      id: "975551e9-411f-44b9-8762-e07533d7769a",
      assetId: "975551e9-411f-44b9-8762-e07533d7769a",
      expiresOn: "2424-12-31T00:00:00Z",
    },
    {
      searchId: "7a65ae9e-f296-4269-9e84-62815670bb53",
      assetId: "7a65ae9e-f296-4269-9e84-62815670bb53",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      category: "photography",
      isRestricted: false,
      isFavorite: false,
      title: "Evil Tail Gaze Weasel",
      description: "look at that lil paint brush, all dipped in white paint",
      altText: "dog is looking at you, watch out",
      usageRightsCopy: "you have the right to be cute and scary",
      files: [
        {
          id: "4da2a620-417f-4153-a14b-ec7dcc3531ba",
          groupName: null,
          fileName: "evil_tail.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_110.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=NUwhftqGhJNMRcLq0i0mJfVRYZ8%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_400.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=D4SVllmjV2Vk41RI0p9aK1SEZ34%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_500.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=3xb5CQKci9rDMRTyHtxi3tZRdeY%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_700.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=3V5HtK5ZL1TNUuz0hWSm3Z6Fn7w%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "a22f39d0-4876-496c-93a6-14fe4011dc8d",
        "8e04ac67-b08a-4f88-ad39-194dd6d80f02",
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "7a65ae9e-f296-4269-9e84-62815670bb53",
      expiresOn: "2222-11-22T05:00:00Z",
    },
    {
      searchId: "5f578d08-75a0-41dc-87e0-556b3e35c14f",
      assetId: "5f578d08-75a0-41dc-87e0-556b3e35c14f",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      category: "photography",
      isRestricted: false,
      isFavorite: false,
      title: "Louise loves her chippies",
      description: "yum. yum chipmunks",
      altText: "dog eats chipmunk",
      copyright: "",
      usageRightsCopy: "",
      files: [
        {
          id: "3f27aff3-327b-42cb-9342-21c59b5d98ad",
          groupName: null,
          fileName: "lick_chippie_sneaky.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_110.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=xI%2Fvvh8gATTcWaB%2FIJoYjkwSqfw%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_400.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=v2qF7X%2FpACSXLY8uvoAsoPkndMw%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_500.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=RIN7ABT0WDfEGGytzswHziwe7VM%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_700.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=E2JNceVjrxFQggxvd%2BTWXmUvv0c%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "a22f39d0-4876-496c-93a6-14fe4011dc8d",
        "8464f380-7dd5-478a-adc4-9fe85f123d00",
      ],
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "5f578d08-75a0-41dc-87e0-556b3e35c14f",
      expiresOn: "2050-05-09T04:00:00Z",
    },
    {
      searchId: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      assetId: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      category: "photography",
      isRestricted: false,
      isFavorite: false,
      title: "Floppy Mouth",
      description: "she lost her dang mind",
      altText: "upside down dog face",
      usageRightsCopy: "",
      files: [
        {
          id: "c6740fb7-ca93-4430-bad6-a7f9796740eb",
          groupName: null,
          fileName: "upside_down_floppy_gums.jpg",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_110.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=fkJCkO89CWLFnRYHWn79KIEu3OE%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_400.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=X%2BUfvHmZ9JwoBPUHltuYavHtyOU%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_500.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=navH48KIYITfyrN4kB1G0f84u%2BI%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_700.png?AWSAccessKeyId=ASIAUHQDK3LLK26ALE5B&Expires=1698549393&x-amz-security-token=IQoJb3JpZ2luX2VjEBMaCXVzLWVhc3QtMSJGMEQCICDVbseucmVTJxJZGzGGh2VfAlWo1nHGTif%2FGeCZxWZ8AiBD1Ctclt7ieMwvj1dC59P0f%2F9iJa1EyHshHl01EzYxRiqDBAg7EAMaDDI5MDk5MTAzNzE0MiIM8jNzfscLdCAG3IOkKuADOjBh44Ku30w6h6Ll2vVcabpnbjl1KCfQTNaCA2o2%2B67ZSS6tUQ%2BjCz5iJMK%2BcR%2FxFHNgzWhCoNtqFJeLiZhBC4gs7qvfL1LAoch0zfUj92C3X%2Fd2HUyYRDq4KnhiO6w64tyfiROHH4G4jExm4dp0S%2Bd8P3s%2BPElLM8lLyRE11uSHe5290LoRvYhdrsFR5joCpMF6GTrfACMan0ZKGKDahyzgTyOeDOGTMxWbTBFtU3pKs5Qyj5IBqxnZcB7U4l9%2F9MLQ5BRZESAGiK4fmVb5xHX1ceQCwHNrgmr%2F%2FGHoBYGNnRpTFEMkKEnvu0mix9usx7iac8lC%2BAOP4Th3PvGSzNtJl8y%2B%2BS9w9bmtbVsxHsL9C9kz3iTWdiPA8lpkL07gpt0gWww5zOLj8OADG7jqy4VAfFFBewvlI1xKKyR8rxygqAcNWu7jlthfEU1kc%2BtfZsvDcNXOLFCa%2FEcNEKNlRCgQGu94bShQS7D8tMXRJnSwyjr5olPjpbCS4lqVc%2B0zVnxTSwXe46lU19%2BO1ySZXSmImmsDbFbdjPKsrdMY3XZ2azrxtTOB0feTzRvIzFidfMM%2F%2BPLKDMeJdybDvb9bsl2qyUHk8ntb3EzKN%2FMqIW0z1QMJDth%2B0xMZ4p9YTGWeMMDQ3KkGOqYBFlkcvCHCD6CcbJL%2FNm%2FGgCQ%2F%2FT2CBUwBie2ikkpelXfB%2BjQYO4Eaf639ncJQyCdgAHpZWqt49z7gqGRNXmrShcs5iMXz%2FIeI21oLQ%2FIZ0U9yDZ262uTrpTA%2BIszFaKQNdpZ4giaCg4R6gMnzU72yIewHieYf102e1E9KJZQ5IYDgHGH%2BSa1uWsNvmyioPxvcZ1Jfc9NOQcEdKZb0r0aMEUeK8guRBg%3D%3D&Signature=FVXQe1OdCkw%2F%2FS12abCqNTSMl18%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "3d8d9269-63a0-437f-90ec-f70204d5c10f",
      ],
      userActions: {
        hasAcceptedUsageRights: true,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      id: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      expiresOn: "2121-11-22T05:00:00Z",
    },
  ],
  filters: {
    unfilteredTotal: 90,
    categories: {
      "76007f55-b998-4883-8e53-64c5ecec4a51": 4,
    },
    subCategories: {
      "a22f39d0-4876-496c-93a6-14fe4011dc8d": 3,
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660": 1,
    },
    tags: {
      "8e04ac67-b08a-4f88-ad39-194dd6d80f02": 2,
      "8464f380-7dd5-478a-adc4-9fe85f123d00": 1,
      "3d8d9269-63a0-437f-90ec-f70204d5c10f": 1,
    },
  },
};

export const complianceSearchResults = {
  results: [
    {
      id: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchId: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      entityId: "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchType: "compliance",
      isFavorite: false,
      title: "Scoring",
      // copy: "The goal of Brand Compliance is to ensure all communications are 100% on-brand once they go into market. In this section you’ll learn how a submission is scored.",
      url: "/compliance/scoring",
      complianceType: "page",
    },
  ],
  total: 1,
  totalPages: 1,
};

export const guidelinesSearchResults = {
  results: [
    {
      id: "557ZAO7oaUXnYiVFjfM3Y1",
      searchId: "557ZAO7oaUXnYiVFjfM3Y1",
      entityId: "557ZAO7oaUXnYiVFjfM3Y1",
      isFavorite: false,
      searchType: "guideline",
      url: "https://brandcentral.verizonwireless.com.test.epqa.us/guidelines/details/retail/digital/logo",
      title: "Retail: Digital Logo",
    },
    {
      id: "7oO2yYCaOsqKDF3upJfJG1",
      searchId: "7oO2yYCaOsqKDF3upJfJG1",
      entityId: "7oO2yYCaOsqKDF3upJfJG1",
      searchType: "guideline",
      isFavorite: false,
      url: "https://brandcentral.verizonwireless.com.test.epqa.us/guidelines/details/verizon-business/get-started/logo-attributes",
      title: "Verizon Business: Logo attributes",
    },
    {
      id: "4yLTjfgXLc8cLLKfSJnFKR",
      searchId: "4yLTjfgXLc8cLLKfSJnFKR",
      entityId: "4yLTjfgXLc8cLLKfSJnFKR",
      searchType: "guideline",
      isFavorite: false,
      url: "https://brandcentral.verizonwireless.com.test.epqa.us/guidelines/details/alliance-branding/Verizon-endorsed/logo-only",
      title: "Alliance branding: Verizon-endorsed Logo only",
    },
  ],
  total: 3,
  totalPages: 1,
};

export const trainingSearchResults = {
  results: [
    {
      id: "13b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchId: "13b4e470-57e8-4c7b-98d9-e40fb27f3499",
      entityId: "13b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchType: "training",
      url: "training/videos/url",
      title: "Verizon business cards & stationery",
      videoSlug: "business-cards-and-stationery",
      isFavorite: false,
      thumbnailAssetUrl:
        "https://images.ctfassets.net/ve6konwi38y5/17sTCemA8NAkiD81hN0Xfb/c7b7095e5ebfda4d35d943147735cab8/verizon-business-cards-stationary.png",
      link1Text: "Learn more",
      link1Url:
        "/guidelines/details/questions-and-answers/get-started/business-cards",
      link2Text: "Download stationery",
      link2Url: "/assets/search?saved=7b19c278-9c15-4afd-b5d5-e02b881513bd",
      playlistSlug: "brand-identity-training-video-series",
    },
    {
      id: "23b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchId: "23b4e470-57e8-4c7b-98d9-e40fb27f3499",
      entityId: "23b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchType: "training",
      url: "training/videos/url",
      title: "Verizon email signature",
      videoSlug: "email-signature",
      isFavorite: false,
      thumbnailAssetUrl:
        "https://images.ctfassets.net/ve6konwi38y5/44TnKVKAlcwSqS167ZIurm/bafd6cc1af3b9a9fa01cdf8d03f983ba/verizon-email-signature-1.png",
      link1Text: "Learn more",
      link1Url:
        "/guidelines/details/questions-and-answers/get-started/email-signature",
      playlistSlug: "brand-identity-training-video-series",
    },
    {
      id: "33b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchId: "33b4e470-57e8-4c7b-98d9-e40fb27f3499",
      entityId: "33b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchType: "training",
      url: "training/videos/url",
      title: "Verizon NHG",
      videoSlug: "verizon-nhg",
      isFavorite: false,
      thumbnailAssetUrl:
        "https://images.ctfassets.net/ve6konwi38y5/1U01rVo1xWS380gjD7MDFQ/0ab5fe3b35529a27023a8f12be91a8bf/verizon-nhg.png",
      link1Text: "Learn more",
      link1Url:
        "/guidelines/details/masterbrand-elements/typography/overview",
      link2Text: "Download Verizon NHG",
      link2Url: "/assets/details/5043f09c8c4b47ac88d36232e03891be",
      playlistSlug: "brand-identity-training-video-series",
    },
    {
      id: "43b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchId: "43b4e470-57e8-4c7b-98d9-e40fb27f3499",
      entityId: "43b4e470-57e8-4c7b-98d9-e40fb27f3499",
      searchType: "training",
      url: "training/videos/url",
      title: "Verizon brand voice",
      videoSlug: "brand-voice",
      isFavorite: false,
      thumbnailAssetUrl:
        "https://images.ctfassets.net/ve6konwi38y5/26WUrhq78k5KWeOSBeVrmd/e5dea62d31ec993bb04711abf00908c9/verizon-brand-voice-1.png",
      link1Text: "Learn more",
      link1Url: "/guidelines/details/masterbrand-elements/voice/overview",
      playlistSlug: "brand-identity-training-video-series",
    },
  ],
  total: 4,
  totalPages: 1,
};

export const templatesSearchResults = {
  results: [
    {
      "assetId": "418ab717-3ed1-4ace-94cd-e703697e7f0e",
      "description": "bed hog with a serious case of grumpies",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2100-05-09T04:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "dog hogging the bed",
      "files": [
        {
          "id": "4122c3ba-1856-42cb-a8f9-f3244717432c",
          "groupName": null,
          "fileName": "grumpy_tucked_in_bed.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0e84467826574b98a7b9906015031d6f_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=%2BnDfbCjMP7xlDVIvSkdv1xUjxA4%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0e84467826574b98a7b9906015031d6f_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=hDZ8FiFL7x%2BcD6htDmFJz3N%2By5U%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0e84467826574b98a7b9906015031d6f_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=Ir62QEPPSab%2FK4Hu4yKVkxcu5zU%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL0e84467826574b98a7b9906015031d6f_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=YbEXYv%2F2%2FNKSUn1rWRk%2Bsif2Slo%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
        "1491b4e6-5127-4d6d-8329-35831079696a",
        "32e464d8-a8b8-4497-9d77-6533ff4e7cde"
      ],
      "category": null,
      "downloadCount": 45,
      "id": "418ab717-3ed1-4ace-94cd-e703697e7f0e",
      "url": "templates/details/418ab717-3ed1-4ace-94cd-e703697e7f0e",
      "searchType": "template",
      "isFavorite": false,
      "title": "Sleepy Weasel",
      "score": 0,
      "createdOn": "2023-10-04T19:10:27.181207Z",
      "modifiedOn": "2023-10-04T19:10:27.170476Z"
    },
    {
      "assetId": "b3af393c-36c0-433d-bb34-9e16d84cabb4",
      "description": "squishy face",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2716-05-14T04:00:00Z",
      "isRestricted": true,
      "isSearchable": true,
      "altText": "smushy house hippo",
      "files": [
        {
          "id": "b77511c6-9b94-418d-9f07-a908f3ea5762",
          "groupName": null,
          "fileName": "Image_20240322_171556_376.jpeg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2b3af9e6b0034e239a63da6d46352751_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=5%2BecLwVwHWCFAthUmOY%2Fev9IRIk%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2b3af9e6b0034e239a63da6d46352751_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=5ADaJ9g2D%2F7buAeqpgTGn%2BiHcPQ%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2b3af9e6b0034e239a63da6d46352751_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=CPQTiTcuqT3R8RLy0MNjdXLCYt0%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL2b3af9e6b0034e239a63da6d46352751_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=CQxwwFkauLlIVwYN73OQcXUXiLY%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
        "1491b4e6-5127-4d6d-8329-35831079696a",
        "32e464d8-a8b8-4497-9d77-6533ff4e7cde"
      ],
      "category": null,
      "downloadCount": 31,
      "id": "b3af393c-36c0-433d-bb34-9e16d84cabb4",
      "url": "templates/details/b3af393c-36c0-433d-bb34-9e16d84cabb4",
      "searchType": "template",
      "isFavorite": false,
      "title": "Mighty Meaty Meatball",
      "score": 0,
      "createdOn": "2024-05-14T19:48:06.996386Z",
      "modifiedOn": "2024-05-14T19:48:06.984939Z"
    },
    {
      "assetId": "b3244dfc-f650-4012-a359-02c87806c5ab",
      "description": "meatball napping and showing his teefs",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2517-05-14T04:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "toofy toofs",
      "files": [
        {
          "id": "f52fb6e1-58f4-475f-a63b-2cc1931662d2",
          "groupName": null,
          "fileName": "Image_20240313_093730_963.jpeg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd5eed4b33c6045aa8cf17b5a66e05abd_110.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=OtomM14S95h8knXqP21wfxU%2BjR8%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd5eed4b33c6045aa8cf17b5a66e05abd_400.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=QwGSccTKz9ztZ5UJtK3Ui02LAHw%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd5eed4b33c6045aa8cf17b5a66e05abd_500.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=f4%2FphxoKmVREJadFbhshCBOwyg0%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLd5eed4b33c6045aa8cf17b5a66e05abd_700.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=VrhMW6JFMCbrzmbOk14buj79gfE%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
        "1491b4e6-5127-4d6d-8329-35831079696a",
        "ed9b962f-a376-4626-bfc9-8b1b5c6c9046"
      ],
      "category": null,
      "downloadCount": 46,
      "id": "b3244dfc-f650-4012-a359-02c87806c5ab",
      "url": "templates/details/b3244dfc-f650-4012-a359-02c87806c5ab",
      "searchType": "template",
      "isFavorite": true,
      "title": "Teefs",
      "score": 0,
      "createdOn": "2024-05-14T19:52:03.388724Z",
      "modifiedOn": "2024-05-14T20:28:14.687879Z"
    },
    {
      "assetId": "d2bb51a9-bf04-4079-8f45-1fc0a9507db6",
      "description": "majestic af",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "you have the right to bow down",
      "expiresOn": "2626-11-22T05:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "glowing dog",
      "files": [
        {
          "id": "2001e7ac-3a48-4865-9ce2-71030f87d7d4",
          "groupName": null,
          "fileName": "sunshine_side_eye.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3a624121b3a145278ba6bf5e5771d6d3_110.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=3S97SK5qeieZD0fCTqSv9BlzPMg%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3a624121b3a145278ba6bf5e5771d6d3_400.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=76YgB04%2FpFbCtmUi%2FcejFDMs8aU%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3a624121b3a145278ba6bf5e5771d6d3_500.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=EQpmnNSkY5d%2BnrO6VULlZRDoM0s%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL3a624121b3a145278ba6bf5e5771d6d3_700.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718716991&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=HFZMiRITZoRMmHwoIKzVjnK%2FO88%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
        "1491b4e6-5127-4d6d-8329-35831079696a",
        "a5d33e9f-13bb-4ed6-9a74-d63f768a7533"
      ],
      "category": null,
      "downloadCount": 48,
      "id": "d2bb51a9-bf04-4079-8f45-1fc0a9507db6",
      "url": "templates/details/d2bb51a9-bf04-4079-8f45-1fc0a9507db6",
      "searchType": "template",
      "isFavorite": false,
      "title": "Majestic Weasel",
      "score": 0,
      "createdOn": "2023-10-04T19:13:12.4048Z",
      "modifiedOn": "2023-10-04T19:13:12.3968Z"
    },
  ],
  filters: {
    unfilteredTotal: 90,
    categories: {
      "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1": 4,
    },
    subCategories: {
      "1491b4e6-5127-4d6d-8329-35831079696a": 4,
    },
    tags: {
      "32e464d8-a8b8-4497-9d77-6533ff4e7cde": 2,
      "ed9b962f-a376-4626-bfc9-8b1b5c6c9046": 1,
      "a5d33e9f-13bb-4ed6-9a74-d63f768a7533": 1
    },
  },
  total: 4,
  totalPages: 1,
};

export const retailSearchResults = {
  results: [
    {
      "assetId": "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      "description": "she lost her dang mind",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2121-11-22T05:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "upside down dog face",
      "files": [
        {
          "id": "c6740fb7-ca93-4430-bad6-a7f9796740eb",
          "groupName": null,
          "fileName": "upside_down_floppy_gums.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_110.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=kieV3%2F%2BJf8353%2FkfYOkiroRweFk%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_400.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=AsB7aIpc%2Bx6BWne1hDJuvCb1Anc%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_500.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=LUU7c4P1S5gHMpZzWSgpVOa2FPM%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL1da2b15f3a2b4c7d9b56d6ae633fd638_700.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=dKe3YFmLz1Yf2umaYmmnqosD80E%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "ccafc402-96da-4547-a02a-0e1551004a55",
        "974d55ee-0566-4c21-8e91-8cdfae764bc2",
        "8a14cfe4-4171-436e-8724-ae35afdd422a"
      ],
      "category": null,
      "downloadCount": 4,
      "id": "63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      "url": "retail/branded-environments/design-advisories/details/63b4e470-57e8-4c7b-98d9-e40fb27f3499",
      "searchType": "retail",
      "isFavorite": true,
      "title": "Floppy Mouth",
      "score": 0,
      "createdOn": "2023-10-04T15:39:22.365061Z",
      "modifiedOn": "2023-10-04T15:39:22.356624Z"
    },
    {
      "assetId": "7a65ae9e-f296-4269-9e84-62815670bb53",
      "description": "look at that lil paint brush, all dipped in white paint",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "you have the right to be cute and scary",
      "expiresOn": "2222-11-22T05:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "dog is looking at you, watch out",
      "files": [
        {
          "id": "4da2a620-417f-4153-a14b-ec7dcc3531ba",
          "groupName": null,
          "fileName": "evil_tail.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_110.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=FpKpuM8VDItIAxALhcoeNftsYw4%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_400.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=XuaTh5c0AQlZ01PT18pPRogjw78%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_500.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=h4hS3DAWN7AyvdduY3PHvGbRmD0%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL5a478588733a4c96bfe20a4f8df8ed25_700.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=ork4%2FVHmgXI9%2B6xI3vDgJpMPgdg%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "ccafc402-96da-4547-a02a-0e1551004a55",
        "974d55ee-0566-4c21-8e91-8cdfae764bc2",
        "8a14cfe4-4171-436e-8724-ae35afdd422a"
      ],
      "category": null,
      "downloadCount": 2,
      "id": "7a65ae9e-f296-4269-9e84-62815670bb53",
      "url": "retail/branded-environments/design-advisories/details/7a65ae9e-f296-4269-9e84-62815670bb53",
      "searchType": "retail",
      "isFavorite": true,
      "title": "Evil Tail Gaze Weasel",
      "score": 0,
      "createdOn": "2023-10-04T15:00:59.72001Z",
      "modifiedOn": "2023-10-04T15:00:59.682499Z"
    },
    {
      "assetId": "5f578d08-75a0-41dc-87e0-556b3e35c14f",
      "description": "yum. yum chipmunks",
      "userActions": {
        "hasAcceptedUsageRights": true,
        "hasPendingAccessRequest": false,
        "canDownload": true,
        "canEdit": true,
        "canDeactivate": true,
        "canExpire": true,
        "canUpdateAssetAccessRequest": true,
        "isAdmin": true
      },
      "usageRightsCopy": "",
      "expiresOn": "2050-05-09T04:00:00Z",
      "isRestricted": false,
      "isSearchable": true,
      "altText": "dog eats chipmunk",
      "files": [
        {
          "id": "3f27aff3-327b-42cb-9342-21c59b5d98ad",
          "groupName": null,
          "fileName": "lick_chippie_sneaky.jpg",
          "approvalStatus": "Approved",
          "thumbnails": {
            "small": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_110.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=msdCQNnQoESDJJ8KrGMuZDjB4i0%3D",
            "medium": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_400.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=g8tk1m99CrcuAgoFS1LgD0N6j6c%3D",
            "large": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_500.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=oSRfAZ2RJ%2FZr2dtODyEhKTgeZok%3D",
            "xLarge": "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL41c52fee098c4811bc55a8650b2ae38e_700.png?AWSAccessKeyId=ASIAUHQDK3LLHCA5E77E&Expires=1718717159&x-amz-security-token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEVci15bm5q5atCgPGtIcp03ByPTNZZlE14dYb0rflDbAiEAv7MNLHxgQmhgbuaUdcIst9WNPj7sZqeRgzLcwypScZcqjAQIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwyOTA5OTEwMzcxNDIiDKC6f781Nzu8t4GgaSrgA7XZH9Vu6a2TJunQE8GwZdkeLbqbNq%2F1jfv8ghtNqPKHseyvFwbzAypx%2BVTkQY5yfqwM9fnPN95lPdxEeuZtI5ERym%2B97bHd4nop0AG8dUyUAs6XpJ%2ByO2oP1V%2BNWDnaVXQ%2FPQlojqg%2FyJ%2F1nAq8JkSSpB3Ob57SOiC3p%2B8sJPP%2Fb74yeCLY3uLvlzy6VhxuHDu%2BBWmeeDlF%2F6yhTuorqwLVcVLoSuf6afqvFOyczaGt2jq7AP4spjD7W4PAAL85SA%2BJY9ahdQDjQseG6F3exzAkuYf44HwSir7X%2FDK%2FG5lXWjWMmWZIzEqvFb6t4LrEuPLdnyMYc3avmPm6esWocfCCV2gUh7LI57cSVaNEFcujq1zlOIUMz9VWF1VtE7Y0vjKRiiUHkpfZoDmDmdg8svZQJ79O7U69dwkLf1QW6XbJEzkc2JWqIn%2F%2BzKsCZXbT1nyQNT2%2BC4Hk4vc271VqXOTEA5iHEnD3AbYuiPEm%2F%2FXn4dcOLtJ1tXlH7MMMOIXSVIF1umEugQz8NB6TwAgShvR3lNrJiba4%2BAaGlaUBXhwwLoIKCxNB3xJPJKJNcEvelGYgOC0stddnXO34NipysG2rLGLQsIJJYyUelR%2BoxPtDakcZYluPB23v91kZd%2F0CgjCI3auzBjqlAWgoGs6sfXtHH5XS5TiIfmrvucZDPWhRORQxh8OqAD4Ew9Q9LNSUGGl3%2BjAS1joUwV5tCA7f9D2TM11LP%2B%2Fp5n3QGK1Mx0SeF%2BuSALcGCTQNoAA%2FqKJYvz9waaSBaHgpIR0bzESR4m5UEDLV4h7f7fmw2hcvCPdFYvq%2Fyy6h6ELmwxW1hcMmR6aAyZLNRq1dXcXqX7K3QxeTtFNL%2BPLnSi9owHkQ%2BQ%3D%3D&Signature=XbfhbPaUk1%2FLMzwuXc3B1Rza8wc%3D",
            "isImage": true
          }
        }
      ],
      "filterIds": [
        "ccafc402-96da-4547-a02a-0e1551004a55",
        "974d55ee-0566-4c21-8e91-8cdfae764bc2",
        "8a14cfe4-4171-436e-8724-ae35afdd422a"
      ],
      "category": null,
      "downloadCount": 1,
      "id": "5f578d08-75a0-41dc-87e0-556b3e35c14f",
      "url": "retail/branded-environments/design-advisories/details/5f578d08-75a0-41dc-87e0-556b3e35c14f",
      "searchType": "retail",
      "isFavorite": false,
      "title": "Louise loves her chippies",
      "score": 0,
      "createdOn": "2023-10-04T15:56:30.198513Z",
      "modifiedOn": "2023-10-04T15:56:30.187993Z"
    },
  ],
  total: 3,
  totalPages: 1,
};

export const mockSavedSearchObject = {
  search: "dog",
  categoryId: "category",
  includeRestricted: true,
  includeExpired: true,
  selectedTags: ["tag 1", "tag 2"],
  searchTypes: ["templates"],
};

export const mockCountries = [
  {
    id: "367b7469-8b6e-4b75-85c4-98a35b902788",
    name: "United States",
    code: "US",
    phoneCode: 1,
    plusPhoneCode: "+1",
  },
  {
    id: "76d5efb1-82e0-478b-ba75-cda9f40437b1",
    name: "Australia",
    code: "AU",
    phoneCode: 61,
    plusPhoneCode: "+61",
  },
  {
    id: "7da85961-8a5f-4710-b5fa-a37fbdd982e1",
    name: "Canada",
    code: "CA",
    phoneCode: 1,
    plusPhoneCode: "+1",
  },
  {
    id: "9379a816-3551-4649-829c-9a919b1dc49e",
    name: "United Kingdom",
    code: "GB",
    phoneCode: 44,
    plusPhoneCode: "+44",
  },
];

export const mockEmploymentTitles = [
  {
    id: "964d3385-fdc2-4863-b07e-6d0f2b07b841",
    name: "Agency creator",
  },
  {
    id: "7f68c38a-16aa-40e9-9a07-1bef80aef17f",
    name: "Agency account & project management",
  },
  {
    id: "28887d49-c7c8-43ef-a43b-bb564657b521",
    name: "Agency CX/tech",
  },
  {
    id: "6dba7566-39ec-4bc2-aca6-179fd557e703",
    name: "Device, Accessory & OEM partners",
  },
  {
    id: "f2ffd3af-55e1-4d73-8cb4-758902acb49c",
    name: "Verizon partner/sponsorship",
  },
  {
    id: "04379e5d-4026-43ae-bb18-751ecf848120",
    name: "Verizon partner/media",
  },
  {
    id: "0e449ce5-8c43-49bc-9417-3edad8530222",
    name: "Vendor/supplier",
  },
  {
    id: "de1e8d79-4011-4b33-876e-8e8a85399ae9",
    name: "Other",
  },
];

export const mockCategories = [
  {
    id: "8a3295f9-4a31-4554-aa9d-eb1933134dcc",
    value: "Campaigns",
    active: false,
    searchType: "asset"
  },
  {
    id: "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
    value: "Campaigns, Creative & Playbooks",
    active: true,
    searchType: "asset"
  },
  {
    id: "ccafc402-96da-4547-a02a-0e1551004a55",
    value: "Design Advisories",
    active: true,
    searchType: "retail"
  },
  {
    id: "316c0651-c5e3-4bd2-b41c-5427484f74bd",
    value: "Devices & Accessories",
    active: true,
    searchType: "asset"
  },
  {
    id: "18cdbe3d-567f-47eb-9c94-9acdab78547b",
    value: "Employee Communications",
    active: true,
    searchType: "asset"
  },
  {
    id: "e492b86d-3a1d-45a0-b518-3ace937747cf",
    value: "Iconography",
    active: true,
    searchType: "asset"
  },
  {
    id: "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
    value: "Logos",
    active: true,
    searchType: "asset"
  },
  {
    id: "76007f55-b998-4883-8e53-64c5ecec4a51",
    value: "Photography",
    active: true,
  },
  {
    id: "7e30e8bd-a9e7-4b3d-a47e-17b02d6cafe1",
    value: "Templates",
    active: true,
  },
];

export const mockSubcategories = [
  {
    id: "f9e5c0cd-1a37-424c-9e8d-98a78a859dcd",
    categoryId: "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
    value: "Creative Type",
    active: false,
  },
  {
    id: "5fee2f58-eac4-4d3d-8e01-52ad5aed2479",
    categoryId: "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
    value: "Market",
    active: true,
  },
  {
    id: "68dc51ef-a4b4-4310-846b-21d4d83ab77a",
    categoryId: "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
    value: "Messaging",
    active: false,
  },
  {
    id: "95107adf-35ed-4e76-806f-1a51700d8c98",
    categoryId: "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
    value: "Segment",
    active: false,
  },
  {
    id: "c51db643-479b-46a3-b3ea-4d5c23f0db1d",
    categoryId: "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
    value: "test",
    active: true,
  },
];

export const mockTags = [
  {
    id: "0bcd68fc-6a74-4924-884f-5f348606be89",
    subCategoryId: "f9e5c0cd-1a37-424c-9e8d-98a78a859dcd",
    value: "Bill Insert",
    active: false,
  },
  {
    id: "93a7bb91-6458-4af0-a154-d8ad12f65aaf",
    subCategoryId: "f9e5c0cd-1a37-424c-9e8d-98a78a859dcd",
    value: "Collateral",
    active: true,
  },
  {
    id: "3747f5d5-f0bc-454a-a336-3754f3155b57",
    subCategoryId: "f9e5c0cd-1a37-424c-9e8d-98a78a859dcd",
    value: "Creative brief",
    active: false,
  },
  {
    id: "9ab6508c-29d8-44a1-a334-a21b5499c01c",
    subCategoryId: "f9e5c0cd-1a37-424c-9e8d-98a78a859dcd",
    value: "Digital",
    active: false,
  },
];
export const mockAccountTypes = ["AgencyOrSupplier", "Employee", "None"];

export const mockChannelsUses = {
  channels: [
    { id: "0", value: "Channel Option One" },
    { id: "1", value: "Other" },
  ],
  requestedUses: [
    { id: "0", value: "Uses Option One" },
    { id: "1", value: "Other" },
  ],
};

export const relatedAssets = [
  {
    id: "test-id",
    searchId: "test-id",
    assetId: "test-id",
    createdDate: "2022-10-05T19:12:25.759419Z",
    modifiedDate: "2023-09-26T16:57:46.174246Z",
    url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
    searchType: "asset",
    isSearchable: true,
    isRestricted: true,
    canDownload: true,
    isFavorite: true,
    title: "Asset 36",
    description: "Asset 36",
    altText: "Asset 36",
    copyright: "",
    subtitle: "Asset 36",
    usageRightsCopy: "Usage rights copy test",
    files: [
      {
        id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
        groupName: null,
        fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
        approvalStatus: "Pending",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
          isImage: true,
        },
      },
    ],
    filterIds: [
      "76007f55-b998-4883-8e53-64c5ecec4a51",
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
      "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
    ],
    userActions: {
      hasAcceptedUsageRights: false,
      hasPendingAccessRequest: false,
      canDownload: true,
      canEdit: true,
      canDeactivate: true,
      canExpire: true,
      canUpdateAssetAccessRequest: true,
      isAdmin: true,
    },
    expiresOn: formatDate(oneYearsTime),
  },
  {
    id: "test-id-2",
    searchId: "test-id-2",
    assetId: "test-id-2",
    createdDate: "2022-10-05T19:12:25.759419Z",
    modifiedDate: "2023-09-26T16:57:46.174246Z",
    url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
    searchType: "asset",
    isSearchable: true,
    isRestricted: true,
    canDownload: true,
    isFavorite: true,
    title: "Asset 2 name",
    description: "Asset 2 description",
    altText: "Asset 2 alt text",
    copyright: "",
    subtitle: "Asset 2 Subtitle",
    usageRightsCopy: "Usage rights copy test",
    files: [
      {
        id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
        groupName: "1",
        fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
        approvalStatus: "Pending",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
          isImage: true,
        },
      },
      {
        id: "dc8bea19-422d-4a75-a297-bdea822fc679",
        groupName: "2",
        fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
        approvalStatus: "Approved",
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
          isImage: true,
        },
      },
    ],
    filterIds: [
      "76007f55-b998-4883-8e53-64c5ecec4a51",
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
      "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
    ],
    userActions: {
      hasAcceptedUsageRights: false,
      hasPendingAccessRequest: false,
      canDownload: true,
      canEdit: true,
      canDeactivate: true,
      canExpire: true,
      canUpdateAssetAccessRequest: true,
      isAdmin: true,
    },
    expiresOn: formatDate(oneYearsTime),
  },
];

export const mockAllEmailTemplates = [
  {
    id: "1",
    name: "Template Option 1",
    description: "Description for template 1",
  },
  {
    id: "2",
    name: "Template Option 2",
    description: "Description for template 2",
  },
  {
    id: "3",
    name: "Template Option 3",
    description: "Description for template 3",
  },
];

export const mockSelectedEmailTemplate = {
  id: "1",
  name: "Template 1",
  description: "Description for template 1",
  subject: "Template 1 subject",
  body: "Template 1 body",
  parameters: [
    {
      id: "1",
      name: "Parameter 1",
      description: "Parameter 1 description",
    },
    {
      id: "2",
      name: "Parameter 2",
      description: "Parameter 2 description",
    },
    {
      id: "3",
      name: "Parameter 3",
      description: "Parameter 3 description",
    },
  ],
};

export const mockAccessRequestList = {
  results: [
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "assets",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
  ],
  query: "",
  page: 1,
  pageSize: 10,
  totalPages: 2,
  totalResults: 11,
};

export const mockAccessRequestList25 = {
  results: [
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "assets",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "templates",
      status: "Pending",
    },
  ],
  query: "",
  page: 1,
  pageSize: 10,
  totalPages: 2,
  totalResults: 11,
};

export const mockAccessRequestListPage2 = {
  results: [
    {
      approverEmail: "chris.bergenheim+smoke@epandcompany.com",
      approverFirstName: "Chris Smoketest",
      approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
      approverLastName: "Bergeheim",
      assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
      assetName: "Asset 38",
      createdOn: "2023-10-07T20:39:46.0610027Z",
      id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
      modifiedOn: "2023-10-07T20:39:46.0610026Z",
      requestorEmail: "christine.tebbitt+su@epandcompany.com",
      requestorFirstName: "Christine",
      requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
      requestorLastName: "SU Test",
      searchIndex: "assets",
      status: "Pending",
    },
  ],
  query: "",
  page: 2,
  pageSize: 10,
  totalPages: 2,
  totalResults: 11,
};

export const mockRequestDetailsPending = {
  approverEmail: "chris.bergenheim+smoke@epandcompany.com",
  approverFirstName: "Chris Smoketest",
  approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
  approverLastName: "Bergeheim",
  assetExpiration: "2424-12-31T00:00:00Z",
  assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
  assetName: "Asset 38",
  channel: "Digital",
  createdOn: "2023-10-07T20:39:46.061002Z",
  id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
  inMarketEndDate: null,
  inMarketStartDate: null,
  isPhotographyAsset: false,
  modifiedOn: "2023-10-07T20:39:46.061002Z",
  otherChannel: "",
  otherRequestedUse: "",
  projectName: "",
  reasonForDecline: null,
  requestedUse: ".com",
  requestorCompany: "EP",
  requestorEmail: "christine.tebbitt+su@epandcompany.com",
  requestorFirstName: "Christine",
  requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
  requestorLastName: "SU Test",
  searchIndex: "assets",
  status: "Pending",
  userActions: {
    canDeactivate: true,
    canDownload: true,
    canEdit: true,
    canExpire: true,
    canUpdateAssetAccessRequest: false,
    hasAcceptedUsageRights: true,
    hasPendingAccessRequest: false,
    isAdmin: false,
  },
};

export const mockRequestDetailsPendingConditionals = {
  approverEmail: "chris.bergenheim+smoke@epandcompany.com",
  approverFirstName: "Chris Smoketest",
  approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
  approverLastName: "Bergeheim",
  assetExpiration: "2424-12-31T00:00:00Z",
  assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
  assetName: "Asset 38",
  channel: "Digital",
  createdOn: "2023-10-07T20:39:46.061002Z",
  id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
  inMarketEndDate: "2023-12-31T20:39:46.061002Z",
  inMarketStartDate: "2023-10-07T20:39:46.061002Z",
  isPhotographyAsset: true,
  modifiedOn: "2023-10-07T20:39:46.061002Z",
  otherChannel: "other channel",
  otherRequestedUse: "other use",
  projectName: "project name",
  reasonForDecline: null,
  requestedUse: ".com",
  requestorCompany: "EP",
  requestorEmail: "christine.tebbitt+su@epandcompany.com",
  requestorFirstName: "Christine",
  requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
  requestorLastName: "SU Test",
  searchIndex: "assets",
  status: "Pending",
  userActions: {
    canDeactivate: true,
    canDownload: true,
    canEdit: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    hasAcceptedUsageRights: true,
    hasPendingAccessRequest: false,
    isAdmin: true,
  },
};

export const mockRequestDetailsApproved = {
  approverEmail: "chris.bergenheim+smoke@epandcompany.com",
  approverFirstName: "Chris Smoketest",
  approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
  approverLastName: "Bergeheim",
  assetExpiration: "2424-12-31T00:00:00Z",
  assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
  assetName: "Asset 38",
  channel: "Digital",
  createdOn: "2023-10-07T20:39:46.061002Z",
  id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
  inMarketEndDate: "2023-12-31T20:39:46.061002Z",
  inMarketStartDate: "2023-10-07T20:39:46.061002Z",
  isPhotographyAsset: true,
  modifiedOn: "2023-10-07T20:39:46.061002Z",
  otherChannel: "other channel",
  otherRequestedUse: "other use",
  projectName: "project name",
  reasonForDecline: null,
  requestedUse: ".com",
  requestorCompany: "EP",
  requestorEmail: "christine.tebbitt+su@epandcompany.com",
  requestorFirstName: "Christine",
  requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
  requestorLastName: "SU Test",
  searchIndex: "assets",
  status: "Approved",
  userActions: {
    canDeactivate: true,
    canDownload: true,
    canEdit: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    hasAcceptedUsageRights: true,
    hasPendingAccessRequest: false,
    isAdmin: true,
  },
};

export const mockRequestDetailsDeclined = {
  approverEmail: "chris.bergenheim+smoke@epandcompany.com",
  approverFirstName: "Chris Smoketest",
  approverId: "117383eb-8e32-4f34-a0b7-b5d085da9cee",
  approverLastName: "Bergeheim",
  assetExpiration: "2424-12-31T00:00:00Z",
  assetId: "e1be3260-3974-4275-b9a1-9638247ac84f",
  assetName: "Asset 38",
  channel: "Digital",
  createdOn: "2023-10-07T20:39:46.061002Z",
  id: "0dbbbd0e-6b63-4029-ac3c-a0676016a822",
  inMarketEndDate: "2023-12-31T20:39:46.061002Z",
  inMarketStartDate: "2023-10-07T20:39:46.061002Z",
  isPhotographyAsset: true,
  modifiedOn: "2023-10-07T20:39:46.061002Z",
  otherChannel: "other channel",
  otherRequestedUse: "other use",
  projectName: "project name",
  reasonForDecline: "reason for decline",
  requestedUse: ".com",
  requestorCompany: "EP",
  requestorEmail: "christine.tebbitt+su@epandcompany.com",
  requestorFirstName: "Christine",
  requestorId: "d8fd6823-2066-4893-8733-edc6c0d5673e",
  requestorLastName: "SU Test",
  searchIndex: "assets",
  status: "Declined",
  userActions: {
    canDeactivate: true,
    canDownload: true,
    canEdit: true,
    canExpire: true,
    canUpdateAssetAccessRequest: true,
    hasAcceptedUsageRights: true,
    hasPendingAccessRequest: false,
    isAdmin: true,
  },
};

export const mockMyDownloads15 = {
  totalResults: 15,
  totalPages: 2,
  pageSize: 10,
  pageNumber: 1,
  results: [
    {
      id: "test-id",
      searchId: "test-id",
      assetId: "test-id",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 36",
      description: "Asset 36",
      altText: "Asset 36",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: null,
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-2",
      searchId: "test-id-2",
      assetId: "test-id-2",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 2 name",
      description: "Asset 2 description",
      source: "Asset 2",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-3",
      searchId: "test-id-3",
      assetId: "test-id-3",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 3 name",
      description: "Asset 2 description",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id",
      searchId: "test-id",
      assetId: "test-id",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 36",
      description: "Asset 36",
      altText: "Asset 36",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: null,
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-2",
      searchId: "test-id-2",
      assetId: "test-id-2",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 2 name",
      description: "Asset 2 description",
      source: "Asset 2",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-3",
      searchId: "test-id-3",
      assetId: "test-id-3",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 3 name",
      description: "Asset 2 description",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id",
      searchId: "test-id",
      assetId: "test-id",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 36",
      description: "Asset 36",
      altText: "Asset 36",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: null,
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-2",
      searchId: "test-id-2",
      assetId: "test-id-2",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 2 name",
      description: "Asset 2 description",
      source: "Asset 2",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-3",
      searchId: "test-id-3",
      assetId: "test-id-3",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 3 name",
      description: "Asset 2 description",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id",
      searchId: "test-id",
      assetId: "test-id",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 36",
      description: "Asset 36",
      altText: "Asset 36",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: null,
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-2",
      searchId: "test-id-2",
      assetId: "test-id-2",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 2 name",
      description: "Asset 2 description",
      source: "Asset 2",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-3",
      searchId: "test-id-3",
      assetId: "test-id-3",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 3 name",
      description: "Asset 2 description",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id",
      searchId: "test-id",
      assetId: "test-id",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 36",
      description: "Asset 36",
      altText: "Asset 36",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: null,
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-2",
      searchId: "test-id-2",
      assetId: "test-id-2",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 2 name",
      description: "Asset 2 description",
      source: "Asset 2",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-3",
      searchId: "test-id-3",
      assetId: "test-id-3",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 3 name",
      description: "Asset 2 description",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
  ],
};

export const mockMyDownloads5 = {
  totalResults: 5,
  totalPages: 1,
  pageSize: 10,
  pageNumber: 1,
  results: [
    {
      id: "test-id",
      assetId: "test-id",
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      name: "Asset 36",
      description: "Asset 36",
      source: "Asset 36",
      altText: "Asset 36",
      keywords: "",
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "Usage rights copy test",
      downloadCount: 1,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      searchIndex: "assets",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: null,
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      createdOn: "2022-10-05T19:12:25.759419Z",
      modifiedOn: "2023-09-26T16:57:46.174246Z",
      lastModifiedDate: "2023-09-26T16:57:46.174246Z",
      expiresOn: formatDate(oneYearsTime),
      score: 0,
    },
    {
      id: "test-id-2",
      assetId: "test-id-2",
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      name: "Asset 2 name",
      description: "Asset 2 description",
      source: "Asset 2",
      altText: "Asset 2 alt text",
      keywords: "",
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      downloadCount: 1,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      searchIndex: "templates",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      createdOn: "2022-10-05T19:12:25.759419Z",
      modifiedOn: "2023-09-26T16:57:46.174246Z",
      lastModifiedDate: "2023-09-26T16:57:46.174246Z",
      expiresOn: formatDate(oneYearsTime),
      score: 0,
    },
    {
      id: "test-id-3",
      assetId: "test-id-3",
      isActive: true,
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      name: "Asset 3 name",
      description: "Asset 2 description",
      source: "Asset 2",
      altText: "Asset 2 alt text",
      keywords: "",
      jobNumber: "",
      guidelinesUrl: "",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      downloadCount: 1,
      thumbnailCountOverride: 0,
      approvalStatus: "Approved",
      searchIndex: "design-advisories",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      filterIds: [
        "76007f55-b998-4883-8e53-64c5ecec4a51",
        "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
        "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
      ],
      thumbnails: null,
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      createdOn: "2022-10-05T19:12:25.759419Z",
      modifiedOn: "2023-09-26T16:57:46.174246Z",
      lastModifiedDate: "2023-09-26T16:57:46.174246Z",
      expiresOn: formatDate(oneYearsTime),
      score: 0,
    },
    {
      id: "test-id",
      searchId: "test-id",
      assetId: "test-id",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 36",
      description: "Asset 36",
      altText: "Asset 36",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: null,
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-2",
      searchId: "test-id-2",
      assetId: "test-id-2",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 2 name",
      description: "Asset 2 description",
      source: "Asset 2",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
  ],
};

export const mockMyDownloadsNoResults = {
  totalResults: 0,
  totalPages: 1,
  pageSize: 10,
  pageNumber: 1,
  results: [],
};

export const mockMyDownloads5Page2 = {
  totalResults: 15,
  totalPages: 2,
  pageSize: 10,
  pageNumber: 2,
  results: [
    {
      id: "test-id",
      searchId: "test-id",
      assetId: "test-id",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 36",
      description: "Asset 36",
      altText: "Asset 36",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: null,
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-2",
      searchId: "test-id-2",
      assetId: "test-id-2",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 2 name",
      description: "Asset 2 description",
      source: "Asset 2",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-3",
      searchId: "test-id-3",
      assetId: "test-id-3",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 3 name",
      description: "Asset 2 description",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id",
      searchId: "test-id",
      assetId: "test-id",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 36",
      description: "Asset 36",
      altText: "Asset 36",
      copyright: "",
      subtitle: "Asset 36",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: null,
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
    {
      id: "test-id-2",
      searchId: "test-id-2",
      assetId: "test-id-2",
      createdDate: "2022-10-05T19:12:25.759419Z",
      modifiedDate: "2023-09-26T16:57:46.174246Z",
      url: "asset/details/827e6605-4342-411e-9de3-848d80e0ac19",
      searchType: "asset",
      isSearchable: true,
      isRestricted: true,
      canDownload: true,
      isFavorite: true,
      title: "Asset 2 name",
      description: "Asset 2 description",
      source: "Asset 2",
      altText: "Asset 2 alt text",
      copyright: "",
      subtitle: "Asset 2 Subtitle",
      usageRightsCopy: "Usage rights copy test",
      files: [
        {
          id: "4c78a466-3bad-4b17-a64e-3964ca67ae8d",
          groupName: "1",
          fileName: "Screenshot Test 2023-06-26 at 2.23.31 PM.png",
          approvalStatus: "Pending",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=vlA%2Br427uRCKbxmtTKU9CrhMCGM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=A7nfvCr%2BJJzdyscIL8xSIclnkIA%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=rGD2W8%2Fga4%2BEzivIi4fIXbkqvFQ%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLfffc8fdca65f42fb8b1a61796d161e25_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=kPysxKIFYp9hqsA1Udp3OoVzJV4%3D",
            isImage: true,
          },
        },
        {
          id: "dc8bea19-422d-4a75-a297-bdea822fc679",
          groupName: "2",
          fileName: "Screenshot 2023-09-26 at 11.41.09 AM.png",
          approvalStatus: "Approved",
          thumbnails: {
            small:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_110_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=N6OhHusneGcBfVphRkiCc15kxMM%3D",
            medium:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_400_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Cl1I35pEbJaHkeJuAeU5zZgN53o%3D",
            large:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_500_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=Wclp59ev18Pva%2F2lcew%2FyrzLhT4%3D",
            xLarge:
              "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL4cdc5ac6ee074811a44e51327b3f5839_700_w.png?AWSAccessKeyId=ASIAUHQDK3LLCNDGKSL4&Expires=1697610219&x-amz-security-token=IQoJb3JpZ2luX2VjEAwaCXVzLWVhc3QtMSJHMEUCIE6sq0%2FNek91C2WevoAgrR5REg9T77DaOrd%2BjhLlQlvIAiEAtAWMn%2FE9WTxc1m162gkAm%2FSaF%2FpKhhioZTGS1uYeSF4qgwQIJRADGgwyOTA5OTEwMzcxNDIiDGQ5sMKW502k%2B0y8wSrgA%2BhKxpS7zKbMqdPCEIFk8KBY7Vva7oSaDL3YCm4xlrj%2BqGxPgQCAaktrKhVmfV4g2Om9bR8KDrCJwMM0WNN4CePBS6fnBoIWKM6Bx%2FqpephFQJcQJSqi%2BW0b%2BpMLoPeOU7Ajm42awBrtjw2xn%2BtJpK7nXkGDBRN8S2gBZLf9Y0Jg7f%2FfqqRgmxNEAEv8zv1NRlR0ySU4gHRgjqmqdO6nnuacHqkpryDKsLIU7Qi7VftbPrZsBg8%2BzeI7NU0eWE2TxDLq2GS%2FeuX04VHYH%2BC55kp66kAn0DDK28bPKF%2FGmVYdwkc8X4FbCnNtgDdgKl2NQ6EppyESKWmENbsI3CmT1wONC%2FWVoVLPKtehlCkjlOMHwUspg7uzlo0vfbL7hWcI1tqesywAr4Oz%2BMxYHxVkbSYZ5WqWB%2FigphVGRAmiVqguQ0DB7K7kld0vmiaajzaTfHO0NH4WGv4ssKkMrbEGal3n9SK0NRx4SwDRK481RyxfaHmTZ8jdUROBgs2nljVXXlhDnVZqJHBUMQaDMhGetxA4zsZZPmrGdzDypJfKUrAvcVfWKvpeA4pKn5JfInjlsdxLFWC3HK4IHYcZxgETfr973%2F0Uia%2B8AbvaYPgdVQ5abDR9XKZz3ILhNxG8eZC%2F8zDRgKOpBjqlARXfhJav9VyyxRClmrQQWE5FZFJHnwQrdgILFItfLpQ6ngimOrjeqaD1bPZ7tIPhDahi8CIEfBSSEMZQ1j3am5WscAdNGbqCgbnHDbQsx3aF2Xf86%2Byef7W4VfQQeKqW2OUk9jn81H0RALkb8p542igR5CzDjh8rw%2F%2BvENKx2XPdJ7yVPmcOGGA9ciC4W1dT4vVqtpcdxbFZawltb80fQq6AaxyTmg%3D%3D&Signature=ctSCTc2PMkHCkG9yW9oX80W8XKQ%3D",
            isImage: true,
          },
        },
      ],
      userActions: {
        hasAcceptedUsageRights: false,
        hasPendingAccessRequest: false,
        canDownload: true,
        canEdit: true,
        canDeactivate: true,
        canExpire: true,
        canUpdateAssetAccessRequest: true,
        isAdmin: true,
      },
      expiresOn: formatDate(oneYearsTime),
    },
  ],
};

export const mockAssetEditData = (pending = false) => {
  return {
    id: "80964d55-a9c7-45f9-83d9-42f50d5e59ed",
    createdOn: "2023-11-14T19:45:34.186787Z",
    modifiedOn: "2023-11-15T15:19:15.790408Z",
    expiresOn: "2040-12-31T05:00:00Z",
    isActive: true,
    isSearchable: true,
    isRestricted: false,
    isFavorite: false,
    packagesReady: false,
    name: "Test Vanity Name Removal",
    description: "sassy dog with neck rolls",
    source: "my camera",
    altText: "sassy dog",
    keywords: "Animal,Canine,Dog,Mammal,Pet,Snout,Louise,Kat,Test",
    jobNumber: "",
    guidelinesUrl: "",
    copyright: "",
    subtitle: "",
    usageRightsCopy: "",
    thumbnailCountOverride: 0,
    approvalStatus: "Approved",
    defaultThumbnailFile: null,
    uploadedByUser: {
      id: "e59ec9a0-62d3-469c-9120-6d35a34203fa",
      firstName: "Kat",
      lastName: "Klauber",
      emailAddress: "kat.klauber+usertest21@epandcompany.com",
    },
    ownedByUser: {
      id: "e59ec9a0-62d3-469c-9120-6d35a34203fa",
      firstName: "Kat",
      lastName: "Klauber",
      emailAddress: "kat.klauber+usertest21@epandcompany.com",
    },
    restrictedApprover: null,
    contacts: [
      {
        id: "47f261f0-ac47-4c68-8acb-dc14beb48c6b",
        firstName: "Christine",
        lastName: "Tebbitt",
        emailAddress: "christine.tebbitt@epandcompany.com",
      },
    ],
    editors: [],
    relatedAssets: [],
    files: [
      {
        id: "5629aa37-2cc6-4d04-bd87-a8d944c4bd7b",
        sendNewVersionNotification: false,
        groupName: null,
        currentVersionId: "57457d71-dd24-4af7-9afe-35dc7bf6ed49",
        versions: [
          {
            id: "57457d71-dd24-4af7-9afe-35dc7bf6ed49",
            createdOn: "2023-11-14T20:38:21.333245Z",
            modifiedOn: "2023-11-15T14:39:41.380936Z",
            uploadedBy: {
              id: "e59ec9a0-62d3-469c-9120-6d35a34203fa",
              firstName: "Kat",
              lastName: "Klauber",
              emailAddress: "kat.klauber+usertest21@epandcompany.com",
            },
            approvalStatus: pending ? "Pending" : "Approved",
            extension: ".jpg",
            fileName: "sassy_pillow_smushy_cheeks3.jpg",
            vanityName: "sassy_pillow_smushy_cheeks1.jpg",
            mimeType: "image/jpeg",
            clipId: null,
            previewUrl: null,
            sizeInBytes: 277640,
            versionNumber: 3,
          },
          {
            id: "8b043884-054f-4d88-bc46-42ec34adb273",
            createdOn: "2023-11-14T20:36:47.894662Z",
            modifiedOn: "2023-11-14T20:37:45.993224Z",
            uploadedBy: {
              id: "e59ec9a0-62d3-469c-9120-6d35a34203fa",
              firstName: "Kat",
              lastName: "Klauber",
              emailAddress: "kat.klauber+usertest21@epandcompany.com",
            },
            approvalStatus: pending ? "Pending" : "Approved",
            extension: ".jpg",
            fileName: "side_view_pink_dinosaur_sad_stare2.jpg",
            vanityName: "side_view_pink_dinosaur_sad_stare2.jpg",
            mimeType: "image/jpeg",
            clipId: null,
            previewUrl: null,
            sizeInBytes: 269677,
            versionNumber: 2,
          },
          {
            id: "86ebed66-4db0-43a1-8ba4-54b53a4f5614",
            createdOn: "2023-11-14T20:34:37.527582Z",
            modifiedOn: "2023-11-14T20:48:18.942468Z",
            uploadedBy: {
              id: "e59ec9a0-62d3-469c-9120-6d35a34203fa",
              firstName: "Kat",
              lastName: "Klauber",
              emailAddress: "kat.klauber+usertest21@epandcompany.com",
            },
            approvalStatus: "Approved",
            extension: ".jpg",
            fileName: "sassy_pillow_smushy_cheeks1.jpg",
            vanityName: "sassy_pillow_smushy_cheeks1.jpg",
            mimeType: "image/jpeg",
            clipId: null,
            previewUrl: null,
            sizeInBytes: 277640,
            versionNumber: 1,
          },
        ],
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL57457d71dd244af79afe35dc7bf6ed49_110.png?AWSAccessKeyId=ASIAUHQDK3LLL4OWJUEA&Expires=1700997365&x-amz-security-token=IQoJb3JpZ2luX2VjELv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAsOiAkRpFgshD%2FVWrwr1qS6jUu9%2BrXO9PXm%2FAm6iN8iAiEAsAsMXBEZ0vug2jA%2FfIyMB7AV7qMfjLzstwIXxPoFNPgqgwQIFBADGgwyOTA5OTEwMzcxNDIiDLKVyrL1WyglHc%2BckirgAy3sD7sXoqoPxrOhbISm6Id8u31bhyPbXJHCLFn67ENcFCA0w3b6BEUCmz6aJ7VNECZoTKLRApD03OpY3BjR3Ke4UeX5IpyGF%2F1jjlgcsDaHtRgRIhdONCyPzRDsr5N3%2F8wj39eVaqy8EL4MDo3G5T0ESqB64o9tYQKaIbuZZuHB5yIGC%2Fs7lsLWFio0D%2By6lpH78ZNzlBwVl53i1DbElThVVCRcqWQQobzZXcZypsqHcZz1otpfXWBDdAfhzMZA3Q30Z%2FjkHzLIxt9Q4RqmpcTkvioylLOzWdcBGvF49eKIYfwNNBhnxgJvfUzcZTodBujheZB6NkIfOpQS64%2B3QWLGw%2BK1rxrWv2JNosbNksSvx7bP48QQ81A4Dhho8lvjUuYBmVrWcDl4roQg1CRLKQi%2FFzMzShGYwpwWVi4RjSsOa1P%2Fc6L6dkz0D2MOVs496hLnz292BVu06b72Ox22lJy7dGCBWAd4vPl6NKnz%2F5m6ENMc1S65v%2FDh0zm9yw9yqJdfz%2FnOp33IgCKYGxqAzN205mz1PIyO3m5omQBJ10v27XsKrhxmXUMq0CbTnnsQX7%2BsUevGt8fNXDM8gmHFxT1Vrq43IsgzeY9uKBr%2Frwbougfc8Pp5iCku3IDevAR1oTDTmPKqBjqlAeX6K8QuSsXZ7cokpLukQ510flelL8ZT4x5UGrbiaD3CsLQyiU2VwlVz91Hz%2FNsqPX8I3PQh9DbNCV8JLwqC9VCwYNxAPCkEpmdvbvZCrtbn235xmnA7XbhYHQiesuHIxGaHBiCqBwsIx3GGr1%2F%2BA421kVA9PY6TvrarvF9jX54H4hQaD7Ng%2F%2F40AMBvKvZZAh6dRIJ3pAaxz7EHGP6JoapaMtYVDw%3D%3D&Signature=y8IgYe0Agv4ExPJSwxJG76vHcYw%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL57457d71dd244af79afe35dc7bf6ed49_400.png?AWSAccessKeyId=ASIAUHQDK3LLL4OWJUEA&Expires=1700997365&x-amz-security-token=IQoJb3JpZ2luX2VjELv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAsOiAkRpFgshD%2FVWrwr1qS6jUu9%2BrXO9PXm%2FAm6iN8iAiEAsAsMXBEZ0vug2jA%2FfIyMB7AV7qMfjLzstwIXxPoFNPgqgwQIFBADGgwyOTA5OTEwMzcxNDIiDLKVyrL1WyglHc%2BckirgAy3sD7sXoqoPxrOhbISm6Id8u31bhyPbXJHCLFn67ENcFCA0w3b6BEUCmz6aJ7VNECZoTKLRApD03OpY3BjR3Ke4UeX5IpyGF%2F1jjlgcsDaHtRgRIhdONCyPzRDsr5N3%2F8wj39eVaqy8EL4MDo3G5T0ESqB64o9tYQKaIbuZZuHB5yIGC%2Fs7lsLWFio0D%2By6lpH78ZNzlBwVl53i1DbElThVVCRcqWQQobzZXcZypsqHcZz1otpfXWBDdAfhzMZA3Q30Z%2FjkHzLIxt9Q4RqmpcTkvioylLOzWdcBGvF49eKIYfwNNBhnxgJvfUzcZTodBujheZB6NkIfOpQS64%2B3QWLGw%2BK1rxrWv2JNosbNksSvx7bP48QQ81A4Dhho8lvjUuYBmVrWcDl4roQg1CRLKQi%2FFzMzShGYwpwWVi4RjSsOa1P%2Fc6L6dkz0D2MOVs496hLnz292BVu06b72Ox22lJy7dGCBWAd4vPl6NKnz%2F5m6ENMc1S65v%2FDh0zm9yw9yqJdfz%2FnOp33IgCKYGxqAzN205mz1PIyO3m5omQBJ10v27XsKrhxmXUMq0CbTnnsQX7%2BsUevGt8fNXDM8gmHFxT1Vrq43IsgzeY9uKBr%2Frwbougfc8Pp5iCku3IDevAR1oTDTmPKqBjqlAeX6K8QuSsXZ7cokpLukQ510flelL8ZT4x5UGrbiaD3CsLQyiU2VwlVz91Hz%2FNsqPX8I3PQh9DbNCV8JLwqC9VCwYNxAPCkEpmdvbvZCrtbn235xmnA7XbhYHQiesuHIxGaHBiCqBwsIx3GGr1%2F%2BA421kVA9PY6TvrarvF9jX54H4hQaD7Ng%2F%2F40AMBvKvZZAh6dRIJ3pAaxz7EHGP6JoapaMtYVDw%3D%3D&Signature=NES14kC9r0uw0UklcjgTD7NPOU0%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL57457d71dd244af79afe35dc7bf6ed49_500.png?AWSAccessKeyId=ASIAUHQDK3LLL4OWJUEA&Expires=1700997365&x-amz-security-token=IQoJb3JpZ2luX2VjELv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAsOiAkRpFgshD%2FVWrwr1qS6jUu9%2BrXO9PXm%2FAm6iN8iAiEAsAsMXBEZ0vug2jA%2FfIyMB7AV7qMfjLzstwIXxPoFNPgqgwQIFBADGgwyOTA5OTEwMzcxNDIiDLKVyrL1WyglHc%2BckirgAy3sD7sXoqoPxrOhbISm6Id8u31bhyPbXJHCLFn67ENcFCA0w3b6BEUCmz6aJ7VNECZoTKLRApD03OpY3BjR3Ke4UeX5IpyGF%2F1jjlgcsDaHtRgRIhdONCyPzRDsr5N3%2F8wj39eVaqy8EL4MDo3G5T0ESqB64o9tYQKaIbuZZuHB5yIGC%2Fs7lsLWFio0D%2By6lpH78ZNzlBwVl53i1DbElThVVCRcqWQQobzZXcZypsqHcZz1otpfXWBDdAfhzMZA3Q30Z%2FjkHzLIxt9Q4RqmpcTkvioylLOzWdcBGvF49eKIYfwNNBhnxgJvfUzcZTodBujheZB6NkIfOpQS64%2B3QWLGw%2BK1rxrWv2JNosbNksSvx7bP48QQ81A4Dhho8lvjUuYBmVrWcDl4roQg1CRLKQi%2FFzMzShGYwpwWVi4RjSsOa1P%2Fc6L6dkz0D2MOVs496hLnz292BVu06b72Ox22lJy7dGCBWAd4vPl6NKnz%2F5m6ENMc1S65v%2FDh0zm9yw9yqJdfz%2FnOp33IgCKYGxqAzN205mz1PIyO3m5omQBJ10v27XsKrhxmXUMq0CbTnnsQX7%2BsUevGt8fNXDM8gmHFxT1Vrq43IsgzeY9uKBr%2Frwbougfc8Pp5iCku3IDevAR1oTDTmPKqBjqlAeX6K8QuSsXZ7cokpLukQ510flelL8ZT4x5UGrbiaD3CsLQyiU2VwlVz91Hz%2FNsqPX8I3PQh9DbNCV8JLwqC9VCwYNxAPCkEpmdvbvZCrtbn235xmnA7XbhYHQiesuHIxGaHBiCqBwsIx3GGr1%2F%2BA421kVA9PY6TvrarvF9jX54H4hQaD7Ng%2F%2F40AMBvKvZZAh6dRIJ3pAaxz7EHGP6JoapaMtYVDw%3D%3D&Signature=VJ%2FLYpNpvf2lJRQnwLGj7cDC0yM%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RL57457d71dd244af79afe35dc7bf6ed49_700.png?AWSAccessKeyId=ASIAUHQDK3LLL4OWJUEA&Expires=1700997365&x-amz-security-token=IQoJb3JpZ2luX2VjELv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAsOiAkRpFgshD%2FVWrwr1qS6jUu9%2BrXO9PXm%2FAm6iN8iAiEAsAsMXBEZ0vug2jA%2FfIyMB7AV7qMfjLzstwIXxPoFNPgqgwQIFBADGgwyOTA5OTEwMzcxNDIiDLKVyrL1WyglHc%2BckirgAy3sD7sXoqoPxrOhbISm6Id8u31bhyPbXJHCLFn67ENcFCA0w3b6BEUCmz6aJ7VNECZoTKLRApD03OpY3BjR3Ke4UeX5IpyGF%2F1jjlgcsDaHtRgRIhdONCyPzRDsr5N3%2F8wj39eVaqy8EL4MDo3G5T0ESqB64o9tYQKaIbuZZuHB5yIGC%2Fs7lsLWFio0D%2By6lpH78ZNzlBwVl53i1DbElThVVCRcqWQQobzZXcZypsqHcZz1otpfXWBDdAfhzMZA3Q30Z%2FjkHzLIxt9Q4RqmpcTkvioylLOzWdcBGvF49eKIYfwNNBhnxgJvfUzcZTodBujheZB6NkIfOpQS64%2B3QWLGw%2BK1rxrWv2JNosbNksSvx7bP48QQ81A4Dhho8lvjUuYBmVrWcDl4roQg1CRLKQi%2FFzMzShGYwpwWVi4RjSsOa1P%2Fc6L6dkz0D2MOVs496hLnz292BVu06b72Ox22lJy7dGCBWAd4vPl6NKnz%2F5m6ENMc1S65v%2FDh0zm9yw9yqJdfz%2FnOp33IgCKYGxqAzN205mz1PIyO3m5omQBJ10v27XsKrhxmXUMq0CbTnnsQX7%2BsUevGt8fNXDM8gmHFxT1Vrq43IsgzeY9uKBr%2Frwbougfc8Pp5iCku3IDevAR1oTDTmPKqBjqlAeX6K8QuSsXZ7cokpLukQ510flelL8ZT4x5UGrbiaD3CsLQyiU2VwlVz91Hz%2FNsqPX8I3PQh9DbNCV8JLwqC9VCwYNxAPCkEpmdvbvZCrtbn235xmnA7XbhYHQiesuHIxGaHBiCqBwsIx3GGr1%2F%2BA421kVA9PY6TvrarvF9jX54H4hQaD7Ng%2F%2F40AMBvKvZZAh6dRIJ3pAaxz7EHGP6JoapaMtYVDw%3D%3D&Signature=j0yofwK7DkPLGKO7yQ%2BztY1F0JU%3D",
          isImage: true,
        },
      },
      {
        id: "d5c39c59-0206-4a79-95ce-c4c212b53fbe",
        sendNewVersionNotification: false,
        groupName: null,
        currentVersionId: "e35f39bd-b327-4c66-906e-254a11d35310",
        versions: [
          {
            id: "e35f39bd-b327-4c66-906e-254a11d35310",
            createdOn: "2023-11-14T20:05:09.842544Z",
            modifiedOn: "2023-11-14T20:33:35.17108Z",
            uploadedBy: {
              id: "e59ec9a0-62d3-469c-9120-6d35a34203fa",
              firstName: "Kat",
              lastName: "Klauber",
              emailAddress: "kat.klauber+usertest21@epandcompany.com",
            },
            approvalStatus: "Approved",
            extension: ".jpg",
            fileName: "couch_chillin_chin_armrest2.jpg",
            vanityName: "couch_chillin_chin_armrest2.jpg",
            mimeType: "image/jpeg",
            clipId: null,
            previewUrl: null,
            sizeInBytes: 166046,
            versionNumber: 2,
          },
          {
            id: "1aa07740-1bc5-4ff1-aed6-6b9801e693a1",
            createdOn: "2023-11-14T19:45:34.186788Z",
            modifiedOn: "2023-11-14T20:25:36.222378Z",
            uploadedBy: {
              id: "e59ec9a0-62d3-469c-9120-6d35a34203fa",
              firstName: "Kat",
              lastName: "Klauber",
              emailAddress: "kat.klauber+usertest21@epandcompany.com",
            },
            approvalStatus: "Approved",
            extension: ".jpg",
            fileName: "sassy_pillow_smushy_cheeks.jpg",
            vanityName: "sassy_pillow_smushy_cheeks.jpg",
            mimeType: "image/jpeg",
            clipId: null,
            previewUrl: null,
            sizeInBytes: 277640,
            versionNumber: 1,
          },
        ],
        thumbnails: {
          small:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe35f39bdb3274c66906e254a11d35310_110.png?AWSAccessKeyId=ASIAUHQDK3LLL4OWJUEA&Expires=1700997365&x-amz-security-token=IQoJb3JpZ2luX2VjELv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAsOiAkRpFgshD%2FVWrwr1qS6jUu9%2BrXO9PXm%2FAm6iN8iAiEAsAsMXBEZ0vug2jA%2FfIyMB7AV7qMfjLzstwIXxPoFNPgqgwQIFBADGgwyOTA5OTEwMzcxNDIiDLKVyrL1WyglHc%2BckirgAy3sD7sXoqoPxrOhbISm6Id8u31bhyPbXJHCLFn67ENcFCA0w3b6BEUCmz6aJ7VNECZoTKLRApD03OpY3BjR3Ke4UeX5IpyGF%2F1jjlgcsDaHtRgRIhdONCyPzRDsr5N3%2F8wj39eVaqy8EL4MDo3G5T0ESqB64o9tYQKaIbuZZuHB5yIGC%2Fs7lsLWFio0D%2By6lpH78ZNzlBwVl53i1DbElThVVCRcqWQQobzZXcZypsqHcZz1otpfXWBDdAfhzMZA3Q30Z%2FjkHzLIxt9Q4RqmpcTkvioylLOzWdcBGvF49eKIYfwNNBhnxgJvfUzcZTodBujheZB6NkIfOpQS64%2B3QWLGw%2BK1rxrWv2JNosbNksSvx7bP48QQ81A4Dhho8lvjUuYBmVrWcDl4roQg1CRLKQi%2FFzMzShGYwpwWVi4RjSsOa1P%2Fc6L6dkz0D2MOVs496hLnz292BVu06b72Ox22lJy7dGCBWAd4vPl6NKnz%2F5m6ENMc1S65v%2FDh0zm9yw9yqJdfz%2FnOp33IgCKYGxqAzN205mz1PIyO3m5omQBJ10v27XsKrhxmXUMq0CbTnnsQX7%2BsUevGt8fNXDM8gmHFxT1Vrq43IsgzeY9uKBr%2Frwbougfc8Pp5iCku3IDevAR1oTDTmPKqBjqlAeX6K8QuSsXZ7cokpLukQ510flelL8ZT4x5UGrbiaD3CsLQyiU2VwlVz91Hz%2FNsqPX8I3PQh9DbNCV8JLwqC9VCwYNxAPCkEpmdvbvZCrtbn235xmnA7XbhYHQiesuHIxGaHBiCqBwsIx3GGr1%2F%2BA421kVA9PY6TvrarvF9jX54H4hQaD7Ng%2F%2F40AMBvKvZZAh6dRIJ3pAaxz7EHGP6JoapaMtYVDw%3D%3D&Signature=0OOF9B5f%2BSVBKXVzrC%2FbliJ%2BxzI%3D",
          medium:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe35f39bdb3274c66906e254a11d35310_400.png?AWSAccessKeyId=ASIAUHQDK3LLL4OWJUEA&Expires=1700997365&x-amz-security-token=IQoJb3JpZ2luX2VjELv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAsOiAkRpFgshD%2FVWrwr1qS6jUu9%2BrXO9PXm%2FAm6iN8iAiEAsAsMXBEZ0vug2jA%2FfIyMB7AV7qMfjLzstwIXxPoFNPgqgwQIFBADGgwyOTA5OTEwMzcxNDIiDLKVyrL1WyglHc%2BckirgAy3sD7sXoqoPxrOhbISm6Id8u31bhyPbXJHCLFn67ENcFCA0w3b6BEUCmz6aJ7VNECZoTKLRApD03OpY3BjR3Ke4UeX5IpyGF%2F1jjlgcsDaHtRgRIhdONCyPzRDsr5N3%2F8wj39eVaqy8EL4MDo3G5T0ESqB64o9tYQKaIbuZZuHB5yIGC%2Fs7lsLWFio0D%2By6lpH78ZNzlBwVl53i1DbElThVVCRcqWQQobzZXcZypsqHcZz1otpfXWBDdAfhzMZA3Q30Z%2FjkHzLIxt9Q4RqmpcTkvioylLOzWdcBGvF49eKIYfwNNBhnxgJvfUzcZTodBujheZB6NkIfOpQS64%2B3QWLGw%2BK1rxrWv2JNosbNksSvx7bP48QQ81A4Dhho8lvjUuYBmVrWcDl4roQg1CRLKQi%2FFzMzShGYwpwWVi4RjSsOa1P%2Fc6L6dkz0D2MOVs496hLnz292BVu06b72Ox22lJy7dGCBWAd4vPl6NKnz%2F5m6ENMc1S65v%2FDh0zm9yw9yqJdfz%2FnOp33IgCKYGxqAzN205mz1PIyO3m5omQBJ10v27XsKrhxmXUMq0CbTnnsQX7%2BsUevGt8fNXDM8gmHFxT1Vrq43IsgzeY9uKBr%2Frwbougfc8Pp5iCku3IDevAR1oTDTmPKqBjqlAeX6K8QuSsXZ7cokpLukQ510flelL8ZT4x5UGrbiaD3CsLQyiU2VwlVz91Hz%2FNsqPX8I3PQh9DbNCV8JLwqC9VCwYNxAPCkEpmdvbvZCrtbn235xmnA7XbhYHQiesuHIxGaHBiCqBwsIx3GGr1%2F%2BA421kVA9PY6TvrarvF9jX54H4hQaD7Ng%2F%2F40AMBvKvZZAh6dRIJ3pAaxz7EHGP6JoapaMtYVDw%3D%3D&Signature=lfp9M%2FybnEe%2FZ5U48rOWj4QPN%2Bc%3D",
          large:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe35f39bdb3274c66906e254a11d35310_500.png?AWSAccessKeyId=ASIAUHQDK3LLL4OWJUEA&Expires=1700997365&x-amz-security-token=IQoJb3JpZ2luX2VjELv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAsOiAkRpFgshD%2FVWrwr1qS6jUu9%2BrXO9PXm%2FAm6iN8iAiEAsAsMXBEZ0vug2jA%2FfIyMB7AV7qMfjLzstwIXxPoFNPgqgwQIFBADGgwyOTA5OTEwMzcxNDIiDLKVyrL1WyglHc%2BckirgAy3sD7sXoqoPxrOhbISm6Id8u31bhyPbXJHCLFn67ENcFCA0w3b6BEUCmz6aJ7VNECZoTKLRApD03OpY3BjR3Ke4UeX5IpyGF%2F1jjlgcsDaHtRgRIhdONCyPzRDsr5N3%2F8wj39eVaqy8EL4MDo3G5T0ESqB64o9tYQKaIbuZZuHB5yIGC%2Fs7lsLWFio0D%2By6lpH78ZNzlBwVl53i1DbElThVVCRcqWQQobzZXcZypsqHcZz1otpfXWBDdAfhzMZA3Q30Z%2FjkHzLIxt9Q4RqmpcTkvioylLOzWdcBGvF49eKIYfwNNBhnxgJvfUzcZTodBujheZB6NkIfOpQS64%2B3QWLGw%2BK1rxrWv2JNosbNksSvx7bP48QQ81A4Dhho8lvjUuYBmVrWcDl4roQg1CRLKQi%2FFzMzShGYwpwWVi4RjSsOa1P%2Fc6L6dkz0D2MOVs496hLnz292BVu06b72Ox22lJy7dGCBWAd4vPl6NKnz%2F5m6ENMc1S65v%2FDh0zm9yw9yqJdfz%2FnOp33IgCKYGxqAzN205mz1PIyO3m5omQBJ10v27XsKrhxmXUMq0CbTnnsQX7%2BsUevGt8fNXDM8gmHFxT1Vrq43IsgzeY9uKBr%2Frwbougfc8Pp5iCku3IDevAR1oTDTmPKqBjqlAeX6K8QuSsXZ7cokpLukQ510flelL8ZT4x5UGrbiaD3CsLQyiU2VwlVz91Hz%2FNsqPX8I3PQh9DbNCV8JLwqC9VCwYNxAPCkEpmdvbvZCrtbn235xmnA7XbhYHQiesuHIxGaHBiCqBwsIx3GGr1%2F%2BA421kVA9PY6TvrarvF9jX54H4hQaD7Ng%2F%2F40AMBvKvZZAh6dRIJ3pAaxz7EHGP6JoapaMtYVDw%3D%3D&Signature=FktrTXEPCWB%2B%2FMySWLXUKZ8M4pU%3D",
          xLarge:
            "https://brandcentral-rl-qa.s3.amazonaws.com/assets/Thumb_png/RLe35f39bdb3274c66906e254a11d35310_700.png?AWSAccessKeyId=ASIAUHQDK3LLL4OWJUEA&Expires=1700997365&x-amz-security-token=IQoJb3JpZ2luX2VjELv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIAsOiAkRpFgshD%2FVWrwr1qS6jUu9%2BrXO9PXm%2FAm6iN8iAiEAsAsMXBEZ0vug2jA%2FfIyMB7AV7qMfjLzstwIXxPoFNPgqgwQIFBADGgwyOTA5OTEwMzcxNDIiDLKVyrL1WyglHc%2BckirgAy3sD7sXoqoPxrOhbISm6Id8u31bhyPbXJHCLFn67ENcFCA0w3b6BEUCmz6aJ7VNECZoTKLRApD03OpY3BjR3Ke4UeX5IpyGF%2F1jjlgcsDaHtRgRIhdONCyPzRDsr5N3%2F8wj39eVaqy8EL4MDo3G5T0ESqB64o9tYQKaIbuZZuHB5yIGC%2Fs7lsLWFio0D%2By6lpH78ZNzlBwVl53i1DbElThVVCRcqWQQobzZXcZypsqHcZz1otpfXWBDdAfhzMZA3Q30Z%2FjkHzLIxt9Q4RqmpcTkvioylLOzWdcBGvF49eKIYfwNNBhnxgJvfUzcZTodBujheZB6NkIfOpQS64%2B3QWLGw%2BK1rxrWv2JNosbNksSvx7bP48QQ81A4Dhho8lvjUuYBmVrWcDl4roQg1CRLKQi%2FFzMzShGYwpwWVi4RjSsOa1P%2Fc6L6dkz0D2MOVs496hLnz292BVu06b72Ox22lJy7dGCBWAd4vPl6NKnz%2F5m6ENMc1S65v%2FDh0zm9yw9yqJdfz%2FnOp33IgCKYGxqAzN205mz1PIyO3m5omQBJ10v27XsKrhxmXUMq0CbTnnsQX7%2BsUevGt8fNXDM8gmHFxT1Vrq43IsgzeY9uKBr%2Frwbougfc8Pp5iCku3IDevAR1oTDTmPKqBjqlAeX6K8QuSsXZ7cokpLukQ510flelL8ZT4x5UGrbiaD3CsLQyiU2VwlVz91Hz%2FNsqPX8I3PQh9DbNCV8JLwqC9VCwYNxAPCkEpmdvbvZCrtbn235xmnA7XbhYHQiesuHIxGaHBiCqBwsIx3GGr1%2F%2BA421kVA9PY6TvrarvF9jX54H4hQaD7Ng%2F%2F40AMBvKvZZAh6dRIJ3pAaxz7EHGP6JoapaMtYVDw%3D%3D&Signature=SG1V5lF7qiuGkcJdtGStpP9L7C4%3D",
          isImage: true,
        },
      },
    ],
    tags: [
      {
        tagId: "48018f84-1fd8-4165-8078-3d83908f22d4",
        category: "Devices & Accessories",
        subcategory: "Brand",
        tag: "Amazon",
      },
    ],
    assignedGroups: [],
    assignedUsers: [],
    requestFeedback: true,
    lastModifiedDate: "2023-11-15T14:39:41.331016Z",
    samePhotoShoot: false,
    userActions: {
      hasAcceptedUsageRights: true,
      hasPendingAccessRequest: false,
      canDownload: true,
      canEdit: true,
      canDeactivate: true,
      canExpire: true,
      canUpdateAssetAccessRequest: true,
      isAdmin: true,
    },
  };
};

export const mockCurrentUser = {
  FirstName: "John",
  LastName: "Doe",
  Email: "john.doe@epandcompany.com",
  PhoneNumber: "0123456789",
  Company: "EP+Co",
  EmploymentTitle: "Agency CX/tech",
  CustomEmploymentTitle: "",
  VerizonContact: "",
  Country: "USA",
  CreatedOn: "0001-01-01T00:00:00",
  ChannelIds: ["cea7b8cb-6bef-4840-b4e8-7928706f1855"],
  FunctionalAreaIds: ["2e7e5697-9b0a-4e2f-990c-007e8a31da7c"],
  Status: 0,
};

export const mockAssetCategories = [
  {
    description: "category 1",
    id: "ccafc402-96da-4547-a02a-0e1551004a55",
    subCategories: [
      {
        categoryId: "ccafc402-96da-4547-a02a-0e1551004a55",
        description: "subcategory 1",
        id: "974d55ee-0566-4c21-8e91-8cdfae764bc2",
        tags: [
          {
            description: "974d55ee-0566-4c21-8e91-8cdfae764bc2",
            id: "8a14cfe4-4171-436e-8724-ae35afdd422a",
            subCategoryId: "subcategory 1",
            value: "tag 1",
          }
        ],
        value: "subcategory 1",
      }
    ],
    value: "category 1",
  },
  {
    description: "category 2",
    id: "category 2",
    subCategories: [
      {
        categoryId: "category 2",
        description: "subcategory 1",
        id: "subcategory 1",
        tags: [
          {
            description: "tag 1",
            id: "tag 1",
            subCategoryId: "subcategory 1",
            value: "tag 1",
          },
          {
            description: "tag 2",
            id: "tag 2",
            subCategoryId: "subcategory 1",
            value: "tag 2",
          }
        ],
        value: "subcategory 1",
      },
      {
        categoryId: "category 2",
        description: "subcategory 2",
        id: "subcategory 2",
        tags: [
          {
            description: "tag 1",
            id: "tag 1",
            subCategoryId: "subcategory 2",
            value: "tag 1",
          },
          {
            description: "tag 2",
            id: "tag 2",
            subCategoryId: "subcategory 2",
            value: "tag 2",
          }
        ],
        value: "subcategory 2",
      }
    ],
    value: "category 2",
  }
];

export const shareMultipleItems = [
  {
    "assetId": "e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
    "description": "CLICK HERE TO WATCH THIS TRAINING VIDEO:\n[Brand Compliance training video](https://brandcentral.verizonwireless.com/training/videos/brand-identity-training-video-series/brand-compliance#playlist)\n\nTraining videos are not available for download. Please use the link above to view this training video.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2025-12-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Brand training video: Brand compliance",
    "files": [
      {
        "id": "f2c8ad3c-859b-4d2b-b3c5-87696d56961e",
        "groupName": "1",
        "fileName": "VZ_DBI_COMPLIANCE_MASTER ALT_20200720.mp4",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_110.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=uqeED8qdjOfShSQxSFI8acc%2B6gg%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_400.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=ZuaMvUULYq6GQ0Bq2HCsPGLueQk%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_500.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=QNPN5n7A%2FdI831%2Bhko23Nkqak7c%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_700.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=O1CMp9z1qWfjXzsYzT9J7DBNljw%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "54dd50ac-f5c3-47ea-be63-92cad84a3014",
      "d08fc3db-419c-42a0-a964-df202e6be8b6",
      "1f125d15-63b0-4edf-9813-5dbc9a6544fb"
    ],
    "category": "Employee Communications",
    "downloadCount": 13,
    "id": "e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
    "url": "assets/details/e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Brand training video: Brand compliance",
    "score": 0.9678475,
    "createdOn": "2020-06-15T17:43:47Z",
    "modifiedOn": "2022-12-13T00:00:00Z"
  },
  {
    "entityId": "DkTj4fhSjETGPLL7SZMLp",
    "id": "b34df7c6-3089-48c0-bbd4-e0d32fba5d87",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/alliance-branding/alliance-branding-assistant/overview",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Alliance branding: Alliance branding assistant",
    "score": 0.96216387,
    "createdOn": "2021-08-13T18:07:33.824Z",
    "modifiedOn": "2023-01-31T20:36:08.428Z"
  },
  {
    "entityId": "7xQaWpqDKppdT5SXZk3nuq",
    "complianceType": "complianceScoringElement",
    "id": "59629541-aaaa-4f63-945c-14e3048b7aab",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Brand Voice",
    "score": 0.95729584,
    "createdOn": "2021-01-15T16:56:57.176Z",
    "modifiedOn": "2023-10-07T17:02:48.964Z"
  },
  {
    "entityId": "4cGPwPwK8L9grGZzk6nGzW",
    "thumbnailAssetUrl": "https://images.ctfassets.net/ve6konwi38y5/3PVEK9qKGPtvitbENZArcm/8c538ff8979db0f8eb19edec6f6afdbc/verizon-brand-compliance.png",
    "playlistSlug": "brand-identity-training-video-series",
    "videoSlug": "brand-compliance",
    "id": "b0c094a7-dba3-4cd4-8f6c-87842d32d13a",
    "url": "/brand-identity-training-video-series/brand-compliance",
    "searchType": "training",
    "isFavorite": false,
    "title": "Brand compliance",
    "score": 0.93007326,
    "createdOn": "2021-01-06T18:25:33.962Z",
    "modifiedOn": "2023-10-07T17:05:46.599Z"
  },
  {
    "assetId": "a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "description": "This is the primary all-purpose Verizon PowerPoint template with over 50 ready-to-use slides. The template is created in widescreen format (16:9 aspect ratio). \n\nJan. 12, 2024 update: color theme.\nJan. 24, 2024 update:  Safe Harbor statement.\n\nGoogle Slides version is found in the Verizon Template Gallery here: https://docs.google.com/presentation/u/0/?ftv=1&folder=0AAsmHEmojaRCUk9PVA&tgif=d",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "PowerPoint template for all purpose use",
    "files": [
      {
        "id": "7fc28927-336d-4e34-ab27-a89c5f57f856",
        "groupName": "1",
        "fileName": "Verizon__PPT_Template_4.3.potx",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_110.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471368&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=PnkdMWhmXiB7RLewX1%2FmXoYrYts%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_400.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471368&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=C%2FLcgGW7nCq7KlvPVHoOTCXeAac%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_500.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471368&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=bq7Uqy0AqJ7TewsJ5zX3rp7VvXE%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_700.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471368&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=J9TLS%2BscELAjtHhZI5kH7eGUSgQ%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "6a23903a-7522-4e2b-8390-ea53fe9ea5fe",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "fe39245d-d069-41df-a8b0-c5f56cb0fa4e"
    ],
    "category": null,
    "downloadCount": 4136,
    "id": "a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "url": "templates/details/a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "searchType": "template",
    "isFavorite": false,
    "title": "PowerPoint template for all purpose use",
    "score": 0.9,
    "createdOn": "2015-10-06T19:58:51Z",
    "modifiedOn": "2024-01-25T19:16:41.688301Z"
  },
  {
    "assetId": "fd61185c-eec8-457b-9510-0df3fbe7671c",
    "description": "2019 DAS Updated Exterior Brand Standards",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2027-03-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2019DAS",
    "files": [
      {
        "id": "d93c0ec5-8713-41a7-a0d7-6f6c8d812526",
        "groupName": null,
        "fileName": "Verizon DAS-19-001-Updated Exterior Brand Standards.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_110.png?AWSAccessKeyId=ASIAUHQDK3LLF2L6WQ2F&Expires=1718471417&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQChs72GBid3qLkUJjEwIn2bBrn08ENh9oJYYF8T%2BSbdHwIgHbFNOcM6FJD2fUmIlSkZv9E%2Bz45xISYrAqAjSwIPy%2B0qgwQIRRAEGgwyOTA5OTEwMzcxNDIiDBBXGGzx2rA0ZzX0IirgA4kAal9zMEJrypoORXweWERLxdMFpQ%2Bm5IJA6qsNZClHx9RKoJaOHTRYxS%2Fq%2Fx91bbDiY1YoH1o0LUd3vbl3Nm%2Fd2oZW0FB%2BSlQ4S331zHwov3Oj06dsY1llnOB4yOE%2Bp4%2F%2FoVLuZ5hMn0dDYdGIJ%2Fs%2BegjeqD3K%2BMjNS4rksJ0RbuppQEtH3cgOWiJ6zgLMyTZCt0aVigXgXWsjeqtzPfbKxRUoB35pUnbcq72Nt%2BFKixGSKGIJgPeodbgx7ZlEygOWFgFFvsHHz37dACgUbaa8Qzq8%2BCcYtguGbVF0xlKpksENHglm%2BZqXTrrKG0rVdJHIQ9qr7HrgeXp6uIH9tlupGiRmZn4VcxGrTOFzlCwJx8bYaxwLx5jBoU3inAfzaJ%2BmcGdAU1Sdl523tl9Z4okP1or1NScXnNS5AAfgsgcvwmnfql3KllA8MxTB%2FDbzix45vuplSAJ7kMx6R86TOZTuU2S0DIBz5R08G4%2FIxleAUlUQcwu11tmjNInaSCyQ2379KMHrd%2Bf8MKTrtnEaIR2YqwzbDkNsEoCgLG6kXO81GDqBZ341irGRYqcJboHiCk%2FIHz7I9Xt9XsNASW4UyyRD2%2FJzb3MkXzxI5IeNGcaiI%2B1i4dgqkI9W%2ByY31nw2%2FzCm0puzBjqlAcQnBaaPgtjyJGJkiPnalUpDU4xYB7EXvRhT9CmjZyiRpg9e5M%2B3LVkiRyrk4EX2EVMAbvIAaAwKJmb2dVhJEMWY0reGnS%2B5wI9OI5iV6AKQE1YsFsmjS20A0zOaTOU5RmQA94wZAsdGu%2B0pl6KH0f2TPBerP474Luxr7siZUcqUkCowYCSztgPESLnxZMWYh%2B3U9TBKEWYeWs1%2BZPC2tHckVhYmDg%3D%3D&Signature=iVl6dGMf%2BZYCj5ONkXxqy4qVQ5U%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_400.png?AWSAccessKeyId=ASIAUHQDK3LLF2L6WQ2F&Expires=1718471417&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQChs72GBid3qLkUJjEwIn2bBrn08ENh9oJYYF8T%2BSbdHwIgHbFNOcM6FJD2fUmIlSkZv9E%2Bz45xISYrAqAjSwIPy%2B0qgwQIRRAEGgwyOTA5OTEwMzcxNDIiDBBXGGzx2rA0ZzX0IirgA4kAal9zMEJrypoORXweWERLxdMFpQ%2Bm5IJA6qsNZClHx9RKoJaOHTRYxS%2Fq%2Fx91bbDiY1YoH1o0LUd3vbl3Nm%2Fd2oZW0FB%2BSlQ4S331zHwov3Oj06dsY1llnOB4yOE%2Bp4%2F%2FoVLuZ5hMn0dDYdGIJ%2Fs%2BegjeqD3K%2BMjNS4rksJ0RbuppQEtH3cgOWiJ6zgLMyTZCt0aVigXgXWsjeqtzPfbKxRUoB35pUnbcq72Nt%2BFKixGSKGIJgPeodbgx7ZlEygOWFgFFvsHHz37dACgUbaa8Qzq8%2BCcYtguGbVF0xlKpksENHglm%2BZqXTrrKG0rVdJHIQ9qr7HrgeXp6uIH9tlupGiRmZn4VcxGrTOFzlCwJx8bYaxwLx5jBoU3inAfzaJ%2BmcGdAU1Sdl523tl9Z4okP1or1NScXnNS5AAfgsgcvwmnfql3KllA8MxTB%2FDbzix45vuplSAJ7kMx6R86TOZTuU2S0DIBz5R08G4%2FIxleAUlUQcwu11tmjNInaSCyQ2379KMHrd%2Bf8MKTrtnEaIR2YqwzbDkNsEoCgLG6kXO81GDqBZ341irGRYqcJboHiCk%2FIHz7I9Xt9XsNASW4UyyRD2%2FJzb3MkXzxI5IeNGcaiI%2B1i4dgqkI9W%2ByY31nw2%2FzCm0puzBjqlAcQnBaaPgtjyJGJkiPnalUpDU4xYB7EXvRhT9CmjZyiRpg9e5M%2B3LVkiRyrk4EX2EVMAbvIAaAwKJmb2dVhJEMWY0reGnS%2B5wI9OI5iV6AKQE1YsFsmjS20A0zOaTOU5RmQA94wZAsdGu%2B0pl6KH0f2TPBerP474Luxr7siZUcqUkCowYCSztgPESLnxZMWYh%2B3U9TBKEWYeWs1%2BZPC2tHckVhYmDg%3D%3D&Signature=UT05cpK6xg7nXpuvijDOOuki6ts%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_500.png?AWSAccessKeyId=ASIAUHQDK3LLF2L6WQ2F&Expires=1718471417&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQChs72GBid3qLkUJjEwIn2bBrn08ENh9oJYYF8T%2BSbdHwIgHbFNOcM6FJD2fUmIlSkZv9E%2Bz45xISYrAqAjSwIPy%2B0qgwQIRRAEGgwyOTA5OTEwMzcxNDIiDBBXGGzx2rA0ZzX0IirgA4kAal9zMEJrypoORXweWERLxdMFpQ%2Bm5IJA6qsNZClHx9RKoJaOHTRYxS%2Fq%2Fx91bbDiY1YoH1o0LUd3vbl3Nm%2Fd2oZW0FB%2BSlQ4S331zHwov3Oj06dsY1llnOB4yOE%2Bp4%2F%2FoVLuZ5hMn0dDYdGIJ%2Fs%2BegjeqD3K%2BMjNS4rksJ0RbuppQEtH3cgOWiJ6zgLMyTZCt0aVigXgXWsjeqtzPfbKxRUoB35pUnbcq72Nt%2BFKixGSKGIJgPeodbgx7ZlEygOWFgFFvsHHz37dACgUbaa8Qzq8%2BCcYtguGbVF0xlKpksENHglm%2BZqXTrrKG0rVdJHIQ9qr7HrgeXp6uIH9tlupGiRmZn4VcxGrTOFzlCwJx8bYaxwLx5jBoU3inAfzaJ%2BmcGdAU1Sdl523tl9Z4okP1or1NScXnNS5AAfgsgcvwmnfql3KllA8MxTB%2FDbzix45vuplSAJ7kMx6R86TOZTuU2S0DIBz5R08G4%2FIxleAUlUQcwu11tmjNInaSCyQ2379KMHrd%2Bf8MKTrtnEaIR2YqwzbDkNsEoCgLG6kXO81GDqBZ341irGRYqcJboHiCk%2FIHz7I9Xt9XsNASW4UyyRD2%2FJzb3MkXzxI5IeNGcaiI%2B1i4dgqkI9W%2ByY31nw2%2FzCm0puzBjqlAcQnBaaPgtjyJGJkiPnalUpDU4xYB7EXvRhT9CmjZyiRpg9e5M%2B3LVkiRyrk4EX2EVMAbvIAaAwKJmb2dVhJEMWY0reGnS%2B5wI9OI5iV6AKQE1YsFsmjS20A0zOaTOU5RmQA94wZAsdGu%2B0pl6KH0f2TPBerP474Luxr7siZUcqUkCowYCSztgPESLnxZMWYh%2B3U9TBKEWYeWs1%2BZPC2tHckVhYmDg%3D%3D&Signature=CG8QEpshqPsw%2FokwytR5Hve0khM%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_700.png?AWSAccessKeyId=ASIAUHQDK3LLF2L6WQ2F&Expires=1718471417&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIQChs72GBid3qLkUJjEwIn2bBrn08ENh9oJYYF8T%2BSbdHwIgHbFNOcM6FJD2fUmIlSkZv9E%2Bz45xISYrAqAjSwIPy%2B0qgwQIRRAEGgwyOTA5OTEwMzcxNDIiDBBXGGzx2rA0ZzX0IirgA4kAal9zMEJrypoORXweWERLxdMFpQ%2Bm5IJA6qsNZClHx9RKoJaOHTRYxS%2Fq%2Fx91bbDiY1YoH1o0LUd3vbl3Nm%2Fd2oZW0FB%2BSlQ4S331zHwov3Oj06dsY1llnOB4yOE%2Bp4%2F%2FoVLuZ5hMn0dDYdGIJ%2Fs%2BegjeqD3K%2BMjNS4rksJ0RbuppQEtH3cgOWiJ6zgLMyTZCt0aVigXgXWsjeqtzPfbKxRUoB35pUnbcq72Nt%2BFKixGSKGIJgPeodbgx7ZlEygOWFgFFvsHHz37dACgUbaa8Qzq8%2BCcYtguGbVF0xlKpksENHglm%2BZqXTrrKG0rVdJHIQ9qr7HrgeXp6uIH9tlupGiRmZn4VcxGrTOFzlCwJx8bYaxwLx5jBoU3inAfzaJ%2BmcGdAU1Sdl523tl9Z4okP1or1NScXnNS5AAfgsgcvwmnfql3KllA8MxTB%2FDbzix45vuplSAJ7kMx6R86TOZTuU2S0DIBz5R08G4%2FIxleAUlUQcwu11tmjNInaSCyQ2379KMHrd%2Bf8MKTrtnEaIR2YqwzbDkNsEoCgLG6kXO81GDqBZ341irGRYqcJboHiCk%2FIHz7I9Xt9XsNASW4UyyRD2%2FJzb3MkXzxI5IeNGcaiI%2B1i4dgqkI9W%2ByY31nw2%2FzCm0puzBjqlAcQnBaaPgtjyJGJkiPnalUpDU4xYB7EXvRhT9CmjZyiRpg9e5M%2B3LVkiRyrk4EX2EVMAbvIAaAwKJmb2dVhJEMWY0reGnS%2B5wI9OI5iV6AKQE1YsFsmjS20A0zOaTOU5RmQA94wZAsdGu%2B0pl6KH0f2TPBerP474Luxr7siZUcqUkCowYCSztgPESLnxZMWYh%2B3U9TBKEWYeWs1%2BZPC2tHckVhYmDg%3D%3D&Signature=zYCWqsLAhAMU5JitmX1qY%2F0JubE%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "4c2c35d9-f799-44a9-b75c-cdad2250e484"
    ],
    "category": null,
    "downloadCount": 1,
    "id": "fd61185c-eec8-457b-9510-0df3fbe7671c",
    "url": "retail/branded-environments/design-advisories/details/fd61185c-eec8-457b-9510-0df3fbe7671c",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2019 DAS Updated Exterior Brand Standards",
    "score": 0.9913409,
    "createdOn": "2022-03-15T13:48:11.686193Z",
    "modifiedOn": "2022-05-21T22:24:10.511834Z"
  }
];

export const shareOneItem = [
  {
    "assetId": "e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
    "description": "CLICK HERE TO WATCH THIS TRAINING VIDEO:\n[Brand Compliance training video](https://brandcentral.verizonwireless.com/training/videos/brand-identity-training-video-series/brand-compliance#playlist)\n\nTraining videos are not available for download. Please use the link above to view this training video.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2025-12-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Brand training video: Brand compliance",
    "files": [
      {
        "id": "f2c8ad3c-859b-4d2b-b3c5-87696d56961e",
        "groupName": "1",
        "fileName": "VZ_DBI_COMPLIANCE_MASTER ALT_20200720.mp4",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_110.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=uqeED8qdjOfShSQxSFI8acc%2B6gg%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_400.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=ZuaMvUULYq6GQ0Bq2HCsPGLueQk%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_500.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=QNPN5n7A%2FdI831%2Bhko23Nkqak7c%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_700.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=O1CMp9z1qWfjXzsYzT9J7DBNljw%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "54dd50ac-f5c3-47ea-be63-92cad84a3014",
      "d08fc3db-419c-42a0-a964-df202e6be8b6",
      "1f125d15-63b0-4edf-9813-5dbc9a6544fb"
    ],
    "category": "Employee Communications",
    "downloadCount": 13,
    "id": "e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
    "url": "assets/details/e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Brand training video: Brand compliance",
    "score": 0.9678475,
    "createdOn": "2020-06-15T17:43:47Z",
    "modifiedOn": "2022-12-13T00:00:00Z"
  }];

export const favoriteModalSingleAsset = {
  "assetId": "0",
  "description": "CLICK HERE TO WATCH THIS TRAINING VIDEO:\n[Brand Compliance training video](https://brandcentral.verizonwireless.com/training/videos/brand-identity-training-video-series/brand-compliance#playlist)\n\nTraining videos are not available for download. Please use the link above to view this training video.",
  "userActions": {
    "hasAcceptedUsageRights": true,
    "hasPendingAccessRequest": false,
    "canDownload": true,
    "canEdit": true,
    "canDeactivate": true,
    "canExpire": true,
    "canUpdateAssetAccessRequest": true,
    "isAdmin": true
  },
  "usageRightsCopy": "",
  "expiresOn": "2025-12-31T00:00:00Z",
  "isRestricted": false,
  "isSearchable": true,
  "altText": "Brand training video: Brand compliance",
  "files": [
    {
      "id": "f2c8ad3c-859b-4d2b-b3c5-87696d56961e",
      "groupName": "1",
      "fileName": "VZ_DBI_COMPLIANCE_MASTER ALT_20200720.mp4",
      "approvalStatus": "Approved",
      "thumbnails": {
        "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_110.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=uqeED8qdjOfShSQxSFI8acc%2B6gg%3D",
        "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_400.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=ZuaMvUULYq6GQ0Bq2HCsPGLueQk%3D",
        "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_500.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=QNPN5n7A%2FdI831%2Bhko23Nkqak7c%3D",
        "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_700.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=O1CMp9z1qWfjXzsYzT9J7DBNljw%3D",
        "isImage": true
      }
    }
  ],
  "filterIds": [
    "54dd50ac-f5c3-47ea-be63-92cad84a3014",
    "d08fc3db-419c-42a0-a964-df202e6be8b6",
    "1f125d15-63b0-4edf-9813-5dbc9a6544fb"
  ],
  "category": "Employee Communications",
  "downloadCount": 13,
  "id": "0",
  "url": "assets/details/e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
  "searchType": "asset",
  "isFavorite": false,
  "title": "Brand training video: Brand compliance",
  "score": 0.9678475,
  "createdOn": "2020-06-15T17:43:47Z",
  "modifiedOn": "2022-12-13T00:00:00Z"
};

export const favoriteModalAssetNoFavorites = {
  "assetId": "asset-without-favorites",
  "description": "CLICK HERE TO WATCH THIS TRAINING VIDEO:\n[Brand Compliance training video](https://brandcentral.verizonwireless.com/training/videos/brand-identity-training-video-series/brand-compliance#playlist)\n\nTraining videos are not available for download. Please use the link above to view this training video.",
  "userActions": {
    "hasAcceptedUsageRights": true,
    "hasPendingAccessRequest": false,
    "canDownload": true,
    "canEdit": true,
    "canDeactivate": true,
    "canExpire": true,
    "canUpdateAssetAccessRequest": true,
    "isAdmin": true
  },
  "usageRightsCopy": "",
  "expiresOn": "2025-12-31T00:00:00Z",
  "isRestricted": false,
  "isSearchable": true,
  "altText": "Brand training video: Brand compliance",
  "files": [
    {
      "id": "f2c8ad3c-859b-4d2b-b3c5-87696d56961e",
      "groupName": "1",
      "fileName": "VZ_DBI_COMPLIANCE_MASTER ALT_20200720.mp4",
      "approvalStatus": "Approved",
      "thumbnails": {
        "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_110.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=uqeED8qdjOfShSQxSFI8acc%2B6gg%3D",
        "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_400.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=ZuaMvUULYq6GQ0Bq2HCsPGLueQk%3D",
        "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_500.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=QNPN5n7A%2FdI831%2Bhko23Nkqak7c%3D",
        "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_700.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=O1CMp9z1qWfjXzsYzT9J7DBNljw%3D",
        "isImage": true
      }
    }
  ],
  "filterIds": [
    "54dd50ac-f5c3-47ea-be63-92cad84a3014",
    "d08fc3db-419c-42a0-a964-df202e6be8b6",
    "1f125d15-63b0-4edf-9813-5dbc9a6544fb"
  ],
  "category": "Employee Communications",
  "downloadCount": 13,
  "id": "asset-without-favorites",
  "url": "assets/details/e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
  "searchType": "asset",
  "isFavorite": false,
  "title": "Brand training video: Brand compliance",
  "score": 0.9678475,
  "createdOn": "2020-06-15T17:43:47Z",
  "modifiedOn": "2022-12-13T00:00:00Z"
};

export const favoriteModalAssetFavorites = {
  "assetId": "asset-with-favorites",
  "description": "CLICK HERE TO WATCH THIS TRAINING VIDEO:\n[Brand Compliance training video](https://brandcentral.verizonwireless.com/training/videos/brand-identity-training-video-series/brand-compliance#playlist)\n\nTraining videos are not available for download. Please use the link above to view this training video.",
  "userActions": {
    "hasAcceptedUsageRights": true,
    "hasPendingAccessRequest": false,
    "canDownload": true,
    "canEdit": true,
    "canDeactivate": true,
    "canExpire": true,
    "canUpdateAssetAccessRequest": true,
    "isAdmin": true
  },
  "usageRightsCopy": "",
  "expiresOn": "2025-12-31T00:00:00Z",
  "isRestricted": false,
  "isSearchable": true,
  "altText": "Brand training video: Brand compliance",
  "files": [
    {
      "id": "f2c8ad3c-859b-4d2b-b3c5-87696d56961e",
      "groupName": "1",
      "fileName": "VZ_DBI_COMPLIANCE_MASTER ALT_20200720.mp4",
      "approvalStatus": "Approved",
      "thumbnails": {
        "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_110.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=uqeED8qdjOfShSQxSFI8acc%2B6gg%3D",
        "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_400.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=ZuaMvUULYq6GQ0Bq2HCsPGLueQk%3D",
        "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_500.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=QNPN5n7A%2FdI831%2Bhko23Nkqak7c%3D",
        "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_700.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=O1CMp9z1qWfjXzsYzT9J7DBNljw%3D",
        "isImage": true
      }
    }
  ],
  "filterIds": [
    "54dd50ac-f5c3-47ea-be63-92cad84a3014",
    "d08fc3db-419c-42a0-a964-df202e6be8b6",
    "1f125d15-63b0-4edf-9813-5dbc9a6544fb"
  ],
  "category": "Employee Communications",
  "downloadCount": 13,
  "id": "asset-with-favorites",
  "url": "assets/details/e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
  "searchType": "asset",
  "isFavorite": false,
  "title": "Brand training video: Brand compliance",
  "score": 0.9678475,
  "createdOn": "2020-06-15T17:43:47Z",
  "modifiedOn": "2022-12-13T00:00:00Z"
};

export const favoriteModalAssetsFavorites = [
  {
  "assetId": "asset-with-favorites",
  "description": "CLICK HERE TO WATCH THIS TRAINING VIDEO:\n[Brand Compliance training video](https://brandcentral.verizonwireless.com/training/videos/brand-identity-training-video-series/brand-compliance#playlist)\n\nTraining videos are not available for download. Please use the link above to view this training video.",
  "userActions": {
    "hasAcceptedUsageRights": true,
    "hasPendingAccessRequest": false,
    "canDownload": true,
    "canEdit": true,
    "canDeactivate": true,
    "canExpire": true,
    "canUpdateAssetAccessRequest": true,
    "isAdmin": true
  },
  "usageRightsCopy": "",
  "expiresOn": "2025-12-31T00:00:00Z",
  "isRestricted": false,
  "isSearchable": true,
  "altText": "Brand training video: Brand compliance",
  "files": [
    {
      "id": "f2c8ad3c-859b-4d2b-b3c5-87696d56961e",
      "groupName": "1",
      "fileName": "VZ_DBI_COMPLIANCE_MASTER ALT_20200720.mp4",
      "approvalStatus": "Approved",
      "thumbnails": {
        "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_110.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=uqeED8qdjOfShSQxSFI8acc%2B6gg%3D",
        "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_400.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=ZuaMvUULYq6GQ0Bq2HCsPGLueQk%3D",
        "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_500.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=QNPN5n7A%2FdI831%2Bhko23Nkqak7c%3D",
        "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_700.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=O1CMp9z1qWfjXzsYzT9J7DBNljw%3D",
        "isImage": true
      }
    }
  ],
  "filterIds": [
    "54dd50ac-f5c3-47ea-be63-92cad84a3014",
    "d08fc3db-419c-42a0-a964-df202e6be8b6",
    "1f125d15-63b0-4edf-9813-5dbc9a6544fb"
  ],
  "category": "Employee Communications",
  "downloadCount": 13,
  "id": "asset-with-favorites",
  "url": "assets/details/e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
  "searchType": "asset",
  "isFavorite": false,
  "title": "Brand training video: Brand compliance",
  "score": 0.9678475,
  "createdOn": "2020-06-15T17:43:47Z",
  "modifiedOn": "2022-12-13T00:00:00Z"
},
  {
    "assetId": "asset-with-same-favorites",
    "description": "Brand: Gizmo\nModel: Watch 3\nColor: Nylon\nSKU: QTAX57NN",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "this is the usage rights copy",
    "expiresOn": "2025-05-19T18:01:09.752203Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Gizmo Watch 3 Nylon",
    "files": [
      {
        "id": "68cefb03-19b2-452f-8bfd-af98152fd8f6",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=r%2BzQ1QPxSD9D%2F1MUGCKMcTpBwUc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=uNGvwIdzfdqD69dvORhdFl9DFoE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=dA4uq8GgoHeI2klcfd7vnDMjKU8%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=4zRNFW%2BzOoNliQuEqrvlmMXc3Y4%3D",
          "isImage": true
        }
      },
      {
        "id": "9af0656c-3b2f-4985-b4a6-ac7d398fae58",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=nJ%2Bz0KL8Elr%2BrWZ8kHOH%2F4w2JI4%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=D0stI8pNQ3IpmrwOn9ktU%2FramRE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=TGojMZDinLSRmnaNvHGLYlxqjkI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=FiunA3xw1yOa6DNSo6hjiMwK548%3D",
          "isImage": true
        }
      },
      {
        "id": "50361d68-6eaf-4512-9079-a57c69705ee2",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "13faa07d-30dd-480d-b4d4-55120044e7e0",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_RTL_GR13_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "ad6e77fd-4789-41c5-bc41-2172d80a6912",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "b4a8eea5-317e-4d70-9c4e-17491b2da33f",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_RTL_GR13_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      }
    ],
    "filterIds": [
      "316c0651-c5e3-4bd2-b41c-5427484f74bd",
      "261d3e08-254e-42d1-845c-db7359285823",
      "c45906c2-bf19-4538-adb5-acf860c15d62",
      "36b18590-90a5-464e-85b0-a765a47e7e72",
      "63949a7a-3241-4410-a655-4a3debc9e8d2",
      "25ee6b4e-0e36-445a-8740-7d432afa3e8a",
      "05f5f9a1-1422-468e-86c3-11cd3ecbd15a",
      "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d",
      "37791870-297d-4c3c-8230-d988fa8625b3",
      "c41fa42b-480a-4090-a085-cabcafe8e45b",
      "e550e4e9-dc56-4348-b99a-6e5894ea2521"
    ],
    "category": "Devices & Accessories",
    "downloadCount": 10,
    "id": "asset-with-same-favorites",
    "url": "assets/details/94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Gizmo Watch 3 Nylon band",
    "score": 0.8676618,
    "createdOn": "2023-05-19T18:09:38.22071Z",
    "modifiedOn": "2023-07-26T13:47:54.677952Z"
  }
];

export const favoriteModalMultipleAssets = [
  {
    "assetId": "0",
    "description": "[Click here to open the Verizon Authorized Retailer Brand Policy](https://brandpolicy.verizon.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzEwNDM4NTYsImlzcyI6ImFjYiIsImp0aSI6IjNmNWU3MzdiLTVhNDYtNDEzMi04Mjc1LTU4ZWRiYzI3OWMxMiJ9.YlbE-WRk5FDg-L4uf9nMTxfD-tXmL2eYTwaiK44yf6A&showHeader=true)\n\nThis site entails the branding ascribed to mandatory and optional touch points along the customer journey at Verizon Authorized Retailers (Indirect agents). It also details the appropriate usage of Verizon logos, advertising templates and additional guidelines pertaining to the desired brand experience.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2028-08-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Authorized Retailer Brand Policy",
    "files": [
      {
        "id": "51f1393d-fa71-4141-a16a-17a83ec16f55",
        "groupName": "",
        "fileName": "Link_for__Authorized_Retailer_Brand_Policy_20221221.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=lkgCI32F6QmseVUTQ1f6SIBs7TI%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=71la8Ly4qyuqBwQIEw1tEaC%2FndE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=nCd800PCTCVVPhd6%2Br2%2F7D2VptU%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=0vDzcPdZhHtfx0TezpiTb2yJv40%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "b88e3848-074a-4d9b-8d97-72cfbec6c01e",
      "cc617e38-baf9-4b69-a15d-74c4488422a1",
      "7327cd61-0f1d-4875-a93c-1462944a8d19"
    ],
    "category": "Logos",
    "downloadCount": 39,
    "id": "0",
    "url": "assets/details/30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Authorized Retailer Brand Policy",
    "score": 0.8208615,
    "createdOn": "2016-08-19T15:12:33Z",
    "modifiedOn": "2022-12-22T15:52:27.172562Z"
  },
  {
    "assetId": "1",
    "description": "Brand: Gizmo\nModel: Watch 3\nColor: Nylon\nSKU: QTAX57NN",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "this is the usage rights copy",
    "expiresOn": "2025-05-19T18:01:09.752203Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Gizmo Watch 3 Nylon",
    "files": [
      {
        "id": "68cefb03-19b2-452f-8bfd-af98152fd8f6",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=r%2BzQ1QPxSD9D%2F1MUGCKMcTpBwUc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=uNGvwIdzfdqD69dvORhdFl9DFoE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=dA4uq8GgoHeI2klcfd7vnDMjKU8%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=4zRNFW%2BzOoNliQuEqrvlmMXc3Y4%3D",
          "isImage": true
        }
      },
      {
        "id": "9af0656c-3b2f-4985-b4a6-ac7d398fae58",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=nJ%2Bz0KL8Elr%2BrWZ8kHOH%2F4w2JI4%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=D0stI8pNQ3IpmrwOn9ktU%2FramRE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=TGojMZDinLSRmnaNvHGLYlxqjkI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=FiunA3xw1yOa6DNSo6hjiMwK548%3D",
          "isImage": true
        }
      },
      {
        "id": "50361d68-6eaf-4512-9079-a57c69705ee2",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "13faa07d-30dd-480d-b4d4-55120044e7e0",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_RTL_GR13_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "ad6e77fd-4789-41c5-bc41-2172d80a6912",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "b4a8eea5-317e-4d70-9c4e-17491b2da33f",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_RTL_GR13_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      }
    ],
    "filterIds": [
      "316c0651-c5e3-4bd2-b41c-5427484f74bd",
      "261d3e08-254e-42d1-845c-db7359285823",
      "c45906c2-bf19-4538-adb5-acf860c15d62",
      "36b18590-90a5-464e-85b0-a765a47e7e72",
      "63949a7a-3241-4410-a655-4a3debc9e8d2",
      "25ee6b4e-0e36-445a-8740-7d432afa3e8a",
      "05f5f9a1-1422-468e-86c3-11cd3ecbd15a",
      "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d",
      "37791870-297d-4c3c-8230-d988fa8625b3",
      "c41fa42b-480a-4090-a085-cabcafe8e45b",
      "e550e4e9-dc56-4348-b99a-6e5894ea2521"
    ],
    "category": "Devices & Accessories",
    "downloadCount": 10,
    "id": "1",
    "url": "assets/details/94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Gizmo Watch 3 Nylon band",
    "score": 0.8676618,
    "createdOn": "2023-05-19T18:09:38.22071Z",
    "modifiedOn": "2023-07-26T13:47:54.677952Z"
  }
];
export const multipleSelectedSingleAsset = [
  {
    "assetId": "e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
    "description": "CLICK HERE TO WATCH THIS TRAINING VIDEO:\n[Brand Compliance training video](https://brandcentral.verizonwireless.com/training/videos/brand-identity-training-video-series/brand-compliance#playlist)\n\nTraining videos are not available for download. Please use the link above to view this training video.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2025-12-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Brand training video: Brand compliance",
    "files": [
      {
        "id": "f2c8ad3c-859b-4d2b-b3c5-87696d56961e",
        "groupName": "1",
        "fileName": "VZ_DBI_COMPLIANCE_MASTER ALT_20200720.mp4",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_110.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=uqeED8qdjOfShSQxSFI8acc%2B6gg%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_400.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=ZuaMvUULYq6GQ0Bq2HCsPGLueQk%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_500.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=QNPN5n7A%2FdI831%2Bhko23Nkqak7c%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLf178e2b3b2be42db9f4816bc1633f63b_700.png?AWSAccessKeyId=ASIAUHQDK3LLKWDPFDVL&Expires=1718471172&x-amz-security-token=IQoJb3JpZ2luX2VjEKz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCID2LrKGjum9uqhWzHQgB%2BunkkN7fXKGyBS9ol4toPyKyAiEA9eCjiVzF71ZqmEPwPa%2BRtAZ8dUllb1ESZ3P4IpbbVdIqgwQIRRAEGgwyOTA5OTEwMzcxNDIiDCTbb%2F6qCKyTDfpEySrgA6zL%2FYtgWW6fwhWWxIgwAgsKBh%2FtgIK4rT24TpJd6amW9b%2BAIrOeTwOkZIkjaWMrUiyEdgLCtU2DONPIVAsIzp4myUdfnpEAWprZPcF8RrXPeEPa1oMZoHrdV7Mk1AbOD3QIy4OUU8GZmQ2eoCVKWP6kBqG5KNwC2V%2BX624eFQObkDINM%2FaO8d3%2BWO6T65yZEudhbI4sjH%2B1NPu4HIglBWOs%2B0WLZlWV964DNRQgFj7T9WXGUA1ILe3iapmN84H8%2FHjvTUf%2FwBCkHFZB0pLGqXIvjsDJm4kAmCD%2BaCnESEu5Dty0GelyN5pHmKzRIdZ6nCrikX%2FspJtsn%2Bjz0mc0SYbRPXiE4FEpFn9UI6ZJpy4dH2gHV%2BlgWFYCRJoo8TyEBaMpzEAlioxTAxmuXolhrYmpzWs39RHLrV6hneO4aFuhqfAHSMIZ3PTSFWfiatFViR3c0m78gBh%2FYEDv9NBmaYuMfJhHA9S1CpyyMO%2FSv8USp8u9iZK9PNCYR9gdORo%2BBL5Vl0amyJCowG208RiF%2FSRM4E1B%2BKLzDfLZdpoHkSPy9NbM7bC%2B5n356%2BjHtfCjBu71hEA00TE%2FzkMdw%2FMCfjsYkz%2B8VtOVBMdjpdy77WESg3ZSY4CUngf0XAw6Z5EolzDS1ZuzBjqlAYFGj%2FUkvr3cIX5WN41TqQT25LTGidCMcuIdDWunBXn6%2BHgCAkU2ha%2FAArUk84wMuY274OvU1hg8tTreuI3ZbOb8AGqdnc5M9zUNzyFMzXPSQ7GvYzcwdymicxDTar3VMTy44SixTRQX5D141DM9%2Fu1mBeLUeEYphmVhkqzNZiBEFuhNG5IRJDyE3wrtZcWE8ZGbJ6EEvCTY%2FffRfEwoFKoclwJrQA%3D%3D&Signature=O1CMp9z1qWfjXzsYzT9J7DBNljw%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "54dd50ac-f5c3-47ea-be63-92cad84a3014",
      "d08fc3db-419c-42a0-a964-df202e6be8b6",
      "1f125d15-63b0-4edf-9813-5dbc9a6544fb"
    ],
    "category": "Employee Communications",
    "downloadCount": 13,
    "id": "e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
    "url": "assets/details/e76524d2-b26a-4ecc-bf81-1ce3b18c540f",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Brand training video: Brand compliance",
    "score": 0.9678475,
    "createdOn": "2020-06-15T17:43:47Z",
    "modifiedOn": "2022-12-13T00:00:00Z"
  }
];

// has one asset with usage rights not accepted
export const multipleSelectedMultipleAssets = [
  {
    "assetId": "30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "description": "[Click here to open the Verizon Authorized Retailer Brand Policy](https://brandpolicy.verizon.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzEwNDM4NTYsImlzcyI6ImFjYiIsImp0aSI6IjNmNWU3MzdiLTVhNDYtNDEzMi04Mjc1LTU4ZWRiYzI3OWMxMiJ9.YlbE-WRk5FDg-L4uf9nMTxfD-tXmL2eYTwaiK44yf6A&showHeader=true)\n\nThis site entails the branding ascribed to mandatory and optional touch points along the customer journey at Verizon Authorized Retailers (Indirect agents). It also details the appropriate usage of Verizon logos, advertising templates and additional guidelines pertaining to the desired brand experience.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2028-08-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Authorized Retailer Brand Policy",
    "files": [
      {
        "id": "51f1393d-fa71-4141-a16a-17a83ec16f55",
        "groupName": "",
        "fileName": "Link_for__Authorized_Retailer_Brand_Policy_20221221.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=lkgCI32F6QmseVUTQ1f6SIBs7TI%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=71la8Ly4qyuqBwQIEw1tEaC%2FndE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=nCd800PCTCVVPhd6%2Br2%2F7D2VptU%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=0vDzcPdZhHtfx0TezpiTb2yJv40%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "b88e3848-074a-4d9b-8d97-72cfbec6c01e",
      "cc617e38-baf9-4b69-a15d-74c4488422a1",
      "7327cd61-0f1d-4875-a93c-1462944a8d19"
    ],
    "category": "Logos",
    "downloadCount": 39,
    "id": "30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "url": "assets/details/30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Authorized Retailer Brand Policy",
    "score": 0.8208615,
    "createdOn": "2016-08-19T15:12:33Z",
    "modifiedOn": "2022-12-22T15:52:27.172562Z"
  },
  {
    "assetId": "94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "description": "Brand: Gizmo\nModel: Watch 3\nColor: Nylon\nSKU: QTAX57NN",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "this is the usage rights copy",
    "expiresOn": "2025-05-19T18:01:09.752203Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Gizmo Watch 3 Nylon",
    "files": [
      {
        "id": "68cefb03-19b2-452f-8bfd-af98152fd8f6",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=r%2BzQ1QPxSD9D%2F1MUGCKMcTpBwUc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=uNGvwIdzfdqD69dvORhdFl9DFoE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=dA4uq8GgoHeI2klcfd7vnDMjKU8%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=4zRNFW%2BzOoNliQuEqrvlmMXc3Y4%3D",
          "isImage": true
        }
      },
      {
        "id": "9af0656c-3b2f-4985-b4a6-ac7d398fae58",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=nJ%2Bz0KL8Elr%2BrWZ8kHOH%2F4w2JI4%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=D0stI8pNQ3IpmrwOn9ktU%2FramRE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=TGojMZDinLSRmnaNvHGLYlxqjkI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=FiunA3xw1yOa6DNSo6hjiMwK548%3D",
          "isImage": true
        }
      },
      {
        "id": "50361d68-6eaf-4512-9079-a57c69705ee2",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "13faa07d-30dd-480d-b4d4-55120044e7e0",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_RTL_GR13_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "ad6e77fd-4789-41c5-bc41-2172d80a6912",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "b4a8eea5-317e-4d70-9c4e-17491b2da33f",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_RTL_GR13_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      }
    ],
    "filterIds": [
      "316c0651-c5e3-4bd2-b41c-5427484f74bd",
      "261d3e08-254e-42d1-845c-db7359285823",
      "c45906c2-bf19-4538-adb5-acf860c15d62",
      "36b18590-90a5-464e-85b0-a765a47e7e72",
      "63949a7a-3241-4410-a655-4a3debc9e8d2",
      "25ee6b4e-0e36-445a-8740-7d432afa3e8a",
      "05f5f9a1-1422-468e-86c3-11cd3ecbd15a",
      "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d",
      "37791870-297d-4c3c-8230-d988fa8625b3",
      "c41fa42b-480a-4090-a085-cabcafe8e45b",
      "e550e4e9-dc56-4348-b99a-6e5894ea2521"
    ],
    "category": "Devices & Accessories",
    "downloadCount": 10,
    "id": "94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "url": "assets/details/94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Gizmo Watch 3 Nylon band",
    "score": 0.8676618,
    "createdOn": "2023-05-19T18:09:38.22071Z",
    "modifiedOn": "2023-07-26T13:47:54.677952Z"
  },
  {
    "assetId": "e7560c32-2926-487a-a430-79e42d364bd7",
    "description": "Individuals or agencies that download and use imagery from Brand Central are responsible for the management and oversight of the asset and its associated usage rights. Individuals using assets beyond the expiration dates will be responsible for all associated payments. The brand team does not monitor imagery use and is not responsible for usage rights. \nSubmit your creative for brand compliance review at verizon.com/brandcompliance",
    "userActions": {
      "hasAcceptedUsageRights": false,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "Usage/Exclusions: Unlimited\nExpiration Date: None",
    "expiresOn": "2026-03-30T04:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "OCR 2019 Fire Fighter",
    "files": [
      {
        "id": "362fbdc7-b366-4316-aab9-fd9803cc8750",
        "groupName": "",
        "fileName": "OCR__BrookeFitts0469-O19in-RGB.jpg",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=5Eusnx2KfQnWleCSHmY1CfHAqCg%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=B0EYbsY2zjOG848%2F6rZTPzV3z7M%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=KN2uztngfxcvzhwrOalYGKL0Y3U%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=Ku2r44VrTkAqImpsjNXUwIlGoWE%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "76007f55-b998-4883-8e53-64c5ecec4a51",
      "6b34af07-6053-449b-a903-fef3017d741b",
      "f336e575-da93-4c1e-8f10-b61f84b61201",
      "1b48cd71-2d41-4cce-91c9-2879baacd6d5",
      "68139f35-4a11-4ff8-809a-087e467e8995",
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
      "990afd3e-7939-4f41-8cb4-279b1189ce29",
      "177c1b1b-cc61-49b0-a389-81fc1a62c320",
      "aa4f0b53-33be-4a2f-bff0-226061fd3001",
      "c8bc00e5-c2c4-4d37-8677-204577875f62",
      "0ce6838e-be32-42c7-b4a1-9382d7e40859",
      "5f2012eb-8a03-43b1-8209-7089100c4bfc",
      "393d0379-3bd6-44eb-93f4-42d7c2e0a295",
      "5822ed2e-6c86-4c2a-abea-be55a7231591",
      "441e77e1-aa06-42d1-ae6e-97112c576fc1",
      "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
      "cddce8a2-074d-48a9-bfc4-16522353e484",
      "b8acea32-28ef-43ee-9ba2-17cff3603481",
      "fe822fad-fd49-434c-8460-58ea6418c10b"
    ],
    "category": "Photography",
    "downloadCount": 1,
    "id": "e7560c32-2926-487a-a430-79e42d364bd7",
    "url": "assets/details/e7560c32-2926-487a-a430-79e42d364bd7",
    "searchType": "asset",
    "isFavorite": false,
    "title": "OCR 2019 Fire Fighter",
    "score": 0.9,
    "createdOn": "2020-03-01T17:28:37Z",
    "modifiedOn": "2024-01-05T19:58:11.64884Z"
  }
];

export const multipleSelectedTwoAssets = [
  {
    "assetId": "30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "description": "[Click here to open the Verizon Authorized Retailer Brand Policy](https://brandpolicy.verizon.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzEwNDM4NTYsImlzcyI6ImFjYiIsImp0aSI6IjNmNWU3MzdiLTVhNDYtNDEzMi04Mjc1LTU4ZWRiYzI3OWMxMiJ9.YlbE-WRk5FDg-L4uf9nMTxfD-tXmL2eYTwaiK44yf6A&showHeader=true)\n\nThis site entails the branding ascribed to mandatory and optional touch points along the customer journey at Verizon Authorized Retailers (Indirect agents). It also details the appropriate usage of Verizon logos, advertising templates and additional guidelines pertaining to the desired brand experience.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2028-08-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Authorized Retailer Brand Policy",
    "files": [
      {
        "id": "51f1393d-fa71-4141-a16a-17a83ec16f55",
        "groupName": "",
        "fileName": "Link_for__Authorized_Retailer_Brand_Policy_20221221.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=lkgCI32F6QmseVUTQ1f6SIBs7TI%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=71la8Ly4qyuqBwQIEw1tEaC%2FndE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=nCd800PCTCVVPhd6%2Br2%2F7D2VptU%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=0vDzcPdZhHtfx0TezpiTb2yJv40%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "b88e3848-074a-4d9b-8d97-72cfbec6c01e",
      "cc617e38-baf9-4b69-a15d-74c4488422a1",
      "7327cd61-0f1d-4875-a93c-1462944a8d19"
    ],
    "category": "Logos",
    "downloadCount": 39,
    "id": "30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "url": "assets/details/30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Authorized Retailer Brand Policy",
    "score": 0.8208615,
    "createdOn": "2016-08-19T15:12:33Z",
    "modifiedOn": "2022-12-22T15:52:27.172562Z"
  },
  {
    "assetId": "94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "description": "Brand: Gizmo\nModel: Watch 3\nColor: Nylon\nSKU: QTAX57NN",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "this is the usage rights copy",
    "expiresOn": "2025-05-19T18:01:09.752203Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Gizmo Watch 3 Nylon",
    "files": [
      {
        "id": "68cefb03-19b2-452f-8bfd-af98152fd8f6",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=r%2BzQ1QPxSD9D%2F1MUGCKMcTpBwUc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=uNGvwIdzfdqD69dvORhdFl9DFoE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=dA4uq8GgoHeI2klcfd7vnDMjKU8%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=4zRNFW%2BzOoNliQuEqrvlmMXc3Y4%3D",
          "isImage": true
        }
      },
      {
        "id": "9af0656c-3b2f-4985-b4a6-ac7d398fae58",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=nJ%2Bz0KL8Elr%2BrWZ8kHOH%2F4w2JI4%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=D0stI8pNQ3IpmrwOn9ktU%2FramRE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=TGojMZDinLSRmnaNvHGLYlxqjkI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=FiunA3xw1yOa6DNSo6hjiMwK548%3D",
          "isImage": true
        }
      },
      {
        "id": "50361d68-6eaf-4512-9079-a57c69705ee2",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "13faa07d-30dd-480d-b4d4-55120044e7e0",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_RTL_GR13_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "ad6e77fd-4789-41c5-bc41-2172d80a6912",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "b4a8eea5-317e-4d70-9c4e-17491b2da33f",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_RTL_GR13_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      }
    ],
    "filterIds": [
      "316c0651-c5e3-4bd2-b41c-5427484f74bd",
      "261d3e08-254e-42d1-845c-db7359285823",
      "c45906c2-bf19-4538-adb5-acf860c15d62",
      "36b18590-90a5-464e-85b0-a765a47e7e72",
      "63949a7a-3241-4410-a655-4a3debc9e8d2",
      "25ee6b4e-0e36-445a-8740-7d432afa3e8a",
      "05f5f9a1-1422-468e-86c3-11cd3ecbd15a",
      "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d",
      "37791870-297d-4c3c-8230-d988fa8625b3",
      "c41fa42b-480a-4090-a085-cabcafe8e45b",
      "e550e4e9-dc56-4348-b99a-6e5894ea2521"
    ],
    "category": "Devices & Accessories",
    "downloadCount": 10,
    "id": "94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "url": "assets/details/94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Gizmo Watch 3 Nylon band",
    "score": 0.8676618,
    "createdOn": "2023-05-19T18:09:38.22071Z",
    "modifiedOn": "2023-07-26T13:47:54.677952Z"
  }
];

export const multipleSelectedMultipleAssetsAllRightsAccepted = [
  {
    "assetId": "30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "description": "[Click here to open the Verizon Authorized Retailer Brand Policy](https://brandpolicy.verizon.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzEwNDM4NTYsImlzcyI6ImFjYiIsImp0aSI6IjNmNWU3MzdiLTVhNDYtNDEzMi04Mjc1LTU4ZWRiYzI3OWMxMiJ9.YlbE-WRk5FDg-L4uf9nMTxfD-tXmL2eYTwaiK44yf6A&showHeader=true)\n\nThis site entails the branding ascribed to mandatory and optional touch points along the customer journey at Verizon Authorized Retailers (Indirect agents). It also details the appropriate usage of Verizon logos, advertising templates and additional guidelines pertaining to the desired brand experience.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2028-08-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Authorized Retailer Brand Policy",
    "files": [
      {
        "id": "51f1393d-fa71-4141-a16a-17a83ec16f55",
        "groupName": "",
        "fileName": "Link_for__Authorized_Retailer_Brand_Policy_20221221.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=lkgCI32F6QmseVUTQ1f6SIBs7TI%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=71la8Ly4qyuqBwQIEw1tEaC%2FndE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=nCd800PCTCVVPhd6%2Br2%2F7D2VptU%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=0vDzcPdZhHtfx0TezpiTb2yJv40%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "b88e3848-074a-4d9b-8d97-72cfbec6c01e",
      "cc617e38-baf9-4b69-a15d-74c4488422a1",
      "7327cd61-0f1d-4875-a93c-1462944a8d19"
    ],
    "category": "Logos",
    "downloadCount": 39,
    "id": "30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "url": "assets/details/30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Authorized Retailer Brand Policy",
    "score": 0.8208615,
    "createdOn": "2016-08-19T15:12:33Z",
    "modifiedOn": "2022-12-22T15:52:27.172562Z"
  },
  {
    "assetId": "94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "description": "Brand: Gizmo\nModel: Watch 3\nColor: Nylon\nSKU: QTAX57NN",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "this is the usage rights copy",
    "expiresOn": "2025-05-19T18:01:09.752203Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Gizmo Watch 3 Nylon",
    "files": [
      {
        "id": "68cefb03-19b2-452f-8bfd-af98152fd8f6",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=r%2BzQ1QPxSD9D%2F1MUGCKMcTpBwUc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=uNGvwIdzfdqD69dvORhdFl9DFoE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=dA4uq8GgoHeI2klcfd7vnDMjKU8%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=4zRNFW%2BzOoNliQuEqrvlmMXc3Y4%3D",
          "isImage": true
        }
      },
      {
        "id": "9af0656c-3b2f-4985-b4a6-ac7d398fae58",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=nJ%2Bz0KL8Elr%2BrWZ8kHOH%2F4w2JI4%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=D0stI8pNQ3IpmrwOn9ktU%2FramRE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=TGojMZDinLSRmnaNvHGLYlxqjkI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=FiunA3xw1yOa6DNSo6hjiMwK548%3D",
          "isImage": true
        }
      },
      {
        "id": "50361d68-6eaf-4512-9079-a57c69705ee2",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "13faa07d-30dd-480d-b4d4-55120044e7e0",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_RTL_GR13_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "ad6e77fd-4789-41c5-bc41-2172d80a6912",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "b4a8eea5-317e-4d70-9c4e-17491b2da33f",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_RTL_GR13_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      }
    ],
    "filterIds": [
      "316c0651-c5e3-4bd2-b41c-5427484f74bd",
      "261d3e08-254e-42d1-845c-db7359285823",
      "c45906c2-bf19-4538-adb5-acf860c15d62",
      "36b18590-90a5-464e-85b0-a765a47e7e72",
      "63949a7a-3241-4410-a655-4a3debc9e8d2",
      "25ee6b4e-0e36-445a-8740-7d432afa3e8a",
      "05f5f9a1-1422-468e-86c3-11cd3ecbd15a",
      "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d",
      "37791870-297d-4c3c-8230-d988fa8625b3",
      "c41fa42b-480a-4090-a085-cabcafe8e45b",
      "e550e4e9-dc56-4348-b99a-6e5894ea2521"
    ],
    "category": "Devices & Accessories",
    "downloadCount": 10,
    "id": "94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "url": "assets/details/94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Gizmo Watch 3 Nylon band",
    "score": 0.8676618,
    "createdOn": "2023-05-19T18:09:38.22071Z",
    "modifiedOn": "2023-07-26T13:47:54.677952Z"
  },
  {
    "assetId": "e7560c32-2926-487a-a430-79e42d364bd7",
    "description": "Individuals or agencies that download and use imagery from Brand Central are responsible for the management and oversight of the asset and its associated usage rights. Individuals using assets beyond the expiration dates will be responsible for all associated payments. The brand team does not monitor imagery use and is not responsible for usage rights. \nSubmit your creative for brand compliance review at verizon.com/brandcompliance",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "Usage/Exclusions: Unlimited\nExpiration Date: None",
    "expiresOn": "2026-03-30T04:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "OCR 2019 Fire Fighter",
    "files": [
      {
        "id": "362fbdc7-b366-4316-aab9-fd9803cc8750",
        "groupName": "",
        "fileName": "OCR__BrookeFitts0469-O19in-RGB.jpg",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=5Eusnx2KfQnWleCSHmY1CfHAqCg%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=B0EYbsY2zjOG848%2F6rZTPzV3z7M%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=KN2uztngfxcvzhwrOalYGKL0Y3U%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=Ku2r44VrTkAqImpsjNXUwIlGoWE%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "76007f55-b998-4883-8e53-64c5ecec4a51",
      "6b34af07-6053-449b-a903-fef3017d741b",
      "f336e575-da93-4c1e-8f10-b61f84b61201",
      "1b48cd71-2d41-4cce-91c9-2879baacd6d5",
      "68139f35-4a11-4ff8-809a-087e467e8995",
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
      "990afd3e-7939-4f41-8cb4-279b1189ce29",
      "177c1b1b-cc61-49b0-a389-81fc1a62c320",
      "aa4f0b53-33be-4a2f-bff0-226061fd3001",
      "c8bc00e5-c2c4-4d37-8677-204577875f62",
      "0ce6838e-be32-42c7-b4a1-9382d7e40859",
      "5f2012eb-8a03-43b1-8209-7089100c4bfc",
      "393d0379-3bd6-44eb-93f4-42d7c2e0a295",
      "5822ed2e-6c86-4c2a-abea-be55a7231591",
      "441e77e1-aa06-42d1-ae6e-97112c576fc1",
      "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
      "cddce8a2-074d-48a9-bfc4-16522353e484",
      "b8acea32-28ef-43ee-9ba2-17cff3603481",
      "fe822fad-fd49-434c-8460-58ea6418c10b"
    ],
    "category": "Photography",
    "downloadCount": 1,
    "id": "e7560c32-2926-487a-a430-79e42d364bd7",
    "url": "assets/details/e7560c32-2926-487a-a430-79e42d364bd7",
    "searchType": "asset",
    "isFavorite": false,
    "title": "OCR 2019 Fire Fighter",
    "score": 0.9,
    "createdOn": "2020-03-01T17:28:37Z",
    "modifiedOn": "2024-01-05T19:58:11.64884Z"
  }
];

export const multipleSelectedSingleCompliance = [
  {
    "entityId": "7xQaWpqDKppdT5SXZk3nuq",
    "complianceType": "complianceScoringElement",
    "id": "59629541-aaaa-4f63-945c-14e3048b7aab",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Brand Voice",
    "score": 0.99371,
    "createdOn": "2021-01-15T16:56:57.176Z",
    "modifiedOn": "2023-10-07T17:02:48.964Z"
  }
];

export const multipleSelectedMultipleCompliance = [
  {
    "entityId": "7xQaWpqDKppdT5SXZk3nuq",
    "complianceType": "complianceScoringElement",
    "id": "59629541-aaaa-4f63-945c-14e3048b7aab",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Brand Voice",
    "score": 0.99371,
    "createdOn": "2021-01-15T16:56:57.176Z",
    "modifiedOn": "2023-10-07T17:02:48.964Z"
  },
  {
    "entityId": "5BHx5PWsWC80omYYuXEFUC",
    "complianceType": "complianceScoringElement",
    "id": "109c67ca-0a0c-4a72-b974-9917073387f0",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Color",
    "score": 0.10085287,
    "createdOn": "2021-01-15T13:55:03.556Z",
    "modifiedOn": "2023-10-07T17:02:16.208Z"
  },
  {
    "entityId": "6i9toukVd7DQ7i8E0zkP6x",
    "complianceType": "complianceScoringElement",
    "id": "e500e072-2d15-4b27-b8f8-6de9794d4a7c",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Logo",
    "score": 0.1,
    "createdOn": "2020-12-21T21:59:59.343Z",
    "modifiedOn": "2023-10-07T17:01:44.434Z"
  }
];

export const multipleSelectedSingleGuideline = [
  {
    "entityId": "16t4NRNbAQ0oYoeeQeQk42",
    "id": "e0da9c2a-97a6-4af7-a6ec-59ce801c134e",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/parent-brand-elements/color/brand-colors",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Color: Brand colors",
    "score": 0.9074147,
    "createdOn": "2018-04-09T15:50:43.305Z",
    "modifiedOn": "2023-11-14T16:02:39.544Z"
  }
];

export const multipleSelectedMultipleGuidelines = [
  {
    "entityId": "16t4NRNbAQ0oYoeeQeQk42",
    "id": "e0da9c2a-97a6-4af7-a6ec-59ce801c134e",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/parent-brand-elements/color/brand-colors",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Color: Brand colors",
    "score": 0.9074147,
    "createdOn": "2018-04-09T15:50:43.305Z",
    "modifiedOn": "2023-11-14T16:02:39.544Z"
  },
  {
    "entityId": "4eOAqLDbpYqgMOAayIEOMY",
    "id": "89d0fe9f-1b9a-4a85-9fee-18ce932a71f3",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/parent-brand-elements/motion/branded-moments",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Motion: Branded moments",
    "score": 0.78391856,
    "createdOn": "2018-04-09T15:52:38.555Z",
    "modifiedOn": "2023-01-31T21:32:19.422Z"
  },
  {
    "entityId": "2PEg1CNnn7FiD1kLVZcN3l",
    "id": "2c742b6a-61be-4beb-b34f-5b3b4092941a",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/digital/announcements/brand3.0-is-here",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Digital: Brand 3.0 launch",
    "score": 0.7562663,
    "createdOn": "2022-09-28T20:32:10.911Z",
    "modifiedOn": "2023-01-31T20:17:59.854Z"
  }
];

export const multipleSelectedSingleTraining = [
  {
    "entityId": "4cGPwPwK8L9grGZzk6nGzW",
    "thumbnailAssetUrl": "https://images.ctfassets.net/ve6konwi38y5/3PVEK9qKGPtvitbENZArcm/8c538ff8979db0f8eb19edec6f6afdbc/verizon-brand-compliance.png",
    "playlistSlug": "brand-identity-training-video-series",
    "videoSlug": "brand-compliance",
    "id": "b0c094a7-dba3-4cd4-8f6c-87842d32d13a",
    "url": "/brand-identity-training-video-series/brand-compliance",
    "searchType": "training",
    "isFavorite": false,
    "title": "Brand compliance",
    "score": 1,
    "createdOn": "2021-01-06T18:25:33.962Z",
    "modifiedOn": "2023-10-07T17:05:46.599Z"
  }
];

export const multipleSelectedMultipleTraining = [
  {
    "entityId": "4cGPwPwK8L9grGZzk6nGzW",
    "thumbnailAssetUrl": "https://images.ctfassets.net/ve6konwi38y5/3PVEK9qKGPtvitbENZArcm/8c538ff8979db0f8eb19edec6f6afdbc/verizon-brand-compliance.png",
    "playlistSlug": "brand-identity-training-video-series",
    "videoSlug": "brand-compliance",
    "id": "b0c094a7-dba3-4cd4-8f6c-87842d32d13a",
    "url": "/brand-identity-training-video-series/brand-compliance",
    "searchType": "training",
    "isFavorite": false,
    "title": "Brand compliance",
    "score": 1,
    "createdOn": "2021-01-06T18:25:33.962Z",
    "modifiedOn": "2023-10-07T17:05:46.599Z"
  },
  {
    "entityId": "NlxkEGwVtpcygTUBROmiu",
    "thumbnailAssetUrl": "https://images.ctfassets.net/ve6konwi38y5/3BcNByQMr8Mcp6YCRID0ke/f85966ead2a03fcdbc0f40d74997ba21/verizon-brand-basics-1.png",
    "playlistSlug": "brand-identity-training-video-series",
    "videoSlug": "brand-basics",
    "id": "696f99a5-849c-4d66-a8ab-f2db98f15156",
    "url": "/brand-identity-training-video-series/brand-basics",
    "searchType": "training",
    "isFavorite": false,
    "title": "Verizon brand basics",
    "score": 0.87478954,
    "createdOn": "2020-12-21T21:59:52.917Z",
    "modifiedOn": "2023-10-07T17:04:57.096Z"
  },
  {
    "entityId": "1qvEDpXa0JtB0k2Z44m0DX",
    "thumbnailAssetUrl": "https://images.ctfassets.net/ve6konwi38y5/44fJrepSQhOMLSZdA3KNT3/4b16b9acccb9c0b8622267ab31ce0141/verizon-colors.png",
    "playlistSlug": "brand-identity-training-video-series",
    "videoSlug": "using-the-verizon-brand-colors",
    "id": "76fc47db-fa41-4d0c-97d6-f76543bd1862",
    "url": "/brand-identity-training-video-series/using-the-verizon-brand-colors",
    "searchType": "training",
    "isFavorite": false,
    "title": "Using the Verizon brand colors",
    "score": 0.8048159,
    "createdOn": "2021-01-06T18:02:25.161Z",
    "modifiedOn": "2023-10-07T17:05:15.506Z"
  }
];

export const multipleSelectedSingleTemplate = [
  {
    "assetId": "a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "description": "This is the primary all-purpose Verizon PowerPoint template with over 50 ready-to-use slides. The template is created in widescreen format (16:9 aspect ratio). \n\nJan. 12, 2024 update: color theme.\nJan. 24, 2024 update:  Safe Harbor statement.\n\nGoogle Slides version is found in the Verizon Template Gallery here: https://docs.google.com/presentation/u/0/?ftv=1&folder=0AAsmHEmojaRCUk9PVA&tgif=d",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "PowerPoint template for all purpose use",
    "files": [
      {
        "id": "7fc28927-336d-4e34-ab27-a89c5f57f856",
        "groupName": "1",
        "fileName": "Verizon__PPT_Template_4.3.potx",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=zNE9j8qC1SQ5Gy2s%2BIm%2BrYBNKIE%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=Q6xz77g%2FJ7E5htg0W0vFbiGC0VY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=5cpuca56d8l83V%2Bu7%2BZrArslBSI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=ai71%2FS%2B7H11bR1Pu6LM39y%2BJTHQ%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "6a23903a-7522-4e2b-8390-ea53fe9ea5fe",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "fe39245d-d069-41df-a8b0-c5f56cb0fa4e"
    ],
    "category": null,
    "downloadCount": 4143,
    "id": "a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "url": "templates/details/a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "searchType": "template",
    "isFavorite": false,
    "title": "PowerPoint template for all purpose use",
    "score": 0.9,
    "createdOn": "2015-10-06T19:58:51Z",
    "modifiedOn": "2024-01-25T19:16:41.688301Z"
  }
];

export const multipleSelectedMultipleTemplates = [
  {
    "assetId": "a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "description": "This is the primary all-purpose Verizon PowerPoint template with over 50 ready-to-use slides. The template is created in widescreen format (16:9 aspect ratio). \n\nJan. 12, 2024 update: color theme.\nJan. 24, 2024 update:  Safe Harbor statement.\n\nGoogle Slides version is found in the Verizon Template Gallery here: https://docs.google.com/presentation/u/0/?ftv=1&folder=0AAsmHEmojaRCUk9PVA&tgif=d",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "PowerPoint template for all purpose use",
    "files": [
      {
        "id": "7fc28927-336d-4e34-ab27-a89c5f57f856",
        "groupName": "1",
        "fileName": "Verizon__PPT_Template_4.3.potx",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=zNE9j8qC1SQ5Gy2s%2BIm%2BrYBNKIE%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=Q6xz77g%2FJ7E5htg0W0vFbiGC0VY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=5cpuca56d8l83V%2Bu7%2BZrArslBSI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=ai71%2FS%2B7H11bR1Pu6LM39y%2BJTHQ%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "6a23903a-7522-4e2b-8390-ea53fe9ea5fe",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "fe39245d-d069-41df-a8b0-c5f56cb0fa4e"
    ],
    "category": null,
    "downloadCount": 4143,
    "id": "a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "url": "templates/details/a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "searchType": "template",
    "isFavorite": false,
    "title": "PowerPoint template for all purpose use",
    "score": 0.9,
    "createdOn": "2015-10-06T19:58:51Z",
    "modifiedOn": "2024-01-25T19:16:41.688301Z"
  },
  {
    "assetId": "48cdd4eb-555b-41a6-a34a-ab40456423f3",
    "description": "This is the Verizon PowerPoint template to create award and recognition certificates.",
    "userActions": {
      "hasAcceptedUsageRights": false,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "This is usage rights text",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Award and recognition template toolkit",
    "files": [
      {
        "id": "a885316d-6d11-48f8-af3c-7f9503bcecf9",
        "groupName": "",
        "fileName": "Verizon_Award_Template-3.pptx",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=z3z%2F5JMDWKEDdjD%2B%2FfDG1K0eteY%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=2ZGhWdCCM6%2FGVk%2BTcBsiw%2FkRNiA%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=50r0SCn3vT9subreLEVlOPl%2Bzj4%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=gjuK%2BhnMTBNRAoiUgsYXnRhs5n4%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "fe39245d-d069-41df-a8b0-c5f56cb0fa4e",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "7711c433-6032-4d5c-a863-fcbaac958d7c"
    ],
    "category": null,
    "downloadCount": 489,
    "id": "48cdd4eb-555b-41a6-a34a-ab40456423f3",
    "url": "templates/details/48cdd4eb-555b-41a6-a34a-ab40456423f3",
    "searchType": "template",
    "isFavorite": false,
    "title": "Award and recognition template toolkit",
    "score": 0.7264392,
    "createdOn": "2019-06-24T13:17:59Z",
    "modifiedOn": "2023-10-23T21:36:44.680526Z"
  },
  {
    "assetId": "3a8ad2be-31f5-4711-98f9-238cfc63f515",
    "description": "This is a PowerPoint template with options to create a header image (JPG, PNG) to be inserted above an email message.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "PPT template",
    "files": [
      {
        "id": "544d378e-4a82-4db4-8139-c10d83be3852",
        "groupName": "",
        "fileName": "Verizon_email_header_templates-Jun2022.zip",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=lOuCOwc9EDQY7%2BpH3AOSAG3KlFg%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=%2F1STy80P9PYpsnXvAyo0sRTpb2k%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=WCDwAygK%2F8HuY9cgsSKV2L9Vy08%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=M3yK1CwoEOnOWwv0lb73ovRVz38%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "ff22f5d2-a031-4e56-960b-0d40e246b829",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "30235ae7-61f0-4a43-b8db-1fb2c2569a4f",
      "fff16217-7f1c-40d5-b11c-b6d738c6c91c"
    ],
    "category": null,
    "downloadCount": 12,
    "id": "3a8ad2be-31f5-4711-98f9-238cfc63f515",
    "url": "templates/details/3a8ad2be-31f5-4711-98f9-238cfc63f515",
    "searchType": "template",
    "isFavorite": false,
    "title": "Verizon email header template",
    "score": 0.53468865,
    "createdOn": "2020-06-08T17:56:37Z",
    "modifiedOn": "2024-05-23T15:32:39.481799Z"
  }
];

export const multipleSelectedSingleRetail = [
  {
    "assetId": "fd61185c-eec8-457b-9510-0df3fbe7671c",
    "description": "2019 DAS Updated Exterior Brand Standards",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2027-03-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2019DAS",
    "files": [
      {
        "id": "d93c0ec5-8713-41a7-a0d7-6f6c8d812526",
        "groupName": null,
        "fileName": "Verizon DAS-19-001-Updated Exterior Brand Standards.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=6dgkApw%2FmpMpgjmsFeHT%2BCbOgFc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=%2F%2FBmG4hx8ySsFhxosh84aHWbzFU%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=04rq2HKIUtrSoQ5AS6hPdl%2FCH2U%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=14LWiufyZ3VnHUqFpSTMciKlsSo%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "4c2c35d9-f799-44a9-b75c-cdad2250e484"
    ],
    "category": null,
    "downloadCount": 1,
    "id": "fd61185c-eec8-457b-9510-0df3fbe7671c",
    "url": "retail/branded-environments/design-advisories/details/fd61185c-eec8-457b-9510-0df3fbe7671c",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2019 DAS Updated Exterior Brand Standards",
    "score": 0.9913409,
    "createdOn": "2022-03-15T13:48:11.686193Z",
    "modifiedOn": "2022-05-21T22:24:10.511834Z"
  }
];

export const multipleSelectedMultipleRetail = [
  {
    "assetId": "fd61185c-eec8-457b-9510-0df3fbe7671c",
    "description": "2019 DAS Updated Exterior Brand Standards",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2027-03-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2019DAS",
    "files": [
      {
        "id": "d93c0ec5-8713-41a7-a0d7-6f6c8d812526",
        "groupName": null,
        "fileName": "Verizon DAS-19-001-Updated Exterior Brand Standards.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=6dgkApw%2FmpMpgjmsFeHT%2BCbOgFc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=%2F%2FBmG4hx8ySsFhxosh84aHWbzFU%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=04rq2HKIUtrSoQ5AS6hPdl%2FCH2U%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=14LWiufyZ3VnHUqFpSTMciKlsSo%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "4c2c35d9-f799-44a9-b75c-cdad2250e484"
    ],
    "category": null,
    "downloadCount": 1,
    "id": "fd61185c-eec8-457b-9510-0df3fbe7671c",
    "url": "retail/branded-environments/design-advisories/details/fd61185c-eec8-457b-9510-0df3fbe7671c",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2019 DAS Updated Exterior Brand Standards",
    "score": 0.9913409,
    "createdOn": "2022-03-15T13:48:11.686193Z",
    "modifiedOn": "2022-05-21T22:24:10.511834Z"
  },
  {
    "assetId": "fcea44cc-3cf9-4719-92d2-40d6f068d06a",
    "description": "2019 DAS Revised Interior Branding Guidelines",
    "userActions": {
      "hasAcceptedUsageRights": false,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "This is usage rights copy.",
    "expiresOn": "2027-05-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2019DAS",
    "files": [
      {
        "id": "10c80f35-b14d-458b-9a60-04c0c9128828",
        "groupName": null,
        "fileName": "Verizon DAS-19-013_Revised Interior Branding Guidelines.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=CDNBP3eZwpr%2FjLFM2jZNmbSwx7M%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=4Al99K8nqTfzqy4aGJgrF%2BSjUSs%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=BRCXd%2FvLE0aFfLh0vdmH%2FehsfGw%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=ZK1FqVWtDyvoIOXne9v6PFefmnE%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "4c2c35d9-f799-44a9-b75c-cdad2250e484",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3"
    ],
    "category": null,
    "downloadCount": 1,
    "id": "fcea44cc-3cf9-4719-92d2-40d6f068d06a",
    "url": "retail/branded-environments/design-advisories/details/fcea44cc-3cf9-4719-92d2-40d6f068d06a",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2019 DAS Revised Interior Branding Guidelines",
    "score": 0.9329889,
    "createdOn": "2022-05-21T22:21:48.609266Z",
    "modifiedOn": "2022-08-27T02:56:41.227939Z"
  },
  {
    "assetId": "176b3edb-196b-4e52-97c7-ac36df3731d9",
    "description": "2020 DAS Updated Interior Branding Technical Guidelines Advisory",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2027-03-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2020 DAS Updated Interior Branding Technical Guidelines Advisory",
    "files": [
      {
        "id": "98c453cd-735a-4ee0-b761-8b56f688f2d9",
        "groupName": null,
        "fileName": "Verizon DAS-20-001_Updated Interior Branding Technical Guidelines Advisory.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=%2BctWnL5U3SaeJ0cL3uq6V0res0A%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=A%2FgrEo50uAssuKxbF0mMtHq%2FJMI%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=JacypS%2F6lBxzIGRnhVn64hNQ3eg%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=IzW3jn%2FqKFru%2Fx5UlVUUQC15SvI%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "87cb4b07-fcbd-453a-8305-b500b9151a3d",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3"
    ],
    "category": null,
    "downloadCount": 4,
    "id": "176b3edb-196b-4e52-97c7-ac36df3731d9",
    "url": "retail/branded-environments/design-advisories/details/176b3edb-196b-4e52-97c7-ac36df3731d9",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2020 DAS Updated Interior Branding Technical Guidelines Advisory",
    "score": 0.8978885,
    "createdOn": "2022-03-15T13:48:11.590403Z",
    "modifiedOn": "2022-05-22T15:50:06.714271Z"
  }
];

export const multipleSelectedAllTypes = [
  {
    "assetId": "30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "description": "[Click here to open the Verizon Authorized Retailer Brand Policy](https://brandpolicy.verizon.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzEwNDM4NTYsImlzcyI6ImFjYiIsImp0aSI6IjNmNWU3MzdiLTVhNDYtNDEzMi04Mjc1LTU4ZWRiYzI3OWMxMiJ9.YlbE-WRk5FDg-L4uf9nMTxfD-tXmL2eYTwaiK44yf6A&showHeader=true)\n\nThis site entails the branding ascribed to mandatory and optional touch points along the customer journey at Verizon Authorized Retailers (Indirect agents). It also details the appropriate usage of Verizon logos, advertising templates and additional guidelines pertaining to the desired brand experience.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2028-08-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Authorized Retailer Brand Policy",
    "files": [
      {
        "id": "51f1393d-fa71-4141-a16a-17a83ec16f55",
        "groupName": "",
        "fileName": "Link_for__Authorized_Retailer_Brand_Policy_20221221.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=lkgCI32F6QmseVUTQ1f6SIBs7TI%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=71la8Ly4qyuqBwQIEw1tEaC%2FndE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=nCd800PCTCVVPhd6%2Br2%2F7D2VptU%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=0vDzcPdZhHtfx0TezpiTb2yJv40%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "b88e3848-074a-4d9b-8d97-72cfbec6c01e",
      "cc617e38-baf9-4b69-a15d-74c4488422a1",
      "7327cd61-0f1d-4875-a93c-1462944a8d19"
    ],
    "category": "Logos",
    "downloadCount": 39,
    "id": "30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "url": "assets/details/30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Authorized Retailer Brand Policy",
    "score": 0.8208615,
    "createdOn": "2016-08-19T15:12:33Z",
    "modifiedOn": "2022-12-22T15:52:27.172562Z"
  },
  {
    "assetId": "94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "description": "Brand: Gizmo\nModel: Watch 3\nColor: Nylon\nSKU: QTAX57NN",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "this is the usage rights copy",
    "expiresOn": "2025-05-19T18:01:09.752203Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Gizmo Watch 3 Nylon",
    "files": [
      {
        "id": "68cefb03-19b2-452f-8bfd-af98152fd8f6",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=r%2BzQ1QPxSD9D%2F1MUGCKMcTpBwUc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=uNGvwIdzfdqD69dvORhdFl9DFoE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=dA4uq8GgoHeI2klcfd7vnDMjKU8%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=4zRNFW%2BzOoNliQuEqrvlmMXc3Y4%3D",
          "isImage": true
        }
      },
      {
        "id": "9af0656c-3b2f-4985-b4a6-ac7d398fae58",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=nJ%2Bz0KL8Elr%2BrWZ8kHOH%2F4w2JI4%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=D0stI8pNQ3IpmrwOn9ktU%2FramRE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=TGojMZDinLSRmnaNvHGLYlxqjkI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=FiunA3xw1yOa6DNSo6hjiMwK548%3D",
          "isImage": true
        }
      },
      {
        "id": "50361d68-6eaf-4512-9079-a57c69705ee2",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "13faa07d-30dd-480d-b4d4-55120044e7e0",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_RTL_GR13_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "ad6e77fd-4789-41c5-bc41-2172d80a6912",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "b4a8eea5-317e-4d70-9c4e-17491b2da33f",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_RTL_GR13_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      }
    ],
    "filterIds": [
      "316c0651-c5e3-4bd2-b41c-5427484f74bd",
      "261d3e08-254e-42d1-845c-db7359285823",
      "c45906c2-bf19-4538-adb5-acf860c15d62",
      "36b18590-90a5-464e-85b0-a765a47e7e72",
      "63949a7a-3241-4410-a655-4a3debc9e8d2",
      "25ee6b4e-0e36-445a-8740-7d432afa3e8a",
      "05f5f9a1-1422-468e-86c3-11cd3ecbd15a",
      "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d",
      "37791870-297d-4c3c-8230-d988fa8625b3",
      "c41fa42b-480a-4090-a085-cabcafe8e45b",
      "e550e4e9-dc56-4348-b99a-6e5894ea2521"
    ],
    "category": "Devices & Accessories",
    "downloadCount": 10,
    "id": "94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "url": "assets/details/94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Gizmo Watch 3 Nylon band",
    "score": 0.8676618,
    "createdOn": "2023-05-19T18:09:38.22071Z",
    "modifiedOn": "2023-07-26T13:47:54.677952Z"
  },
  {
    "assetId": "e7560c32-2926-487a-a430-79e42d364bd7",
    "description": "Individuals or agencies that download and use imagery from Brand Central are responsible for the management and oversight of the asset and its associated usage rights. Individuals using assets beyond the expiration dates will be responsible for all associated payments. The brand team does not monitor imagery use and is not responsible for usage rights. \nSubmit your creative for brand compliance review at verizon.com/brandcompliance",
    "userActions": {
      "hasAcceptedUsageRights": false,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "Usage/Exclusions: Unlimited\nExpiration Date: None",
    "expiresOn": "2026-03-30T04:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "OCR 2019 Fire Fighter",
    "files": [
      {
        "id": "362fbdc7-b366-4316-aab9-fd9803cc8750",
        "groupName": "",
        "fileName": "OCR__BrookeFitts0469-O19in-RGB.jpg",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=5Eusnx2KfQnWleCSHmY1CfHAqCg%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=B0EYbsY2zjOG848%2F6rZTPzV3z7M%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=KN2uztngfxcvzhwrOalYGKL0Y3U%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=Ku2r44VrTkAqImpsjNXUwIlGoWE%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "76007f55-b998-4883-8e53-64c5ecec4a51",
      "6b34af07-6053-449b-a903-fef3017d741b",
      "f336e575-da93-4c1e-8f10-b61f84b61201",
      "1b48cd71-2d41-4cce-91c9-2879baacd6d5",
      "68139f35-4a11-4ff8-809a-087e467e8995",
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
      "990afd3e-7939-4f41-8cb4-279b1189ce29",
      "177c1b1b-cc61-49b0-a389-81fc1a62c320",
      "aa4f0b53-33be-4a2f-bff0-226061fd3001",
      "c8bc00e5-c2c4-4d37-8677-204577875f62",
      "0ce6838e-be32-42c7-b4a1-9382d7e40859",
      "5f2012eb-8a03-43b1-8209-7089100c4bfc",
      "393d0379-3bd6-44eb-93f4-42d7c2e0a295",
      "5822ed2e-6c86-4c2a-abea-be55a7231591",
      "441e77e1-aa06-42d1-ae6e-97112c576fc1",
      "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
      "cddce8a2-074d-48a9-bfc4-16522353e484",
      "b8acea32-28ef-43ee-9ba2-17cff3603481",
      "fe822fad-fd49-434c-8460-58ea6418c10b"
    ],
    "category": "Photography",
    "downloadCount": 1,
    "id": "e7560c32-2926-487a-a430-79e42d364bd7",
    "url": "assets/details/e7560c32-2926-487a-a430-79e42d364bd7",
    "searchType": "asset",
    "isFavorite": false,
    "title": "OCR 2019 Fire Fighter",
    "score": 0.9,
    "createdOn": "2020-03-01T17:28:37Z",
    "modifiedOn": "2024-01-05T19:58:11.64884Z"
  },
  {
    "entityId": "7xQaWpqDKppdT5SXZk3nuq",
    "complianceType": "complianceScoringElement",
    "id": "59629541-aaaa-4f63-945c-14e3048b7aab",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Brand Voice",
    "score": 0.99371,
    "createdOn": "2021-01-15T16:56:57.176Z",
    "modifiedOn": "2023-10-07T17:02:48.964Z"
  },
  {
    "entityId": "5BHx5PWsWC80omYYuXEFUC",
    "complianceType": "complianceScoringElement",
    "id": "109c67ca-0a0c-4a72-b974-9917073387f0",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Color",
    "score": 0.10085287,
    "createdOn": "2021-01-15T13:55:03.556Z",
    "modifiedOn": "2023-10-07T17:02:16.208Z"
  },
  {
    "entityId": "6i9toukVd7DQ7i8E0zkP6x",
    "complianceType": "complianceScoringElement",
    "id": "e500e072-2d15-4b27-b8f8-6de9794d4a7c",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Logo",
    "score": 0.1,
    "createdOn": "2020-12-21T21:59:59.343Z",
    "modifiedOn": "2023-10-07T17:01:44.434Z"
  },
  {
    "entityId": "16t4NRNbAQ0oYoeeQeQk42",
    "id": "e0da9c2a-97a6-4af7-a6ec-59ce801c134e",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/parent-brand-elements/color/brand-colors",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Color: Brand colors",
    "score": 0.9074147,
    "createdOn": "2018-04-09T15:50:43.305Z",
    "modifiedOn": "2023-11-14T16:02:39.544Z"
  },
  {
    "entityId": "4eOAqLDbpYqgMOAayIEOMY",
    "id": "89d0fe9f-1b9a-4a85-9fee-18ce932a71f3",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/parent-brand-elements/motion/branded-moments",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Motion: Branded moments",
    "score": 0.78391856,
    "createdOn": "2018-04-09T15:52:38.555Z",
    "modifiedOn": "2023-01-31T21:32:19.422Z"
  },
  {
    "entityId": "2PEg1CNnn7FiD1kLVZcN3l",
    "id": "2c742b6a-61be-4beb-b34f-5b3b4092941a",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/digital/announcements/brand3.0-is-here",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Digital: Brand 3.0 launch",
    "score": 0.7562663,
    "createdOn": "2022-09-28T20:32:10.911Z",
    "modifiedOn": "2023-01-31T20:17:59.854Z"
  },
  {
    "entityId": "4cGPwPwK8L9grGZzk6nGzW",
    "thumbnailAssetUrl": "https://images.ctfassets.net/ve6konwi38y5/3PVEK9qKGPtvitbENZArcm/8c538ff8979db0f8eb19edec6f6afdbc/verizon-brand-compliance.png",
    "playlistSlug": "brand-identity-training-video-series",
    "videoSlug": "brand-compliance",
    "id": "b0c094a7-dba3-4cd4-8f6c-87842d32d13a",
    "url": "/brand-identity-training-video-series/brand-compliance",
    "searchType": "training",
    "isFavorite": false,
    "title": "Brand compliance",
    "score": 1,
    "createdOn": "2021-01-06T18:25:33.962Z",
    "modifiedOn": "2023-10-07T17:05:46.599Z"
  },
  {
    "entityId": "NlxkEGwVtpcygTUBROmiu",
    "thumbnailAssetUrl": "https://images.ctfassets.net/ve6konwi38y5/3BcNByQMr8Mcp6YCRID0ke/f85966ead2a03fcdbc0f40d74997ba21/verizon-brand-basics-1.png",
    "playlistSlug": "brand-identity-training-video-series",
    "videoSlug": "brand-basics",
    "id": "696f99a5-849c-4d66-a8ab-f2db98f15156",
    "url": "/brand-identity-training-video-series/brand-basics",
    "searchType": "training",
    "isFavorite": false,
    "title": "Verizon brand basics",
    "score": 0.87478954,
    "createdOn": "2020-12-21T21:59:52.917Z",
    "modifiedOn": "2023-10-07T17:04:57.096Z"
  },
  {
    "entityId": "1qvEDpXa0JtB0k2Z44m0DX",
    "thumbnailAssetUrl": "https://images.ctfassets.net/ve6konwi38y5/44fJrepSQhOMLSZdA3KNT3/4b16b9acccb9c0b8622267ab31ce0141/verizon-colors.png",
    "playlistSlug": "brand-identity-training-video-series",
    "videoSlug": "using-the-verizon-brand-colors",
    "id": "76fc47db-fa41-4d0c-97d6-f76543bd1862",
    "url": "/brand-identity-training-video-series/using-the-verizon-brand-colors",
    "searchType": "training",
    "isFavorite": false,
    "title": "Using the Verizon brand colors",
    "score": 0.8048159,
    "createdOn": "2021-01-06T18:02:25.161Z",
    "modifiedOn": "2023-10-07T17:05:15.506Z"
  },
  {
    "assetId": "a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "description": "This is the primary all-purpose Verizon PowerPoint template with over 50 ready-to-use slides. The template is created in widescreen format (16:9 aspect ratio). \n\nJan. 12, 2024 update: color theme.\nJan. 24, 2024 update:  Safe Harbor statement.\n\nGoogle Slides version is found in the Verizon Template Gallery here: https://docs.google.com/presentation/u/0/?ftv=1&folder=0AAsmHEmojaRCUk9PVA&tgif=d",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "PowerPoint template for all purpose use",
    "files": [
      {
        "id": "7fc28927-336d-4e34-ab27-a89c5f57f856",
        "groupName": "1",
        "fileName": "Verizon__PPT_Template_4.3.potx",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=zNE9j8qC1SQ5Gy2s%2BIm%2BrYBNKIE%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=Q6xz77g%2FJ7E5htg0W0vFbiGC0VY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=5cpuca56d8l83V%2Bu7%2BZrArslBSI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=ai71%2FS%2B7H11bR1Pu6LM39y%2BJTHQ%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "6a23903a-7522-4e2b-8390-ea53fe9ea5fe",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "fe39245d-d069-41df-a8b0-c5f56cb0fa4e"
    ],
    "category": null,
    "downloadCount": 4143,
    "id": "a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "url": "templates/details/a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "searchType": "template",
    "isFavorite": false,
    "title": "PowerPoint template for all purpose use",
    "score": 0.9,
    "createdOn": "2015-10-06T19:58:51Z",
    "modifiedOn": "2024-01-25T19:16:41.688301Z"
  },
  {
    "assetId": "48cdd4eb-555b-41a6-a34a-ab40456423f3",
    "description": "This is the Verizon PowerPoint template to create award and recognition certificates.",
    "userActions": {
      "hasAcceptedUsageRights": false,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "This is usage rights text",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Award and recognition template toolkit",
    "files": [
      {
        "id": "a885316d-6d11-48f8-af3c-7f9503bcecf9",
        "groupName": "",
        "fileName": "Verizon_Award_Template-3.pptx",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=z3z%2F5JMDWKEDdjD%2B%2FfDG1K0eteY%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=2ZGhWdCCM6%2FGVk%2BTcBsiw%2FkRNiA%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=50r0SCn3vT9subreLEVlOPl%2Bzj4%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=gjuK%2BhnMTBNRAoiUgsYXnRhs5n4%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "fe39245d-d069-41df-a8b0-c5f56cb0fa4e",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "7711c433-6032-4d5c-a863-fcbaac958d7c"
    ],
    "category": null,
    "downloadCount": 489,
    "id": "48cdd4eb-555b-41a6-a34a-ab40456423f3",
    "url": "templates/details/48cdd4eb-555b-41a6-a34a-ab40456423f3",
    "searchType": "template",
    "isFavorite": false,
    "title": "Award and recognition template toolkit",
    "score": 0.7264392,
    "createdOn": "2019-06-24T13:17:59Z",
    "modifiedOn": "2023-10-23T21:36:44.680526Z"
  },
  {
    "assetId": "3a8ad2be-31f5-4711-98f9-238cfc63f515",
    "description": "This is a PowerPoint template with options to create a header image (JPG, PNG) to be inserted above an email message.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "PPT template",
    "files": [
      {
        "id": "544d378e-4a82-4db4-8139-c10d83be3852",
        "groupName": "",
        "fileName": "Verizon_email_header_templates-Jun2022.zip",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=lOuCOwc9EDQY7%2BpH3AOSAG3KlFg%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=%2F1STy80P9PYpsnXvAyo0sRTpb2k%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=WCDwAygK%2F8HuY9cgsSKV2L9Vy08%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=M3yK1CwoEOnOWwv0lb73ovRVz38%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "ff22f5d2-a031-4e56-960b-0d40e246b829",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "30235ae7-61f0-4a43-b8db-1fb2c2569a4f",
      "fff16217-7f1c-40d5-b11c-b6d738c6c91c"
    ],
    "category": null,
    "downloadCount": 12,
    "id": "3a8ad2be-31f5-4711-98f9-238cfc63f515",
    "url": "templates/details/3a8ad2be-31f5-4711-98f9-238cfc63f515",
    "searchType": "template",
    "isFavorite": false,
    "title": "Verizon email header template",
    "score": 0.53468865,
    "createdOn": "2020-06-08T17:56:37Z",
    "modifiedOn": "2024-05-23T15:32:39.481799Z"
  },
  {
    "assetId": "fd61185c-eec8-457b-9510-0df3fbe7671c",
    "description": "2019 DAS Updated Exterior Brand Standards",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2027-03-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2019DAS",
    "files": [
      {
        "id": "d93c0ec5-8713-41a7-a0d7-6f6c8d812526",
        "groupName": null,
        "fileName": "Verizon DAS-19-001-Updated Exterior Brand Standards.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=6dgkApw%2FmpMpgjmsFeHT%2BCbOgFc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=%2F%2FBmG4hx8ySsFhxosh84aHWbzFU%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=04rq2HKIUtrSoQ5AS6hPdl%2FCH2U%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=14LWiufyZ3VnHUqFpSTMciKlsSo%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "4c2c35d9-f799-44a9-b75c-cdad2250e484"
    ],
    "category": null,
    "downloadCount": 1,
    "id": "fd61185c-eec8-457b-9510-0df3fbe7671c",
    "url": "retail/branded-environments/design-advisories/details/fd61185c-eec8-457b-9510-0df3fbe7671c",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2019 DAS Updated Exterior Brand Standards",
    "score": 0.9913409,
    "createdOn": "2022-03-15T13:48:11.686193Z",
    "modifiedOn": "2022-05-21T22:24:10.511834Z"
  },
  {
    "assetId": "fcea44cc-3cf9-4719-92d2-40d6f068d06a",
    "description": "2019 DAS Revised Interior Branding Guidelines",
    "userActions": {
      "hasAcceptedUsageRights": false,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "This is usage rights copy.",
    "expiresOn": "2027-05-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2019DAS",
    "files": [
      {
        "id": "10c80f35-b14d-458b-9a60-04c0c9128828",
        "groupName": null,
        "fileName": "Verizon DAS-19-013_Revised Interior Branding Guidelines.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=CDNBP3eZwpr%2FjLFM2jZNmbSwx7M%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=4Al99K8nqTfzqy4aGJgrF%2BSjUSs%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=BRCXd%2FvLE0aFfLh0vdmH%2FehsfGw%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=ZK1FqVWtDyvoIOXne9v6PFefmnE%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "4c2c35d9-f799-44a9-b75c-cdad2250e484",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3"
    ],
    "category": null,
    "downloadCount": 1,
    "id": "fcea44cc-3cf9-4719-92d2-40d6f068d06a",
    "url": "retail/branded-environments/design-advisories/details/fcea44cc-3cf9-4719-92d2-40d6f068d06a",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2019 DAS Revised Interior Branding Guidelines",
    "score": 0.9329889,
    "createdOn": "2022-05-21T22:21:48.609266Z",
    "modifiedOn": "2022-08-27T02:56:41.227939Z"
  },
  {
    "assetId": "176b3edb-196b-4e52-97c7-ac36df3731d9",
    "description": "2020 DAS Updated Interior Branding Technical Guidelines Advisory",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2027-03-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2020 DAS Updated Interior Branding Technical Guidelines Advisory",
    "files": [
      {
        "id": "98c453cd-735a-4ee0-b761-8b56f688f2d9",
        "groupName": null,
        "fileName": "Verizon DAS-20-001_Updated Interior Branding Technical Guidelines Advisory.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=%2BctWnL5U3SaeJ0cL3uq6V0res0A%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=A%2FgrEo50uAssuKxbF0mMtHq%2FJMI%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=JacypS%2F6lBxzIGRnhVn64hNQ3eg%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=IzW3jn%2FqKFru%2Fx5UlVUUQC15SvI%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "87cb4b07-fcbd-453a-8305-b500b9151a3d",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3"
    ],
    "category": null,
    "downloadCount": 4,
    "id": "176b3edb-196b-4e52-97c7-ac36df3731d9",
    "url": "retail/branded-environments/design-advisories/details/176b3edb-196b-4e52-97c7-ac36df3731d9",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2020 DAS Updated Interior Branding Technical Guidelines Advisory",
    "score": 0.8978885,
    "createdOn": "2022-03-15T13:48:11.590403Z",
    "modifiedOn": "2022-05-22T15:50:06.714271Z"
  }
];

export const multipleSelectedAllTypesLastTabOneItem = [
  {
    "assetId": "30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "description": "[Click here to open the Verizon Authorized Retailer Brand Policy](https://brandpolicy.verizon.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzEwNDM4NTYsImlzcyI6ImFjYiIsImp0aSI6IjNmNWU3MzdiLTVhNDYtNDEzMi04Mjc1LTU4ZWRiYzI3OWMxMiJ9.YlbE-WRk5FDg-L4uf9nMTxfD-tXmL2eYTwaiK44yf6A&showHeader=true)\n\nThis site entails the branding ascribed to mandatory and optional touch points along the customer journey at Verizon Authorized Retailers (Indirect agents). It also details the appropriate usage of Verizon logos, advertising templates and additional guidelines pertaining to the desired brand experience.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2028-08-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Authorized Retailer Brand Policy",
    "files": [
      {
        "id": "51f1393d-fa71-4141-a16a-17a83ec16f55",
        "groupName": "",
        "fileName": "Link_for__Authorized_Retailer_Brand_Policy_20221221.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=lkgCI32F6QmseVUTQ1f6SIBs7TI%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=71la8Ly4qyuqBwQIEw1tEaC%2FndE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=nCd800PCTCVVPhd6%2Br2%2F7D2VptU%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLad169c29a77f43f0bfba8a6efe204049_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718479949&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=0vDzcPdZhHtfx0TezpiTb2yJv40%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "b88e3848-074a-4d9b-8d97-72cfbec6c01e",
      "cc617e38-baf9-4b69-a15d-74c4488422a1",
      "7327cd61-0f1d-4875-a93c-1462944a8d19"
    ],
    "category": "Logos",
    "downloadCount": 39,
    "id": "30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "url": "assets/details/30194e26-8bc2-4e39-acfc-e4b20b0b3c54",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Authorized Retailer Brand Policy",
    "score": 0.8208615,
    "createdOn": "2016-08-19T15:12:33Z",
    "modifiedOn": "2022-12-22T15:52:27.172562Z"
  },
  {
    "assetId": "94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "description": "Brand: Gizmo\nModel: Watch 3\nColor: Nylon\nSKU: QTAX57NN",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "this is the usage rights copy",
    "expiresOn": "2025-05-19T18:01:09.752203Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Gizmo Watch 3 Nylon",
    "files": [
      {
        "id": "68cefb03-19b2-452f-8bfd-af98152fd8f6",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=r%2BzQ1QPxSD9D%2F1MUGCKMcTpBwUc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=uNGvwIdzfdqD69dvORhdFl9DFoE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=dA4uq8GgoHeI2klcfd7vnDMjKU8%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLc1738bf5c89942d18b54e8e4eab8dfd3_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=4zRNFW%2BzOoNliQuEqrvlmMXc3Y4%3D",
          "isImage": true
        }
      },
      {
        "id": "9af0656c-3b2f-4985-b4a6-ac7d398fae58",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.png",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=nJ%2Bz0KL8Elr%2BrWZ8kHOH%2F4w2JI4%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=D0stI8pNQ3IpmrwOn9ktU%2FramRE%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=TGojMZDinLSRmnaNvHGLYlxqjkI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL618f373070604e2a9565cac9dcf34a69_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=FiunA3xw1yOa6DNSo6hjiMwK548%3D",
          "isImage": true
        }
      },
      {
        "id": "50361d68-6eaf-4512-9079-a57c69705ee2",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_DIG_RGB_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "13faa07d-30dd-480d-b4d4-55120044e7e0",
        "groupName": "1622150_1",
        "fileName": "VZ_1622150_1_Gizmo Watch 3 Dark Navy Nylon_F_RTL_GR13_SIMP_v3.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "ad6e77fd-4789-41c5-bc41-2172d80a6912",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_DIG_RGB_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      },
      {
        "id": "b4a8eea5-317e-4d70-9c4e-17491b2da33f",
        "groupName": "1622150_2",
        "fileName": "VZ_1622150_2_Gizmo Watch 3 Dark Navy Nylon_FQ_RTL_GR13_SIMP_v2.psd",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=EneyOH9joIPc%2FUikTQ08eg4tZRs%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=c5v8rOV%2B32vDPXlU9MsUh9VcnyY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=h7tD4Cf%2BRbhhELhmI9EMgpCvk58%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLdcd767e836074e38935fb33327934d81_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480044&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=li9RMN%2F0Q35XjETsWBQl4IV6clU%3D",
          "isImage": false
        }
      }
    ],
    "filterIds": [
      "316c0651-c5e3-4bd2-b41c-5427484f74bd",
      "261d3e08-254e-42d1-845c-db7359285823",
      "c45906c2-bf19-4538-adb5-acf860c15d62",
      "36b18590-90a5-464e-85b0-a765a47e7e72",
      "63949a7a-3241-4410-a655-4a3debc9e8d2",
      "25ee6b4e-0e36-445a-8740-7d432afa3e8a",
      "05f5f9a1-1422-468e-86c3-11cd3ecbd15a",
      "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d",
      "37791870-297d-4c3c-8230-d988fa8625b3",
      "c41fa42b-480a-4090-a085-cabcafe8e45b",
      "e550e4e9-dc56-4348-b99a-6e5894ea2521"
    ],
    "category": "Devices & Accessories",
    "downloadCount": 10,
    "id": "94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "url": "assets/details/94160fe0-f6cd-42c6-9b2c-dceefc25ea89",
    "searchType": "asset",
    "isFavorite": false,
    "title": "Gizmo Watch 3 Nylon band",
    "score": 0.8676618,
    "createdOn": "2023-05-19T18:09:38.22071Z",
    "modifiedOn": "2023-07-26T13:47:54.677952Z"
  },
  {
    "assetId": "e7560c32-2926-487a-a430-79e42d364bd7",
    "description": "Individuals or agencies that download and use imagery from Brand Central are responsible for the management and oversight of the asset and its associated usage rights. Individuals using assets beyond the expiration dates will be responsible for all associated payments. The brand team does not monitor imagery use and is not responsible for usage rights. \nSubmit your creative for brand compliance review at verizon.com/brandcompliance",
    "userActions": {
      "hasAcceptedUsageRights": false,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "Usage/Exclusions: Unlimited\nExpiration Date: None",
    "expiresOn": "2026-03-30T04:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "OCR 2019 Fire Fighter",
    "files": [
      {
        "id": "362fbdc7-b366-4316-aab9-fd9803cc8750",
        "groupName": "",
        "fileName": "OCR__BrookeFitts0469-O19in-RGB.jpg",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=5Eusnx2KfQnWleCSHmY1CfHAqCg%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=B0EYbsY2zjOG848%2F6rZTPzV3z7M%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=KN2uztngfxcvzhwrOalYGKL0Y3U%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL8f3571f4d2304568abaf3332acf2cb60_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480092&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=Ku2r44VrTkAqImpsjNXUwIlGoWE%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "76007f55-b998-4883-8e53-64c5ecec4a51",
      "6b34af07-6053-449b-a903-fef3017d741b",
      "f336e575-da93-4c1e-8f10-b61f84b61201",
      "1b48cd71-2d41-4cce-91c9-2879baacd6d5",
      "68139f35-4a11-4ff8-809a-087e467e8995",
      "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
      "990afd3e-7939-4f41-8cb4-279b1189ce29",
      "177c1b1b-cc61-49b0-a389-81fc1a62c320",
      "aa4f0b53-33be-4a2f-bff0-226061fd3001",
      "c8bc00e5-c2c4-4d37-8677-204577875f62",
      "0ce6838e-be32-42c7-b4a1-9382d7e40859",
      "5f2012eb-8a03-43b1-8209-7089100c4bfc",
      "393d0379-3bd6-44eb-93f4-42d7c2e0a295",
      "5822ed2e-6c86-4c2a-abea-be55a7231591",
      "441e77e1-aa06-42d1-ae6e-97112c576fc1",
      "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
      "cddce8a2-074d-48a9-bfc4-16522353e484",
      "b8acea32-28ef-43ee-9ba2-17cff3603481",
      "fe822fad-fd49-434c-8460-58ea6418c10b"
    ],
    "category": "Photography",
    "downloadCount": 1,
    "id": "e7560c32-2926-487a-a430-79e42d364bd7",
    "url": "assets/details/e7560c32-2926-487a-a430-79e42d364bd7",
    "searchType": "asset",
    "isFavorite": false,
    "title": "OCR 2019 Fire Fighter",
    "score": 0.9,
    "createdOn": "2020-03-01T17:28:37Z",
    "modifiedOn": "2024-01-05T19:58:11.64884Z"
  },
  {
    "entityId": "7xQaWpqDKppdT5SXZk3nuq",
    "complianceType": "complianceScoringElement",
    "id": "59629541-aaaa-4f63-945c-14e3048b7aab",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Brand Voice",
    "score": 0.99371,
    "createdOn": "2021-01-15T16:56:57.176Z",
    "modifiedOn": "2023-10-07T17:02:48.964Z"
  },
  {
    "entityId": "5BHx5PWsWC80omYYuXEFUC",
    "complianceType": "complianceScoringElement",
    "id": "109c67ca-0a0c-4a72-b974-9917073387f0",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Color",
    "score": 0.10085287,
    "createdOn": "2021-01-15T13:55:03.556Z",
    "modifiedOn": "2023-10-07T17:02:16.208Z"
  },
  {
    "entityId": "6i9toukVd7DQ7i8E0zkP6x",
    "complianceType": "complianceScoringElement",
    "id": "e500e072-2d15-4b27-b8f8-6de9794d4a7c",
    "url": "/compliance/scoring",
    "searchType": "compliance",
    "isFavorite": false,
    "title": "Logo",
    "score": 0.1,
    "createdOn": "2020-12-21T21:59:59.343Z",
    "modifiedOn": "2023-10-07T17:01:44.434Z"
  },
  {
    "entityId": "16t4NRNbAQ0oYoeeQeQk42",
    "id": "e0da9c2a-97a6-4af7-a6ec-59ce801c134e",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/parent-brand-elements/color/brand-colors",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Color: Brand colors",
    "score": 0.9074147,
    "createdOn": "2018-04-09T15:50:43.305Z",
    "modifiedOn": "2023-11-14T16:02:39.544Z"
  },
  {
    "entityId": "4eOAqLDbpYqgMOAayIEOMY",
    "id": "89d0fe9f-1b9a-4a85-9fee-18ce932a71f3",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/parent-brand-elements/motion/branded-moments",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Motion: Branded moments",
    "score": 0.78391856,
    "createdOn": "2018-04-09T15:52:38.555Z",
    "modifiedOn": "2023-01-31T21:32:19.422Z"
  },
  {
    "entityId": "2PEg1CNnn7FiD1kLVZcN3l",
    "id": "2c742b6a-61be-4beb-b34f-5b3b4092941a",
    "url": "https://brandcentral.verizonwireless.com/guidelines/details/digital/announcements/brand3.0-is-here",
    "searchType": "guideline",
    "isFavorite": false,
    "title": "Digital: Brand 3.0 launch",
    "score": 0.7562663,
    "createdOn": "2022-09-28T20:32:10.911Z",
    "modifiedOn": "2023-01-31T20:17:59.854Z"
  },
  {
    "entityId": "4cGPwPwK8L9grGZzk6nGzW",
    "thumbnailAssetUrl": "https://images.ctfassets.net/ve6konwi38y5/3PVEK9qKGPtvitbENZArcm/8c538ff8979db0f8eb19edec6f6afdbc/verizon-brand-compliance.png",
    "playlistSlug": "brand-identity-training-video-series",
    "videoSlug": "brand-compliance",
    "id": "b0c094a7-dba3-4cd4-8f6c-87842d32d13a",
    "url": "/brand-identity-training-video-series/brand-compliance",
    "searchType": "training",
    "isFavorite": false,
    "title": "Brand compliance",
    "score": 1,
    "createdOn": "2021-01-06T18:25:33.962Z",
    "modifiedOn": "2023-10-07T17:05:46.599Z"
  },
  {
    "assetId": "a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "description": "This is the primary all-purpose Verizon PowerPoint template with over 50 ready-to-use slides. The template is created in widescreen format (16:9 aspect ratio). \n\nJan. 12, 2024 update: color theme.\nJan. 24, 2024 update:  Safe Harbor statement.\n\nGoogle Slides version is found in the Verizon Template Gallery here: https://docs.google.com/presentation/u/0/?ftv=1&folder=0AAsmHEmojaRCUk9PVA&tgif=d",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "PowerPoint template for all purpose use",
    "files": [
      {
        "id": "7fc28927-336d-4e34-ab27-a89c5f57f856",
        "groupName": "1",
        "fileName": "Verizon__PPT_Template_4.3.potx",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=zNE9j8qC1SQ5Gy2s%2BIm%2BrYBNKIE%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=Q6xz77g%2FJ7E5htg0W0vFbiGC0VY%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=5cpuca56d8l83V%2Bu7%2BZrArslBSI%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6768ea28a6824f3fac80b7d7433f260c_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=ai71%2FS%2B7H11bR1Pu6LM39y%2BJTHQ%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "6a23903a-7522-4e2b-8390-ea53fe9ea5fe",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "fe39245d-d069-41df-a8b0-c5f56cb0fa4e"
    ],
    "category": null,
    "downloadCount": 4143,
    "id": "a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "url": "templates/details/a8a70046-fdeb-465d-b4a6-afa8469fb44f",
    "searchType": "template",
    "isFavorite": false,
    "title": "PowerPoint template for all purpose use",
    "score": 0.9,
    "createdOn": "2015-10-06T19:58:51Z",
    "modifiedOn": "2024-01-25T19:16:41.688301Z"
  },
  {
    "assetId": "48cdd4eb-555b-41a6-a34a-ab40456423f3",
    "description": "This is the Verizon PowerPoint template to create award and recognition certificates.",
    "userActions": {
      "hasAcceptedUsageRights": false,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "This is usage rights text",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "Award and recognition template toolkit",
    "files": [
      {
        "id": "a885316d-6d11-48f8-af3c-7f9503bcecf9",
        "groupName": "",
        "fileName": "Verizon_Award_Template-3.pptx",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=z3z%2F5JMDWKEDdjD%2B%2FfDG1K0eteY%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=2ZGhWdCCM6%2FGVk%2BTcBsiw%2FkRNiA%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=50r0SCn3vT9subreLEVlOPl%2Bzj4%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL9fb5f42f497f4506a9c05113a634b034_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=gjuK%2BhnMTBNRAoiUgsYXnRhs5n4%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "fe39245d-d069-41df-a8b0-c5f56cb0fa4e",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "7711c433-6032-4d5c-a863-fcbaac958d7c"
    ],
    "category": null,
    "downloadCount": 489,
    "id": "48cdd4eb-555b-41a6-a34a-ab40456423f3",
    "url": "templates/details/48cdd4eb-555b-41a6-a34a-ab40456423f3",
    "searchType": "template",
    "isFavorite": false,
    "title": "Award and recognition template toolkit",
    "score": 0.7264392,
    "createdOn": "2019-06-24T13:17:59Z",
    "modifiedOn": "2023-10-23T21:36:44.680526Z"
  },
  {
    "assetId": "3a8ad2be-31f5-4711-98f9-238cfc63f515",
    "description": "This is a PowerPoint template with options to create a header image (JPG, PNG) to be inserted above an email message.",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2026-11-07T05:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "PPT template",
    "files": [
      {
        "id": "544d378e-4a82-4db4-8139-c10d83be3852",
        "groupName": "",
        "fileName": "Verizon_email_header_templates-Jun2022.zip",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_110.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=lOuCOwc9EDQY7%2BpH3AOSAG3KlFg%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_400.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=%2F1STy80P9PYpsnXvAyo0sRTpb2k%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_500.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=WCDwAygK%2F8HuY9cgsSKV2L9Vy08%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL2214b963c31145e5b556d616ca173a01_700.png?AWSAccessKeyId=ASIAUHQDK3LLOWTDHFUY&Expires=1718480504&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDkoysKecnHo0cSrm1qaXDxRH%2BggQ5vkmpEMN9vAVAwqAIhAPl6So4oB9LpOL0JIRmiWxMHgxzu798LnGAFbpFHy%2F%2BRKoMECEoQBBoMMjkwOTkxMDM3MTQyIgyNp0hZb1cMuoYagrIq4AM2VNIlclw0jQlkR1YkXhFppFYPz3GnDQ4Ml1oChJTz%2F%2BCYExbFQLO%2F2JpjnbueQDFvYcBKKtXvkQCZAewGPZuRK6s%2BmuVPKF21Fo8Yk7QdRArm%2F08VqT6HQhzs3Rvb%2BWHXvgDRSZN3Le0eGnj22pgSPkhWFUfM5hxPPK%2BHFBKJk3zIHoy1MP3GBopuptLNGE%2FmWV4qvaYxHls0XabdUrN0bmspvDAa%2BVUy1welBFW1D5jvXydYbhR%2B7rIZ3gar7N8547SgCBmohvriEaF2VnDuPWGw%2BGqYkOa5oNRmn%2F6d7%2Fs0VBTXx6mHcLPQmVeZfmLy7gl6vvdzb104PpSrcL%2BkW1Vg6A2h3h7W3HIv81rq779bGEpGjtCWGScKLWzPtbhM%2FqCFIOGDqgpjkt%2FWDWE5gaT8Pq1hBbViXKZv%2FQvf4%2B2VKArTfFw7F4R2evQW9Pov9zgv3NkEz%2BxKnnlZpm%2FbnrN5Jqv0d2qAGO5hkYGAhnbx4I5BqCEWLw5zGG9rWrtoxnm5ygd9LDUvLwjM8W8watqoUhFgnxThC9ASM582ozMYYW8Ze8Y%2F%2BEfyX7OCRmjpJmzAFVuzl5xZ2JdP2GAfz52GhgdsKIvvzZvPTKgLx5%2FDwbqIdb9bWfc3ytzRryUw6uicswY6pAFrHfWGCiU9Z0VJe%2FqCMHsf2CBIVzuKpoviX3z%2BadT4DUvOdZXnuBeZbnU53e6Wlr7a9CW2reimhF2TCSfNmpaQ7%2FjbbURzNw2%2Fb2qmhPmZFibuhD2Ef97G%2BPnScMrrIU7h4WV4zSteUo9EBcEzSrf4fqZgBJjxAU%2BZ5srbEPx1Hl5GxHC49JyokNqIOm%2Brj%2FPl7SviRXAV8Az1pCvLc7qkW7Z%2FHg%3D%3D&Signature=M3yK1CwoEOnOWwv0lb73ovRVz38%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "5d36495d-8cd4-4303-bebd-e6f5639438aa",
      "ce18cedf-3422-4f4f-80dc-dc23ece17492",
      "ff22f5d2-a031-4e56-960b-0d40e246b829",
      "ec192677-e594-47ad-ac36-b55a8a505650",
      "30235ae7-61f0-4a43-b8db-1fb2c2569a4f",
      "fff16217-7f1c-40d5-b11c-b6d738c6c91c"
    ],
    "category": null,
    "downloadCount": 12,
    "id": "3a8ad2be-31f5-4711-98f9-238cfc63f515",
    "url": "templates/details/3a8ad2be-31f5-4711-98f9-238cfc63f515",
    "searchType": "template",
    "isFavorite": false,
    "title": "Verizon email header template",
    "score": 0.53468865,
    "createdOn": "2020-06-08T17:56:37Z",
    "modifiedOn": "2024-05-23T15:32:39.481799Z"
  },
  {
    "assetId": "fd61185c-eec8-457b-9510-0df3fbe7671c",
    "description": "2019 DAS Updated Exterior Brand Standards",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2027-03-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2019DAS",
    "files": [
      {
        "id": "d93c0ec5-8713-41a7-a0d7-6f6c8d812526",
        "groupName": null,
        "fileName": "Verizon DAS-19-001-Updated Exterior Brand Standards.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=6dgkApw%2FmpMpgjmsFeHT%2BCbOgFc%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=%2F%2FBmG4hx8ySsFhxosh84aHWbzFU%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=04rq2HKIUtrSoQ5AS6hPdl%2FCH2U%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RL6f688082c47e4ab0a227b132ab3f26b2_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=14LWiufyZ3VnHUqFpSTMciKlsSo%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "4c2c35d9-f799-44a9-b75c-cdad2250e484"
    ],
    "category": null,
    "downloadCount": 1,
    "id": "fd61185c-eec8-457b-9510-0df3fbe7671c",
    "url": "retail/branded-environments/design-advisories/details/fd61185c-eec8-457b-9510-0df3fbe7671c",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2019 DAS Updated Exterior Brand Standards",
    "score": 0.9913409,
    "createdOn": "2022-03-15T13:48:11.686193Z",
    "modifiedOn": "2022-05-21T22:24:10.511834Z"
  },
  {
    "assetId": "fcea44cc-3cf9-4719-92d2-40d6f068d06a",
    "description": "2019 DAS Revised Interior Branding Guidelines",
    "userActions": {
      "hasAcceptedUsageRights": false,
      "hasPendingAccessRequest": false,
      "canDownload": false,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "This is usage rights copy.",
    "expiresOn": "2027-05-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2019DAS",
    "files": [
      {
        "id": "10c80f35-b14d-458b-9a60-04c0c9128828",
        "groupName": null,
        "fileName": "Verizon DAS-19-013_Revised Interior Branding Guidelines.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=CDNBP3eZwpr%2FjLFM2jZNmbSwx7M%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=4Al99K8nqTfzqy4aGJgrF%2BSjUSs%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=BRCXd%2FvLE0aFfLh0vdmH%2FehsfGw%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLb2a10eb6e8144bccafec43be18a0c764_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=ZK1FqVWtDyvoIOXne9v6PFefmnE%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "4c2c35d9-f799-44a9-b75c-cdad2250e484",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3"
    ],
    "category": null,
    "downloadCount": 1,
    "id": "fcea44cc-3cf9-4719-92d2-40d6f068d06a",
    "url": "retail/branded-environments/design-advisories/details/fcea44cc-3cf9-4719-92d2-40d6f068d06a",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2019 DAS Revised Interior Branding Guidelines",
    "score": 0.9329889,
    "createdOn": "2022-05-21T22:21:48.609266Z",
    "modifiedOn": "2022-08-27T02:56:41.227939Z"
  },
  {
    "assetId": "176b3edb-196b-4e52-97c7-ac36df3731d9",
    "description": "2020 DAS Updated Interior Branding Technical Guidelines Advisory",
    "userActions": {
      "hasAcceptedUsageRights": true,
      "hasPendingAccessRequest": false,
      "canDownload": true,
      "canEdit": true,
      "canDeactivate": true,
      "canExpire": true,
      "canUpdateAssetAccessRequest": true,
      "isAdmin": true
    },
    "usageRightsCopy": "",
    "expiresOn": "2027-03-31T00:00:00Z",
    "isRestricted": false,
    "isSearchable": true,
    "altText": "2020 DAS Updated Interior Branding Technical Guidelines Advisory",
    "files": [
      {
        "id": "98c453cd-735a-4ee0-b761-8b56f688f2d9",
        "groupName": null,
        "fileName": "Verizon DAS-20-001_Updated Interior Branding Technical Guidelines Advisory.pdf",
        "approvalStatus": "Approved",
        "thumbnails": {
          "small": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_110.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=%2BctWnL5U3SaeJ0cL3uq6V0res0A%3D",
          "medium": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_400.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=A%2FgrEo50uAssuKxbF0mMtHq%2FJMI%3D",
          "large": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_500.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=JacypS%2F6lBxzIGRnhVn64hNQ3eg%3D",
          "xLarge": "https://brandcentral-rl-prod.s3.amazonaws.com/assets/Thumb_png/RLabe93d0099164d64838fca13261dba83_700.png?AWSAccessKeyId=ASIAUHQDK3LLKXTTP2UL&Expires=1718480604&x-amz-security-token=IQoJb3JpZ2luX2VjELL%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBl1oPV%2BsdaipWR3Ce4%2FPviezB12vVAILWStWGMw727NAiBpcVtqsJngRiAExVbyZWvnrcMaJ8nTnHZB4G2t1u1ACCqDBAhLEAQaDDI5MDk5MTAzNzE0MiIMANgMuKc1YAdeldt%2BKuAD8%2BKjGeROGIeR%2BbsQcfdS%2Fbl0NLZm5kGmlIlwqdhIfBysj9Hly%2Ba%2F1CB1g8121%2FmbHKakshSSJoKz9SBra8%2B90fxgf1uSbYaAELFuC3rJRDsmnDM9rxQDzCnnybcz%2B1cFsEXjX7eZ%2FykE84%2BCussezmkSVWdoNPgC1Hdg6y27%2B1t37OxZhGqieI%2B1Cnfj93e%2FYBNogrNviuLlOevNyuzHDd0Ja8GaOxXKFTrAa3wlwOxIOesq1AozrLw99TKYEf1eNYqtGDbaXTo32sdz4vspg%2BXg2JWv5eOtRqakHRZUHDmAyEIW1QV1O%2BBq2RR87slya3Zpv43zVkt5KOyUqYhLotXytsE6dPyF0%2B6VGnXQRYBIHC%2FBRWPeX5KZqRB1BYYexqfX9E1v9DBmXQahziecVzYJjnpSXi%2BOdpoLFsbJskyYXnwMq4ZlV1P4eHduxbfoUOt10LnD5GGQiYgQ8%2FAAMMFsI1505cZUm7UJR24N7%2BI2eItiqcHAIXUPYh7bhx2oeDRrdFuZFcW%2F5E%2FFki21gCnMyGEv5ZKDPP1xChimPw5amQecSWUqpaxLSszCth58VqR%2FjAux%2FKYMYLw6gDvhFg9jatgXJfpIpjfsnllcc1sMdVEtEFwDdShyL695m4WvMIzunLMGOqYBniwkrb%2BimjW263%2Fu%2FjYkYYC4A8jLRcxi5B%2Brj9L%2F1d5pp9bTXxh1LSfqamvlo%2FRzfvW7ipIiC1xAeGjmPdszqtgyA143VoCHY7UX0h92AVVgiZSIjy7pUcWaOqviugr5t%2FHGt00FZolHYfVPH%2BrZ9KrlM7hWLIJOfNfUwkVDw65icO0AsKAKeLJAVbOiInet7h2WSgSZ4L63Vz5%2FQyfK8g%2BrpECoNg%3D%3D&Signature=IzW3jn%2FqKFru%2Fx5UlVUUQC15SvI%3D",
          "isImage": true
        }
      }
    ],
    "filterIds": [
      "6f55dd6b-6795-497b-b990-0d0ae6d9e803",
      "c0fe24a2-b49e-4f52-9220-196c419272f5",
      "87cb4b07-fcbd-453a-8305-b500b9151a3d",
      "ccc1fc56-ff61-48cb-8789-bec4abaf7eab",
      "cda44f2f-980c-4f67-848b-603412cc0df3"
    ],
    "category": null,
    "downloadCount": 4,
    "id": "176b3edb-196b-4e52-97c7-ac36df3731d9",
    "url": "retail/branded-environments/design-advisories/details/176b3edb-196b-4e52-97c7-ac36df3731d9",
    "searchType": "retail",
    "isFavorite": false,
    "title": "2020 DAS Updated Interior Branding Technical Guidelines Advisory",
    "score": 0.8978885,
    "createdOn": "2022-03-15T13:48:11.590403Z",
    "modifiedOn": "2022-05-22T15:50:06.714271Z"
  }
];

export const initialMobileSearchSettings = {
  "subcategories": [],
  "selectedSearchTypes": [
    "All"
  ],
  "selectedCategories": [],
  "selectedCategory": 0,
  "selectedSearchType": 0,
  "selectedCategoryId": null,
  "categoryOptions": [],
  "categories": [],
  "correlationId": "",
  "savedSearch": null,
  "savedSearchApplied": false,
  "filters": {
    "restricted": true,
    "expired": false,
    "expiresAfter": false,
    "expiresOn": null,
    "selectedTags": [],
    "submit": false
  }
};

export const assetsWithTagsAndFiltersMobileSearchSettings = {
  "subcategories": [
    {
      "id": "a22f39d0-4876-496c-93a6-14fe4011dc8d",
      "description": "Age",
      "value": "Age",
      "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
      "tags": [
        {
          "id": "8e04ac67-b08a-4f88-ad39-194dd6d80f02",
          "description": "Adult 32-50",
          "value": "Adult 32-50",
          "subCategoryId": "a22f39d0-4876-496c-93a6-14fe4011dc8d"
        }
      ]
    }
  ],
  "selectedSearchTypes": [
    "asset"
  ],
  "selectedCategories": [
    "76007f55-b998-4883-8e53-64c5ecec4a51"
  ],
  "selectedCategory": 7,
  "selectedSearchType": 1,
  "selectedCategoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
  "categoryOptions": [
    {
      "label": "All",
      "value": 0
    },
    {
      "label": "Campaigns, Creative & Playbooks",
      "value": 1
    },
    {
      "label": "Devices & Accessories",
      "value": 2
    },
    {
      "label": "Employee Communications",
      "value": 3
    },
    {
      "label": "Entertainment Assets",
      "value": 4
    },
    {
      "label": "Iconography",
      "value": 5
    },
    {
      "label": "Logos",
      "value": 6
    },
    {
      "label": "Photography",
      "value": 7
    }
  ],
  "categories": [
    {
      "id": "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
      "description": "Campaigns, Creative & Playbooks",
      "value": "Campaigns, Creative & Playbooks",
      "subCategories": [
        {
          "id": "5fee2f58-eac4-4d3d-8e01-52ad5aed2479",
          "description": "Market",
          "value": "Market",
          "categoryId": "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
          "tags": []
        },
        {
          "id": "c51db643-479b-46a3-b3ea-4d5c23f0db1d",
          "description": "test",
          "value": "test",
          "categoryId": "96d3d20f-2161-4c7e-b05e-62e8b00e4b7f",
          "tags": [
            {
              "id": "00b2f403-8017-4498-b591-5e7dbac2f47c",
              "description": "test tag",
              "value": "test tag",
              "subCategoryId": "c51db643-479b-46a3-b3ea-4d5c23f0db1d"
            }
          ]
        }
      ]
    },
    {
      "id": "316c0651-c5e3-4bd2-b41c-5427484f74bd",
      "description": "Devices & Accessories",
      "value": "Devices & Accessories",
      "subCategories": [
        {
          "id": "36b18590-90a5-464e-85b0-a765a47e7e72",
          "description": "Accessory Type",
          "value": "Accessory Type",
          "categoryId": "316c0651-c5e3-4bd2-b41c-5427484f74bd",
          "tags": [
            {
              "id": "541e31a0-b794-4004-b5d3-2e7ffa5ab2c7",
              "description": "Adapters",
              "value": "Adapters",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "1f1042fe-789f-4997-b1ac-047743c90c57",
              "description": "Antenna",
              "value": "Antenna",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "4a304e58-0e66-4130-9b92-72734694f576",
              "description": "Batteries",
              "value": "Batteries",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "1aa5d204-6b34-46e9-8971-401675f98da6",
              "description": "Bluetooth",
              "value": "Bluetooth",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "f4bdd9d7-5368-4cf8-a3dc-3e29cbd573db",
              "description": "Bundles",
              "value": "Bundles",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "9f7d7c69-79d4-4d2f-bef9-e99172c06284",
              "description": "Cables",
              "value": "Cables",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "090c26a0-33a3-41f4-82bf-e52108eca019",
              "description": "Camera",
              "value": "Camera",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "5815e843-ab07-4efe-b8c6-0395e24f5337",
              "description": "Cases, Covers & Holsters",
              "value": "Cases, Covers & Holsters",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "49499657-87e8-4244-922c-57aa13551db2",
              "description": "Chargers",
              "value": "Chargers",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "9dac8dff-1c30-4bef-aa65-a642cde6f023",
              "description": "Display Protection",
              "value": "Display Protection",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "9af3b7ae-3b8e-459f-b584-b32b8c481b37",
              "description": "Docks",
              "value": "Docks",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "207b4db9-5363-453e-8d5c-3a7b22c39ac5",
              "description": "Headphones / Headsets",
              "value": "Headphones / Headsets",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "9ca845c7-7891-406c-a9f9-9d6e86008f94",
              "description": "Keyboards",
              "value": "Keyboards",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "cfb7311c-a5d4-49ff-aca3-06e415de19ec",
              "description": "Memory cards & Readers",
              "value": "Memory cards & Readers",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "b5874891-b3a4-4ac6-82fd-93d53af69700",
              "description": "Mobile Gaming",
              "value": "Mobile Gaming",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "909834c3-b812-44ef-9686-59538b17be2e",
              "description": "Monitors",
              "value": "Monitors",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "ee78bfef-1621-4225-91d0-5f30262938eb",
              "description": "Mounts",
              "value": "Mounts",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "264d2b1b-60f8-44e9-9276-7c3eece1ac98",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "929c4408-33a9-4849-bea9-bd489c96fb1e",
              "description": "Router",
              "value": "Router",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "3121848c-10c4-4bf2-96d3-a6c0f240dd7c",
              "description": "Signal boosters",
              "value": "Signal boosters",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "446310a7-a4eb-44af-aec1-6d9597a5dac2",
              "description": "Smart Accessories",
              "value": "Smart Accessories",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "8e97627a-0d54-4a7d-a4a2-3425693b9e4f",
              "description": "Speakers",
              "value": "Speakers",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "d85db58c-0d4b-4229-92db-3e420a935b96",
              "description": "Sports & Fitness",
              "value": "Sports & Fitness",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "bc5d37cc-07b4-47f3-8e92-42f9e1ae5ef9",
              "description": "Stands",
              "value": "Stands",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "8610d88c-fdd6-4e4f-bc61-71efb5c7a94f",
              "description": "Stylus",
              "value": "Stylus",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            },
            {
              "id": "db9faba1-5a42-4b53-bac0-80d839dc7e24",
              "description": "Toys",
              "value": "Toys",
              "subCategoryId": "36b18590-90a5-464e-85b0-a765a47e7e72"
            }
          ]
        },
        {
          "id": "25ee6b4e-0e36-445a-8740-7d432afa3e8a",
          "description": "Brand",
          "value": "Brand",
          "categoryId": "316c0651-c5e3-4bd2-b41c-5427484f74bd",
          "tags": [
            {
              "id": "07894662-f956-4182-8fdd-0efedc349a00",
              "description": "Adopted",
              "value": "Adopted",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "1cf79ab9-d90a-4796-a4d4-e84b3b7d0c85",
              "description": "Altec Lansing",
              "value": "Altec Lansing",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "48018f84-1fd8-4165-8078-3d83908f22d4",
              "description": "Amazon",
              "value": "Amazon",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "4a483fcf-e022-4c73-9bcb-27924cd7d4c5",
              "description": "Apple",
              "value": "Apple",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "02471a5d-a9fd-4cfd-b0ca-afec49173a8c",
              "description": "Beats",
              "value": "Beats",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "ca9062c5-a166-4e74-8ab4-ff80b2d950e7",
              "description": "Belkin",
              "value": "Belkin",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "79d8a942-d00e-4b7b-abe2-f704bca345ab",
              "description": "BlackBerry",
              "value": "BlackBerry",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "ce2d39be-7da2-4590-a81d-5fb4d76497a1",
              "description": "Body Glove",
              "value": "Body Glove",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "65500862-d02b-4862-874c-2d7793d7ce31",
              "description": "Bose",
              "value": "Bose",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "f8460d9d-5f57-42f0-b429-05f4f24eba55",
              "description": "Casio",
              "value": "Casio",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "e159c13f-591c-44e0-bbd0-839fc052ebac",
              "description": "Compaq",
              "value": "Compaq",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "d14b9ed1-32c2-4717-9e72-22e7f0f9c38e",
              "description": "Dell",
              "value": "Dell",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "edaa3ef6-55a0-42dc-b1e6-e4760aa99a9b",
              "description": "Delphi",
              "value": "Delphi",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "5251383f-61db-4dc9-be28-dca806f67876",
              "description": "Fitbit",
              "value": "Fitbit",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "b00ebf56-b2cf-489a-8140-73c30e866813",
              "description": "Gateway",
              "value": "Gateway",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "def5f5b1-dd07-4c2b-9344-155126d035e8",
              "description": "GoPro",
              "value": "GoPro",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "cbe0d6af-e528-4942-8426-7138aed0f862",
              "description": "Google",
              "value": "Google",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "6720341a-f616-4197-b732-f33b3b73b9a6",
              "description": "Griffin",
              "value": "Griffin",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "be7a70a8-33a9-4040-b8e8-ee42b8e827e2",
              "description": "HP",
              "value": "HP",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "bf964926-9e08-4df6-a387-29f9818aa262",
              "description": "HTC",
              "value": "HTC",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "cd42f224-930e-4df3-a0ae-fc679292b4b9",
              "description": "Honeywell",
              "value": "Honeywell",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "312a31b2-3e25-46c3-a939-385220c578ae",
              "description": "Incase",
              "value": "Incase",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "ed4fe8ac-2616-4efb-9276-759314ca51aa",
              "description": "Incipio",
              "value": "Incipio",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "4b653c90-8bf2-4b73-97a4-5b23f2b7c95f",
              "description": "Jabra",
              "value": "Jabra",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "70a567fd-fbc3-43b5-94ca-8501f11daa5b",
              "description": "Jawbone",
              "value": "Jawbone",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "caf2e7ba-d16a-4a42-b37a-125c88c57a59",
              "description": "Kyocera",
              "value": "Kyocera",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "dbc3760e-07c1-4e7d-b56c-0e575e45c4b9",
              "description": "LG",
              "value": "LG",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "8996fe93-b20b-49a8-b115-c9587591df6b",
              "description": "Lifeproof",
              "value": "Lifeproof",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "7c998121-641b-4991-ba0a-2ac88f0bb460",
              "description": "Micani",
              "value": "Micani",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "0f1c0f23-4773-47e8-89a0-071c5850ea8d",
              "description": "Mophie",
              "value": "Mophie",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "015323e6-6d03-4717-abb4-d81097d50d97",
              "description": "Motorola",
              "value": "Motorola",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "3cab8bc4-ad80-4338-9ca3-8a2ee234720f",
              "description": "Nokia",
              "value": "Nokia",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "cbbcfbc6-37c2-4c7e-b933-94ed6ecec70c",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "2cf175aa-b3c2-4372-b9fd-837e5fc1e9dc",
              "description": "OtterBox",
              "value": "OtterBox",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "dcc68c80-e564-44a2-8d56-bf64833bdb4f",
              "description": "Palm",
              "value": "Palm",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "8cb8780a-add5-415b-a484-ebda21b0f093",
              "description": "Pantech",
              "value": "Pantech",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "81718a84-5a49-4411-8b6b-0bbcce619b44",
              "description": "Plantronics",
              "value": "Plantronics",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "37fc3fc2-2458-4ed7-9b79-fe4866aada83",
              "description": "Samsung",
              "value": "Samsung",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "d8194509-3b4e-498e-8a23-cd4a2a3b9205",
              "description": "SanDisk",
              "value": "SanDisk",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "cf517ce9-576c-4074-9d07-2a48869fea00",
              "description": "Scosche",
              "value": "Scosche",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "2b83d7d1-3d11-45a9-ac94-ae7a6b321a9e",
              "description": "Sharp",
              "value": "Sharp",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "e65c3a2d-5c10-4fab-8d56-001b6ba34580",
              "description": "Skullcandy",
              "value": "Skullcandy",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "cbbe9d5f-5fef-4825-97d0-3ddaf69dabd4",
              "description": "Sol Republic",
              "value": "Sol Republic",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "70198542-47b1-4b14-a777-36acf0fdbc30",
              "description": "Sony Ericsson",
              "value": "Sony Ericsson",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "b1cbdcf5-de65-41a1-8070-765590662226",
              "description": "Speck",
              "value": "Speck",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "6829cb11-1b28-4428-a6bb-9d95623239f7",
              "description": "Targus",
              "value": "Targus",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "3a074997-a5fe-4b6a-812d-025ac8d8cd03",
              "description": "USA Wireless",
              "value": "USA Wireless",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "22868b43-90ec-4008-a00c-568da9eaaf5d",
              "description": "Verizon",
              "value": "Verizon",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "f3af6294-3220-4867-9b01-6554c876fec8",
              "description": "Viva Movil",
              "value": "Viva Movil",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "dfc3c571-215e-4d05-a002-b4bb1cefcc50",
              "description": "Wicked",
              "value": "Wicked",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "e2e2cda0-7fde-4bb1-bb2f-844cf895b5bb",
              "description": "Windows Phone",
              "value": "Windows Phone",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "450f35b4-7a59-4428-ad83-01247a361b0a",
              "description": "Withings",
              "value": "Withings",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "ea5e2474-4fd3-4aa4-9d84-1d50118fb120",
              "description": "Xentris",
              "value": "Xentris",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "8af20e34-6cc5-4eb1-b161-0351361fa8e3",
              "description": "Yurbuds",
              "value": "Yurbuds",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "aed74aca-b8c2-4b22-8cc8-4acd6604b4ad",
              "description": "ZAGG",
              "value": "ZAGG",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "bbc02379-34a2-4f85-bb79-bb29acb524a7",
              "description": "ZTE",
              "value": "ZTE",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "eb453f9d-deb3-4785-a983-942087e55b9c",
              "description": "iGO",
              "value": "iGO",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            },
            {
              "id": "098f82b1-f18d-4a1c-a632-964ca6b9abcf",
              "description": "iHip",
              "value": "iHip",
              "subCategoryId": "25ee6b4e-0e36-445a-8740-7d432afa3e8a"
            }
          ]
        },
        {
          "id": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d",
          "description": "Color",
          "value": "Color",
          "categoryId": "316c0651-c5e3-4bd2-b41c-5427484f74bd",
          "tags": [
            {
              "id": "03a43581-852c-4bdb-a75c-27321bce2057",
              "description": "Black",
              "value": "Black",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "37791870-297d-4c3c-8230-d988fa8625b3",
              "description": "Blue",
              "value": "Blue",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "c6f47e62-8ada-4b40-a14a-eb1cb93ce6aa",
              "description": "Brown",
              "value": "Brown",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "b152e9bc-ebed-4f03-82c7-248495aeffc4",
              "description": "Clear",
              "value": "Clear",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "15467f17-b42b-4481-b5e5-a78f9eccb519",
              "description": "Gold",
              "value": "Gold",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "94115aab-a5e3-458a-89f4-6dc4dc6d5a10",
              "description": "Gray",
              "value": "Gray",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "a86a4f9a-ba0d-4bf1-baf8-6418208d03a7",
              "description": "Green",
              "value": "Green",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "53cd017a-e70a-4b96-864b-7c30bcfc3d1e",
              "description": "Gun Metal",
              "value": "Gun Metal",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "99b7b561-f809-4314-a93d-d41eaa02380c",
              "description": "Nikka",
              "value": "Nikka",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "8c9b3dd9-405c-4f17-8ec0-0de349309028",
              "description": "Orange",
              "value": "Orange",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "5d919b32-57f2-4c78-b112-955fbc445eb9",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "e8e3a864-4d78-4f99-910a-b291639a0ba6",
              "description": "Pink",
              "value": "Pink",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "8b1006d5-65a6-4030-aec9-6d627533b3cb",
              "description": "Platinum",
              "value": "Platinum",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "54dc1f4e-4d9f-4159-bb05-05d68acc6a23",
              "description": "Purple",
              "value": "Purple",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "c88e20f3-3b6c-4461-a1c9-a630fdd26454",
              "description": "Rainbow",
              "value": "Rainbow",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "cead4f36-1548-4f76-9455-fcac5de0d144",
              "description": "Red",
              "value": "Red",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "d793cd99-2875-4fe6-bdeb-7f374ec9a0e0",
              "description": "Silver",
              "value": "Silver",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "326a2f0f-f0ff-4bed-963a-03dfa23846ad",
              "description": "Tan",
              "value": "Tan",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "40ea5855-df40-44cd-861e-fb8605548906",
              "description": "Teal",
              "value": "Teal",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "2f80117e-a358-448a-843d-3cc326c26425",
              "description": "White",
              "value": "White",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            },
            {
              "id": "10c30423-d53c-428d-b0d0-d0bc9b3046a3",
              "description": "Yellow",
              "value": "Yellow",
              "subCategoryId": "7db379ef-ebd8-416f-98fb-8b6fd5ac9a2d"
            }
          ]
        },
        {
          "id": "261d3e08-254e-42d1-845c-db7359285823",
          "description": "Device Type",
          "value": "Device Type",
          "categoryId": "316c0651-c5e3-4bd2-b41c-5427484f74bd",
          "tags": [
            {
              "id": "1c85f0fb-0d71-48c7-9d35-8d265aa37488",
              "description": "4G Laptops",
              "value": "4G Laptops",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "9c67a50a-cadc-436f-b5a3-5f43d22ec473",
              "description": "4G Smartphone",
              "value": "4G Smartphone",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "b55bd8f1-5d89-4b9e-b481-107c6212ceb9",
              "description": "4G Tablets",
              "value": "4G Tablets",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "bc97af03-17eb-4e6a-8046-72cb90aaba84",
              "description": "5G Laptops",
              "value": "5G Laptops",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "92d65b91-6393-495a-adad-02acaad03051",
              "description": "5G Smartphone",
              "value": "5G Smartphone",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "fb6450ab-47bb-42aa-b286-9bb03f148e39",
              "description": "5G Tablets",
              "value": "5G Tablets",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "722d40c6-11a4-4056-aeff-b569d255159b",
              "description": "Accessory Image",
              "value": "Accessory Image",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "d3975850-d181-45b9-8b9b-78393aba4878",
              "description": "Basic Phones",
              "value": "Basic Phones",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "90c26938-a33a-4387-b465-ded8a61daa74",
              "description": "Home Services",
              "value": "Home Services",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "8bee6688-4cc3-4e9a-bbfa-53f34191e884",
              "description": "Internet Devices",
              "value": "Internet Devices",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "e34ff5e4-2194-4a86-89b9-c1f420dd21e4",
              "description": "Mobile Hotspots",
              "value": "Mobile Hotspots",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "8198636a-ad1a-4bb7-92af-c25e88c07cdb",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "ec9db031-8426-47f2-9e2f-8c4891076a7d",
              "description": "USB Modems",
              "value": "USB Modems",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            },
            {
              "id": "640ea0d5-68b3-493d-8277-fdcf475cc30f",
              "description": "Wearable Technology",
              "value": "Wearable Technology",
              "subCategoryId": "261d3e08-254e-42d1-845c-db7359285823"
            }
          ]
        },
        {
          "id": "c41fa42b-480a-4090-a085-cabcafe8e45b",
          "description": "Model",
          "value": "Model",
          "categoryId": "316c0651-c5e3-4bd2-b41c-5427484f74bd",
          "tags": [
            {
              "id": "0cc61b3c-56c8-48f5-ad19-13a53b7bd7fc",
              "description": "ATIV Odyssey",
              "value": "ATIV Odyssey",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "3840e513-4b1f-439c-81f7-160054765060",
              "description": "Bold",
              "value": "Bold",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "480d18b3-94bf-44e1-a659-da08c31789c0",
              "description": "Breakout",
              "value": "Breakout",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "24177114-4409-4ca6-a6fc-7386ff5ce906",
              "description": "Brigadier",
              "value": "Brigadier",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "6ccbc6c6-9378-4ed5-bacf-b0aa3ee2f5a0",
              "description": "Brightside",
              "value": "Brightside",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "da8bb3c8-d7e4-40c2-9803-d26ec37daab3",
              "description": "Commando",
              "value": "Commando",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "07888122-a640-4203-b737-9b6a8a797038",
              "description": "Continuum",
              "value": "Continuum",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "6b1c8f3e-1bde-4aae-8cdd-eaf69bbf550f",
              "description": "Convoy",
              "value": "Convoy",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "f2ac46f3-860f-473f-9215-19d451a3ba48",
              "description": "Cosmos",
              "value": "Cosmos",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "3e1fdbae-3ff1-4733-804d-f811a6a6f9ce",
              "description": "Curve",
              "value": "Curve",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "672ce4c6-c744-4093-823e-e39a51ee5f98",
              "description": "Delphi Connect",
              "value": "Delphi Connect",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "6bb6a69d-e70d-4277-a668-551535d98bea",
              "description": "Droid",
              "value": "Droid",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "1428c3b4-03dd-4002-8f4f-8c94e7c9c6f3",
              "description": "Droid 4",
              "value": "Droid 4",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "657b82ba-6ff5-4bb3-b87e-8d20dacc75a3",
              "description": "Droid Bionic",
              "value": "Droid Bionic",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "8e954aa2-cc00-4d03-a685-298f6d674416",
              "description": "Droid DNA",
              "value": "Droid DNA",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "49add117-ff1c-43c6-9f02-aa55e6c0e963",
              "description": "Droid Incredible",
              "value": "Droid Incredible",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "8e1515e4-0db4-4d11-9f7e-cfd46dddd482",
              "description": "Droid Maxx",
              "value": "Droid Maxx",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "1796bc47-deae-49cd-8cc4-590814e0ea20",
              "description": "Droid Mini",
              "value": "Droid Mini",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "893e6995-c63e-4bdc-8e11-082edb83bb68",
              "description": "Droid Ultra",
              "value": "Droid Ultra",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "a23cd7d0-9e0c-4681-b827-ca9d15fff0a2",
              "description": "Droid X",
              "value": "Droid X",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "6e886643-efbe-4729-8e9c-54fc6e201bdc",
              "description": "Droid Xyboard",
              "value": "Droid Xyboard",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "22287059-1266-4bb7-b7a8-0451abc7a2a3",
              "description": "Elite",
              "value": "Elite",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "0889a24b-34e5-4cd2-977a-9e42fa2ceabf",
              "description": "Ellipsis",
              "value": "Ellipsis",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "5675302c-e6da-4b8a-b33c-d4f9115ce285",
              "description": "Enact",
              "value": "Enact",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "e66ef4e7-b081-473e-8aee-de773ebdde0e",
              "description": "Enlighten",
              "value": "Enlighten",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "0b535c17-518e-4371-ac3f-45c9ef0a06a1",
              "description": "Etrak",
              "value": "Etrak",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "4464193b-5303-4310-aeed-c57483248ef7",
              "description": "Exalt",
              "value": "Exalt",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "1517ab0a-317a-4aa1-ae69-c3f952f2e805",
              "description": "Extravert",
              "value": "Extravert",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "246a6551-b865-47e3-917d-4aec9629ab17",
              "description": "G Pad",
              "value": "G Pad",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "db582f7d-6677-40d4-837f-1faaace1af2a",
              "description": "G2",
              "value": "G2",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "5c4eaf5f-a656-4918-9e8b-80ebb05442d7",
              "description": "Galaxy",
              "value": "Galaxy",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "a5aff3b7-5bec-425e-b05a-b49c4e113fb3",
              "description": "Galaxy Legend",
              "value": "Galaxy Legend",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "1f466c4e-8f78-4c18-97cf-28a221516735",
              "description": "Galaxy S3",
              "value": "Galaxy S3",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "edd5dde2-7990-4fef-8e8c-ba539ec34cf2",
              "description": "Galaxy S5",
              "value": "Galaxy S5",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "03ccc12c-6202-4f3b-817d-e598238817d5",
              "description": "Galaxy Tab",
              "value": "Galaxy Tab",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "b74dec00-94bc-442a-8f1f-f11315e72025",
              "description": "Gusto",
              "value": "Gusto",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "b8cde134-7a80-44d8-a31d-c92af63b2dc7",
              "description": "Haven",
              "value": "Haven",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "42e130a9-b603-4950-b81e-c8ec1e7c1bdd",
              "description": "Hero",
              "value": "Hero",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "48f5a8d0-2162-4ada-bf5e-c14a88bfe7ab",
              "description": "Home Phone Connect",
              "value": "Home Phone Connect",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "14e6a19d-bf16-47be-a820-7052b10ac658",
              "description": "Hydro Elite",
              "value": "Hydro Elite",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "875361d3-31ca-4083-a596-c39c4d26aa4a",
              "description": "Illusion",
              "value": "Illusion",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "a42fee22-5d5d-4732-9c62-c020d6e2c61b",
              "description": "Intensity",
              "value": "Intensity",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "3a6fdc7c-fe87-4274-8c06-f53c5387b94f",
              "description": "Intuition",
              "value": "Intuition",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "debcd43b-a678-4536-aaa7-e02cb855c499",
              "description": "Jest",
              "value": "Jest",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "6fd384c5-395a-43de-aa23-20e6088f175b",
              "description": "Jetpack",
              "value": "Jetpack",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "b4623cfa-619c-4a73-bf15-b0383c17cd63",
              "description": "Lucid",
              "value": "Lucid",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "a72979b1-87cf-400e-a05e-6cd057e5bd43",
              "description": "Lumia",
              "value": "Lumia",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "9117dce3-c0a8-452f-982a-6693848b5403",
              "description": "Marauder",
              "value": "Marauder",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "9a58ac03-10e8-46cc-adf4-293b636b3646",
              "description": "Mobile Hotspot",
              "value": "Mobile Hotspot",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "0aaba639-0ec5-4183-a623-65e5701c3000",
              "description": "Moto G",
              "value": "Moto G",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "f7d1710d-b0b7-47ee-a9db-b1eef6fffd61",
              "description": "Moto X",
              "value": "Moto X",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "2901bbb2-dc4e-4fe1-bc1e-de0a768f0a92",
              "description": "Nest",
              "value": "Nest",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "05d75ada-a7d2-4e7c-a643-89b78158d34f",
              "description": "Nexus",
              "value": "Nexus",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "bff980e2-4406-437a-afdd-51031940ecc3",
              "description": "Note",
              "value": "Note",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "a1a9fae2-d3a4-41a1-ac7d-11be251842d5",
              "description": "One",
              "value": "One",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "3b62a9e2-d0cf-4cce-83ee-8c7df2e0b9b9",
              "description": "Optimus",
              "value": "Optimus",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "1e45c840-62aa-4d5b-afd4-d02199da99bf",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "c3d334b6-cc4e-4b18-8ab9-3ca3e1a0f11b",
              "description": "Perception",
              "value": "Perception",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "2f99a2c5-c205-4353-b364-c4524aaab282",
              "description": "Pill",
              "value": "Pill",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "fae3a8bf-32a8-449f-b5c1-d49a46bb0f1f",
              "description": "Q10",
              "value": "Q10",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "c1635d34-6ac1-4913-a7d4-3737b0e8064c",
              "description": "Ravine",
              "value": "Ravine",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "12b8a8e2-1f64-4457-a70c-12ad278527fe",
              "description": "Revere",
              "value": "Revere",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "64fb2195-f04f-418d-8dd9-aece9e3140bc",
              "description": "Revolution",
              "value": "Revolution",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "039d7acd-9d3e-493e-b505-f21a27c2c515",
              "description": "Rezound",
              "value": "Rezound",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "02d40dfc-8689-4200-9402-03e9382f32ec",
              "description": "Rhyme",
              "value": "Rhyme",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "308f1d60-3e1f-4901-a9dd-03d05381bb59",
              "description": "Router",
              "value": "Router",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "db915df7-adbf-41c4-95f4-5dd2e82cfb13",
              "description": "Spectrum",
              "value": "Spectrum",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "f88e1a01-c919-425a-bb90-5cdd34c5bfd4",
              "description": "Stratosphere",
              "value": "Stratosphere",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "d7fe384d-9faf-4d8f-a5f8-4f526d82c3eb",
              "description": "SureResponse",
              "value": "SureResponse",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "45bfca50-fcfb-45f6-a40a-f2b5149945a0",
              "description": "Torch",
              "value": "Torch",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "d5c9a7ca-efb5-47d9-8fa5-3e6846030e75",
              "description": "Trophy",
              "value": "Trophy",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "6b1ce876-d253-4bd9-9562-53f1c6b628e8",
              "description": "USB Modems",
              "value": "USB Modems",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "91249230-ed35-49f1-adcd-9cf51fdf8635",
              "description": "Vehicle Diagnostic",
              "value": "Vehicle Diagnostic",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "b6565ca2-3e37-467c-8fcf-81765d94beec",
              "description": "Windows Phone 8X",
              "value": "Windows Phone 8X",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "9b59df85-a5c4-4104-b4cd-b8f11c7034a5",
              "description": "Xperia",
              "value": "Xperia",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "fdb11977-b2f9-43c0-adcc-6cd047d313ab",
              "description": "Z10",
              "value": "Z10",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "4a3d4b0e-994f-4ef5-9b74-290badb047d9",
              "description": "Z30",
              "value": "Z30",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "bd147f22-2df5-41ed-a33d-5884a2a94d9f",
              "description": "iPad",
              "value": "iPad",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            },
            {
              "id": "2fc3c912-7077-4572-8e75-efa808495ae9",
              "description": "iPhone",
              "value": "iPhone",
              "subCategoryId": "c41fa42b-480a-4090-a085-cabcafe8e45b"
            }
          ]
        }
      ]
    },
    {
      "id": "18cdbe3d-567f-47eb-9c94-9acdab78547b",
      "description": "Employee Communications",
      "value": "Employee Communications",
      "subCategories": [
        {
          "id": "57c0436d-bc92-4b66-b39a-68daed6e80f3",
          "description": "Channel",
          "value": "Channel",
          "categoryId": "18cdbe3d-567f-47eb-9c94-9acdab78547b",
          "tags": [
            {
              "id": "1db0c5c3-55f4-4471-8d4d-6a7876c82044",
              "description": "Digital/Web",
              "value": "Digital/Web",
              "subCategoryId": "57c0436d-bc92-4b66-b39a-68daed6e80f3"
            },
            {
              "id": "1360c2f9-4213-4aa6-81f7-3ebb94ad3972",
              "description": "Packaging",
              "value": "Packaging",
              "subCategoryId": "57c0436d-bc92-4b66-b39a-68daed6e80f3"
            },
            {
              "id": "6bae776f-543c-4ed6-ab8a-309965c406e5",
              "description": "TV",
              "value": "TV",
              "subCategoryId": "57c0436d-bc92-4b66-b39a-68daed6e80f3"
            }
          ]
        }
      ]
    },
    {
      "id": "d4989371-83f3-497e-984b-5d23a3c6bab1",
      "description": "Entertainment Assets",
      "value": "Entertainment Assets",
      "subCategories": [
        {
          "id": "a77b4a7a-ee69-4499-9e78-67fa581b313c",
          "description": "Application Graphics",
          "value": "Application Graphics",
          "categoryId": "d4989371-83f3-497e-984b-5d23a3c6bab1",
          "tags": [
            {
              "id": "903ce8a2-9135-4467-a3ff-2f1f06b6e579",
              "description": "App Store",
              "value": "App Store",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "9e0b0074-528b-43d5-bdf9-19d67fe85ab3",
              "description": "Books & Reference",
              "value": "Books & Reference",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "16aff617-1cfa-4f46-b17a-5e646775dfe4",
              "description": "Business",
              "value": "Business",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "a3587dbf-283a-40f0-b047-c8a1f07767b7",
              "description": "Catalog",
              "value": "Catalog",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "e515de99-c018-4dc4-935b-e4a6e1a13402",
              "description": "Education",
              "value": "Education",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "cf174eb0-e378-422d-ad41-142e39349d9d",
              "description": "Email",
              "value": "Email",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "15ee7a56-1380-43e1-88af-25654862eb05",
              "description": "Entertainment",
              "value": "Entertainment",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "af455428-a064-4dbd-a243-1a6cfdccb716",
              "description": "Finance",
              "value": "Finance",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "3af5b3fd-84b1-4728-b7d8-b69ee57b36ed",
              "description": "Food & Cooking",
              "value": "Food & Cooking",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "ae224e8c-44af-43cd-8247-c4faca16d482",
              "description": "Health & Fitness",
              "value": "Health & Fitness",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "c0da22e6-f0b2-4821-848a-d807ebcff50b",
              "description": "Kids & Family",
              "value": "Kids & Family",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "4006d9e6-4279-4fc8-87f6-40e604931612",
              "description": "Lifestyle",
              "value": "Lifestyle",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "c3081011-61fc-4d40-a4f0-4b7863bbaf85",
              "description": "Maps & Navigation",
              "value": "Maps & Navigation",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "7fd683d4-b978-49ed-943c-55d6ce904053",
              "description": "Messaging",
              "value": "Messaging",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "aad6358f-c216-4a01-a6cc-4016045c2c7e",
              "description": "Music & Audio",
              "value": "Music & Audio",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "8144f0cd-de48-438d-a24f-e316f4e152b0",
              "description": "Music Purchasing",
              "value": "Music Purchasing",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "9be546b0-13ba-49dc-8053-ab01f4093d6f",
              "description": "NFC",
              "value": "NFC",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "04cb28e0-8927-4514-a89c-59209ce5c016",
              "description": "News & Magazine",
              "value": "News & Magazine",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "a5dc04db-3875-4e96-87cc-e16ac381fd92",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "e49d5e68-1345-4fce-9588-53f04eb36ef4",
              "description": "Photo & Video",
              "value": "Photo & Video",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "55856bc3-7557-434a-93ad-8accc8321c2f",
              "description": "Productivity",
              "value": "Productivity",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "d0af4d82-e2ca-4aa3-b7a0-2be8c4b1ba9f",
              "description": "Shopping",
              "value": "Shopping",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "af8f49b0-338d-41e9-9a06-c8c454873ede",
              "description": "Social",
              "value": "Social",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "4d160b0d-5e05-4b70-bdfb-f8c79293ce2c",
              "description": "Sports",
              "value": "Sports",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "3bc267ee-b906-4de5-9cdc-bdfb83f6b401",
              "description": "Tools & Utility",
              "value": "Tools & Utility",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "989a03a0-d41d-4686-90e9-716663daa842",
              "description": "Transportation",
              "value": "Transportation",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "c3303cbe-10fd-4967-9a02-16b978ff6454",
              "description": "Travel & Local",
              "value": "Travel & Local",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "e8e21742-9a6a-428a-986b-91c9ea087488",
              "description": "Video Calling",
              "value": "Video Calling",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            },
            {
              "id": "5bf2403a-aac0-4a80-bdb1-87b9a1aa4327",
              "description": "Weather",
              "value": "Weather",
              "subCategoryId": "a77b4a7a-ee69-4499-9e78-67fa581b313c"
            }
          ]
        },
        {
          "id": "6bdb6482-72fb-4919-97ea-1f85815171d5",
          "description": "Category",
          "value": "Category",
          "categoryId": "d4989371-83f3-497e-984b-5d23a3c6bab1",
          "tags": [
            {
              "id": "baf61da9-5bdc-4df2-810c-feb75c03265e",
              "description": "Album Cover Art",
              "value": "Album Cover Art",
              "subCategoryId": "6bdb6482-72fb-4919-97ea-1f85815171d5"
            },
            {
              "id": "f7aeee40-13e0-4442-bcb3-b1025434c643",
              "description": "Banner",
              "value": "Banner",
              "subCategoryId": "6bdb6482-72fb-4919-97ea-1f85815171d5"
            },
            {
              "id": "6f418d96-4732-4805-822b-8643d1f1c8e6",
              "description": "Copy Deck",
              "value": "Copy Deck",
              "subCategoryId": "6bdb6482-72fb-4919-97ea-1f85815171d5"
            },
            {
              "id": "7c8ba326-ea63-4e58-acde-d47b675a2bb6",
              "description": "Hot Sheet",
              "value": "Hot Sheet",
              "subCategoryId": "6bdb6482-72fb-4919-97ea-1f85815171d5"
            },
            {
              "id": "78c9fcd4-8533-4d75-a3e7-c5bbeec2fa4d",
              "description": "Icon",
              "value": "Icon",
              "subCategoryId": "6bdb6482-72fb-4919-97ea-1f85815171d5"
            },
            {
              "id": "90dde234-c6e1-4054-a836-94f611ede076",
              "description": "Logo Art",
              "value": "Logo Art",
              "subCategoryId": "6bdb6482-72fb-4919-97ea-1f85815171d5"
            },
            {
              "id": "43ca6783-801c-4427-a8e7-839cfd70baed",
              "description": "Publicity Photos",
              "value": "Publicity Photos",
              "subCategoryId": "6bdb6482-72fb-4919-97ea-1f85815171d5"
            },
            {
              "id": "6266bee3-e759-46ba-96b9-2a71b5af2abd",
              "description": "Screenshot",
              "value": "Screenshot",
              "subCategoryId": "6bdb6482-72fb-4919-97ea-1f85815171d5"
            },
            {
              "id": "89fc3b43-341c-4017-8fd6-484e2d7f869e",
              "description": "Splash Screen",
              "value": "Splash Screen",
              "subCategoryId": "6bdb6482-72fb-4919-97ea-1f85815171d5"
            },
            {
              "id": "cc00d1de-c103-4241-99a3-95fc7f7db9a5",
              "description": "Video",
              "value": "Video",
              "subCategoryId": "6bdb6482-72fb-4919-97ea-1f85815171d5"
            }
          ]
        },
        {
          "id": "8b212a68-3ffd-4969-8fbc-c9fcb5a6bf83",
          "description": "Device Specific",
          "value": "Device Specific",
          "categoryId": "d4989371-83f3-497e-984b-5d23a3c6bab1",
          "tags": [
            {
              "id": "9778b9c0-a421-40c7-9c58-872342408b3f",
              "description": "Phone",
              "value": "Phone",
              "subCategoryId": "8b212a68-3ffd-4969-8fbc-c9fcb5a6bf83"
            },
            {
              "id": "a1af8813-f837-4d66-89b3-7c915b2fe94b",
              "description": "Tablet",
              "value": "Tablet",
              "subCategoryId": "8b212a68-3ffd-4969-8fbc-c9fcb5a6bf83"
            }
          ]
        },
        {
          "id": "8ca6556d-cca9-4c27-889f-2babb763f5a2",
          "description": "Game Graphics",
          "value": "Game Graphics",
          "categoryId": "d4989371-83f3-497e-984b-5d23a3c6bab1",
          "tags": [
            {
              "id": "4711b626-af9d-4a5e-8324-c355863f4b2c",
              "description": "Action & Adventure",
              "value": "Action & Adventure",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            },
            {
              "id": "ffda5ec8-8f54-47eb-b073-4c47e17fcbe6",
              "description": "Arcade",
              "value": "Arcade",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            },
            {
              "id": "64224401-92c5-45df-b8ea-eaa77c5d07ca",
              "description": "Board",
              "value": "Board",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            },
            {
              "id": "1675955f-e4a6-4cee-b659-e9dcb74ad2f2",
              "description": "Brain & Puzzle",
              "value": "Brain & Puzzle",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            },
            {
              "id": "20abb922-5645-42e6-8f7e-b770b29d2fb4",
              "description": "Casino & Card",
              "value": "Casino & Card",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            },
            {
              "id": "f82cbc44-ad49-42ed-b512-b5219c231abd",
              "description": "Casual & Social",
              "value": "Casual & Social",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            },
            {
              "id": "b3522c61-c4b3-4374-a329-651a286ca394",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            },
            {
              "id": "fe4d8905-968c-4f21-ae57-a589e437601a",
              "description": "Racing",
              "value": "Racing",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            },
            {
              "id": "af72b1ad-9153-4371-8538-266e4be027b0",
              "description": "Sports",
              "value": "Sports",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            },
            {
              "id": "31f04d65-62fa-42a7-9a71-96d1c3076baf",
              "description": "Strategy & Sim",
              "value": "Strategy & Sim",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            },
            {
              "id": "ed746721-8660-4423-b334-39f3ef84979d",
              "description": "Verizon Exclusive",
              "value": "Verizon Exclusive",
              "subCategoryId": "8ca6556d-cca9-4c27-889f-2babb763f5a2"
            }
          ]
        },
        {
          "id": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0",
          "description": "Music Graphics",
          "value": "Music Graphics",
          "categoryId": "d4989371-83f3-497e-984b-5d23a3c6bab1",
          "tags": [
            {
              "id": "fec85575-0afd-4dd3-a681-6e9ccb04d0c9",
              "description": "Alternative / Indie",
              "value": "Alternative / Indie",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "38cb788e-aa75-4651-96f5-8642f4033e5a",
              "description": "Blues",
              "value": "Blues",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "86b8e96f-2a48-4531-9cca-a0f5f5ca32fb",
              "description": "Children's Music",
              "value": "Children's Music",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "69daf15a-7b3a-4124-a04a-42eb7145d373",
              "description": "Christian / Gospel",
              "value": "Christian / Gospel",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "aba0323b-4f8b-433e-af52-34f9528944a9",
              "description": "Classical",
              "value": "Classical",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "f850f458-cd8e-4633-a338-20b632421abd",
              "description": "Comedy / SpokenWord",
              "value": "Comedy / SpokenWord",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "c7f530d1-6432-437c-ba6b-aa427a8d2f41",
              "description": "Country",
              "value": "Country",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "171ceee3-9558-491a-a43e-1865b0165388",
              "description": "Dance / Electronic",
              "value": "Dance / Electronic",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "4e1022fa-205b-464e-8829-4d688e263893",
              "description": "Folk",
              "value": "Folk",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "4ff38a10-ebbf-4f8e-94a6-70c5f07ae516",
              "description": "Hip-Hop / Rap",
              "value": "Hip-Hop / Rap",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "dd537eab-2ad0-4ed8-9a6a-bdeab262f073",
              "description": "Holiday",
              "value": "Holiday",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "aa7965a3-9b35-4fc6-973d-74d848448cee",
              "description": "Jazz",
              "value": "Jazz",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "c1c6b013-2c3d-4065-8648-1765a756ff2f",
              "description": "Latin",
              "value": "Latin",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "326bad79-bc19-41f0-a889-a8f2e32e76f3",
              "description": "Metal",
              "value": "Metal",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "db3ef0e4-f60c-43aa-8b3e-bb771257d1d9",
              "description": "New Age",
              "value": "New Age",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "361ff0fe-0f10-4f91-afe6-33a2e009f176",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "ee823273-60b3-4edf-94ce-fc10175f072c",
              "description": "Pop",
              "value": "Pop",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "2161683e-df83-404f-b26e-c2c5bf0006bb",
              "description": "R&B / Soul",
              "value": "R&B / Soul",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "2689fdbd-ff3c-4a0b-97f6-3e728621865e",
              "description": "Reggae / Ska",
              "value": "Reggae / Ska",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "ae1895a1-fdc7-4932-9627-65f4ae1d939e",
              "description": "Rock",
              "value": "Rock",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "62d464f0-6251-4c08-b277-1dd690bbac61",
              "description": "Soundtrack",
              "value": "Soundtrack",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "f1afa036-4942-4a08-b202-80bb837eeadc",
              "description": "Vocal / Easy Listening",
              "value": "Vocal / Easy Listening",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            },
            {
              "id": "01233215-c209-4a1c-983d-3647e8656bad",
              "description": "World",
              "value": "World",
              "subCategoryId": "66a325e6-6fd4-4a62-a9e9-0ee50bdbf1e0"
            }
          ]
        },
        {
          "id": "3c637b24-7742-4ea0-980f-0bcda6f5a99c",
          "description": "Operating System",
          "value": "Operating System",
          "categoryId": "d4989371-83f3-497e-984b-5d23a3c6bab1",
          "tags": [
            {
              "id": "7bdb6842-0685-4cb3-8b0e-0f03f7f6c131",
              "description": "Android",
              "value": "Android",
              "subCategoryId": "3c637b24-7742-4ea0-980f-0bcda6f5a99c"
            },
            {
              "id": "12751db5-5a8e-4b33-8aa9-7bd015d0efc9",
              "description": "BREW",
              "value": "BREW",
              "subCategoryId": "3c637b24-7742-4ea0-980f-0bcda6f5a99c"
            },
            {
              "id": "1835dfaa-c312-49b1-8288-af68783dc839",
              "description": "BlackBerry",
              "value": "BlackBerry",
              "subCategoryId": "3c637b24-7742-4ea0-980f-0bcda6f5a99c"
            },
            {
              "id": "ae8f8eab-6a71-4dd2-8705-2734a30c3448",
              "description": "Windows",
              "value": "Windows",
              "subCategoryId": "3c637b24-7742-4ea0-980f-0bcda6f5a99c"
            },
            {
              "id": "319a3734-911f-4359-bf16-59d98c346ebb",
              "description": "iOS",
              "value": "iOS",
              "subCategoryId": "3c637b24-7742-4ea0-980f-0bcda6f5a99c"
            }
          ]
        },
        {
          "id": "6c0a6da8-a69a-4c4b-8bdf-68b5520370cf",
          "description": "Orientation",
          "value": "Orientation",
          "categoryId": "d4989371-83f3-497e-984b-5d23a3c6bab1",
          "tags": [
            {
              "id": "89960a21-55ea-40dc-a8db-6eeb926521ab",
              "description": "Horizontal",
              "value": "Horizontal",
              "subCategoryId": "6c0a6da8-a69a-4c4b-8bdf-68b5520370cf"
            },
            {
              "id": "96ad1272-8357-41c1-ae10-b0c12529e54c",
              "description": "Square",
              "value": "Square",
              "subCategoryId": "6c0a6da8-a69a-4c4b-8bdf-68b5520370cf"
            },
            {
              "id": "aa88b0b5-466c-4a32-8aa1-dd070932b916",
              "description": "Vertical",
              "value": "Vertical",
              "subCategoryId": "6c0a6da8-a69a-4c4b-8bdf-68b5520370cf"
            }
          ]
        },
        {
          "id": "e451ee9b-1a10-4eb0-abbb-e3ddf1edd879",
          "description": "Training Materials",
          "value": "Training Materials",
          "categoryId": "d4989371-83f3-497e-984b-5d23a3c6bab1",
          "tags": [
            {
              "id": "921cc55c-13c5-4525-839c-8e6ef3e48873",
              "description": "Forms",
              "value": "Forms",
              "subCategoryId": "e451ee9b-1a10-4eb0-abbb-e3ddf1edd879"
            },
            {
              "id": "9d9207d3-eb9b-491d-a1d1-b5f83ecd8374",
              "description": "Hotsheet",
              "value": "Hotsheet",
              "subCategoryId": "e451ee9b-1a10-4eb0-abbb-e3ddf1edd879"
            },
            {
              "id": "c73a7314-bba1-45e9-ac3e-3075b069792e",
              "description": "User Guides",
              "value": "User Guides",
              "subCategoryId": "e451ee9b-1a10-4eb0-abbb-e3ddf1edd879"
            }
          ]
        }
      ]
    },
    {
      "id": "e492b86d-3a1d-45a0-b518-3ace937747cf",
      "description": "Iconography",
      "value": "Iconography",
      "subCategories": [
        {
          "id": "f19f31e1-54a7-4563-b614-ebb88631107a",
          "description": "Business",
          "value": "Business",
          "categoryId": "e492b86d-3a1d-45a0-b518-3ace937747cf",
          "tags": [
            {
              "id": "358305a0-dcd2-4002-8041-d5adfe84a734",
              "description": "B2B",
              "value": "B2B",
              "subCategoryId": "f19f31e1-54a7-4563-b614-ebb88631107a"
            },
            {
              "id": "8529fdfb-77d9-40f7-aa2e-221cc592b6ae",
              "description": "Objects & Industries",
              "value": "Objects & Industries",
              "subCategoryId": "f19f31e1-54a7-4563-b614-ebb88631107a"
            }
          ]
        },
        {
          "id": "39f6460e-7746-4767-94f7-354aa62d5253",
          "description": "Interface",
          "value": "Interface",
          "categoryId": "e492b86d-3a1d-45a0-b518-3ace937747cf",
          "tags": [
            {
              "id": "0604a980-03fa-484d-8c3b-cbad5262c139",
              "description": "Directional",
              "value": "Directional",
              "subCategoryId": "39f6460e-7746-4767-94f7-354aa62d5253"
            },
            {
              "id": "943ac556-7980-4358-9ef2-67c11c8964a5",
              "description": "Navigational",
              "value": "Navigational",
              "subCategoryId": "39f6460e-7746-4767-94f7-354aa62d5253"
            },
            {
              "id": "d9d3c77e-a000-4822-9fd8-c6a1f0885b1f",
              "description": "Notifications & Feedback",
              "value": "Notifications & Feedback",
              "subCategoryId": "39f6460e-7746-4767-94f7-354aa62d5253"
            }
          ]
        },
        {
          "id": "121ed840-4049-43f3-a3be-ecd0887e5f11",
          "description": "Misc.",
          "value": "Misc.",
          "categoryId": "e492b86d-3a1d-45a0-b518-3ace937747cf",
          "tags": [
            {
              "id": "d8e01bab-f9a6-4c6c-ac6c-f5027c9b3d92",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "121ed840-4049-43f3-a3be-ecd0887e5f11"
            },
            {
              "id": "e3e1b271-fb37-413a-9cf4-e00cede94536",
              "description": "Utilities",
              "value": "Utilities",
              "subCategoryId": "121ed840-4049-43f3-a3be-ecd0887e5f11"
            }
          ]
        },
        {
          "id": "015b170f-c5c0-42eb-ad45-6085b594a2d4",
          "description": "Product",
          "value": "Product",
          "categoryId": "e492b86d-3a1d-45a0-b518-3ace937747cf",
          "tags": [
            {
              "id": "53882030-b97a-4455-9e5e-2e7de96a226a",
              "description": "Calling",
              "value": "Calling",
              "subCategoryId": "015b170f-c5c0-42eb-ad45-6085b594a2d4"
            },
            {
              "id": "192a20d2-27c7-4dfe-a6fb-7ee89c0936fb",
              "description": "International",
              "value": "International",
              "subCategoryId": "015b170f-c5c0-42eb-ad45-6085b594a2d4"
            },
            {
              "id": "e61d105f-1833-48fa-b2c6-9264077b518c",
              "description": "Orders & Pricing",
              "value": "Orders & Pricing",
              "subCategoryId": "015b170f-c5c0-42eb-ad45-6085b594a2d4"
            },
            {
              "id": "69a0b5fc-4daf-4226-8332-0f8022aeb054",
              "description": "Plans & Devices",
              "value": "Plans & Devices",
              "subCategoryId": "015b170f-c5c0-42eb-ad45-6085b594a2d4"
            }
          ]
        }
      ]
    },
    {
      "id": "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
      "description": "Logos",
      "value": "Logos",
      "subCategories": [
        {
          "id": "25683b96-0a1f-4ccb-ac3e-0e7e32a5c29b",
          "description": "Channel",
          "value": "Channel",
          "categoryId": "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
          "tags": []
        },
        {
          "id": "e2803068-57c8-434f-9743-c728df4916d1",
          "description": "Employee Communications",
          "value": "Employee Communications",
          "categoryId": "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
          "tags": []
        },
        {
          "id": "c268f8b4-9834-4947-8271-4c2a07ff3572",
          "description": "Others",
          "value": "Others",
          "categoryId": "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
          "tags": []
        },
        {
          "id": "b5598c75-7aac-4c95-8bb6-7bcb525e337b",
          "description": "Primary",
          "value": "Primary",
          "categoryId": "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
          "tags": []
        },
        {
          "id": "f677f2bb-7ead-4601-918d-43726ada3387",
          "description": "Segment",
          "value": "Segment",
          "categoryId": "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
          "tags": [
            {
              "id": "ef5d7174-ccaa-4879-96bc-332b7294a342",
              "description": "Business",
              "value": "Business",
              "subCategoryId": "f677f2bb-7ead-4601-918d-43726ada3387"
            },
            {
              "id": "6a23903a-7522-4e2b-8390-ea53fe9ea5fe",
              "description": "Consumer",
              "value": "Consumer",
              "subCategoryId": "f677f2bb-7ead-4601-918d-43726ada3387"
            },
            {
              "id": "4ee23ac0-d42b-4da2-b9b6-3a03712aae1b",
              "description": "Enhanced Community",
              "value": "Enhanced Community",
              "subCategoryId": "f677f2bb-7ead-4601-918d-43726ada3387"
            },
            {
              "id": "10372b75-8cb5-4c2d-b4aa-5e63d385928b",
              "description": "Enterprise",
              "value": "Enterprise",
              "subCategoryId": "f677f2bb-7ead-4601-918d-43726ada3387"
            },
            {
              "id": "230b977e-7a06-4743-b037-c6fe0a1d3664",
              "description": "Experiential",
              "value": "Experiential",
              "subCategoryId": "f677f2bb-7ead-4601-918d-43726ada3387"
            },
            {
              "id": "ac76e536-9258-4612-bc97-09e6ee12ec1d",
              "description": "Government",
              "value": "Government",
              "subCategoryId": "f677f2bb-7ead-4601-918d-43726ada3387"
            }
          ]
        },
        {
          "id": "88113f99-0a09-42f7-8b7b-c7fcbbd401cf",
          "description": "Type",
          "value": "Type",
          "categoryId": "94413c10-2ae5-4ccc-8845-6f4dfe2d4285",
          "tags": []
        }
      ]
    },
    {
      "id": "76007f55-b998-4883-8e53-64c5ecec4a51",
      "description": "Photography",
      "value": "Photography",
      "subCategories": [
        {
          "id": "a22f39d0-4876-496c-93a6-14fe4011dc8d",
          "description": "Age",
          "value": "Age",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "8e04ac67-b08a-4f88-ad39-194dd6d80f02",
              "description": "Adult 32-50",
              "value": "Adult 32-50",
              "subCategoryId": "a22f39d0-4876-496c-93a6-14fe4011dc8d"
            },
            {
              "id": "8464f380-7dd5-478a-adc4-9fe85f123d00",
              "description": "Family",
              "value": "Family",
              "subCategoryId": "a22f39d0-4876-496c-93a6-14fe4011dc8d"
            },
            {
              "id": "86de57e7-7615-4d70-a3e0-9f5974ae2345",
              "description": "Mature 51 & Older",
              "value": "Mature 51 & Older",
              "subCategoryId": "a22f39d0-4876-496c-93a6-14fe4011dc8d"
            },
            {
              "id": "2e080ec9-e2a5-4806-8b30-0d82bb624007",
              "description": "Teen 15-18",
              "value": "Teen 15-18",
              "subCategoryId": "a22f39d0-4876-496c-93a6-14fe4011dc8d"
            },
            {
              "id": "68e58d8c-b1e1-43d5-ac2c-8c1fe20bddcb",
              "description": "Young Adult 19-31",
              "value": "Young Adult 19-31",
              "subCategoryId": "a22f39d0-4876-496c-93a6-14fe4011dc8d"
            },
            {
              "id": "f50cd84f-282a-46fb-a97e-61f2119fe56b",
              "description": "Youth 14 & Under",
              "value": "Youth 14 & Under",
              "subCategoryId": "a22f39d0-4876-496c-93a6-14fe4011dc8d"
            }
          ]
        },
        {
          "id": "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660",
          "description": "Channel",
          "value": "Channel",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "3d8d9269-63a0-437f-90ec-f70204d5c10f",
              "description": "Business",
              "value": "Business",
              "subCategoryId": "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660"
            },
            {
              "id": "9bedcce5-f0e5-4b3f-86db-2ee0b810c091",
              "description": "Consumer",
              "value": "Consumer",
              "subCategoryId": "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660"
            },
            {
              "id": "9066ae36-b552-4e82-98af-f5dc2dd2fe10",
              "description": "Enterprise",
              "value": "Enterprise",
              "subCategoryId": "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660"
            },
            {
              "id": "990afd3e-7939-4f41-8cb4-279b1189ce29",
              "description": "Government",
              "value": "Government",
              "subCategoryId": "7a4dbad6-d7a5-4007-9a00-0bf20b6cb660"
            }
          ]
        },
        {
          "id": "177c1b1b-cc61-49b0-a389-81fc1a62c320",
          "description": "Creative Copyright",
          "value": "Creative Copyright",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "6d397aaf-5e7d-40bb-983d-b670fe749003",
              "description": "Commissioned Rights Managed",
              "value": "Commissioned Rights Managed",
              "subCategoryId": "177c1b1b-cc61-49b0-a389-81fc1a62c320"
            },
            {
              "id": "aa4f0b53-33be-4a2f-bff0-226061fd3001",
              "description": "Commissioned Royalty Free",
              "value": "Commissioned Royalty Free",
              "subCategoryId": "177c1b1b-cc61-49b0-a389-81fc1a62c320"
            },
            {
              "id": "a5046de3-aeb5-486e-a3d3-92754eff4c11",
              "description": "Stock Rights Managed",
              "value": "Stock Rights Managed",
              "subCategoryId": "177c1b1b-cc61-49b0-a389-81fc1a62c320"
            },
            {
              "id": "8a4b61eb-7419-4740-b8a9-a4c29a1e17d1",
              "description": "Stock Royalty Free",
              "value": "Stock Royalty Free",
              "subCategoryId": "177c1b1b-cc61-49b0-a389-81fc1a62c320"
            }
          ]
        },
        {
          "id": "6c9b87d2-c783-4c1c-924d-f554966ead84",
          "description": "Device Specific",
          "value": "Device Specific",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "b9729b3d-5823-43fd-b7dc-c4a46685331e",
              "description": "Accessory",
              "value": "Accessory",
              "subCategoryId": "6c9b87d2-c783-4c1c-924d-f554966ead84"
            },
            {
              "id": "84f6e4b3-9c1a-4eb6-bbde-5234073925cf",
              "description": "Feature phone",
              "value": "Feature phone",
              "subCategoryId": "6c9b87d2-c783-4c1c-924d-f554966ead84"
            },
            {
              "id": "6358b437-ac3d-4281-8f9c-91fd741a8b6d",
              "description": "None",
              "value": "None",
              "subCategoryId": "6c9b87d2-c783-4c1c-924d-f554966ead84"
            },
            {
              "id": "bba156ef-fe25-46d9-a0cb-dfabd9506bcf",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "6c9b87d2-c783-4c1c-924d-f554966ead84"
            },
            {
              "id": "8ec5d6de-ff63-4694-bb7b-4140815e94c4",
              "description": "PPT phone",
              "value": "PPT phone",
              "subCategoryId": "6c9b87d2-c783-4c1c-924d-f554966ead84"
            },
            {
              "id": "37661f09-dbb5-49b0-8305-867fac2a8ae5",
              "description": "Smart Accessory",
              "value": "Smart Accessory",
              "subCategoryId": "6c9b87d2-c783-4c1c-924d-f554966ead84"
            },
            {
              "id": "54604d24-c498-4861-8dfd-b63b36f8ed98",
              "description": "Smartphone",
              "value": "Smartphone",
              "subCategoryId": "6c9b87d2-c783-4c1c-924d-f554966ead84"
            },
            {
              "id": "f7eadaeb-996e-4f80-9e92-8a5dadab5f4b",
              "description": "Tablet",
              "value": "Tablet",
              "subCategoryId": "6c9b87d2-c783-4c1c-924d-f554966ead84"
            }
          ]
        },
        {
          "id": "e87a25ab-53dd-44f1-8b14-5fbb0c9a8066",
          "description": "Environment Specific",
          "value": "Environment Specific",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "851080ab-0b19-4d5c-9764-969aba56a79d",
              "description": "Beach",
              "value": "Beach",
              "subCategoryId": "e87a25ab-53dd-44f1-8b14-5fbb0c9a8066"
            },
            {
              "id": "9000668b-7825-4d0a-8182-0b62824dfd44",
              "description": "Day",
              "value": "Day",
              "subCategoryId": "e87a25ab-53dd-44f1-8b14-5fbb0c9a8066"
            },
            {
              "id": "3a162319-62e7-4745-b5c8-43caab40e7ca",
              "description": "Indoors",
              "value": "Indoors",
              "subCategoryId": "e87a25ab-53dd-44f1-8b14-5fbb0c9a8066"
            },
            {
              "id": "389e50a8-5fb8-40db-8144-376f68cd1252",
              "description": "Night",
              "value": "Night",
              "subCategoryId": "e87a25ab-53dd-44f1-8b14-5fbb0c9a8066"
            },
            {
              "id": "9b2d697c-9832-4969-b108-ce812ec56eff",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "e87a25ab-53dd-44f1-8b14-5fbb0c9a8066"
            },
            {
              "id": "4fcac08e-41ba-4b05-af33-ada5af46140d",
              "description": "Outdoors",
              "value": "Outdoors",
              "subCategoryId": "e87a25ab-53dd-44f1-8b14-5fbb0c9a8066"
            },
            {
              "id": "706412e9-6423-4fd5-ac7c-aaf537b9a52d",
              "description": "Rural",
              "value": "Rural",
              "subCategoryId": "e87a25ab-53dd-44f1-8b14-5fbb0c9a8066"
            },
            {
              "id": "ffcb48d2-da74-48e3-9df8-c1dbbcb6612b",
              "description": "Suburban",
              "value": "Suburban",
              "subCategoryId": "e87a25ab-53dd-44f1-8b14-5fbb0c9a8066"
            },
            {
              "id": "7222c04f-0cc0-42ff-a50e-aac6e4a8cd6f",
              "description": "Urban",
              "value": "Urban",
              "subCategoryId": "e87a25ab-53dd-44f1-8b14-5fbb0c9a8066"
            }
          ]
        },
        {
          "id": "6b34af07-6053-449b-a903-fef3017d741b",
          "description": "Ethnicity",
          "value": "Ethnicity",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "f336e575-da93-4c1e-8f10-b61f84b61201",
              "description": "African Descent",
              "value": "African Descent",
              "subCategoryId": "6b34af07-6053-449b-a903-fef3017d741b"
            },
            {
              "id": "e17a8adb-3c57-4232-9c97-6c739141ad6f",
              "description": "Asian Descent",
              "value": "Asian Descent",
              "subCategoryId": "6b34af07-6053-449b-a903-fef3017d741b"
            },
            {
              "id": "7089b40b-0bce-4316-be53-0766c4c46f1e",
              "description": "Caucasian Descent",
              "value": "Caucasian Descent",
              "subCategoryId": "6b34af07-6053-449b-a903-fef3017d741b"
            },
            {
              "id": "91cd9a16-802a-4714-b9df-0a2fee7eff42",
              "description": "Hispanic Descent",
              "value": "Hispanic Descent",
              "subCategoryId": "6b34af07-6053-449b-a903-fef3017d741b"
            },
            {
              "id": "ac3dc4ce-a62c-468f-a326-186482254cff",
              "description": "Indian Descent",
              "value": "Indian Descent",
              "subCategoryId": "6b34af07-6053-449b-a903-fef3017d741b"
            },
            {
              "id": "89ef6f9a-27c0-495e-a318-92a5511cf31f",
              "description": "Middle Eastern Descent",
              "value": "Middle Eastern Descent",
              "subCategoryId": "6b34af07-6053-449b-a903-fef3017d741b"
            },
            {
              "id": "e22450f7-c90a-4495-a980-b493eed22bb6",
              "description": "Multi-Ethnic",
              "value": "Multi-Ethnic",
              "subCategoryId": "6b34af07-6053-449b-a903-fef3017d741b"
            },
            {
              "id": "84f06a8f-b9a4-4ad0-bdc5-2ad0d3fb1d4a",
              "description": "Native American",
              "value": "Native American",
              "subCategoryId": "6b34af07-6053-449b-a903-fef3017d741b"
            },
            {
              "id": "230a358d-8522-40bd-a882-d25c8492249a",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "6b34af07-6053-449b-a903-fef3017d741b"
            },
            {
              "id": "c170dd5d-e39b-412d-8472-5ab82cb2aa02",
              "description": "Pacific Islander",
              "value": "Pacific Islander",
              "subCategoryId": "6b34af07-6053-449b-a903-fef3017d741b"
            }
          ]
        },
        {
          "id": "1b48cd71-2d41-4cce-91c9-2879baacd6d5",
          "description": "Gender",
          "value": "Gender",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "5f2c9443-60d3-48aa-a713-ce327f5df3f2",
              "description": "Female",
              "value": "Female",
              "subCategoryId": "1b48cd71-2d41-4cce-91c9-2879baacd6d5"
            },
            {
              "id": "68139f35-4a11-4ff8-809a-087e467e8995",
              "description": "Male",
              "value": "Male",
              "subCategoryId": "1b48cd71-2d41-4cce-91c9-2879baacd6d5"
            },
            {
              "id": "38c61ce5-5d21-44b6-92cc-ca71c91186bc",
              "description": "Male & Female",
              "value": "Male & Female",
              "subCategoryId": "1b48cd71-2d41-4cce-91c9-2879baacd6d5"
            }
          ]
        },
        {
          "id": "4ebecaeb-7933-4cb6-a578-4ef99e90f367",
          "description": "Location",
          "value": "Location",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "693c6362-7a93-4b0a-a019-aa1f0437196e",
              "description": "Bora Bora",
              "value": "Bora Bora",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "cddce8a2-074d-48a9-bfc4-16522353e484",
              "description": "Bora Bora",
              "value": "Bora Bora",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "109fefc4-9d4d-4398-b6e3-14e64a5b0db6",
              "description": "Coastal/Beach/Sand",
              "value": "Coastal/Beach/Sand",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "00d4fb66-1757-4936-adee-ecdfc40736af",
              "description": "Construction Site",
              "value": "Construction Site",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "55514f1b-6c50-4ac3-9c90-9561c546d14c",
              "description": "Courthouse",
              "value": "Courthouse",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "55ac1ac7-d6cb-4ab7-8b27-0df33cd4a3ce",
              "description": "Desert",
              "value": "Desert",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "27a12928-b39e-46a2-ae2f-60d770d24e4b",
              "description": "Forest",
              "value": "Forest",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "565039a0-6cd7-4999-909d-7ca3c39406f9",
              "description": "Green",
              "value": "Green",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "1fd31225-0841-42f6-a2d9-e72f159b8b6c",
              "description": "Gymnasium",
              "value": "Gymnasium",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "c2a93b5b-4bf7-4f01-9852-003f9013c072",
              "description": "Home",
              "value": "Home",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "7ce6346b-96a6-4e30-8ab7-d36fb30e5a5e",
              "description": "Hospital",
              "value": "Hospital",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "0d94afe7-0526-4130-81e9-d8cf1cf438ae",
              "description": "Lake/River",
              "value": "Lake/River",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "5bc92ae6-e7f7-451f-9c27-b24c53b5652e",
              "description": "Mailroom/Delivery",
              "value": "Mailroom/Delivery",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "4e1f1697-5b00-4560-9f74-5bf16aaf4dcb",
              "description": "Mass Transit",
              "value": "Mass Transit",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "cfb9f110-8e33-4e18-955c-54d41f95ab94",
              "description": "Mountains",
              "value": "Mountains",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "1289f8d1-2fef-49ad-84f0-47edeffb7e03",
              "description": "Office",
              "value": "Office",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "b748d5f8-528c-428a-9ed0-8143c3918d19",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "706be7b8-c61b-4dfb-804f-957ab7178139",
              "description": "Park",
              "value": "Park",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "c5c3bb76-f936-495d-a241-85356dc7e2d7",
              "description": "Restaurant/Cafe/Diner",
              "value": "Restaurant/Cafe/Diner",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "850e32f0-7610-4674-8797-603678c92ff3",
              "description": "School/Classroom",
              "value": "School/Classroom",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "02ff0a60-6dc1-4968-964c-c8be6767f2da",
              "description": "Stadium",
              "value": "Stadium",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            },
            {
              "id": "03d7c08d-b8f6-405a-9907-567d417e71f5",
              "description": "Street",
              "value": "Street",
              "subCategoryId": "4ebecaeb-7933-4cb6-a578-4ef99e90f367"
            }
          ]
        },
        {
          "id": "c8bc00e5-c2c4-4d37-8677-204577875f62",
          "description": "Number of People",
          "value": "Number of People",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "0daae20d-d411-424e-a7d1-111aa6d8adf5",
              "description": "Large Group (6+ People)",
              "value": "Large Group (6+ People)",
              "subCategoryId": "c8bc00e5-c2c4-4d37-8677-204577875f62"
            },
            {
              "id": "12d4368f-d5ca-4f39-b245-6223967bc0c0",
              "description": "None",
              "value": "None",
              "subCategoryId": "c8bc00e5-c2c4-4d37-8677-204577875f62"
            },
            {
              "id": "0ce6838e-be32-42c7-b4a1-9382d7e40859",
              "description": "One Person",
              "value": "One Person",
              "subCategoryId": "c8bc00e5-c2c4-4d37-8677-204577875f62"
            },
            {
              "id": "cd810368-0b08-4598-89b0-88f37e12cb35",
              "description": "Small Group (3-5 People)",
              "value": "Small Group (3-5 People)",
              "subCategoryId": "c8bc00e5-c2c4-4d37-8677-204577875f62"
            },
            {
              "id": "9fd38d31-5180-45b9-a3e3-d84d1a54a4fd",
              "description": "Two People",
              "value": "Two People",
              "subCategoryId": "c8bc00e5-c2c4-4d37-8677-204577875f62"
            }
          ]
        },
        {
          "id": "8ea2120a-f120-4971-981d-39730823a220",
          "description": "Region Specific",
          "value": "Region Specific",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "cf33c931-1cba-45e3-ae87-8b966f273b76",
              "description": "Mid-Atlantic",
              "value": "Mid-Atlantic",
              "subCategoryId": "8ea2120a-f120-4971-981d-39730823a220"
            },
            {
              "id": "6b15dca2-7746-4d04-9416-45996254d1a6",
              "description": "Midwest",
              "value": "Midwest",
              "subCategoryId": "8ea2120a-f120-4971-981d-39730823a220"
            },
            {
              "id": "3577afdf-e3e4-458e-aaaa-fa2af5ae4ffd",
              "description": "Northeast",
              "value": "Northeast",
              "subCategoryId": "8ea2120a-f120-4971-981d-39730823a220"
            },
            {
              "id": "4070ce82-1163-4442-b39a-c96472101a2d",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "8ea2120a-f120-4971-981d-39730823a220"
            },
            {
              "id": "cf46b86c-84cb-4319-b4f6-4abf8be3b650",
              "description": "Pacific Northwest",
              "value": "Pacific Northwest",
              "subCategoryId": "8ea2120a-f120-4971-981d-39730823a220"
            },
            {
              "id": "aea3bab0-52c3-4318-bd68-4f363da71a45",
              "description": "Rocky Mountain",
              "value": "Rocky Mountain",
              "subCategoryId": "8ea2120a-f120-4971-981d-39730823a220"
            },
            {
              "id": "f409d3cf-3339-4834-83d5-565a8211c75c",
              "description": "Southeast",
              "value": "Southeast",
              "subCategoryId": "8ea2120a-f120-4971-981d-39730823a220"
            },
            {
              "id": "e6eb6dcc-d0a2-45d5-894d-1c895bb8b302",
              "description": "Southwest",
              "value": "Southwest",
              "subCategoryId": "8ea2120a-f120-4971-981d-39730823a220"
            },
            {
              "id": "9c89671c-5c38-4896-90dd-658167245319",
              "description": "West",
              "value": "West",
              "subCategoryId": "8ea2120a-f120-4971-981d-39730823a220"
            }
          ]
        },
        {
          "id": "5822ed2e-6c86-4c2a-abea-be55a7231591",
          "description": "Season Specific",
          "value": "Season Specific",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "8009b2e8-4630-43a7-96d4-ad2ca46451a3",
              "description": "Fall",
              "value": "Fall",
              "subCategoryId": "5822ed2e-6c86-4c2a-abea-be55a7231591"
            },
            {
              "id": "dbd281de-0917-477c-aa0e-0d780b24ad49",
              "description": "Spring",
              "value": "Spring",
              "subCategoryId": "5822ed2e-6c86-4c2a-abea-be55a7231591"
            },
            {
              "id": "2ca761bf-815b-49da-8e03-025c8f04c3b4",
              "description": "Summer",
              "value": "Summer",
              "subCategoryId": "5822ed2e-6c86-4c2a-abea-be55a7231591"
            },
            {
              "id": "11ccb11c-4552-4ac1-8127-3409f873ddba",
              "description": "Winter",
              "value": "Winter",
              "subCategoryId": "5822ed2e-6c86-4c2a-abea-be55a7231591"
            }
          ]
        },
        {
          "id": "50cedcf8-065b-4010-ab2b-b7fbc8856896",
          "description": "Store Photography",
          "value": "Store Photography",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "89db9b9c-34b9-4d21-b79e-7b0bf3220b86",
              "description": "Evo",
              "value": "Evo",
              "subCategoryId": "50cedcf8-065b-4010-ab2b-b7fbc8856896"
            },
            {
              "id": "9a6700c4-0316-40b6-9898-2d529e3b2a5f",
              "description": "Exterior",
              "value": "Exterior",
              "subCategoryId": "50cedcf8-065b-4010-ab2b-b7fbc8856896"
            },
            {
              "id": "3767d2ae-d0b6-4c34-ac0b-91b39b1f2785",
              "description": "Free Standing",
              "value": "Free Standing",
              "subCategoryId": "50cedcf8-065b-4010-ab2b-b7fbc8856896"
            },
            {
              "id": "3db961b2-38e8-44c6-915f-3bef62638c2e",
              "description": "Interior",
              "value": "Interior",
              "subCategoryId": "50cedcf8-065b-4010-ab2b-b7fbc8856896"
            },
            {
              "id": "44eb6d5c-e1e9-499f-bd34-b5b7b876f5b2",
              "description": "Kiosk",
              "value": "Kiosk",
              "subCategoryId": "50cedcf8-065b-4010-ab2b-b7fbc8856896"
            },
            {
              "id": "47838fc8-4a0d-4ef9-a053-bb224ee3e299",
              "description": "Legacy",
              "value": "Legacy",
              "subCategoryId": "50cedcf8-065b-4010-ab2b-b7fbc8856896"
            },
            {
              "id": "2f097f62-9bfc-4908-a88b-0c37fd558993",
              "description": "Mall",
              "value": "Mall",
              "subCategoryId": "50cedcf8-065b-4010-ab2b-b7fbc8856896"
            },
            {
              "id": "eeca010e-61e2-44a2-b572-5abded19d1f6",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "50cedcf8-065b-4010-ab2b-b7fbc8856896"
            },
            {
              "id": "bc85c7c4-9506-4ac3-8501-1032d805a415",
              "description": "SWAS",
              "value": "SWAS",
              "subCategoryId": "50cedcf8-065b-4010-ab2b-b7fbc8856896"
            },
            {
              "id": "1d118f8a-715a-48b6-aa3b-81e23df2fc62",
              "description": "Strip Mall",
              "value": "Strip Mall",
              "subCategoryId": "50cedcf8-065b-4010-ab2b-b7fbc8856896"
            }
          ]
        },
        {
          "id": "b8acea32-28ef-43ee-9ba2-17cff3603481",
          "description": "Theme",
          "value": "Theme",
          "categoryId": "76007f55-b998-4883-8e53-64c5ecec4a51",
          "tags": [
            {
              "id": "d41efe62-6730-4077-ae77-fabd44df935e",
              "description": "Administrative",
              "value": "Administrative",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "7f392214-ba2d-4ec0-ae12-04a7715ce018",
              "description": "Agriculture",
              "value": "Agriculture",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "adfc0f4e-ce18-49f3-b219-6ae7c0e8f514",
              "description": "Artist",
              "value": "Artist",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "f72ab608-91d7-476f-bb53-c35c7cad177b",
              "description": "Business",
              "value": "Business",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "3b781f0f-67d7-442a-84d6-bc11d595e9ac",
              "description": "Casual",
              "value": "Casual",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "12373030-fbe6-4430-a665-4de22e8bfd4a",
              "description": "Construction",
              "value": "Construction",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "0f422604-dcc9-4d41-8f42-0e9c2d336591",
              "description": "Distribution",
              "value": "Distribution",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "bd81654a-4202-4ef2-ab6a-dd3ba3700f98",
              "description": "Education",
              "value": "Education",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "7d6cc187-f365-45dd-92f4-7ef91dc248df",
              "description": "Emergency Response",
              "value": "Emergency Response",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "602499fa-4582-4a10-9acc-ece802b961f4",
              "description": "Energy & Utilities",
              "value": "Energy & Utilities",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "f3e0517f-ed0d-4142-9af2-ea495c944a57",
              "description": "Engineering",
              "value": "Engineering",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "0be9d1dc-5e1c-48e7-9113-9c4659e3de45",
              "description": "Event – Casual",
              "value": "Event – Casual",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "f250eece-ee9d-4b11-8312-fdd98b0d6c4f",
              "description": "Event – Formal",
              "value": "Event – Formal",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "917af61f-c138-49ef-9b4a-5727878a548e",
              "description": "Exercise",
              "value": "Exercise",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "32e48d3d-80a3-4602-a1af-54731b48caa0",
              "description": "Family",
              "value": "Family",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "9764724a-5e0e-4e60-847b-1d381221879c",
              "description": "Federal – Civilian",
              "value": "Federal – Civilian",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "d33c84bf-08f4-46e9-93f7-ea6da020c21f",
              "description": "Federal – Defense",
              "value": "Federal – Defense",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "4f904f79-6dea-4f63-a9b7-dcb393d2e549",
              "description": "Finance",
              "value": "Finance",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "12e95712-cdf9-4a16-8ff0-b49ce84403b0",
              "description": "Gaming",
              "value": "Gaming",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "ad531a77-bf7a-4a8c-b180-4d607cbb04ef",
              "description": "Health Care",
              "value": "Health Care",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "314efc07-48a5-4b63-883a-8e2e60785959",
              "description": "Industrial",
              "value": "Industrial",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "3389bc02-df8c-4d37-93e4-917130b10dbb",
              "description": "Installation",
              "value": "Installation",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "d1c84d56-a2f6-4129-b0e4-b7dc06d33dea",
              "description": "Insurance",
              "value": "Insurance",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "4c70b889-846b-48e1-89f3-e014fa03223a",
              "description": "Law Enforcement",
              "value": "Law Enforcement",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "00fbc7c2-6b13-4180-ae05-d4a162f9702a",
              "description": "Legal Services",
              "value": "Legal Services",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "fc739766-8567-46d6-bf37-0204d7882dd7",
              "description": "Manufacturing",
              "value": "Manufacturing",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "4921b135-cd37-4836-970a-4de29d149da4",
              "description": "Media/Broadcast",
              "value": "Media/Broadcast",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "95c9e535-4821-4d5a-ac94-7d8a1fa01670",
              "description": "Music",
              "value": "Music",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "45e2331d-a989-4309-a9a4-efbdeeef3f02",
              "description": "Other",
              "value": "Other",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "fd6fe524-67de-40c2-8d09-1d0950cab30c",
              "description": "Pharmaceutical",
              "value": "Pharmaceutical",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "eec8f11d-488b-4b7f-bee4-2ae6dc8cd2e8",
              "description": "Professional Services",
              "value": "Professional Services",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "fe822fad-fd49-434c-8460-58ea6418c10b",
              "description": "Public Safety",
              "value": "Public Safety",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "c4a4598f-07e6-497a-86ee-05deda56fe48",
              "description": "Public Sector",
              "value": "Public Sector",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "ff4e54e3-ea6c-4c46-856d-1bd3f04bdb46",
              "description": "Real Estate",
              "value": "Real Estate",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "c7fbce74-e39f-4287-beb7-f93edbe619b5",
              "description": "Recreation",
              "value": "Recreation",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "46ec8c77-fe68-4381-803a-e4f6b68c7f02",
              "description": "Research/Science",
              "value": "Research/Science",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "f4fc543c-98d0-4dcf-a316-7f9af8764d84",
              "description": "Retail",
              "value": "Retail",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "dfd2ad2c-fcd0-4122-9f7e-825e1e78135c",
              "description": "Safety Gear",
              "value": "Safety Gear",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "7babd8ce-e712-4e26-8ccd-5c60ec23eff6",
              "description": "Sports – Fan",
              "value": "Sports – Fan",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "6132780e-7533-4424-8493-dc29f4d7c6aa",
              "description": "Sports – Professional",
              "value": "Sports – Professional",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "4b79c4b9-92df-42a2-a5db-1e1daa47da03",
              "description": "State & Local",
              "value": "State & Local",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "449be360-f553-492f-ae4e-c13caeac41f1",
              "description": "Technology",
              "value": "Technology",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "517357cf-b073-406f-95f7-8ab3c0120ff2",
              "description": "The Network",
              "value": "The Network",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "1ed7f98b-9f26-4507-a733-c33dc94d6128",
              "description": "Transportation – Distribution",
              "value": "Transportation – Distribution",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "55e83619-918f-42c2-8411-06295460c059",
              "description": "Transportation – Fleet",
              "value": "Transportation – Fleet",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "3aa0a656-6a21-4ebf-b471-f10acc89af57",
              "description": "Transportation – Mass",
              "value": "Transportation – Mass",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "73787a3f-8dab-4806-9a09-d9226d8f6f25",
              "description": "Travel",
              "value": "Travel",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "c76d913d-1cee-4184-817e-bd5747bc039e",
              "description": "Utilities",
              "value": "Utilities",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "82fdae84-d2db-4c2f-b3c6-1e719082da37",
              "description": "Vehicle",
              "value": "Vehicle",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "3a33e1f1-f195-4304-8f53-b97e51339e35",
              "description": "Verizon Employee",
              "value": "Verizon Employee",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            },
            {
              "id": "125776a9-5453-4939-ae73-bc0367d1104c",
              "description": "Vocational",
              "value": "Vocational",
              "subCategoryId": "b8acea32-28ef-43ee-9ba2-17cff3603481"
            }
          ]
        }
      ]
    }
  ],
  "correlationId": "U0lEOmMwNWI0ODIzLTk3YjQtNDU3MC1hMTE1LTIzMmRkMDMzMWRhMXxQSDowfFJJRDpjZDBhZjNhNi0zMDNmLTRjMzgtOTkwYi0yYTg5ZmE3MDJjODE=",
  "filters": {
    "restricted": false,
    "expired": true,
    "expiresAfter": true,
    "expiresOn": oneYearsTime,
    "selectedTags": [
      {
        "id": "8e04ac67-b08a-4f88-ad39-194dd6d80f02",
        "value": "Adult 32-50"
      }
    ],
    "submit": false
  },
  "savedSearch": null,
  "savedSearchApplied": false,
};

export const allPackagingManagerProjectsReporting = {
  results: [
    {
      "id": "dfdd2498-e142-47c3-b02a-8da3c617e082",
      "createdOn": "2024-12-02T16:16:28.8896472Z",
      "modifiedOn": "2024-12-02T16:16:28.8896472Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "Packaging Support",
      "jobCode": "PSUP-0000017",
      "status": "new",
      "name": "Kat Packaging Support Project With A Very Long Name Test 1",
      "score": 0
    },
    {
      "id": "2fb1c920-c1a9-4930-963f-952144ae7195",
      "createdOn": "2024-11-21T18:47:21.488721Z",
      "modifiedOn": "2024-11-21T19:41:44.502905Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000016",
      "status": "new",
      "name": "Kat Multi File Upload Test",
      "score": 0
    },
    {
      "id": "01c02090-cf47-4c1b-9b01-fc7166f5488d",
      "createdOn": "2024-11-20T19:49:57.07672Z",
      "modifiedOn": "2024-11-21T17:42:22.94923Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000015",
      "status": "new",
      "name": "Kat New Product Project Test 3",
      "score": 0
    },
    {
      "id": "fcabbda4-9e97-4ca4-81cd-2f2fd4242d70",
      "createdOn": "2024-11-20T18:13:55.577097Z",
      "modifiedOn": "2024-11-21T13:45:34.093894Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000012",
      "status": "new",
      "name": "Kat New Product Project Test 1",
      "score": 0
    },
    {
      "id": "9183541a-9f15-44c9-874f-6ee95b254c55",
      "createdOn": "2024-11-20T19:28:25.255872Z",
      "modifiedOn": "2024-11-20T19:28:25.255872Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000014",
      "status": "new",
      "name": "Kat New Product Project Test 2",
      "score": 0
    },
    {
      "id": "b57c5f00-4544-4589-8d85-93deddd0b143",
      "createdOn": "2024-11-20T19:03:31.032919Z",
      "modifiedOn": "2024-11-20T19:03:31.032919Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000013",
      "status": "new",
      "name": "Preview New Product test",
      "score": 0
    },
    {
      "id": "5eb9999d-fd34-4606-b105-29539b4bcc60",
      "createdOn": "2024-11-20T18:10:38.476756Z",
      "modifiedOn": "2024-11-20T18:11:58.2682544Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "Packaging Support",
      "jobCode": "PSUP-0000011",
      "status": "new",
      "name": "Kat Support Test Project 1",
      "score": 0
    },
    {
      "id": "08f223db-6e38-499f-bb15-cd6321ec28df",
      "createdOn": "2024-11-20T14:14:21.301966Z",
      "modifiedOn": "2024-11-20T14:14:21.301966Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000010",
      "status": "new",
      "name": "Test Brief file upload",
      "score": 0
    },
    {
      "id": "0db113ed-e514-410c-949f-c8eb0ca5f29e",
      "createdOn": "2024-11-19T14:24:43.144898Z",
      "modifiedOn": "2024-11-19T21:18:55.525608Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000002",
      "status": "in-progress",
      "name": "Chris Test Project 1",
      "score": 0
    },
    {
      "id": "a46d4cbb-732c-4d0e-8509-6b7bedeac954",
      "createdOn": "2024-11-19T20:21:51.641184Z",
      "modifiedOn": "2024-11-19T20:21:51.641184Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000009",
      "status": "new",
      "name": "Chris Test prepaid",
      "score": 0
    }
  ],
  filters: {
    "unfilteredTotal": 11,
    "statusFilterResponse": [
      {
        "name": "new",
        "count": 10
      },
      {
        "name": "in-progress",
        "count": 1
      }
    ],
    "groupTypeFilterResponse": [
      {
        "id": "b054f4f5-141e-4f3f-8fe7-6349380fb0b9",
        "name": "Other",
        "groups": [
          {
            "id": "380a6048-7524-472f-9181-326309630e01",
            "name": "EP+Co Packaging",
            "count": 5
          },
          {
            "id": "e48e1eab-38ab-4a01-b7d2-8df269452be0",
            "name": "Pentagram Packaging",
            "count": 3
          }
        ]
      },
      {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "groups": [
          {
            "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
            "name": "Verizon New Product Packaging Lead",
            "count": 3
          },
          {
            "id": "9c7fddaf-50b0-44f8-8e20-05032ce0d996",
            "name": "Verizon Packaging Support Lead",
            "count": 1
          },
          {
            "id": "a1f7a82c-c6e7-4563-b981-efcfb89783c2",
            "name": "Chris General user OEM",
            "count": 1
          }
        ]
      }
    ],
    "projectTypeFilterResponse": [
      {
        "id": "93c7d30c-a04f-4431-acc0-901e1dcb7de0",
        "name": "New Product",
        "count": 6
      },
      {
        "id": "0412c3d0-190c-4fe8-9bf4-6972ddf04a39",
        "name": "Packaging Support",
        "count": 3
      },
      {
        "id": "6456b525-0211-486b-97aa-d65b8ed70c73",
        "name": "Prepaid Device",
        "count": 2
      }
    ]
  },
  total: 11,
  totalPages: 2,
}
export const allPackagingManagerProjectsReportingPage2 = {
  results: [
    {
      "id": "3d351c3b-069e-4602-a1e6-6a08df53f67d",
      "createdOn": "2024-11-19T20:14:11.8184665Z",
      "modifiedOn": "2024-11-19T20:14:11.8184664Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Packaging Support",
      "jobCode": "PSUP-0000007",
      "status": "new",
      "name": "Chris test Packaging support",
      "score": 0
    }
  ],
  filters: {
    "unfilteredTotal": 11,
    "statusFilterResponse": [
      {
        "name": "new",
        "count": 10
      },
      {
        "name": "in-progress",
        "count": 1
      }
    ],
    "groupTypeFilterResponse": [
      {
        "id": "b054f4f5-141e-4f3f-8fe7-6349380fb0b9",
        "name": "Other",
        "groups": [
          {
            "id": "380a6048-7524-472f-9181-326309630e01",
            "name": "EP+Co Packaging",
            "count": 5
          },
          {
            "id": "e48e1eab-38ab-4a01-b7d2-8df269452be0",
            "name": "Pentagram Packaging",
            "count": 3
          }
        ]
      },
      {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "groups": [
          {
            "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
            "name": "Verizon New Product Packaging Lead",
            "count": 3
          },
          {
            "id": "9c7fddaf-50b0-44f8-8e20-05032ce0d996",
            "name": "Verizon Packaging Support Lead",
            "count": 1
          },
          {
            "id": "a1f7a82c-c6e7-4563-b981-efcfb89783c2",
            "name": "Chris General user OEM",
            "count": 1
          }
        ]
      }
    ],
    "projectTypeFilterResponse": [
      {
        "id": "93c7d30c-a04f-4431-acc0-901e1dcb7de0",
        "name": "New Product",
        "count": 6
      },
      {
        "id": "0412c3d0-190c-4fe8-9bf4-6972ddf04a39",
        "name": "Packaging Support",
        "count": 3
      },
      {
        "id": "6456b525-0211-486b-97aa-d65b8ed70c73",
        "name": "Prepaid Device",
        "count": 2
      }
    ]
  },
  total: 11,
  totalPages: 2,
}

export const packagingManagerProjectsReporting25 = {
  results: [
    {
      "id": "01c02090-cf47-4c1b-9b01-fc7166f5488d",
      "createdOn": "2024-11-20T19:49:57.07672Z",
      "modifiedOn": "2024-12-02T21:00:55.0392846Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000015",
      "status": "new",
      "name": "Kat New Product Project Test 3",
      "score": 0
    },
    {
      "id": "dfdd2498-e142-47c3-b02a-8da3c617e082",
      "createdOn": "2024-12-02T16:16:28.8896472Z",
      "modifiedOn": "2024-12-02T16:16:28.8896472Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "Packaging Support",
      "jobCode": "PSUP-0000017",
      "status": "new",
      "name": "Kat Packaging Support Project With A Very Long Name Test 1",
      "score": 0
    },
    {
      "id": "2fb1c920-c1a9-4930-963f-952144ae7195",
      "createdOn": "2024-11-21T18:47:21.488721Z",
      "modifiedOn": "2024-11-21T19:41:44.502905Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000016",
      "status": "new",
      "name": "Kat Multi File Upload Test",
      "score": 0
    },
    {
      "id": "fcabbda4-9e97-4ca4-81cd-2f2fd4242d70",
      "createdOn": "2024-11-20T18:13:55.577097Z",
      "modifiedOn": "2024-11-21T13:45:34.093894Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000012",
      "status": "new",
      "name": "Kat New Product Project Test 1",
      "score": 0
    },
    {
      "id": "9183541a-9f15-44c9-874f-6ee95b254c55",
      "createdOn": "2024-11-20T19:28:25.255872Z",
      "modifiedOn": "2024-11-20T19:28:25.255872Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000014",
      "status": "new",
      "name": "Kat New Product Project Test 2",
      "score": 0
    },
    {
      "id": "b57c5f00-4544-4589-8d85-93deddd0b143",
      "createdOn": "2024-11-20T19:03:31.032919Z",
      "modifiedOn": "2024-11-20T19:03:31.032919Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000013",
      "status": "new",
      "name": "Preview New Product test",
      "score": 0
    },
    {
      "id": "5eb9999d-fd34-4606-b105-29539b4bcc60",
      "createdOn": "2024-11-20T18:10:38.476756Z",
      "modifiedOn": "2024-11-20T18:11:58.2682544Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "projectType": "Packaging Support",
      "jobCode": "PSUP-0000011",
      "status": "new",
      "name": "Kat Support Test Project 1",
      "score": 0
    },
    {
      "id": "08f223db-6e38-499f-bb15-cd6321ec28df",
      "createdOn": "2024-11-20T14:14:21.301966Z",
      "modifiedOn": "2024-11-20T14:14:21.301966Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000010",
      "status": "new",
      "name": "Test Brief file upload",
      "score": 0
    },
    {
      "id": "0db113ed-e514-410c-949f-c8eb0ca5f29e",
      "createdOn": "2024-11-19T14:24:43.144898Z",
      "modifiedOn": "2024-11-19T21:18:55.525608Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000002",
      "status": "in-progress",
      "name": "Chris Test Project 1",
      "score": 0
    },
    {
      "id": "a46d4cbb-732c-4d0e-8509-6b7bedeac954",
      "createdOn": "2024-11-19T20:21:51.641184Z",
      "modifiedOn": "2024-11-19T20:21:51.641184Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000009",
      "status": "new",
      "name": "Chris Test prepaid",
      "score": 0
    },
    {
      "id": "3d351c3b-069e-4602-a1e6-6a08df53f67d",
      "createdOn": "2024-11-19T20:14:11.8184665Z",
      "modifiedOn": "2024-11-19T20:14:11.8184664Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Packaging Support",
      "jobCode": "PSUP-0000007",
      "status": "new",
      "name": "Chris test Packaging support",
      "score": 0
    }
  ],
  filters: {
    "unfilteredTotal": 11,
    "statusFilterResponse": [
      {
        "name": "new",
        "count": 10
      },
      {
        "name": "in-progress",
        "count": 1
      }
    ],
    "groupTypeFilterResponse": [
      {
        "id": "b054f4f5-141e-4f3f-8fe7-6349380fb0b9",
        "name": "Other",
        "groups": [
          {
            "id": "380a6048-7524-472f-9181-326309630e01",
            "name": "EP+Co Packaging",
            "count": 5
          },
          {
            "id": "e48e1eab-38ab-4a01-b7d2-8df269452be0",
            "name": "Pentagram Packaging",
            "count": 3
          }
        ]
      },
      {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "groups": [
          {
            "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
            "name": "Verizon New Product Packaging Lead",
            "count": 3
          },
          {
            "id": "9c7fddaf-50b0-44f8-8e20-05032ce0d996",
            "name": "Verizon Packaging Support Lead",
            "count": 1
          },
          {
            "id": "a1f7a82c-c6e7-4563-b981-efcfb89783c2",
            "name": "Chris General user OEM",
            "count": 1
          }
        ]
      }
    ],
    "projectTypeFilterResponse": [
      {
        "id": "93c7d30c-a04f-4431-acc0-901e1dcb7de0",
        "name": "New Product",
        "count": 6
      },
      {
        "id": "0412c3d0-190c-4fe8-9bf4-6972ddf04a39",
        "name": "Packaging Support",
        "count": 3
      },
      {
        "id": "6456b525-0211-486b-97aa-d65b8ed70c73",
        "name": "Prepaid Device",
        "count": 2
      }
    ]
  },
  total: 11,
  totalPages: 1,
};

export const emptyPackagingManagerProjectsReporting = {
  results: [],
  filters: {
    "unfilteredTotal": 0,
    "statusFilterResponse": [
      {
        "name": "new",
        "count": 10
      },
      {
        "name": "in-progress",
        "count": 1
      }
    ],
    "groupTypeFilterResponse": [
      {
        "id": "b054f4f5-141e-4f3f-8fe7-6349380fb0b9",
        "name": "Other",
        "groups": [
          {
            "id": "380a6048-7524-472f-9181-326309630e01",
            "name": "EP+Co Packaging",
            "count": 5
          },
          {
            "id": "e48e1eab-38ab-4a01-b7d2-8df269452be0",
            "name": "Pentagram Packaging",
            "count": 3
          }
        ]
      },
      {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "groups": [
          {
            "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
            "name": "Verizon New Product Packaging Lead",
            "count": 3
          },
          {
            "id": "9c7fddaf-50b0-44f8-8e20-05032ce0d996",
            "name": "Verizon Packaging Support Lead",
            "count": 1
          },
          {
            "id": "a1f7a82c-c6e7-4563-b981-efcfb89783c2",
            "name": "Chris General user OEM",
            "count": 1
          }
        ]
      }
    ],
    "projectTypeFilterResponse": [
      {
        "id": "93c7d30c-a04f-4431-acc0-901e1dcb7de0",
        "name": "New Product",
        "count": 6
      },
      {
        "id": "0412c3d0-190c-4fe8-9bf4-6972ddf04a39",
        "name": "Packaging Support",
        "count": 3
      },
      {
        "id": "6456b525-0211-486b-97aa-d65b8ed70c73",
        "name": "Prepaid Device",
        "count": 2
      }
    ]
  },
  total: 0,
  totalPages: 1,
};

export const packagingManagerAwsPolicy = {
  accessKey: "accessKey",
  bucket: "bucket",
  policy: "policy",
  signature: "signature",
  uploadDirectory: "uploadDirectory"
};

export const groupNoPackagingAccess = {
  "Id": "85da4af4-4320-4969-b4f9-90a8596edb55",
  "Name": "140 Photography Group",
  "Company": "140 Photography Group",
  "Status": "Active",
  "groupType": undefined,
  "groupPermission": undefined
};

export const groupWithPackagingAccess = {
  "Id": "85da4af4-4320-4969-b4f9-90a8596edb55",
  "Name": "140 Photography Group",
  "Company": "140 Photography Group",
  "Status": "Active",
  "groupType": {
    "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
    "name": "Brand Lead",
    "description": "Brand Lead",
    "clientCode": "PKM"
  },
  "groupPermission": {
    "id": "3ec04532-5c9f-4329-b08d-007a112672a5",
    "name": "Admin",
    "value": "pkm_admin",
    "clientCode": "PKM"
  }
};

export const pmGroupTypes = [
  {
    "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
    "name": "Brand Lead",
    "description": "Brand Lead",
    "clientCode": "PKM"
  },
  {
    "id": "618d5385-5337-4acd-84fe-fbd96560600b",
    "name": "OEM",
    "description": "OEM",
    "clientCode": "PKM"
  },
  {
    "id": "b054f4f5-141e-4f3f-8fe7-6349380fb0b9",
    "name": "Other",
    "description": "Other",
    "clientCode": "PKM"
  },
  {
    "id": "d442858d-53d2-43bb-ac34-eb680f14fe43",
    "name": "Packaging Supplier",
    "description": "Packaging Supplier",
    "clientCode": "PKM"
  },
  {
    "id": "789d208b-a889-4c6d-8f72-abecf49a9862",
    "name": "Product Lead",
    "description": "Product Lead",
    "clientCode": "PKM"
  }
];

export const pmGroupTypesResponse = {
  "data": [
    {
      "Id": "85da4af4-4320-4969-b4f9-90a8596edb55",
      "Name": "140 Photography Group",
      "Company": "140 Photography Group",
      "Status": "Active",
      "groupType": {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "description": "Brand Lead",
        "clientCode": "PKM"
      },
      "groupPermission": {
        "id": "3ec04532-5c9f-4329-b08d-007a112672a5",
        "name": "Admin",
        "value": "pkm_admin",
        "clientCode": "PKM"
      }
    },
    {
      "Id": "a1f7a82c-c6e7-4563-b981-efcfb89783c2",
      "Name": "Chris General user OEM",
      "Company": "Chris General user OEM",
      "Status": "Active",
      "groupType": {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "description": "Brand Lead",
        "clientCode": "PKM"
      },
      "groupPermission": {
        "id": "8c79b24e-f788-414e-9c35-626c02890d00",
        "name": "General",
        "value": "pkm_general",
        "clientCode": "PKM"
      }
    },
    {
      "Id": "380a6048-7524-472f-9181-326309630e01",
      "Name": "EP+Co Packaging",
      "Company": "EP+Co Packaging",
      "Status": "Active",
      "groupType": {
        "id": "b054f4f5-141e-4f3f-8fe7-6349380fb0b9",
        "name": "Other",
        "description": "Other",
        "clientCode": "PKM"
      },
      "groupPermission": {
        "id": "3ec04532-5c9f-4329-b08d-007a112672a5",
        "name": "Admin",
        "value": "pkm_admin",
        "clientCode": "PKM"
      }
    },
    {
      "Id": "e48e1eab-38ab-4a01-b7d2-8df269452be0",
      "Name": "Pentagram Packaging",
      "Company": "Pentagram Packaging",
      "Status": "Active",
      "groupType": {
        "id": "b054f4f5-141e-4f3f-8fe7-6349380fb0b9",
        "name": "Other",
        "description": "Other",
        "clientCode": "PKM"
      },
      "groupPermission": {
        "id": "8c79b24e-f788-414e-9c35-626c02890d00",
        "name": "General",
        "value": "pkm_general",
        "clientCode": "PKM"
      }
    },
    {
      "Id": "2ae36a49-3a40-4b2c-8354-cb94d18f34c0",
      "Name": "Verizon 140 Studio  Packaging",
      "Company": "Verizon 140 Studio  Packaging",
      "Status": "Active",
      "groupType": {
        "id": "b054f4f5-141e-4f3f-8fe7-6349380fb0b9",
        "name": "Other",
        "description": "Other",
        "clientCode": "PKM"
      },
      "groupPermission": {
        "id": "8c79b24e-f788-414e-9c35-626c02890d00",
        "name": "General",
        "value": "pkm_general",
        "clientCode": "PKM"
      }
    },
    {
      "Id": "274b5c26-223a-43b0-8c05-36adb285469a",
      "Name": "Verizon Brand Team",
      "Company": "VZ Brand Team",
      "Status": "Active",
      "groupType": {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "description": "Brand Lead",
        "clientCode": "PKM"
      },
      "groupPermission": {
        "id": "8c79b24e-f788-414e-9c35-626c02890d00",
        "name": "General",
        "value": "pkm_general",
        "clientCode": "PKM"
      }
    },
    {
      "Id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
      "Name": "Verizon New Product Packaging Lead",
      "Company": "Verizon New Product Packaging Lead",
      "Status": "Active",
      "groupType": {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "description": "Brand Lead",
        "clientCode": "PKM"
      },
      "groupPermission": {
        "id": "3ec04532-5c9f-4329-b08d-007a112672a5",
        "name": "Admin",
        "value": "pkm_admin",
        "clientCode": "PKM"
      }
    },
    {
      "Id": "9c7fddaf-50b0-44f8-8e20-05032ce0d996",
      "Name": "Verizon Packaging Support Lead",
      "Company": "Verizon Packaging Support Lead",
      "Status": "Active",
      "groupType": {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "description": "Brand Lead",
        "clientCode": "PKM"
      },
      "groupPermission": {
        "id": "3ec04532-5c9f-4329-b08d-007a112672a5",
        "name": "Admin",
        "value": "pkm_admin",
        "clientCode": "PKM"
      }
    },
    {
      "Id": "46c63bc1-94ef-4df6-971d-7734d77af322",
      "Name": "Verizon Prepaid Packaging Lead",
      "Company": "Verizon Prepaid Packaging Lead",
      "Status": "Active",
      "groupType": {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "description": "Brand Lead",
        "clientCode": "PKM"
      },
      "groupPermission": {
        "id": "3ec04532-5c9f-4329-b08d-007a112672a5",
        "name": "Admin",
        "value": "pkm_admin",
        "clientCode": "PKM"
      }
    }
  ],
  "meta": {
    "page": 0,
    "perPage": 0,
    "totalRecords": 9,
    "totalPages": 0,
    "orderByOptions": [
      "Name",
      "Status"
    ],
    "searchableColumns": [
      "Name",
      "Status"
    ]
  }
};

export const pmGroupPermissions = [
  {
    "id": "3ec04532-5c9f-4329-b08d-007a112672a5",
    "name": "Admin",
    "value": "pkm_admin",
    "clientCode": "PKM"
  },
  {
    "id": "8c79b24e-f788-414e-9c35-626c02890d00",
    "name": "General",
    "value": "pkm_general",
    "clientCode": "PKM"
  }
];

export const pmProjectBriefs = [
  {
    "instanceId": "6337b5d1-6873-486f-96f3-487ecb2b92cb",
    "blueprintId": "205d6088-eb5e-45fc-885d-0706b1195313",
    "name": "Kickoff Brief",
    "isComplete": false
  },
  {
    "instanceId": "93bd8e5d-e032-4122-bfff-e1b3b92b7960",
    "blueprintId": "8900faf1-28b6-4558-a069-1be4a7181def",
    "name": "New Product Brief",
    "isComplete": false
  },
  {
    "instanceId": "b74b316e-b8e8-469d-94d3-a9279bcb4d01",
    "blueprintId": "8900faf1-28b6-4558-a069-1be4a7181def",
    "name": "New Product Brief",
    "isComplete": false
  },
  {
    "instanceId": "7e49e566-e3ad-4b24-a51a-e34be20468dc",
    "blueprintId": "8900faf1-28b6-4558-a069-1be4a7181def",
    "name": "New Product Brief",
    "isComplete": false
  },
  {
    "instanceId": "6f6ec969-e2e9-40be-b0cd-a3e451eee4f6",
    "blueprintId": "8900faf1-28b6-4558-a069-1be4a7181def",
    "name": "New Product Brief",
    "isComplete": true
  }
];

export const pmAllProjectTasks = [
  {
    "taskInstanceId": "1e2d1104-6331-4166-9802-c80b8b7868eb",
    "stepType": "form",
    "status": "in-progress",
    "name": "Complete kickoff brief",
    "dueDate": null,
    "checklist": [
      {
        "id": "f13a1ed8-309c-498f-b377-b653a7812c15",
        "label": "Fill out the brief",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "05fef1b0-0cd8-4782-8d8e-57bb740dfee4",
        "name": "OEM"
      }
    ]
  },
  {
    "taskInstanceId": "105350fd-cd2e-4bac-893f-ad230d46b7cc",
    "stepType": "checklist",
    "status": "completed",
    "name": "Approve kickoff brief",
    "dueDate": null,
    "checklist": [
      {
        "id": "9707d127-5a0f-41a9-9770-17dd2b67cad6",
        "label": "Approval submitted",
        "isComplete": true,
        "completedOn": "2024-12-04T18:17:39.05Z"
      }
    ],
    "assignedToTeams": [
      {
        "id": "522e0eaa-9048-4276-8dbc-69b11de77bbd",
        "name": "Verizon Packaging Lead"
      }
    ]
  },
  {
    "taskInstanceId": "f86327e4-da48-47e7-ac78-d823f5381242",
    "stepType": "form",
    "status": "in-progress",
    "name": "Complete brief",
    "dueDate": null,
    "checklist": [
      {
        "id": "54aecd4a-248d-465b-be94-366ed79feef3",
        "label": "Fill out the brief",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "19f93829-018e-4d65-a17c-a4f079f11b5d",
    "stepType": "checklist",
    "status": "completed",
    "name": "Approve brief",
    "dueDate": null,
    "checklist": [
      {
        "id": "e518b84b-cc41-417a-ab64-86b076c0f508",
        "label": " Approval submitted",
        "isComplete": true,
        "completedOn": "2024-12-04T18:32:35.131Z"
      }
    ],
    "assignedToTeams": [
      {
        "id": "522e0eaa-9048-4276-8dbc-69b11de77bbd",
        "name": "Verizon Packaging Lead"
      }
    ]
  },
  {
    "taskInstanceId": "8ee17624-2663-4bef-bd07-431606904dcd",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Kick-off design intent and out of box experience",
    "dueDate": null,
    "checklist": [
      {
        "id": "da36ff51-85f3-49ed-bbec-1c094b566087",
        "label": "Design intent and out-of-box experience completed",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "e32164e6-f083-4de3-b7ab-c9c023b0c95f",
        "label": "Out-of-box experience approved",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "38aad5f6-2a88-494c-8002-87fdb17195b9",
        "name": "Pentagram"
      }
    ]
  },
  {
    "taskInstanceId": "cea91525-5a82-4dfe-9810-a8e724093797",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Award project to a supplier",
    "dueDate": null,
    "checklist": [
      {
        "id": "a7dc06a6-4d62-4750-a939-ebd307a105cf",
        "label": "Supplier documentation provided",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "38aad5f6-2a88-494c-8002-87fdb17195b9",
        "name": "Pentagram"
      }
    ]
  },
  {
    "taskInstanceId": "59dc020a-eda6-4d50-b5a9-57934fd457e2",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Approval of dieline file",
    "dueDate": null,
    "checklist": [
      {
        "id": "e0b9910d-9980-4479-829e-c8fea4e192ca",
        "label": "Dielines approved",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "38aad5f6-2a88-494c-8002-87fdb17195b9",
        "name": "Pentagram"
      }
    ]
  },
  {
    "taskInstanceId": "edd045df-7553-452a-962e-6a59a35b20bb",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Approval of dieline file",
    "dueDate": null,
    "checklist": [
      {
        "id": "5fe4928f-2c52-4827-856d-1dc4661aebc7",
        "label": "Dielines approved",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "8c61caf2-7d10-477c-8300-ee5e1d4a0627",
        "name": "EP + Co"
      }
    ]
  },
  {
    "taskInstanceId": "f20624f3-c02d-4dd5-b70f-82347e8e24eb",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Build artwork",
    "dueDate": null,
    "checklist": [
      {
        "id": "734aee2a-d99d-4e54-bcbf-bcb4da33a54a",
        "label": "Version 1 submitted",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "77827e1f-ce61-480f-b05b-7b1ffc3e3640",
        "label": "H2R logo received",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "8f8a4340-b2a2-45ec-9d06-ec9367e8a548",
        "label": "FSC received",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "f2ea3e54-a4b4-4f27-b53a-4361828208e3",
        "label": "Artwork development in progress",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "f2cc3b87-1723-4d77-8920-de159953dd23",
        "label": "Revisions in progress (if needed)",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "8c61caf2-7d10-477c-8300-ee5e1d4a0627",
        "name": "EP + Co"
      }
    ]
  },
  {
    "taskInstanceId": "7c4344fd-23e8-4eaa-ac67-900fe8c53dc3",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "White Box samples shipped",
    "dueDate": null,
    "checklist": [
      {
        "id": "3bfe3b25-6a14-43cf-9bef-7bf894c88e55",
        "label": "Tracking information sent",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "eaf422b5-c217-4ff5-ba0c-b33c640e3fa6",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Approve white box samples",
    "dueDate": null,
    "checklist": [
      {
        "id": "5c5ff858-4645-44d8-b9f8-ab1ac74dfb2d",
        "label": "Verizon approved",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "8fcf235e-aa9b-4414-9347-17fa3595897e",
        "label": "EP approved",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "22524611-db1d-439a-8f52-e39f3a065f55",
        "name": "Verizon + EP+Co"
      }
    ]
  },
  {
    "taskInstanceId": "8501e327-4a8b-47d2-a434-b26cd6aad28f",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Provide in-box setup guide instructions",
    "dueDate": null,
    "checklist": [
      {
        "id": "85d0a427-9f1f-46b7-ac56-237349981f89",
        "label": "Provided copy and imagery",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "bb699d3b-f747-44e1-97cf-ceb5430fee66",
        "label": "Product team approval received",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "60cd0c4a-2cac-4288-969e-36c9cc1fdb70",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Approve in-box setup guide instructions",
    "dueDate": null,
    "checklist": [
      {
        "id": "afceb56d-b0f0-4214-9e2d-da0f86972305",
        "label": "Setup guide approved",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "9b11c918-7ac3-4bb1-8c4e-23279240f1fe",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Device photography (if needed)",
    "dueDate": null,
    "checklist": [
      {
        "id": "fceb8338-b473-4a76-9053-394b3fe1232e",
        "label": "Device unit shipped",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "9f662f0b-127e-415d-b53e-d1b1ad221408",
        "label": "Tracking provided to Verizon Studio",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "6f06dee3-0460-4933-a415-cfaffac4fbcf",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Complete ISTA-2A Transit Testing",
    "dueDate": null,
    "checklist": [
      {
        "id": "687a444d-f008-4bce-a8df-cf9c97df2744",
        "label": "ISTA-2 uploaded",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "ef945ae4-46e6-4f67-970d-9f005d7ceddf",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Approve artwork",
    "dueDate": null,
    "checklist": [
      {
        "id": "687a071e-f625-4e99-8b83-95b04e5b3475",
        "label": "Final version submitted",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "ecb61ff6-10c1-452b-9ce9-59dbd0370731",
        "label": "Legal approval",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "e77d9b2e-e2c3-4d4c-9a93-82fbacdb9664",
        "label": "Product team approval",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "46019471-a4c5-458b-9cd0-cf71799a2c33",
        "label": "CX approval",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "ced212ea-4f81-4c1c-b458-200db8d19afb",
        "label": "OEM approval",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "8f2f372e-d762-40e7-8b9d-ab24bfe14cec",
        "label": "3rd party approval (if necessary)",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "22524611-db1d-439a-8f52-e39f3a065f55",
        "name": "Verizon + EP+Co"
      }
    ]
  },
  {
    "taskInstanceId": "b53c917e-02e8-40cb-99c2-49c072368a0c",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Release final artwork files",
    "dueDate": null,
    "checklist": [
      {
        "id": "ae719296-ede2-4ebd-8579-f4f64f3a95b3",
        "label": "Released files",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "8c61caf2-7d10-477c-8300-ee5e1d4a0627",
        "name": "EP + Co"
      }
    ]
  },
  {
    "taskInstanceId": "5f31451d-ce57-4f7c-a790-783cd36838f4",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Upload proof files into project",
    "dueDate": null,
    "checklist": [
      {
        "id": "d7fb1dab-28fe-427b-b899-c43e508101bd",
        "label": "Uploaded files",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "8fbb99f6-120f-4105-812f-98fcd03154af",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "EP and Brand review digital package proof files",
    "dueDate": null,
    "checklist": [
      {
        "id": "85ede2c7-ad1a-4d7c-9bc3-2da81938c9ef",
        "label": "EP approves files.",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "50686c77-e0b5-4078-95af-2295ac7b2099",
        "label": "Verizon approves files",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "22524611-db1d-439a-8f52-e39f3a065f55",
        "name": "Verizon + EP+Co"
      }
    ]
  },
  {
    "taskInstanceId": "77eca5bc-eab9-461c-8a54-47b1722bb213",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Create and share 3D renderings",
    "dueDate": null,
    "checklist": [
      {
        "id": "91709675-0114-4f64-b434-4b360c2b70bc",
        "label": "3D renderings created",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "3e1c0134-a64a-4c31-b117-2cc474046ca7",
        "label": "Renderings shared",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "8c61caf2-7d10-477c-8300-ee5e1d4a0627",
        "name": "EP + Co"
      }
    ]
  },
  {
    "taskInstanceId": "9b73654b-67b6-4d4e-8b75-0234dc1adf18",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Send tracking for sample",
    "dueDate": null,
    "checklist": [
      {
        "id": "cdd3d1fa-efd0-42a9-9f02-ce261c41e2d6",
        "label": "Tracking information sent",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "84b38c36-e13e-4a6c-8365-254980fdd731",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Material audit",
    "dueDate": null,
    "checklist": [
      {
        "id": "d647d513-0892-4499-ba83-07cb49396454",
        "label": "Physical sample received",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "78505f11-9ad5-41e4-ba9d-29707482128a",
        "label": "Audit sheet and approval uploaded to Packaging Manager",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "8c61caf2-7d10-477c-8300-ee5e1d4a0627",
        "name": "EP + Co"
      }
    ]
  },
  {
    "taskInstanceId": "206ce0cb-5b6e-46fc-b9f6-4d5b03d82ac9",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Sample review presentation",
    "dueDate": null,
    "checklist": [
      {
        "id": "ba0cb797-5ea0-45a3-bb16-768437885660",
        "label": "Physical sample received",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "e00d72bc-fe9e-4b47-a8fd-22cd266d7bd9",
        "label": "Presentation uploaded",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "826e8c4e-564c-407d-a469-94748ca725da",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Material Audit review",
    "dueDate": null,
    "checklist": [
      {
        "id": "cf4ed0db-57ba-4f17-a47a-8ac74129adee",
        "label": "Final approval on sample provided",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "522e0eaa-9048-4276-8dbc-69b11de77bbd",
        "name": "Verizon Packaging Lead"
      }
    ]
  },
  {
    "taskInstanceId": "7e6e44ad-9148-4d88-8543-f96c8a3a5029",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "NewTask title",
    "dueDate": "2025-01-08T05:00:00Z",
    "checklist": [
      {
        "id": "88f1e5bb-9ae4-4e65-aa54-a923e5c43166",
        "label": "Review report attached and upload to Gdrive",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "3ad009e0-dba2-4c9b-8da1-bf0f85a0520d",
        "label": "Contact EP",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "1169493f-d199-4677-9b8c-925484b5c137",
        "label": "Send copy to EP office",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "38aad5f6-2a88-494c-8002-87fdb17195b9",
        "name": "Pentagram"
      }
    ]
  },
  {
    "taskInstanceId": "7523109f-3f93-468e-878c-1745474ae3f6",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Kick off 2nd source supplier project",
    "dueDate": null,
    "checklist": [
      {
        "id": "815dba04-e215-44a7-a7ac-918fa1540528",
        "label": "Teams alerted",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "62796e2a-415b-452e-85a4-30dbb5342b7c",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Share dielines with team",
    "dueDate": null,
    "checklist": [
      {
        "id": "707630a8-2dcf-4369-a6f1-e6b72e77aa06",
        "label": "Dielines uploaded",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "f3d6a5c3-7d9a-4d2f-aa30-65ae37783a34",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Dielines approval",
    "dueDate": null,
    "checklist": [
      {
        "id": "e7a444bb-d8c0-429e-b07c-72fe0f9db255",
        "label": "Verizon approved",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "1947155d-6631-4a7a-a98b-38df3c871fcb",
        "label": "EP approved",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "f7f0ab0a-4c4c-4f5b-a4bb-81e2fa40514e",
        "name": "Verizon + EP+Co"
      }
    ]
  },
  {
    "taskInstanceId": "592b634a-5112-4fdb-b063-f60ce983f14a",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "White Box samples shipped",
    "dueDate": null,
    "checklist": [
      {
        "id": "0ee8ee77-f2b9-4d5a-80c3-7368ad74be31",
        "label": "Tracking information provided",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "fa8ef750-1408-4c2a-84ac-86282ee7191a",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Build and share packaging",
    "dueDate": null,
    "checklist": [
      {
        "id": "c8b56dde-bab6-4f6d-a180-fd7714083a10",
        "label": "Artwork built with new dielines, new content, and existing content from the original artwork",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "0afc6f82-554f-431f-a958-ba0533e4e0c3",
        "label": "V1 artwork sent to Verizon New Product team with list of pending final items",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "994b69e6-9c0c-4f29-a666-344680d89cb5",
        "label": "Additional copy and updates if needed",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "251cacb8-651b-4dc5-b0c1-4870f22375bc",
        "label": "Artwork files shared for approval",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "e622d618-8742-4985-9fdf-5d2aa01de5a0",
        "name": "EP + Co"
      }
    ]
  },
  {
    "taskInstanceId": "60724398-d217-48b6-a0ca-7afc611b3c40",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Approve final PDF",
    "dueDate": null,
    "checklist": [
      {
        "id": "4926f153-04c2-4100-8398-6987e8b312c5",
        "label": "File approved",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "dfb25923-91bf-4bef-b762-9af3c8823d00",
        "name": "Verizon Packaging Lead"
      }
    ]
  },
  {
    "taskInstanceId": "96839789-029f-492f-8089-8d10d0b212e1",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Release files",
    "dueDate": null,
    "checklist": [
      {
        "id": "8b09c1c6-88e1-4880-9d9e-e21618354ddc",
        "label": "Files shared",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "dfb25923-91bf-4bef-b762-9af3c8823d00",
        "name": "Verizon Packaging Lead"
      }
    ]
  },
  {
    "taskInstanceId": "9b4809f6-f359-49ec-8421-4226dcd5bdcb",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Share proofs",
    "dueDate": null,
    "checklist": [
      {
        "id": "71993eff-81f5-4221-86b7-7cff02712170",
        "label": "PDF proof files uploaded",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "2d3c155f-daa4-48a3-b675-e02e1de77bcf",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Approve proofs",
    "dueDate": null,
    "checklist": [
      {
        "id": "15c4d449-5780-4854-ba44-0ce024797c64",
        "label": "PDF files approved by Verizon",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "aaf6c51e-535e-443a-9bc3-c20e9b5aedc7",
        "label": "PDF files approved by EP+Co",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "f7f0ab0a-4c4c-4f5b-a4bb-81e2fa40514e",
        "name": "Verizon + EP+Co"
      }
    ]
  },
  {
    "taskInstanceId": "972dc5d7-e291-459b-a0cc-2d702d5a56c5",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Send tracking for sample",
    "dueDate": null,
    "checklist": [
      {
        "id": "afddb90b-51c2-4a02-917f-4f265327c71b",
        "label": "Golden samples shipped and tracking provided",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": []
  },
  {
    "taskInstanceId": "db5559da-6551-446a-bb3a-af7cb4f28a58",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Create and upload 3D renderings of the packaging",
    "dueDate": null,
    "checklist": [
      {
        "id": "e8171ab7-2206-4701-a5fd-37c467ecb6d1",
        "label": "Created 3D renderings",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "e622d618-8742-4985-9fdf-5d2aa01de5a0",
        "name": "EP + Co"
      }
    ]
  },
  {
    "taskInstanceId": "4ba1ee79-0802-4648-a51e-2858917cae4f",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Material audit",
    "dueDate": null,
    "checklist": [
      {
        "id": "1f43638f-d744-44eb-a3cf-fd13251b4b5c",
        "label": "Physical sample received",
        "isComplete": false,
        "completedOn": null
      },
      {
        "id": "95a0610b-ed61-49d7-8fda-357140b2afbd",
        "label": "Audit sheet and approval uploaded to Packaging Manager",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "e622d618-8742-4985-9fdf-5d2aa01de5a0",
        "name": "EP + Co"
      }
    ]
  },
  {
    "taskInstanceId": "a8a7e6e1-75ab-490f-b799-db2dc57a1cf7",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Material Audit review and approval",
    "dueDate": null,
    "checklist": [
      {
        "id": "a747a898-c6b2-44a0-b611-768fad4a8846",
        "label": "Files reviewed and shared with OEM",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "dfb25923-91bf-4bef-b762-9af3c8823d00",
        "name": "Verizon Packaging Lead"
      }
    ]
  }
];

export const pmTaskWithDueDateAssignedToAndFiles = {
  "taskInstanceId": "2836d83f-3927-424b-91a8-e19ae9dc4238",
  "taskInstanceStepId": "7a0995c1-f17b-4c61-a817-6665db46215f",
  "stepType": "form",
  "status": "in-progress",
  "name": "Test kickoff brief revision",
  "description": "This is the task description.",
  "dueDate": "2025-11-30T05:00:00Z",
  "formBlueprintId": "205d6088-eb5e-45fc-885d-0706b1195313",
  "checklist": [
    {
      "id": "1f1af1bd-42a2-4073-89f1-2b8169645d64",
      "label": "fill out brief",
      "isComplete": false,
      "completedOn": null
    }
  ],
  "assignedToTeams": [
    {
      "id": "32135bd2-6608-406b-b998-ac74d185aaf5",
      "name": "Verizon New Product Team"
    }
  ],
  "files": [
    {
      "id": "aa692b4a-8f5b-4c80-97d2-0916d1cf131a",
      "parentFileId": "2e10dab5-0577-40e0-9e00-305ea8c71132",
      "name": "cute_innocent_weasel.jpeg",
      "extension": "jpeg",
      "sizeInBytes": 1930839,
      "mimeType": "image/png"
    },
    {
      "id": "7cbeebf1-b517-4d5b-b3c1-cf16fd30ad8b",
      "parentFileId": "b132b6f9-4123-4ccc-b572-a4f969bd40d9",
      "name": "sleeping_weasel_and_meatball.jpeg",
      "extension": "jpeg",
      "sizeInBytes": 2982538,
      "mimeType": "image/png"
    }
  ]
};

export const pmTaskChecklistComplete = {
  "taskInstanceId": "2836d83f-3927-424b-91a8-e19ae9dc4238",
  "taskInstanceStepId": "7a0995c1-f17b-4c61-a817-6665db46215f",
  "stepType": "form",
  "status": "in-progress",
  "name": "Test kickoff brief revision",
  "description": "This is the task description.",
  "dueDate": "2025-11-30T05:00:00Z",
  "formBlueprintId": "205d6088-eb5e-45fc-885d-0706b1195313",
  "checklist": [
    {
      "id": "1f1af1bd-42a2-4073-89f1-2b8169645d64",
      "label": "fill out brief",
      "isComplete": true,
      "completedOn": "2024-11-30T05:00:00Z"
    }
  ],
  "assignedToTeams": [
    {
      "id": "32135bd2-6608-406b-b998-ac74d185aaf5",
      "name": "Verizon New Product Team"
    }
  ],
  "files": [
    {
      "id": "aa692b4a-8f5b-4c80-97d2-0916d1cf131a",
      "parentFileId": "2e10dab5-0577-40e0-9e00-305ea8c71132",
      "name": "cute_innocent_weasel.jpeg",
      "extension": "jpeg",
      "sizeInBytes": 1930839,
      "mimeType": "image/png"
    },
    {
      "id": "7cbeebf1-b517-4d5b-b3c1-cf16fd30ad8b",
      "parentFileId": "b132b6f9-4123-4ccc-b572-a4f969bd40d9",
      "name": "sleeping_weasel_and_meatball.jpeg",
      "extension": "jpeg",
      "sizeInBytes": 2982538,
      "mimeType": "image/png"
    }
  ]
};

export const pmTaskCompleted = {
  "taskInstanceId": "2836d83f-3927-424b-91a8-e19ae9dc4238",
  "taskInstanceStepId": "7a0995c1-f17b-4c61-a817-6665db46215f",
  "stepType": "form",
  "status": "completed",
  "name": "Test kickoff brief revision",
  "description": "This is the task description.",
  "dueDate": "2025-11-30T05:00:00Z",
  "formBlueprintId": "205d6088-eb5e-45fc-885d-0706b1195313",
  "checklist": [
    {
      "id": "1f1af1bd-42a2-4073-89f1-2b8169645d64",
      "label": "fill out brief",
      "isComplete": true,
      "completedOn": "2024-11-30T05:00:00Z"
    }
  ],
  "assignedToTeams": [
    {
      "id": "32135bd2-6608-406b-b998-ac74d185aaf5",
      "name": "Verizon New Product Team"
    }
  ],
  "files": [
    {
      "id": "aa692b4a-8f5b-4c80-97d2-0916d1cf131a",
      "parentFileId": "2e10dab5-0577-40e0-9e00-305ea8c71132",
      "name": "cute_innocent_weasel.jpeg",
      "extension": "jpeg",
      "sizeInBytes": 1930839,
      "mimeType": "image/png"
    },
    {
      "id": "7cbeebf1-b517-4d5b-b3c1-cf16fd30ad8b",
      "parentFileId": "b132b6f9-4123-4ccc-b572-a4f969bd40d9",
      "name": "sleeping_weasel_and_meatball.jpeg",
      "extension": "jpeg",
      "sizeInBytes": 2982538,
      "mimeType": "image/png"
    }
  ]
};

export const pmTaskDetailsNoDueDateAssignedToOrFiles = {
  "taskInstanceId": "5f40b940-e6b1-48cd-a4de-ab1688560941",
  "taskInstanceStepId": "a53f3ca9-a861-4b23-9a7b-2fc5bc4d7b97",
  "stepType": "checklist",
  "status": "in-progress",
  "name": "Kick-off design intent and out of box experience",
  "description": "",
  "dueDate": null,
  "formBlueprintId": null,
  "checklist": [
    {
      "id": "bc18a063-48c2-479e-9287-36930c4dcfc8",
      "label": "Design intent and out-of-box experience completed",
      "isComplete": false,
      "completedOn": null
    },
    {
      "id": "4165b8bb-e189-4fc4-83fd-849a4cd4cb71",
      "label": "Out-of-box experience approved",
      "isComplete": false,
      "completedOn": null
    }
  ],
  "files": null
};

export const pmProjectInstanceNewProduct = {
  "id": "41c01966-5443-453f-9e90-348bfc22e951",
  "name": "Demo project 12/4",
  "projectType": "New Product",
  "jobCode": "DEVC-0000014",
  "status": "new",
  "submittedByUser": {
    "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
    "firstName": "Chris ",
    "lastName": "Bergenheim",
    "emailAddress": "chris.bergenheim@epandcompany.com"
  },
  "teams": [
    {
      "id": "6a4c433d-0811-421d-a80a-f460316d2e68",
      "name": "Verizon New Product Team",
      "groups": []
    },
    {
      "id": "522e0eaa-9048-4276-8dbc-69b11de77bbd",
      "name": "Verizon Packaging Lead",
      "groups": [
        {
          "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
          "company": "Verizon New Product Packaging Lead",
          "name": "Verizon New Product Packaging Lead"
        }
      ]
    },
    {
      "id": "38aad5f6-2a88-494c-8002-87fdb17195b9",
      "name": "Pentagram",
      "groups": [
        {
          "id": "e48e1eab-38ab-4a01-b7d2-8df269452be0",
          "company": "Pentagram Packaging",
          "name": "Pentagram Packaging"
        }
      ]
    },
    {
      "id": "05fef1b0-0cd8-4782-8d8e-57bb740dfee4",
      "name": "OEM",
      "groups": [
        {
          "id": "a27b0064-11fc-4568-a320-b126254a494e",
          "company": "Packaging Manager OEM",
          "name": "Packaging Manager OEM"
        }
      ]
    },
    {
      "id": "a83d928d-ff64-4ec5-bfd7-b7d07d5e89f1",
      "name": "Supplier",
      "groups": []
    },
    {
      "id": "8c61caf2-7d10-477c-8300-ee5e1d4a0627",
      "name": "EP + Co",
      "groups": [
        {
          "id": "380a6048-7524-472f-9181-326309630e01",
          "company": "EP+Co Packaging",
          "name": "EP+Co Packaging"
        }
      ]
    },
    {
      "id": "22524611-db1d-439a-8f52-e39f3a065f55",
      "name": "Verizon + EP+Co",
      "groups": [
        {
          "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
          "company": "Verizon New Product Packaging Lead",
          "name": "Verizon New Product Packaging Lead"
        },
        {
          "id": "380a6048-7524-472f-9181-326309630e01",
          "company": "EP+Co Packaging",
          "name": "EP+Co Packaging"
        }
      ]
    },
    {
      "id": "0a95488d-16f3-4cb4-8298-d46da0dd9188",
      "name": "CX team",
      "groups": []
    },
    {
      "id": "b2d61c58-e6c7-4bad-8ba9-aa33d61b89a3",
      "name": "Legal",
      "groups": []
    },
    {
      "id": "6c1a674c-3f6b-4df7-bc59-cd88103e3a71",
      "name": "Marketing Team",
      "groups": []
    },
    {
      "id": "99b6bac8-b5cd-48d4-962f-690518f0bc4d",
      "name": "OEM/Supplier",
      "groups": []
    }
  ]
};

export const pmProjectInstancePrepaid = {
  "id": "6c5c1bf8-b004-4370-89a9-999f9800f6a3",
  "name": "Custom",
  "projectType": "Prepaid Device",
  "jobCode": "PRPD-0000017",
  "status": "new",
  "submittedByUser": {
    "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
    "firstName": "Chris ",
    "lastName": "Bergenheim",
    "emailAddress": "chris.bergenheim@epandcompany.com"
  },
  "teams": [
    {
      "id": "1682b48e-c710-4ad4-bea4-b7776ff7eb67",
      "name": "EP + Co",
      "groups": [
        {
          "id": "380a6048-7524-472f-9181-326309630e01",
          "company": "EP+Co Packaging",
          "name": "EP+Co Packaging"
        }
      ]
    },
    {
      "id": "2ce59b70-f93b-47e6-82db-43083c1657bc",
      "name": "Verizon Packaging Lead",
      "groups": [
        {
          "id": "46c63bc1-94ef-4df6-971d-7734d77af322",
          "company": "Verizon Prepaid Packaging Lead",
          "name": "Verizon Prepaid Packaging Lead"
        }
      ]
    },
    {
      "id": "4b59f03f-7bfa-442b-8b95-b6da7facef16",
      "name": "Verizon 140 Studio",
      "groups": [
        {
          "id": "2ae36a49-3a40-4b2c-8354-cb94d18f34c0",
          "company": "Verizon 140 Studio  Packaging",
          "name": "Verizon 140 Studio  Packaging"
        }
      ]
    },
    {
      "id": "cf4fa475-4e46-44a5-aaf6-3f8cb2e45694",
      "name": "Verizon + EP+Co",
      "groups": [
        {
          "id": "380a6048-7524-472f-9181-326309630e01",
          "company": "EP+Co Packaging",
          "name": "EP+Co Packaging"
        },
        {
          "id": "46c63bc1-94ef-4df6-971d-7734d77af322",
          "company": "Verizon Prepaid Packaging Lead",
          "name": "Verizon Prepaid Packaging Lead"
        }
      ]
    },
    {
      "id": "65157846-b09b-4260-94a0-fe17cf86dcf1",
      "name": "OEM",
      "groups": []
    },
    {
      "id": "ee410aa1-3ee0-4b95-806d-d9b85679aaef",
      "name": "Supplier",
      "groups": []
    }
  ]
};

export const pmNewTaskWithChecklist = {
  "type": "checklist",
  "name": "Test custom task data",
  "description": "Test custom task data",
  "assignedToTeams": [
    "05fef1b0-0cd8-4782-8d8e-57bb740dfee4",
    "6a4c433d-0811-421d-a80a-f460316d2e68"
  ],
  "dueDate": "2025-06-30T04:00:00.000Z",
  "files": [
    {
      "fileId": "a6b196ce-bb88-4ab1-9570-9730a985032a",
      "name": "softsugarcookies.jpg",
      "extension": "jpg",
      "mimeType": "image/jpeg",
      "sizeInBytes": 66304
    }
  ],
  "checklistLabels": [
    "step 1",
    "step 2",
    "step 3"
  ]
};

export const pmNewTaskWithFormNoFiles = {
  "type": "form",
  "name": "Test custom form task",
  "description": "Test custom form task",
  "assignedToTeams": [
    "522e0eaa-9048-4276-8dbc-69b11de77bbd",
    "38aad5f6-2a88-494c-8002-87fdb17195b9"
  ],
  "dueDate": "2025-07-31T04:00:00.000Z",
  "formBlueprintId": "cf9b359a-46d3-496a-b13d-742d3e5dfaf9",
  "files": [],
  "checklistLabels": [
    "Fill out brief"
  ]
};

export const pmFormBlueprintTypes = [
  {
    "id": "205d6088-eb5e-45fc-885d-0706b1195313",
    "name": "Kickoff Brief"
  },
  {
    "id": "8900faf1-28b6-4558-a069-1be4a7181def",
    "name": "New Product Brief"
  },
  {
    "id": "cf9b359a-46d3-496a-b13d-742d3e5dfaf9",
    "name": "Prepaid Device Brief"
  }
];

export const pmDefaultTaskTypes = [
  "2nd Source Supplier",
  "Clean and Screen"
];

export const mockPmFormTaskEditDataNoFilesOrTeams = (pending = false) => {
  return {
    "taskInstanceId": "47b5e6bd-5607-42bd-9ce2-e87dca1869af",
    "taskInstanceStepId": "18e11aa1-b926-473b-87d9-edf12729dbe5",
    "stepType": "form",
    "status": "completed",
    "name": "Complete kickoff brief",
    "description": "Please make sure to submit upon completion.",
    "dueDate": null,
    "formBlueprintId": "205d6088-eb5e-45fc-885d-0706b1195313",
    "checklist": [
      {
        "id": "3092222a-6310-4841-bcc5-0773868a3b05",
        "label": "Fill out the brief",
        "isComplete": true,
        "completedOn": "2024-11-19T14:54:53.65Z"
      }
    ],
    "assignedToTeams": [],
    "files": null
  };
};

export const mockPmChecklistTaskEditDataWithFilesAndTeams = (pending = false) => {
  return {
    "taskInstanceId": "2836d83f-3927-424b-91a8-e19ae9dc4238",
    "taskInstanceStepId": "7a0995c1-f17b-4c61-a817-6665db46215f",
    "stepType": "form",
    "status": "in-progress",
    "name": "Test kickoff brief revision",
    "description": "This is the task description.",
    "dueDate": "2025-11-30T05:00:00Z",
    "formBlueprintId": "205d6088-eb5e-45fc-885d-0706b1195313",
    "checklist": [
      {
        "id": "1f1af1bd-42a2-4073-89f1-2b8169645d64",
        "label": "fill out brief",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "2ce59b70-f93b-47e6-82db-43083c1657bc",
        "name": "Verizon Packaging Lead"
      }
    ],
    "files": [
      {
        "id": "aa692b4a-8f5b-4c80-97d2-0916d1cf131a",
        "parentFileId": "2e10dab5-0577-40e0-9e00-305ea8c71132",
        "name": "cute_innocent_weasel.jpeg",
        "extension": "jpeg",
        "sizeInBytes": 1930839,
        "mimeType": "image/png"
      },
      {
        "id": "7cbeebf1-b517-4d5b-b3c1-cf16fd30ad8b",
        "parentFileId": "b132b6f9-4123-4ccc-b572-a4f969bd40d9",
        "name": "sleeping_weasel_and_meatball.jpeg",
        "extension": "jpeg",
        "sizeInBytes": 2982538,
        "mimeType": "image/png"
      }
    ]
  };
};

export const projectForFilesTab = {
  "id": "2fb1c920-c1a9-4930-963f-952144ae7195",
  "name": "Kat Multi File Upload Test",
  "projectType": "New Product",
  "jobCode": "DEVC-0000016",
  "status": "new",
  "submittedByUser": {
    "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
    "firstName": "Kat",
    "lastName": "Klauber",
    "emailAddress": "kat.klauber+usertest21@epandcompany.com"
  },
  "teams": [
    {
      "id": "f73e8346-b207-4d52-a75c-b9a36f81a829",
      "name": "Verizon New Product Team",
      "groups": []
    },
    {
      "id": "a9ba4bca-8b64-41ee-9bee-a5ff4f2f2de3",
      "name": "Verizon Packaging Lead",
      "groups": [
        {
          "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
          "company": "Verizon New Product Packaging Lead",
          "name": "Verizon New Product Packaging Lead"
        }
      ]
    },
    {
      "id": "daaccf40-4cf6-455e-8547-d792bfb45a67",
      "name": "Pentagram",
      "groups": [
        {
          "id": "e48e1eab-38ab-4a01-b7d2-8df269452be0",
          "company": "Pentagram Packaging",
          "name": "Pentagram Packaging"
        }
      ]
    },
    {
      "id": "1913aca5-c7cd-4d10-b865-3eb4b93373c3",
      "name": "OEM",
      "groups": []
    },
    {
      "id": "bd7f28ae-5d71-4ef7-bbba-af49c4f3d7e0",
      "name": "Supplier",
      "groups": []
    },
    {
      "id": "831395e7-638f-4e99-9db1-3bbb88096f44",
      "name": "EP + Co",
      "groups": [
        {
          "id": "380a6048-7524-472f-9181-326309630e01",
          "company": "EP+Co Packaging",
          "name": "EP+Co Packaging"
        }
      ]
    },
    {
      "id": "39c18ea1-ec88-46cf-918e-e12b24e184ea",
      "name": "Verizon + EP+Co",
      "groups": [
        {
          "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
          "company": "Verizon New Product Packaging Lead",
          "name": "Verizon New Product Packaging Lead"
        },
        {
          "id": "380a6048-7524-472f-9181-326309630e01",
          "company": "EP+Co Packaging",
          "name": "EP+Co Packaging"
        }
      ]
    },
    {
      "id": "85abcb4f-5624-4583-bcf5-5eb8c80bde7a",
      "name": "CX team",
      "groups": []
    },
    {
      "id": "bb0a0026-1359-45b9-b413-d71b66efbb0d",
      "name": "Legal",
      "groups": []
    },
    {
      "id": "626c9846-fd7e-4698-b5aa-0dfcd8d9c862",
      "name": "Marketing Team",
      "groups": []
    },
    {
      "id": "8713f1e1-413b-4c23-b0b1-3725c8143ba0",
      "name": "OEM/Supplier",
      "groups": []
    }
  ]
};

export const pmFilesResponse = {
  "results": [
    {
      "id": "9cb76c2f-7a32-4395-9715-ebf54164ca4c",
      "parentFileId": "1a77bb27-de0b-4134-8289-47b541cbd06e",
      "instanceId": "2fb1c920-c1a9-4930-963f-952144ae7195",
      "instanceType": "project",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Screenshot 2024-12-02 at 11.27.00 AM.png",
      "extension": "png",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-12-05T15:33:59.00803Z"
    },
    {
      "id": "6ed65785-d67d-43fc-ad74-cf1a11967d2a",
      "parentFileId": "19b3a1d5-0ff5-450d-ac38-b832bb8885ee",
      "instanceId": "2fb1c920-c1a9-4930-963f-952144ae7195",
      "instanceType": "project",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Screenshot 2024-11-12 at 12.17.23 PM.png",
      "extension": "png",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-12-05T15:36:07.2200436Z"
    },
    {
      "id": "be9b5926-ead3-4eb4-88af-e2d50dd482ed",
      "parentFileId": "e2e19612-0023-483c-af54-28f600d50fc3",
      "instanceId": "9a6e14a3-539c-4ea0-b9fe-5271fb3b493f",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": true,
      "name": "Kat Multi File Upload Test-Kickoff Brief-version0.pdf",
      "extension": "pdf",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-12-13T18:24:55.4766003Z"
    },
    {
      "id": "2354e032-6328-486e-a3b3-c1ae03b0f079",
      "parentFileId": "39c9fc68-a67e-40eb-bbe9-71d6cc60c2c4",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "squinty_snoot.jpg",
      "extension": "jpg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T19:37:52.0320253Z"
    },
    {
      "id": "fa493723-c409-4b27-ab7f-596a2a0fe00c",
      "parentFileId": "d97ab373-0ce9-49fb-9ea8-fc5132a042f8",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "sassy_pillow_smushy_cheeks1.jpg",
      "extension": "jpg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T18:48:53.9887592Z"
    },
    {
      "id": "11c86ae3-0fcf-4e8a-a122-8d5e9b5ab652",
      "parentFileId": "775638d4-32cb-4b73-bbf8-da0d151b563a",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "squinty_snoot.jpg",
      "extension": "jpg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T18:48:54.2040278Z"
    },
    {
      "id": "94b2d16d-04d3-48fc-911e-0ec030df5baf",
      "parentFileId": "958d981f-0210-4d3a-a523-34b544a8fd4e",
      "instanceId": "2fb1c920-c1a9-4930-963f-952144ae7195",
      "instanceType": "project",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "sugarcookies.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-12-04T19:26:01.0161158Z"
    },
    {
      "id": "2af584e9-e4be-4958-9be3-088319b1c656",
      "parentFileId": "4f3da4ce-54cd-4cc3-bedd-8899a105ad42",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20240118_101139_937.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T18:50:00.3171203Z"
    },
    {
      "id": "50be2099-bc6a-4c0f-ad74-8700dfcfca1b",
      "parentFileId": "e5c01365-fcd0-4546-9d81-85913fb9933b",
      "instanceId": "b1129634-a3a0-4541-a8f4-9150d88e2388",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20230814_144111.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T19:42:41.5540039Z"
    },
    {
      "id": "80213a3e-3de4-47f3-a01c-f25a7607eccb",
      "parentFileId": "c3287206-b0f7-4f81-a497-68b2a79b7bdf",
      "instanceId": "b1129634-a3a0-4541-a8f4-9150d88e2388",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20230814_144154_401.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T19:44:33.3909396Z"
    },
    {
      "id": "e41fb687-32b2-4e71-9722-393cee1531eb",
      "parentFileId": "31961a3d-cc3f-48a3-9d11-861f0ba8a9fe",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "squinty_snoot.jpg",
      "extension": "jpg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T19:39:20.9586744Z"
    },
    {
      "id": "3b2a74b3-bda6-41da-82e9-4c98b71be9ae",
      "parentFileId": "9eb48379-1d87-46c0-b779-9e0cc5e7dd74",
      "instanceId": "b1129634-a3a0-4541-a8f4-9150d88e2388",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20211117_154215.jpg",
      "extension": "jpg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T19:44:33.2328144Z"
    }
  ],
  "form": {
    "sortOn": "relevance",
    "sortOrder": "desc",
    "filter": "all",
    "page": 1,
    "significance": false,
    "query": null,
    "pageSize": 12,
    "updateSearchHistory": false
  },
  "total": 13,
  "totalPages": 2,
  "correlationId": null,
  "requestFeedback": false
};
export const pmFilesPage2Response = {
  "results": [
    {
      "id": "d07fd23b-6284-443b-ab80-6799026db703",
      "parentFileId": "afeafeae-787f-4116-bcf8-4e9b90b71257",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "onpopularonion.jpg",
      "extension": "jpg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-12-04T19:21:56.4603026Z"
    }
  ],
  "form": {
    "sortOn": "relevance",
    "sortOrder": "desc",
    "filter": "all",
    "page": 2,
    "significance": false,
    "query": null,
    "pageSize": 12,
    "updateSearchHistory": false
  },
  "total": 13,
  "totalPages": 2,
  "correlationId": null,
  "requestFeedback": false
};

export const pmFilesAfterUploading = {
  "results": [
    {
      "id": "9cb76c2f-7a32-4395-9715-ebf54164ca4c",
      "parentFileId": "1a77bb27-de0b-4134-8289-47b541cbd06e",
      "instanceId": "2fb1c920-c1a9-4930-963f-952144ae7195",
      "instanceType": "project",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Screenshot 2024-12-02 at 11.27.00 AM.png",
      "extension": "png",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-12-05T15:33:59.00803Z"
    },
    {
      "id": "6ed65785-d67d-43fc-ad74-cf1a11967d2a",
      "parentFileId": "19b3a1d5-0ff5-450d-ac38-b832bb8885ee",
      "instanceId": "2fb1c920-c1a9-4930-963f-952144ae7195",
      "instanceType": "project",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Screenshot 2024-11-12 at 12.17.23 PM.png",
      "extension": "png",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-12-05T15:36:07.2200436Z"
    },
    {
      "id": "be9b5926-ead3-4eb4-88af-e2d50dd482ed",
      "parentFileId": "e2e19612-0023-483c-af54-28f600d50fc3",
      "instanceId": "9a6e14a3-539c-4ea0-b9fe-5271fb3b493f",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": true,
      "name": "Kat Multi File Upload Test-Kickoff Brief-version0.pdf",
      "extension": "pdf",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-12-13T18:24:55.4766003Z"
    },
    {
      "id": "14927a63-a3c4-4e33-8cbf-04b590fac3da",
      "parentFileId": "e1c25872-21f6-40a4-94bd-c03ecbb2a47f",
      "instanceId": "2fb1c920-c1a9-4930-963f-952144ae7195",
      "instanceType": "project",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "myfile.pdf",
      "extension": "pdf",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-12-13T18:47:48.0447228Z"
    },
    {
      "id": "2354e032-6328-486e-a3b3-c1ae03b0f079",
      "parentFileId": "39c9fc68-a67e-40eb-bbe9-71d6cc60c2c4",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "squinty_snoot.jpg",
      "extension": "jpg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T19:37:52.0320253Z"
    },
    {
      "id": "fa493723-c409-4b27-ab7f-596a2a0fe00c",
      "parentFileId": "d97ab373-0ce9-49fb-9ea8-fc5132a042f8",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "sassy_pillow_smushy_cheeks1.jpg",
      "extension": "jpg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T18:48:53.9887592Z"
    },
    {
      "id": "11c86ae3-0fcf-4e8a-a122-8d5e9b5ab652",
      "parentFileId": "775638d4-32cb-4b73-bbf8-da0d151b563a",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "squinty_snoot.jpg",
      "extension": "jpg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T18:48:54.2040278Z"
    },
    {
      "id": "94b2d16d-04d3-48fc-911e-0ec030df5baf",
      "parentFileId": "958d981f-0210-4d3a-a523-34b544a8fd4e",
      "instanceId": "2fb1c920-c1a9-4930-963f-952144ae7195",
      "instanceType": "project",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "sugarcookies.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-12-04T19:26:01.0161158Z"
    },
    {
      "id": "2af584e9-e4be-4958-9be3-088319b1c656",
      "parentFileId": "4f3da4ce-54cd-4cc3-bedd-8899a105ad42",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20240118_101139_937.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T18:50:00.3171203Z"
    },
    {
      "id": "50be2099-bc6a-4c0f-ad74-8700dfcfca1b",
      "parentFileId": "e5c01365-fcd0-4546-9d81-85913fb9933b",
      "instanceId": "b1129634-a3a0-4541-a8f4-9150d88e2388",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20230814_144111.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T19:42:41.5540039Z"
    },
    {
      "id": "80213a3e-3de4-47f3-a01c-f25a7607eccb",
      "parentFileId": "c3287206-b0f7-4f81-a497-68b2a79b7bdf",
      "instanceId": "b1129634-a3a0-4541-a8f4-9150d88e2388",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20230814_144154_401.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T19:44:33.3909396Z"
    },
    {
      "id": "e41fb687-32b2-4e71-9722-393cee1531eb",
      "parentFileId": "31961a3d-cc3f-48a3-9d11-861f0ba8a9fe",
      "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "squinty_snoot.jpg",
      "extension": "jpg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-21T19:39:20.9586744Z"
    }
  ],
  "form": {
    "sortOn": "relevance",
    "sortOrder": "desc",
    "filter": "all",
    "page": 1,
    "significance": false,
    "query": null,
    "pageSize": 12,
    "updateSearchHistory": false
  },
  "total": 14,
  "totalPages": 2,
  "correlationId": null,
  "requestFeedback": false
};

export const pmEmptyFilesResponse = {
  "results": [],
  "form": {
    "sortOn": "relevance",
    "sortOrder": "desc",
    "filter": "all",
    "page": 1,
    "significance": false,
    "query": "teastas",
    "pageSize": 12,
    "updateSearchHistory": false
  },
  "total": 0,
  "totalPages": 0,
  "correlationId": null,
  "requestFeedback": false
};

export const pmFileWithTwoVersions = [
  {
    "id": "fa493723-c409-4b27-ab7f-596a2a0fe00c",
    "parentFileId": "d97ab373-0ce9-49fb-9ea8-fc5132a042f8",
    "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
    "instanceType": "form",
    "isCurrentVersion": true,
    "isFormPdf": false,
    "name": "sassy_pillow_smushy_cheeks1.jpg",
    "extension": "jpg",
    "uploadedByUser": {
      "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
      "firstName": "Kat",
      "lastName": "Klauber",
      "emailAddress": "kat.klauber+usertest21@epandcompany.com"
    },
    "uploadedDate": "2024-11-21T18:48:53.9887592Z"
  },
  {
    "id": "f19ff747-c626-458c-b293-6c41f0080ea5",
    "parentFileId": "d97ab373-0ce9-49fb-9ea8-fc5132a042f8",
    "instanceId": "ff0816ca-e2cd-4ce7-983f-54f8c85604b2",
    "instanceType": "form",
    "isCurrentVersion": false,
    "isFormPdf": false,
    "name": "cute_innocent_weasel.jpeg",
    "extension": "jpeg",
    "uploadedByUser": {
      "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
      "firstName": "Kat",
      "lastName": "Klauber",
      "emailAddress": "kat.klauber+usertest21@epandcompany.com"
    },
    "uploadedDate": "2024-11-21T18:48:15.902195Z"
  }
];

export const pmProjectTeamsInitial = [
  {
    "id": "f73e8346-b207-4d52-a75c-b9a36f81a829",
    "name": "Verizon New Product Team",
    "groups": []
  },
  {
    "id": "a9ba4bca-8b64-41ee-9bee-a5ff4f2f2de3",
    "name": "Verizon Packaging Lead",
    "groups": [
      {
        "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
        "company": "Verizon New Product Packaging Lead",
        "name": "Verizon New Product Packaging Lead"
      }
    ]
  },
  {
    "id": "daaccf40-4cf6-455e-8547-d792bfb45a67",
    "name": "Pentagram",
    "groups": [
      {
        "id": "e48e1eab-38ab-4a01-b7d2-8df269452be0",
        "company": "Pentagram Packaging",
        "name": "Pentagram Packaging"
      }
    ]
  },
  {
    "id": "1913aca5-c7cd-4d10-b865-3eb4b93373c3",
    "name": "OEM",
    "groups": []
  },
  {
    "id": "bd7f28ae-5d71-4ef7-bbba-af49c4f3d7e0",
    "name": "Supplier",
    "groups": []
  },
  {
    "id": "831395e7-638f-4e99-9db1-3bbb88096f44",
    "name": "EP + Co",
    "groups": [
      {
        "id": "380a6048-7524-472f-9181-326309630e01",
        "company": "EP+Co Packaging",
        "name": "EP+Co Packaging"
      }
    ]
  },
  {
    "id": "39c18ea1-ec88-46cf-918e-e12b24e184ea",
    "name": "Verizon + EP+Co",
    "groups": [
      {
        "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
        "company": "Verizon New Product Packaging Lead",
        "name": "Verizon New Product Packaging Lead"
      },
      {
        "id": "380a6048-7524-472f-9181-326309630e01",
        "company": "EP+Co Packaging",
        "name": "EP+Co Packaging"
      }
    ]
  },
  {
    "id": "85abcb4f-5624-4583-bcf5-5eb8c80bde7a",
    "name": "CX team",
    "groups": []
  },
  {
    "id": "bb0a0026-1359-45b9-b413-d71b66efbb0d",
    "name": "Legal",
    "groups": []
  },
  {
    "id": "626c9846-fd7e-4698-b5aa-0dfcd8d9c862",
    "name": "Marketing Team",
    "groups": []
  },
  {
    "id": "8713f1e1-413b-4c23-b0b1-3725c8143ba0",
    "name": "OEM/Supplier",
    "groups": []
  }
];
export const pmProjectEmptyTeams = [];

export const pmMessagesResponse = {
  "results": [
    {
      "id": "03a9f632-0920-4863-8f44-f4e9f0fdf69b",
      "body": "Test adding messages to get pagination to show",
      "createdByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [],
      "createdOn": "2024-12-05T15:39:29.3535746Z",
      "modifiedOn": "2024-12-05T15:39:29.3535746Z"
    },
    {
      "id": "30133706-9302-4915-b987-1cafd610931a",
      "body": "Test adding messages to get pagination to show",
      "createdByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [],
      "createdOn": "2024-12-05T15:39:18.465807Z",
      "modifiedOn": "2024-12-05T15:39:18.465807Z"
    },
    {
      "id": "85cd3d6d-5803-4a08-baf4-5a6ee10251a6",
      "body": "Test adding messages to get pagination to show",
      "createdByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [],
      "createdOn": "2024-12-05T15:39:08.1467015Z",
      "modifiedOn": "2024-12-05T15:39:08.1467015Z"
    },
    {
      "id": "c294cb27-f225-47aa-b456-fab05a1e6622",
      "body": "Test adding messages to get pagination to show",
      "createdByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [],
      "createdOn": "2024-12-05T15:38:57.2167391Z",
      "modifiedOn": "2024-12-05T15:38:57.2167391Z"
    },
    {
      "id": "52977c54-e8d7-47c3-9cdd-65caafc7145d",
      "body": "Test adding messages to get pagination to show",
      "createdByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [],
      "createdOn": "2024-12-05T15:38:47.4166216Z",
      "modifiedOn": "2024-12-05T15:38:47.4166216Z"
    },
    {
      "id": "59890d0b-f5a8-4413-9305-12419647ce96",
      "body": "Test adding messages to get pagination to show",
      "createdByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [],
      "createdOn": "2024-12-05T15:38:40.8264861Z",
      "modifiedOn": "2024-12-05T15:38:40.8264861Z"
    },
    {
      "id": "2b613792-c879-4e66-b533-f8659cb2bf93",
      "body": "Test adding messages to get pagination to show",
      "createdByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [],
      "createdOn": "2024-12-05T15:38:32.6313426Z",
      "modifiedOn": "2024-12-05T15:38:32.6313426Z"
    },
    {
      "id": "1672fc1c-7126-4c13-ad6a-409ddb5cad65",
      "body": "Test adding messages to get pagination to show",
      "createdByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [],
      "createdOn": "2024-12-05T15:38:11.8034817Z",
      "modifiedOn": "2024-12-05T15:38:11.8034817Z"
    },
    {
      "id": "6ffc7d30-6876-4653-8653-33d34301e528",
      "body": "message from General user with group now with edit\n\n",
      "createdByUser": {
        "id": "35b0094a-204f-4e39-993a-b59ed4126b5a",
        "firstName": "Chris Tester",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim+fctest@epandcompany.com"
      },
      "createdByUserTeams": [
        {
          "id": "f24d8a23-7533-46bc-a53c-bd5032a0d18a",
          "name": "OEM"
        },
        {
          "id": "a19dce9b-b37c-4e0d-9873-a8ae835d4c94",
          "name": "New team"
        }
      ],
      "sendToTeams": [
        {
          "id": "f24d8a23-7533-46bc-a53c-bd5032a0d18a",
          "name": "OEM"
        },
        {
          "id": "a19dce9b-b37c-4e0d-9873-a8ae835d4c94",
          "name": "New team"
        }
      ],
      "createdOn": "2024-11-19T18:26:57.708118Z",
      "modifiedOn": "2024-11-19T18:27:24.8672831Z"
    },
    {
      "id": "983a774f-1ce2-44d4-bfcd-9fdc56a80bdc",
      "body": "message to all",
      "createdByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [
        {
          "id": "f24d8a23-7533-46bc-a53c-bd5032a0d18a",
          "name": "OEM"
        }
      ],
      "createdOn": "2024-11-19T15:53:19.5747533Z",
      "modifiedOn": "2024-11-19T15:53:19.5747531Z"
    }
  ],
  "form": {
    "sortOn": "modified-on",
    "sortOrder": "desc",
    "page": 1,
    "significance": false,
    "query": null,
    "pageSize": 10,
    "updateSearchHistory": false
  },
  "total": 12,
  "totalPages": 2,
  "correlationId": null,
  "requestFeedback": false
};

export const pmMessagesPage2 = {
  "results": [
    {
      "id": "fadba861-cbc5-4e8a-83d1-2c9944318884",
      "body": "message to OEM Team",
      "createdByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [
        {
          "id": "f24d8a23-7533-46bc-a53c-bd5032a0d18a",
          "name": "OEM"
        }
      ],
      "createdOn": "2024-11-19T15:53:04.5061165Z",
      "modifiedOn": "2024-11-19T15:53:04.5061165Z"
    },
    {
      "id": "48f5a80c-6569-4f35-9e13-fe1d44115aac",
      "body": "Message to multi teams\n",
      "createdByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "createdByUserTeams": [],
      "sendToTeams": [],
      "createdOn": "2024-11-19T14:56:26.2633054Z",
      "modifiedOn": "2024-11-19T14:56:26.2633053Z"
    }
  ],
  "form": {
    "sortOn": "modified-on",
    "sortOrder": "desc",
    "page": 2,
    "significance": false,
    "query": null,
    "pageSize": 10,
    "updateSearchHistory": false
  },
  "total": 12,
  "totalPages": 2,
  "correlationId": null,
  "requestFeedback": false
};

export const pmEmptyMessagesResponse = {
  "results": [],
  "form": {
    "sortOn": "modified-on",
    "sortOrder": "desc",
    "page": 1,
    "significance": false,
    "query": "al;dsjkfds",
    "pageSize": 10,
    "updateSearchHistory": false
  },
  "total": 0,
  "totalPages": 0,
  "correlationId": null,
  "requestFeedback": false
};

export const pmFilesForHomeTab = {
  "results": [
    {
      "id": "289905fe-9b6d-45c7-92f8-a4aa641badc7",
      "parentFileId": "394e3693-f562-42cc-8df4-64783a9a45d7",
      "instanceId": "e5208193-f292-49f1-9e30-f4952f5cd7d3",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": true,
      "name": "Chris Test Project 1-Kickoff Brief-version1.pdf",
      "extension": "pdf",
      "uploadedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "uploadedDate": "2024-11-19T14:27:04.0868377Z"
    },
    {
      "id": "0c95ef0c-2f2d-4cb3-b0a0-d1507da60f9d",
      "parentFileId": "2b8da69b-e7d9-42a9-8769-a6beeb9ac348",
      "instanceId": "d88d4273-f43d-4b1a-bd49-ee22fe65574a",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20230814_144154_237.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-20T03:58:31.6087885Z"
    },
    {
      "id": "baab89d3-cd78-4799-8769-3dc8c2769119",
      "parentFileId": "0265594b-7d1c-4253-bb13-f5192b3e1623",
      "instanceId": "d88d4273-f43d-4b1a-bd49-ee22fe65574a",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20230814_144154_237.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-20T03:40:34.3375113Z"
    },
    {
      "id": "6b474a08-40c7-4cd0-9d6b-5a3d881cb29e",
      "parentFileId": "0a498578-ba9b-4dbf-9911-83397f14957e",
      "instanceId": "d88d4273-f43d-4b1a-bd49-ee22fe65574a",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20240118_101139_937.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-20T03:38:56.2043254Z"
    },
    {
      "id": "c3256186-af8c-4712-9af2-da13f1d75792",
      "parentFileId": "f6f4d590-d394-4f61-b4ed-694622757c33",
      "instanceId": "d88d4273-f43d-4b1a-bd49-ee22fe65574a",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20230814_144154_237.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-20T13:47:48.1939928Z"
    },
    {
      "id": "aa070b8b-8ff7-4763-84e8-fd54cb871d24",
      "parentFileId": "7110c2b3-5126-41fc-a549-0a0c7e905d3a",
      "instanceId": "d88d4273-f43d-4b1a-bd49-ee22fe65574a",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": false,
      "name": "Image_20240118_101139_937.jpeg",
      "extension": "jpeg",
      "uploadedByUser": {
        "id": "e59ec9a0-62d3-469c-9120-6d35a34203fa",
        "firstName": "Kat",
        "lastName": "Klauber",
        "emailAddress": "kat.klauber+usertest21@epandcompany.com"
      },
      "uploadedDate": "2024-11-20T02:50:04.3520151Z"
    },
    {
      "id": "ea8d6d11-84f7-4703-942c-8ac6c069529b",
      "parentFileId": "48ddd2fe-6e72-414c-b9ad-dfb613e72a62",
      "instanceId": "d88d4273-f43d-4b1a-bd49-ee22fe65574a",
      "instanceType": "form",
      "isCurrentVersion": true,
      "isFormPdf": true,
      "name": "Chris Test Project 1-New Product Brief-version8.pdf",
      "extension": "pdf",
      "uploadedByUser": {
        "id": "d8663d32-ae7f-4679-80b8-614365d79766",
        "firstName": "Chris",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "uploadedDate": "2024-11-19T20:41:00.7939699Z"
    }
  ],
  "form": {
    "sortOn": "relevance",
    "sortOrder": "desc",
    "filter": "all",
    "page": 1,
    "significance": false,
    "query": null,
    "pageSize": 12,
    "updateSearchHistory": false
  },
  "total": 7,
  "totalPages": 1,
  "correlationId": null,
  "requestFeedback": false
};

export const pmTasksForHomeTab = [
  {
    "taskInstanceId": "a8121b70-3920-4d20-9ba6-885e09f9a283",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "Award project to a supplier",
    "dueDate": null,
    "checklist": [
      {
        "id": "04c19d08-cf37-4b40-ae03-9ac54b91051f",
        "label": "Supplier documentation provided",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "648a35f3-5c5f-4b33-b81a-d8ca7d3911e6",
        "name": "CX team"
      }
    ]
  },
  {
    "taskInstanceId": "d5b53e65-8ffb-4c85-ae12-5aeead4299f4",
    "stepType": "checklist",
    "status": "in-progress",
    "name": "White Box samples shipped",
    "dueDate": null,
    "checklist": [
      {
        "id": "34d553c5-1e31-47dc-ac14-e71a34200a58",
        "label": "Tracking information sent",
        "isComplete": false,
        "completedOn": null
      }
    ],
    "assignedToTeams": [
      {
        "id": "648a35f3-5c5f-4b33-b81a-d8ca7d3911e6",
        "name": "CX team"
      }
    ]
  }
];

export const pmProjectsResponse = {
  "results": [
    {
      "id": "4e1e5e25-60e2-4bda-8e78-8d535d6ab45b",
      "createdOn": "2024-12-13T15:41:49.0973221Z",
      "modifiedOn": "2024-12-13T15:41:49.0973221Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
        "firstName": "Chris ",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Packaging Support",
      "jobCode": "PSUP-0000026",
      "status": "new",
      "name": "Packaging sup test",
      "score": 0
    },
    {
      "id": "49f523d4-8b38-4203-af5f-f92776253d1e",
      "createdOn": "2024-12-04T18:42:04.2699914Z",
      "modifiedOn": "2024-12-04T18:42:04.2699914Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
        "firstName": "Chris ",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Packaging Support",
      "jobCode": "PSUP-0000015",
      "status": "new",
      "name": "Demo Packaging support",
      "score": 0
    },
    {
      "id": "9bb5e8c1-0110-4282-ae99-15b699f853f5",
      "createdOn": "2024-11-25T16:38:15.3671083Z",
      "modifiedOn": "2024-11-25T16:38:15.3671083Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "148acff1-dd41-442d-ad87-91bcea6a185f",
        "firstName": "Cass Standard",
        "lastName": "Account",
        "emailAddress": "cassidy.rounsley+s@epandcompany.com"
      },
      "projectType": "Packaging Support",
      "jobCode": "PSUP-0000004",
      "status": "new",
      "name": "QA test 2",
      "score": 0
    },
    {
      "id": "f5e16a3d-c2f9-41c2-8565-02b8801c913c",
      "createdOn": "2024-12-16T20:23:22.759401Z",
      "modifiedOn": "2024-12-17T18:56:42.5431401Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
        "firstName": "Chris ",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000027",
      "status": "new",
      "name": "Test project 12/16",
      "score": 0
    },
    {
      "id": "6023e092-2e6f-4c44-902c-94606119d268",
      "createdOn": "2024-12-13T15:06:48.746036Z",
      "modifiedOn": "2024-12-16T19:56:37.4044245Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
        "firstName": "Chris ",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000024",
      "status": "in-progress",
      "name": "Prepaid 12/13",
      "score": 0
    },
    {
      "id": "f1f5b993-19ba-41e3-ba6c-9b73c4678fb7",
      "createdOn": "2024-12-06T20:58:33.72129Z",
      "modifiedOn": "2024-12-13T14:49:17.9143363Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "77c99ec5-a09e-4ed7-90b0-f9de9a07561d",
        "firstName": "Allison",
        "lastName": "Templeton",
        "emailAddress": "allison.templeton@epandco.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000023",
      "status": "in-progress",
      "name": "Prepaid 12/6 Test",
      "score": 0
    },
    {
      "id": "6c5c1bf8-b004-4370-89a9-999f9800f6a3",
      "createdOn": "2024-12-04T18:52:55.354863Z",
      "modifiedOn": "2024-12-13T14:49:09.3326279Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
        "firstName": "Chris ",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000017",
      "status": "in-progress",
      "name": "Custom",
      "score": 0
    },
    {
      "id": "949c3450-0700-4832-9b98-5927498911df",
      "createdOn": "2024-12-04T18:42:42.950017Z",
      "modifiedOn": "2024-12-04T18:50:50.6401784Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
        "firstName": "Chris ",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000016",
      "status": "in-progress",
      "name": "Prepaid Demo - retired 12/2",
      "score": 0
    },
    {
      "id": "145c7091-2449-4927-b141-a957190ceaff",
      "createdOn": "2024-11-25T18:09:17.27963Z",
      "modifiedOn": "2024-12-04T16:27:42.6573213Z",
      "completedOn": "2024-12-04T16:27:42.6543575Z",
      "submittedByUser": {
        "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
        "firstName": "Chris ",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000013",
      "status": "completed",
      "name": "Prepaid Test chris 11/25",
      "score": 0
    },
    {
      "id": "d1a9ca4f-f22e-4ae9-8c29-0c45b15242b9",
      "createdOn": "2024-11-25T16:46:30.37393Z",
      "modifiedOn": "2024-12-04T16:26:56.5712455Z",
      "completedOn": "2024-12-04T16:26:56.5686586Z",
      "submittedByUser": {
        "id": "148acff1-dd41-442d-ad87-91bcea6a185f",
        "firstName": "Cass Standard",
        "lastName": "Account",
        "emailAddress": "cassidy.rounsley+s@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000012",
      "status": "completed",
      "name": "QA Test to get Pagination 8",
      "score": 0
    }
  ],
  "filters": {
    "unfilteredTotal": 26,
    "statusFilterResponse": [
      {
        "name": "new",
        "count": 17
      },
      {
        "name": "in-progress",
        "count": 7
      },
      {
        "name": "completed",
        "count": 2
      }
    ],
    "groupTypeFilterResponse": [
      {
        "id": "b054f4f5-141e-4f3f-8fe7-6349380fb0b9",
        "name": "Other",
        "groups": [
          {
            "id": "380a6048-7524-472f-9181-326309630e01",
            "name": "EP+Co Packaging",
            "count": 26
          },
          {
            "id": "2ae36a49-3a40-4b2c-8354-cb94d18f34c0",
            "name": "Verizon 140 Studio  Packaging",
            "count": 15
          },
          {
            "id": "e48e1eab-38ab-4a01-b7d2-8df269452be0",
            "name": "Pentagram Packaging",
            "count": 10
          }
        ]
      },
      {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "groups": [
          {
            "id": "46c63bc1-94ef-4df6-971d-7734d77af322",
            "name": "Verizon Prepaid Packaging Lead",
            "count": 14
          },
          {
            "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
            "name": "Verizon New Product Packaging Lead",
            "count": 9
          },
          {
            "id": "9c7fddaf-50b0-44f8-8e20-05032ce0d996",
            "name": "Verizon Packaging Support Lead",
            "count": 3
          }
        ]
      },
      {
        "id": "618d5385-5337-4acd-84fe-fbd96560600b",
        "name": "OEM",
        "groups": [
          {
            "id": "a27b0064-11fc-4568-a320-b126254a494e",
            "name": "Packaging Manager OEM",
            "count": 4
          }
        ]
      },
      {
        "id": "d442858d-53d2-43bb-ac34-eb680f14fe43",
        "name": "Packaging Supplier",
        "groups": [
          {
            "id": "3973cd07-009c-4d51-abba-30a96a5e10ec",
            "name": "Packaging - Asus",
            "count": 1
          },
          {
            "id": "77901f0a-6f5c-49ed-a13f-74069953ea6a",
            "name": "Packaging Manager General ",
            "count": 1
          }
        ]
      }
    ],
    "projectTypeFilterResponse": [
      {
        "id": "6456b525-0211-486b-97aa-d65b8ed70c73",
        "name": "Prepaid Device",
        "count": 14
      },
      {
        "id": "93c7d30c-a04f-4431-acc0-901e1dcb7de0",
        "name": "New Product",
        "count": 9
      },
      {
        "id": "0412c3d0-190c-4fe8-9bf4-6972ddf04a39",
        "name": "Packaging Support",
        "count": 3
      }
    ]
  },
  "form": {
    "sortOn": "job-code",
    "sortOrder": "desc",
    "statusFilters": [],
    "groupFilters": [],
    "projectTypeFilters": [],
    "assignedToUserGroupFilters": [],
    "page": 1,
    "significance": false,
    "query": null,
    "pageSize": 10,
    "updateSearchHistory": false
  },
  "total": 26,
  "totalPages": 3,
  "correlationId": null,
  "requestFeedback": false
};

export const pmProjectsResponsePage2 = {
  "results": [
    {
      "id": "a0e34c46-fba6-4315-9a5f-5845ce171d40",
      "createdOn": "2024-11-25T16:46:13.8694529Z",
      "modifiedOn": "2024-11-25T16:46:13.8694529Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "148acff1-dd41-442d-ad87-91bcea6a185f",
        "firstName": "Cass Standard",
        "lastName": "Account",
        "emailAddress": "cassidy.rounsley+s@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000011",
      "status": "new",
      "name": "QA Test to get Pagination 7",
      "score": 0
    },
    {
      "id": "98b2cf72-ac44-4d91-a69f-babea2a6875c",
      "createdOn": "2024-11-25T16:45:43.7429864Z",
      "modifiedOn": "2024-11-25T16:45:43.7429864Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "148acff1-dd41-442d-ad87-91bcea6a185f",
        "firstName": "Cass Standard",
        "lastName": "Account",
        "emailAddress": "cassidy.rounsley+s@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000010",
      "status": "new",
      "name": "QA Test to get Pagination 6",
      "score": 0
    },
    {
      "id": "2e1556ab-6c0a-412c-a909-91d6b2c98865",
      "createdOn": "2024-11-25T16:45:27.0226254Z",
      "modifiedOn": "2024-11-25T16:45:27.0226254Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "148acff1-dd41-442d-ad87-91bcea6a185f",
        "firstName": "Cass Standard",
        "lastName": "Account",
        "emailAddress": "cassidy.rounsley+s@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000009",
      "status": "new",
      "name": "QA Test to get Pagination 5",
      "score": 0
    },
    {
      "id": "c30305a4-8d82-4ad9-ba67-738a687e3055",
      "createdOn": "2024-11-25T16:45:14.3625411Z",
      "modifiedOn": "2024-11-25T16:45:14.3625411Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "148acff1-dd41-442d-ad87-91bcea6a185f",
        "firstName": "Cass Standard",
        "lastName": "Account",
        "emailAddress": "cassidy.rounsley+s@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000008",
      "status": "new",
      "name": "QA Test to get Pagination 4",
      "score": 0
    },
    {
      "id": "3d39b699-a177-43ca-8308-f5fe046f38c2",
      "createdOn": "2024-11-25T16:44:47.6702084Z",
      "modifiedOn": "2024-11-25T16:44:47.6702084Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "148acff1-dd41-442d-ad87-91bcea6a185f",
        "firstName": "Cass Standard",
        "lastName": "Account",
        "emailAddress": "cassidy.rounsley+s@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000007",
      "status": "new",
      "name": "QA Test to get Pagination 3",
      "score": 0
    },
    {
      "id": "752bdb46-6bd3-4185-a7c5-34750df8a5db",
      "createdOn": "2024-11-25T16:44:36.466182Z",
      "modifiedOn": "2024-11-25T16:44:36.466182Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "148acff1-dd41-442d-ad87-91bcea6a185f",
        "firstName": "Cass Standard",
        "lastName": "Account",
        "emailAddress": "cassidy.rounsley+s@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000006",
      "status": "new",
      "name": "QA Test to get Pagination 2",
      "score": 0
    },
    {
      "id": "0cd3bffe-ac0f-4834-9bbd-a34410f150dd",
      "createdOn": "2024-11-25T16:44:27.7479697Z",
      "modifiedOn": "2024-11-25T16:44:27.7479697Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "148acff1-dd41-442d-ad87-91bcea6a185f",
        "firstName": "Cass Standard",
        "lastName": "Account",
        "emailAddress": "cassidy.rounsley+s@epandcompany.com"
      },
      "projectType": "Prepaid Device",
      "jobCode": "PRPD-0000005",
      "status": "new",
      "name": "QA Test to get Pagination 1",
      "score": 0
    },
    {
      "id": "b2d03e40-2181-4768-b3fe-65de89deb1ad",
      "createdOn": "2024-12-13T15:39:50.688193Z",
      "modifiedOn": "2024-12-13T15:41:14.0010654Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
        "firstName": "Chris ",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000025",
      "status": "new",
      "name": "New Product",
      "score": 0
    },
    {
      "id": "313f7292-244f-420c-a97b-2026ce013074",
      "createdOn": "2024-12-06T20:00:46.643507Z",
      "modifiedOn": "2024-12-06T20:55:06.8376148Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "77c99ec5-a09e-4ed7-90b0-f9de9a07561d",
        "firstName": "Allison",
        "lastName": "Templeton",
        "emailAddress": "allison.templeton@epandco.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000022",
      "status": "new",
      "name": "New Product Test - 12/6",
      "score": 0
    },
    {
      "id": "c5aad4ed-0d40-49f8-be56-e6c0aaa9e494",
      "createdOn": "2024-12-05T15:39:50.361764Z",
      "modifiedOn": "2024-12-06T16:43:56.900159Z",
      "completedOn": null,
      "submittedByUser": {
        "id": "e3a7a82e-c1b7-4763-930b-b1429cea0418",
        "firstName": "Chris ",
        "lastName": "Bergenheim",
        "emailAddress": "chris.bergenheim@epandcompany.com"
      },
      "projectType": "New Product",
      "jobCode": "DEVC-0000021",
      "status": "in-progress",
      "name": "New Product  12/5/24",
      "score": 0
    }
  ],
  "filters": {
    "unfilteredTotal": 26,
    "statusFilterResponse": [
      {
        "name": "new",
        "count": 17
      },
      {
        "name": "in-progress",
        "count": 7
      },
      {
        "name": "completed",
        "count": 2
      }
    ],
    "groupTypeFilterResponse": [
      {
        "id": "b054f4f5-141e-4f3f-8fe7-6349380fb0b9",
        "name": "Other",
        "groups": [
          {
            "id": "380a6048-7524-472f-9181-326309630e01",
            "name": "EP+Co Packaging",
            "count": 26
          },
          {
            "id": "2ae36a49-3a40-4b2c-8354-cb94d18f34c0",
            "name": "Verizon 140 Studio  Packaging",
            "count": 15
          },
          {
            "id": "e48e1eab-38ab-4a01-b7d2-8df269452be0",
            "name": "Pentagram Packaging",
            "count": 10
          }
        ]
      },
      {
        "id": "f4462d70-ac33-4ab8-b78b-d6d59f64a68d",
        "name": "Brand Lead",
        "groups": [
          {
            "id": "46c63bc1-94ef-4df6-971d-7734d77af322",
            "name": "Verizon Prepaid Packaging Lead",
            "count": 14
          },
          {
            "id": "19b8f0f0-2008-4533-8498-d2fb56822a80",
            "name": "Verizon New Product Packaging Lead",
            "count": 9
          },
          {
            "id": "9c7fddaf-50b0-44f8-8e20-05032ce0d996",
            "name": "Verizon Packaging Support Lead",
            "count": 3
          }
        ]
      },
      {
        "id": "618d5385-5337-4acd-84fe-fbd96560600b",
        "name": "OEM",
        "groups": [
          {
            "id": "a27b0064-11fc-4568-a320-b126254a494e",
            "name": "Packaging Manager OEM",
            "count": 4
          }
        ]
      },
      {
        "id": "d442858d-53d2-43bb-ac34-eb680f14fe43",
        "name": "Packaging Supplier",
        "groups": [
          {
            "id": "3973cd07-009c-4d51-abba-30a96a5e10ec",
            "name": "Packaging - Asus",
            "count": 1
          },
          {
            "id": "77901f0a-6f5c-49ed-a13f-74069953ea6a",
            "name": "Packaging Manager General ",
            "count": 1
          }
        ]
      }
    ],
    "projectTypeFilterResponse": [
      {
        "id": "6456b525-0211-486b-97aa-d65b8ed70c73",
        "name": "Prepaid Device",
        "count": 14
      },
      {
        "id": "93c7d30c-a04f-4431-acc0-901e1dcb7de0",
        "name": "New Product",
        "count": 9
      },
      {
        "id": "0412c3d0-190c-4fe8-9bf4-6972ddf04a39",
        "name": "Packaging Support",
        "count": 3
      }
    ]
  },
  "form": {
    "sortOn": "job-code",
    "sortOrder": "desc",
    "statusFilters": [],
    "groupFilters": [],
    "projectTypeFilters": [],
    "assignedToUserGroupFilters": [],
    "page": 2,
    "significance": false,
    "query": null,
    "pageSize": 10,
    "updateSearchHistory": false
  },
  "total": 26,
  "totalPages": 3,
  "correlationId": null,
  "requestFeedback": false
};

export const pmProjectTypes = [
  {
    "id": "ab44eb61-d7b4-4938-bca6-b757ba46f739",
    "name": "New Product Project"
  },
  {
    "id": "ee7ff251-2573-4cde-b665-1ab5d44a9c88",
    "name": "Packaging Support Project"
  },
  {
    "id": "10712bb6-647e-4bc5-806d-6fe107c4f2c2",
    "name": "Prepaid Device Project"
  }
];