import styled from "styled-components";

export const ProtectedWrapper = styled.main`
  line-height: 1.25;
  min-height: 100vh;

  &.intro-visible {
    max-height: 100vh;
    overflow: hidden;
  }
`;
