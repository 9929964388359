import { observable, makeObservable } from 'mobx';

export default class ImageGridVersion1 {

  id = null;
  type = null;
  alt = null;
  images = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      alt: observable,
      images: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id
    this.type = props.type
    this.alt = props.alt
    this.images = props.images
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
  }
} 