import { lazy } from "react";
import paths from "./paths";
import http from "services/http/http";
import { defer, Navigate } from "react-router-dom";
import { withSuspense } from "HOCs";
import {Asset} from "interfaces";
import AuthRedirect from "components/layouts/AuthRedirect/AuthRedirect";
import RerouteUnifiedSearch from "views/RerouteUnifiedSearch/RerouteUnifiedSearch";
import SignOutRedirect from "components/layouts/SignOutRedirect/SignOutRedirect";
import {ProjectTaskDetailsResponse} from "../interfaces/PackagingManager";

const Auth = withSuspense(lazy(() => import("views/Auth/Auth")));
const ForgotPassword = withSuspense(
  lazy(() => import("views/ForgotPassword/ForgotPassword"))
);
const SignUp = withSuspense(lazy(() => import("views/SignUp/SignUp")));
const Home = withSuspense(lazy(() => import("views/Home/Home")));
const Assets = withSuspense(lazy(() => import("views/Assets/Assets")));
const AssetDetails = withSuspense(
  lazy(() => import("views/AssetDetails/AssetDetails"))
);
const AssetEdit = withSuspense(
  lazy(() => import("views/AssetEdit/AssetEditContainer"))
);
const AssetEditMultiple = withSuspense(
  lazy(() => import("views/AssetEditMultiple/AssetEditMultipleContainer"))
);
const AssetUpload = withSuspense(
  lazy(() => import("views/AssetUpload/AssetUploadContainer"))
);
const UnifiedSearch = withSuspense(
  lazy(() => import("views/UnifiedSearch/UnifiedSearchContainer"))
);
const Templates = withSuspense(lazy(() => import("views/Templates/Templates")));
const ContactUs = withSuspense(lazy(() => import("views/ContactUs/ContactUs")));
const BrandUpdatesPage = withSuspense(
  lazy(() => import("views/BrandUpdatesPage/BrandUpdatesPage"))
);

const TrainingOverview = withSuspense(
  lazy(() => import("views/TrainingOverview/TrainingOverview"))
);
const TrainingVideos = withSuspense(
  lazy(() => import("views/TrainingVideos/TrainingVideos"))
);
const AdminUserManagement = withSuspense(
  lazy(() => import("views/AdminUserManagement/AdminUserManagement"))
);
const AdminUserManagementEdit = withSuspense(
  lazy(
    () =>
      import("views/AdminUserManagementEdit/AdminUserManagementEditContainer")
  )
);
const AdminPendingUserManagement = withSuspense(
  lazy(
    () => import("views/AdminPendingUserManagement/AdminPendingUserManagement")
  )
);
const AdminPendingUserManagementEdit = withSuspense(
  lazy(
    () =>
      import(
        "views/AdminPendingUserManagementEdit/AdminPendingUserManagementEditContainer"
      )
  )
);
const AdminGroupManagement = withSuspense(
  lazy(() => import("views/AdminGroupManagement/AdminGroupManagement"))
);
const AdminGroupManagementEdit = withSuspense(
  lazy(
    () =>
      import("views/AdminGroupManagementEdit/AdminGroupManagementEditContainer")
  )
);
const AdminFilterManagement = withSuspense(
  lazy(
    () => import("views/AdminFilterManagement/AdminFilterManagementContainer")
  )
);
const MyAccount = withSuspense(lazy(() => import("views/MyAccount/MyAccount")));
const MyUploads = withSuspense(lazy(() => import("views/MyUploads/MyUploads")));
const MyDownloads = withSuspense(
  lazy(() => import("views/MyDownloads/MyDownloads"))
);
const MyFavorites = withSuspense(
  lazy(() => import("views/MyFavorites/MyFavorites"))
);
const Feedback = withSuspense(lazy(() => import("views/Feedback/Feedback")));
const SavedSearches = withSuspense(
  lazy(() => import("views/SavedSearches/SavedSearches"))
);
const FileCourier = withSuspense(
  lazy(() => import("views/FileCourier/FileCourier"))
);
const FileCourierSent = withSuspense(
  lazy(() => import("views/FileCourierSent/FileCourierSent"))
);
const FileCourierReceived = withSuspense(
  lazy(() => import("views/FileCourierReceived/FileCourierReceived"))
);
const MyRequests = withSuspense(
  lazy(() => import("views/MyRequests/MyRequests"))
);
const ManageRequests = withSuspense(
  lazy(() => import("views/ManageRequests/ManageRequests"))
);
const RequestDetails = withSuspense(
  lazy(() => import("views/RequestDetails/RequestDetails"))
);
const EmailTemplates = withSuspense(
  lazy(() => import("views/EmailTemplates/EmailTemplates"))
);

const ComplianceOverview = withSuspense(
  lazy(() => import("views/ComplianceOverview/ComplianceOverview"))
);

const ComplianceProcess = withSuspense(
  lazy(() => import("views/ComplianceProcess/ComplianceProcess"))
);

const ComplianceScoring = withSuspense(
  lazy(() => import("views/ComplianceScoring/ComplianceScoring"))
);

const ComplianceResources = withSuspense(
  lazy(() => import("views/ComplianceResources/ComplianceResources"))
);
const AdminAssetSearch = withSuspense(
  lazy(() => import("views/AdminAssetSearch/AdminAssetSearch"))
);

const ComplianceFaqs = withSuspense(
  lazy(() => import("views/ComplianceFaqs/ComplianceFaqs"))
);

const Retail = withSuspense(lazy(() => import("views/Retail/Retail")));

const PackagingManagerProjects = withSuspense(lazy(() => import("views/PackagingManagerProjects/PackagingManagerProjects")));

const PackagingManagerReporting = withSuspense(lazy(() => import("views/PackagingManagerReporting/PackagingManagerReporting")));

const PackagingManagerProjectBase = withSuspense(lazy(() => import("views/PackagingManagerProjectBase/PackagingManagerProjectBase")));

const PackagingManagerProjectTaskDetails = withSuspense(lazy(() => import("views/PackagingManagerProjectTaskDetails/PackagingManagerProjectTaskDetails")));
const PackagingManagerProjectTaskEdit = withSuspense(lazy(() => import("views/PackagingManagerProjectTaskEdit/TaskEditContainer")));
const PackagingManagerProjectTaskCreate = withSuspense(lazy(() => import("views/PackagingManagerProjectTaskCreate/TaskCreateContainer")));

const PackagingManagerProjectFilesEdit = withSuspense(lazy(() => import("views/PackagingManagerProjectFilesEdit/PackagingManagerProjectFilesEdit")));

const PackagingManagerBriefContainer = withSuspense(lazy(() => import("views/PackagingManagerBriefContainer/PackagingManagerBriefContainer")));
const WithGuidelinesData = withSuspense(
  lazy(() => import("views/Guidelines/WithGuidelinesData"))
);

export const routeList = {
  unprotected: [
    {
      element: <Navigate to={paths.SIGNIN} />,
      path: paths.EMPTY,
    },
    {
      element: <Auth />,
      path: paths.SIGNIN,
    },
    {
      element: <SignUp />,
      path: paths.SIGNUP,
    },
    {
      element: <ForgotPassword />,
      path: paths.FORGOT_PASSWORD,
    },
  ],
  protected: [
    {
      element: <AuthRedirect />,
      path: paths.AUTH,
    },
    {
      element: <SignOutRedirect />,
      path: paths.SIGNOUT,
    },
    {
      element: <Home />,
      path: paths.HOME,
    },
    {
      element: <WithGuidelinesData />,
      path: paths.GUIDELINES,
    },
    {
      path: paths.ASSETS,
      element: <Assets />,
    },
    {
      path: `${paths.ASSETS}${paths.ASSET_DETAILS}/:assetId`,
      element: <AssetDetails />,
      loader: async ({ params }: any) => {
        const assetDetailsPromise: Promise<Asset> = http
          .get(`/asset/${params.assetId}`)
          .catch(() => {
            throw new Response("Not Found", { status: 404 });
          });
        return defer({
          assetDetails: assetDetailsPromise,
        });
      },
    },
    {
      path: `${paths.ASSETS}/:assetId${paths.ASSET_EDIT}`,
      element: <AssetEdit />,
      loader: async ({ params }: any) => {
        const assetDetailsPromise: Promise<Asset> = http
          .get(`/asset/${params.assetId}/edit`)
          .catch(() => {
            throw new Response("Not Found", { status: 404 });
          });
        return defer({
          assetDetails: assetDetailsPromise,
        });
      },
    },
    {
      path: `${paths.ASSETS}${paths.ASSET_UPLOAD}`,
      element: <AssetUpload />,
    },
    {
      path: `${paths.ASSETS}${paths.MY_UPLOADS}`,
      element: <MyUploads />,
    },
    {
      path: `${paths.ASSETS}${paths.MY_DOWNLOADS}`,
      element: <MyDownloads />,
    },
    {
      path: `${paths.ASSETS}${paths.MY_FAVORITES}`,
      element: <MyFavorites />,
    },
    {
      path: `${paths.ASSETS}${paths.RESTRICTED_ASSETS}${paths.MY_REQUESTS}`,
      element: <MyRequests />,
    },
    {
      path: `${paths.ASSETS}${paths.RESTRICTED_ASSETS}${paths.MANAGE_REQUESTS}`,
      element: <ManageRequests />,
    },
    {
      path: paths.DOWNLOAD_REQUESTS,
      element: <ManageRequests />,
    },
    {
      path: `${paths.ASSETS}${paths.RESTRICTED_ASSETS}${paths.REQUEST_DETAILS}/:requestId`,
      element: <RequestDetails />,
    },
    {
      path: `${paths.ASSET_SEARCH}`,
      element: <UnifiedSearch />,
    },
    {
      path: `${paths.ASSET_SEARCH_REDIRECT}`,
      element: <RerouteUnifiedSearch />,
    },
    {
      path: `${paths.TEMPLATE_SAVED_SEARCH_REDIRECT}`,
      element: <RerouteUnifiedSearch />,
    },
    {
      element: <SavedSearches />,
      path: `${paths.ASSETS}${paths.SAVED_SEARCHES}`,
    },
    {
      element: <Templates />,
      path: paths.TEMPLATES,
    },
    {
      path: `${paths.RETAIL_DESIGN_ADVISORIES}${paths.ASSET_DETAILS}/:assetId`,
      element: <AssetDetails />,
      loader: async ({ params }: any) => {
        const assetDetailsPromise: Promise<Asset> = http
          .get(`/asset/${params.assetId}`)
          .catch(() => {});
        return defer({
          assetDetails: assetDetailsPromise,
        });
      },
    },
    {
      path: `${paths.TEMPLATES}${paths.ASSET_DETAILS}/:assetId`,
      element: <AssetDetails />,
      loader: async ({ params }: any) => {
        const assetDetailsPromise: Promise<Asset> = http
          .get(`/asset/${params.assetId}`)
          .catch(() => {});
        return defer({
          assetDetails: assetDetailsPromise,
        });
      },
    },
    {
      element: <ContactUs />,
      path: paths.CONTACT_US,
    },
    {
      element: <TrainingOverview />,
      path: paths.TRAINING,
    },
    {
      element: <TrainingVideos />,
      path: `${paths.TRAINING_VIDEOS}/:playlist?/:video?`,
    },
    {
      element: <MyAccount />,
      path: paths.MY_ACCOUNT,
    },
    {
      path: `${paths.ADMIN}`,
      children: [
        {
          path: `${paths.ADMIN}${paths.USER_MANAGEMENT}/`,
          element: <AdminUserManagement />,
          exact: true,
        },
        {
          path: `${paths.ADMIN}${paths.USER_MANAGEMENT_EDIT}/:userId`,
          element: <AdminUserManagementEdit />,
        },
        {
          path: `${paths.ADMIN}${paths.PENDING_USER_MANAGEMENT}/`,
          element: <AdminPendingUserManagement />,
          exact: true,
        },
        {
          path: `${paths.ADMIN}${paths.PENDING_USER_MANAGEMENT_EDIT}/:userId`,
          element: <AdminPendingUserManagementEdit />,
        },
        {
          path: `${paths.ADMIN}${paths.GROUP_MANAGEMENT}/`,
          element: <AdminGroupManagement />,
          exact: true,
        },
        {
          path: `${paths.ADMIN}${paths.GROUP_MANAGEMENT_EDIT}/:userId`,
          element: <AdminGroupManagementEdit />,
        },
        {
          path: `${paths.ADMIN}${paths.EMAIL_TEMPLATES}/`,
          element: <EmailTemplates />,
          exact: true,
        },
        {
          path: paths.ADMIN_FILTER_MANAGEMENT,
          element: <AdminFilterManagement />,
        },
        {
          path: `${paths.ADMIN_ASSET_SEARCH}/`,
          element: <AdminAssetSearch />,
          exact: true,
        },
        {
          path: `${paths.ADMIN_ASSET_SEARCH}${paths.BULK_EDIT}`,
          element: <AssetEditMultiple />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url),
              assetIds = url.searchParams.get("ids")?.split(",");
            if (assetIds) {
              const promiseAll: Promise<any[]> = Promise.all(
                assetIds.map((assetId: string) =>
                  http.get(`/asset/${assetId}/edit`)
                )
              );
              return defer({
                multipleAssets: promiseAll,
              });
            }
            return null;
          },
        },
      ],
    },
    {
      path: `${paths.FEEDBACK}`,
      element: <Feedback />,
    },
    {
      element: <ComplianceOverview />,
      path: paths.COMPLIANCE_OVERVIEW,
    },
    {
      element: <ComplianceProcess />,
      path: paths.COMPLIANCE_PROCESS,
    },
    {
      element: <ComplianceScoring />,
      path: paths.COMPLIANCE_SCORING,
    },
    {
      element: <ComplianceResources />,
      path: paths.COMPLIANCE_RESOURCES,
    },
    {
      element: <ComplianceFaqs />,
      path: paths.COMPLIANCE_FAQS,
    },
    {
      element: <BrandUpdatesPage />,
      path: paths.BRAND_UPDATES,
    },
    {
      element: <Retail />,
      path: paths.RETAIL,
    },
    // {
    //   element: <RetailCenter />,
    //   path: paths.RETAIL_CENTER,
    // },
    // {
    //   path: `${paths.RETAIL_CENTER}${paths.RETAIL_GOVERNANCE}/`,
    //   element: <RetailGovernance />,
    // },
    // {
    //   path: `${paths.RETAIL_CENTER}${paths.RETAIL_SUPPORT}/`,
    //   element: <RetailSupport />,
    // },
    // {
    //   element: <RetailBrandedEnvironment />,
    //   path: paths.RETAIL_DESIGN_GUIDE,
    // },
    // {
    //   element: <RetailDesignAdvisories />,
    //   path: paths.RETAIL_DESIGN_ADVISORIES,
    // },
    // {
    //   element: <RetailTemplatesTools />,
    //   path: paths.RETAIL_TEMPLATES_TOOLS,
    // },
    // {
    //   element: <RetailGovernanceAuditsReports />,
    //   path: paths.RETAIL_GOVERNANCE_AUDITS_REPORTS,
    // },
    // {
    //   element: <RetailContactUs />,
    //   path: paths.RETAIL_CONTACT_US,
    // },
    // {
    //   element: <RetailTools />,
    //   path: paths.RETAIL_TOOLS,
    // },
    {
      path: paths.FILE_COURIER,
      element: <FileCourier />,
    },
    {
      path: paths.FILE_COURIER_SENT,
      element: <FileCourierSent />,
    },
    {
      path: `${paths.FILE_COURIER_RECEIVED}/:fileId?`,
      element: <FileCourierReceived />,
    },
    {
      path: paths.PACKAGING_MANAGER_PROJECTS,
      element: <PackagingManagerProjects />,
    },
    {
      path: paths.PACKAGING_MANAGER,
      element: <PackagingManagerProjects />,
    },
    {
      path: paths.PACKAGING_MANAGER_REPORTING,
      element: <PackagingManagerReporting />,
    },
    {
      path: `${paths.PACKAGING_MANAGER_PROJECT}`,
      element: <PackagingManagerProjectBase />,
    },
    {
      element: <PackagingManagerProjectBase />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_HOME}/:projectInstanceId`,
    },
    {
      element: <PackagingManagerProjectBase />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_TASKS}/:projectInstanceId`,
    },
    {
      element: <PackagingManagerProjectBase />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_BRIEFS}/:projectInstanceId`,
    },
    {
      element: <PackagingManagerProjectBase />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_FILES}/:projectInstanceId`,
    },
    {
      element: <PackagingManagerProjectBase />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_TEAMS}/:projectInstanceId`,
    },
    {
      element: <PackagingManagerProjectBase />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_TEAMS}/:projectInstanceId${paths.PACKAGING_MANAGER_PROJECT_TEAMS_EDIT}`,
    },
    {
      element: <PackagingManagerProjectTaskDetails />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_TASKS}/:projectInstanceId${paths.PACKAGING_MANAGER_PROJECT_TASK_DETAILS}/:taskInstanceId`,
    },
    {
      element: <PackagingManagerProjectTaskCreate />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_TASKS}/:projectInstanceId${paths.PACKAGING_MANAGER_PROJECT_TASK_CREATE}`,
    },
    {
      element: <PackagingManagerProjectFilesEdit />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_FILES}/:projectInstanceId${paths.PACKAGING_MANAGER_PROJECT_FILES_EDIT}/:parentFileId`,
    },
    {
      element: <PackagingManagerBriefContainer />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_BRIEFS}/:projectInstanceId${paths.PACKAGING_MANAGER_PROJECT_BRIEF_EDIT}/:briefBlueprintId/:projectInstanceFormId`,
    },
    {
      element: <PackagingManagerBriefContainer />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_BRIEFS}/:projectInstanceId${paths.PACKAGING_MANAGER_PROJECT_BRIEF_PREVIEW}/:briefBlueprintId/:projectInstanceFormId`,
    },
    {
      element: <PackagingManagerProjectTaskEdit />,
      path: `${paths.PACKAGING_MANAGER_PROJECT_TASKS}/:projectInstanceId${paths.PACKAGING_MANAGER_PROJECT_TASK_EDIT}/:taskInstanceId`,
      loader: async ({ params }: any) => {
        const taskDetailsPromise: Promise<ProjectTaskDetailsResponse> = http
        .get(`/packaging-manager/project-task/task/${params.projectInstanceId}/${params.taskInstanceId}`)
        .catch(() => {
          throw new Response("Not Found", { status: 404 });
        });
        return defer({
          taskDetails: taskDetailsPromise,
        });
      },
    },
  ],
};
