import { observable, makeObservable } from 'mobx';

export default class Animation {
  id = "";
  type = "";
  largeVideo = null;
  largePoster = null;
  smallVideo = null;
  smallPoster = null;
  caption = null;
  playButtonColor = null;
  lineColor = null;
  hasOptionalBorder = null;
  additionalPaddingBelowComponent = null;
  autoplay = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      largeVideo: observable,
      largePoster: observable,
      smallVideo: observable,
      smallPoster: observable,
      caption: observable,
      playButtonColor: observable,
      lineColor: observable,
      hasOptionalBorder: observable,
      additionalPaddingBelowComponent: observable,
      autoplay: observable
    });

    this.id = props.id
    this.type = props.type
    this.largeVideo = props.largeVideo
    this.largePoster = props.largePoster
    this.smallVideo = props.smallVideo
    this.smallPoster = props.smallPoster
    this.caption = props.caption
    this.playButtonColor = props.playButtonColor
    this.lineColor = props.lineColor
    this.hasOptionalBorder = props.hasOptionalBorder
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
    this.autoplay = props.autoplay
  }
}
