import {useEffect, useState} from "react";
import {TextButton, Button, FlexBox, Modal, Title, Loader, MultiSelect} from "components";
import {useLazyGetPackagingManagerGroupsQuery} from "services/api/api.slice";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";
import Input from "../../components/forms/Input/Input";
import {ProjectGroupProps, ProjectTeamProps} from "../../interfaces/PackagingManager";


export interface EditPackagingTeamModalProps {
  onClose: Function;
  onComplete: Function;
  team: ProjectTeamProps;
}

export interface NewTeamProps {
  id: string;
  name: string;
  groups: Array<ProjectGroupProps>;
}

const EditPackagingTeamModal = ({onClose, onComplete, team}: EditPackagingTeamModalProps) => {

  const dispatch = useDispatch();
  
  const [updatedTeam, _updatedTeam] = useState<NewTeamProps>(team);
  const [teamChanged, _teamChanged] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };
  
  const handleTeamNameChange = (e: any) => {
    _updatedTeam((prevState) => {
      return {
        id: prevState.id,
        name: e.target.value,
        groups: [...prevState.groups.map((group) => group)]
      };
    });
  }
  
  const [groups, _groups] = useState<Array<ProjectGroupProps>>([]);
  const setGroups = (groupData: Array<ProjectGroupProps>) => {
    _groups(groupData);
  };
  const [callGetGroups, { data: initialGroups, isLoading: groupsLoading }] =
    useLazyGetPackagingManagerGroupsQuery()
  
  const getGroups = () => {
    callGetGroups()
    .unwrap()
    .catch((error: unknown) => {
      dispatch(
        setNotification({
          type: "error",
          message: "Could not fetch folders.",
        })
      );
      console.error(error);
    });
  };
  
  useEffect(() => {
    getGroups();
  }, []);
  
  useEffect(() => {
    if (initialGroups && initialGroups.length > 0) {
      setGroups(initialGroups);
    }
  }, [initialGroups]);
  
  useEffect(() => {
    if (initialGroups && initialGroups.length > 0) {
      let tempGroups: Array<ProjectGroupProps> = initialGroups.map((group) => group);
      if (updatedTeam.groups.length > 0) {
        if (tempGroups.length !== updatedTeam.groups.length) {
          
            updatedTeam.groups.forEach((selectedGroup) => {
              tempGroups.filter((initialGroup) => {
                if (selectedGroup.id !== initialGroup.id) {
                  return initialGroup;
                }
              })
            });
          _groups(tempGroups);
        }
      } else {
        _groups(initialGroups);
      }
    }
    teamsMatch();

  }, [updatedTeam]);
  
  const updateTeamGroups = (updatedGroups: Array<ProjectGroupProps>) => {
    const newGroups = updatedGroups.map((group) => group);
    
    if (initialGroups && initialGroups.length > 0) {

        _updatedTeam((currentTeam) => {
          return {
            id: currentTeam.id,
            name: currentTeam.name,
            groups: [...newGroups]
          };
      });
    }
  };
  
  const removeTeamGroup = (selected: ProjectGroupProps) => () => {
    
    if (initialGroups && initialGroups.length > 0) {
      
      _updatedTeam((currentTeam) => {
        const newGroups = currentTeam.groups.filter((group) => group.id !== selected.id);
        return {
          id: currentTeam.id,
          name: currentTeam.name,
          groups: [...newGroups]
        };
      });
    }
  };
  
  const teamsMatch = () => {
    if (team.name.trim() !== updatedTeam.name.trim()) {
      _teamChanged(true);
      return;
    } else {
      if (team.groups.length !== updatedTeam.groups.length) {
        _teamChanged(true);
        return;
      } else {
        team.groups.forEach((group) => {
          if (!updatedTeam.groups.find((updatedGroup) => updatedGroup.name === group.name)) {
            _teamChanged(true);
            return;
          }
        });
        updatedTeam.groups.forEach((updatedGroup) => {
          if (!team.groups.find((group) => updatedGroup.name === group.name)) {
            _teamChanged(true);
            return;
          } else {
            _teamChanged(false);
            return;
          }
        });
      }
      
    }
  };
  
  return (
    <Modal testId="edit-project-team-modal" open onClose={handleClose} disabled={groupsLoading}>
      
      <FlexBox minWidth="100%">
        <Title size="large" bold pd="0 0 2rem">
          Edit team {team.name}
        </Title>
        
        
        
        {groupsLoading ? (
          <Loader containerHeight="100%" active />
        ) :
          <>
            <FlexBox pd="0 0 1.5rem">
              <Input
                name="updatedTeamName"
                value={updatedTeam.name}
                onChange={handleTeamNameChange}
                label="Team name"
                maxWidth="60ch"
                data-testid="team-name"
              />
            </FlexBox>
            {(initialGroups && initialGroups.length > 0) && (
              <FlexBox pd="0 0 2rem 0" maxWidth="60ch">
                <MultiSelect
                  title
                  label="Groups"
                  value={updatedTeam.groups}
                  options={groups}
                  onChange={(_, value: Array<ProjectGroupProps>, reason) => {
                    if (reason === "selectOption") {
                      updateTeamGroups(value)
                    }}
                  }
                  removeSelected={(selected: any) => removeTeamGroup(selected)}
                  // TODO: add error handling
                  orientation="vertical"
                />
              </FlexBox>
            )}
            <FlexBox row gap="1.5rem" align="center" justify="flex-start">
              <Button
                use="primary"
                testId="submit-button"
                onClick={() => onComplete(updatedTeam)}
                disabled={groupsLoading || !(updatedTeam.name.trim().length > 0) || updatedTeam.groups.length === 0 || !teamChanged}
              >
                Update team
              </Button>
              <TextButton testId="cancel-button" onClick={handleClose} disabled={groupsLoading}>
                Cancel
              </TextButton>
            </FlexBox>
          </>
        }
        
      </FlexBox>
      
      
    </Modal>
  );
};

export default EditPackagingTeamModal;
