import { observable, makeObservable } from "mobx";

export default class HomeGuidelineLink {
  id = "";
  isNew = false;
  title = "";
  description = "";
  ctaLabel = "";
  textColor = "";
  url = "";
  linkType = "";
  alt = "";
  largeSquareImage = null;
  smallSquareImage = null;
  video = null;
  relatedTopics = null;
  primaryTopics = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      isNew: observable,
      title: observable,
      description: observable,
      ctaLabel: observable,
      textColor: observable,
      url: observable,
      linkType: observable,
      alt: observable,
      largeSquareImage: observable,
      smallSquareImage: observable,
      video: observable,
      relatedTopics: observable,
      primaryTopics: observable
    });

    this.id = props.id;
    this.isNew = props.isNew;
    this.title = props.title;
    this.ctaLabel = props.ctaLabel;
    this.textColor = props.textColor;
    this.description = props.description;
    this.url = props.url.startsWith("/details") ? `/guidelines${props.url}` : props.url;
    this.linkType = props.linkType;
    this.alt = props.alt;
    this.largeSquareImage = props.largeSquareImage;
    this.smallSquareImage = props.smallSquareImage;
    this.video = props.video;
    this.relatedTopics = props.relatedTopics;
    this.primaryTopics = props.primaryTopics;
  }
}
