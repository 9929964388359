import { observable, makeObservable } from 'mobx';

export default class Image {

  id = null;
  type = null;
  caption = null;
  lineColor = null;
  alt = null;
  srcLarge = null;
  largeWidth = null;
  largeHeight = null;
  srcSmall = null;
  smallWidth = null;
  smallHeight = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      caption: observable,
      lineColor: observable,
      alt: observable,
      srcLarge: observable,
      largeWidth: observable,
      largeHeight: observable,
      srcSmall: observable,
      smallWidth: observable,
      smallHeight: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id
    this.type = props.type
    this.caption = props.caption
    this.lineColor = props.lineColor
    this.alt = props.alt
    this.srcLarge = props.srcLarge
    this.largeWidth = props.largeWidth
    this.largeHeight = props.largeHeight
    this.srcSmall = props.srcSmall
    this.smallWidth = props.smallWidth
    this.smallHeight = props.smallHeight
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
  }
} 