import { observable, computed, makeObservable } from "mobx";
import _ from "lodash";

import COMPONENT_CONSTANTS from "./componentDataTypes/COMPONENT_CONSTANTS";

export default class Subcategory {
  id = "";
  title = "";
  urlSlug = "";
  searchKeywords = [];
  components = [];

  prevSubcategoryTitle = "";
  prevSubcategoryURL = "";
  nextSubcategoryTitle = "";
  nextSubcategoryURL = "";

  sidenavSubnavLinks = [];

  constructor(props) {
    makeObservable(this, {
      id: observable,
      title: observable,
      urlSlug: observable,
      searchKeywords: observable,
      components: observable,
      prevSubcategoryTitle: observable,
      prevSubcategoryURL: observable,
      nextSubcategoryTitle: observable,
      nextSubcategoryURL: observable,
      sidenavSubnavLinks: observable,
      heroComponentTheme: computed
    });

    this.id = props.id;
    this.title = props.title;
    this.urlSlug = props.urlSlug;
    this.searchKeywords = props.searchKeywords;
    this.components = props.components;
    this.prevSubcategoryTitle = props.prevSubcategoryTitle;
    this.prevSubcategoryURL = props.prevSubcategoryURL;
    this.prevSubcategoryTheme = props.prevSubcategoryTheme;
    this.nextSubcategoryTitle = props.nextSubcategoryTitle;
    this.nextSubcategoryURL = props.nextSubcategoryURL;
    this.nextSubcategoryTheme = props.nextSubcategoryTheme;

    this.sidenavSubnavLinks = props.sidenavSubnavLinks;
  }

  get heroComponentTheme() {
    let heroTheme = "Black_BG";

    _.each(this.components, (component) => {
      if (component.type === COMPONENT_CONSTANTS.TYPES.HERO) {
        heroTheme = component.theme;
      }
    });

    return heroTheme;
  }
}
