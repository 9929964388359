import {useLocation, useNavigate} from "react-router-dom";
import React from "react";
import {useGetGlobalQuery} from "../services";
import {inject} from "mobx-react";

// this is used to pass our router methods and properties to guidelines class components to replace their custom router
const withRouterForGuidelines = (ClassComponent: any) => {
  
  const ComponentWithRouterProp = inject('store')((props: any) => {
    
    const { data: global} = useGetGlobalQuery();
    
    let location = useLocation();
    let navigate = useNavigate();

    let guidelineSlug = "";
    let categorySlug = "";
    let subcategorySlug = "";
    let componentId = "";
    if (location.pathname.startsWith("/guidelines/print/")) {
      const firstSplit = location.pathname.split('/guidelines/print/');
      if (firstSplit.length > 1) {
        const pathSegments = firstSplit[1].split('/').filter(Boolean); // ["param1", "param2"]
        if (pathSegments.length > 1) {
          guidelineSlug = pathSegments[0] ? pathSegments[0] : "";
          categorySlug = pathSegments[1] ? pathSegments[1] : "";
        } else {
          guidelineSlug = firstSplit[1];
        }
      }
    } else if (location.pathname.startsWith("/guidelines/component/")) {
      const firstSplit = location.pathname.split('/guidelines/component/');
      if (firstSplit.length > 1) {
        componentId = firstSplit[1] ? firstSplit[1] : "";
      }
    } else if (location.pathname.startsWith("/guidelines/details/")) {
      
      const firstSplit = location.pathname.split('/guidelines/details/');
      if (firstSplit.length > 1) {
        const pathSegments = firstSplit[1].split('/').filter(Boolean); // ["path1", "path2", "path3"]
        if (pathSegments.length > 0) {
          guidelineSlug = pathSegments[0] ? pathSegments[0] : "";
          categorySlug = pathSegments[1] ? pathSegments[1] : "";
          subcategorySlug = pathSegments[2] ? pathSegments[2] : "";
        }
      }
    }
    return <ClassComponent sitealertbanner={global?.siteAlertBanner ? "true" : "false"} {...props} location={location} navigate={navigate} guidelineSlug={guidelineSlug} categorySlug={categorySlug} subcategorySlug={subcategorySlug} componentId={componentId} />;
  });
  
  ComponentWithRouterProp.wrappedComponent.displayName = `WithRouter(${ClassComponent.displayName || ClassComponent.name})`;
  return ComponentWithRouterProp;
  
}

export default withRouterForGuidelines;