import { observable, makeObservable } from 'mobx';

export default class TableCell {

  id = null;
  content = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      content: observable
    });

    this.id = props.id
    this.content = props.content
  }
} 