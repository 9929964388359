import { observable, makeObservable } from 'mobx';

export default class AnimationMobileAndDesktopCompare {

  id = "";
  type = null;
  mobileAnimation = null;
  desktopAnimation = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      mobileAnimation: observable,
      desktopAnimation: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id
    this.type = props.type
    this.mobileAnimation = props.mobileAnimation
    this.desktopAnimation = props.desktopAnimation
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
  }
}