import { observable, action, makeObservable, runInAction } from 'mobx';
import _ from "lodash";
import queryString from "query-string";

import { DataService } from "./../services";

import { CategoryItem, SubcategoryItem } from "./guidelineNavigation";

import { Category, Guideline, Subcategory } from "./contentTypes";

import STORE_CONSTANTS from "./STORE_CONSTANTS";

let rawGuidelinesData;

class Store {
  homePage = null;

  footer = null;

  decisionTree = null;
  decisionTreeOpen = false;

  userPreferences = {
    firstName: "User",
    lastName: "Name",
  };

  guidelines = [];
  externalGuidelines = [];

  selectedGuideline = null;
  selectedCategory = null;
  selectedSubcategory = null;

  selectedSubnavItemIndex = null;

  leftNavSmallScreenOpen = false;

  guidelineNavigationItems = [];

  componentPreviewID = null;
  componentPreviewData = null;

  printIndexContent = null;
  printContent = null;

  navFixed = false;
  navFixedVisible = false;
  
  previousPage = "";
  
  
  constructor() {
    
    makeObservable(this, {
      homePage: observable,
      footer: observable,
      decisionTree: observable,
      decisionTreeOpen: observable,
      userPreferences: observable,
      guidelines: observable,
      externalGuidelines: observable,
      selectedGuideline: observable,
      selectedCategory: observable,
      selectedSubcategory: observable,
      selectedSubnavItemIndex: observable,
      leftNavSmallScreenOpen: observable,
      guidelineNavigationItems: observable,
      componentPreviewID: observable,
      componentPreviewData: observable,
      printIndexContent: observable,
      printContent: observable,
      navFixed: observable,
      navFixedVisible: observable,
      setNavFixed: action,
      setNavFixedVisible: action,
      previousPage: observable,
      setPreviousPage: action,
      parseGuidelinesData: action,
      setSelectedGuideline: action,
      setPrintContent: action,
      setPrintIndexContent: action,
      initSideNavigation: action,
      toggleSideNavigationCategory: action,
      loadComponentData: action,
      handleButtonAction: action,
      setSelectedSubnavItemIndex: action,
      setLeftNavSmallScreenOpen: action,
    });
    
    // makeAutoObservable(this);
    if (window.UserFirstName)
      this.userPreferences.firstName = window.UserFirstName;

    if (window.UserLastName)
      this.userPreferences.lastName = window.UserLastName;
  }

  loadContent(type, cb) {
    DataService.getData(type)
      .then((data) => {
        switch (type) {
          case STORE_CONSTANTS.CONTENT.TYPES.HOME_PAGE:
            runInAction(() => {
              this.homePage = data.homePage;
            });

            break;

          case STORE_CONSTANTS.CONTENT.TYPES.FOOTER:
            runInAction(() => {
              this.footer = data.footer;
            });

            break;

          case STORE_CONSTANTS.CONTENT.TYPES.DECISION_TREE:
            runInAction(() => {
              this.decisionTree = data.decisionTree;
            });

            break;

          case STORE_CONSTANTS.CONTENT.TYPES.GUIDELINES:
            runInAction(() => {
              rawGuidelinesData = data.guidelines;
              this.parseGuidelinesData();
              
              this.externalGuidelines.replace(data.externalGuidelines);
            });

            break;

          default:
            break;
        }

        if (cb) cb();
      })
      .catch((error) => {
        console.log(error);

        //eslint-disable-next-line
        const stopErrorRedirect = queryString.parse(location.search)
          .stopErrorRedirect
          ? true
          : false;

        if (!stopErrorRedirect)
          setTimeout(() => {
            window.location = "/guidelines/error";
          }, 3000);
      });
  }
  
  setPreviousPage(previousUrl) {
    this.previousPage = previousUrl;
  }
  
  setNavFixed(newNavFixed) {
    this.navFixed = newNavFixed;
  }
  
  setNavFixedVisible(newNavFixedVisible) {
    this.navFixedVisible = newNavFixedVisible;
  }
  
  setSelectedSubnavItemIndex(newIndex) {
    this.selectedSubnavItemIndex = newIndex;
  }
  
  setLeftNavSmallScreenOpen(isOpen) {
    this.leftNavSmallScreenOpen = isOpen;
  }

  parseGuidelinesData() {
    let guidelines = [];

    const guidelinesData = [];

    _.each(rawGuidelinesData, (guidelineData) => {
      guidelinesData.push(new Guideline(_.assign({}, guidelineData)));
    });

    _.each(guidelinesData, (guideline) => {
      const includedCategories = [];

      _.each(guideline.categories, (categoryData) => {
        const category = new Category(_.assign({}, categoryData));

        const includedSubcategories = [];

        _.each(categoryData.subcategories, (subcategoryData) => {
          const subcategory = new Subcategory(_.assign({}, subcategoryData));

          includedSubcategories.push(subcategory);
        });

        category.subcategories = includedSubcategories;

        includedCategories.push(category);
      });

      guideline.categories = includedCategories;

      const subcategoryNavigationData =
        this.parseSubcategoryNavigation(guideline);
      let navIndex = 0;

      _.each(guideline.categories, (category, categoryKey) => {
        _.each(category.subcategories, (subcategory, subcategoryKey) => {
          subcategory.prevSubcategoryTitle =
            subcategoryNavigationData[navIndex].prevSubcategoryTitle;
          subcategory.prevSubcategoryURL =
            subcategoryNavigationData[navIndex].prevSubcategoryURL;
          subcategory.prevSubcategoryTheme =
            subcategoryNavigationData[navIndex].prevSubcategoryTheme;
          subcategory.nextSubcategoryTitle =
            subcategoryNavigationData[navIndex].nextSubcategoryTitle;
          subcategory.nextSubcategoryURL =
            subcategoryNavigationData[navIndex].nextSubcategoryURL;
          subcategory.nextSubcategoryTheme =
            subcategoryNavigationData[navIndex].nextSubcategoryTheme;

          navIndex++;
        });
      });

      guidelines.push(guideline);
    });

    guidelines = _.sortBy(guidelines, ["dropdownPriority", "sortTitle"]);

    this.guidelines.replace(guidelines);
  }

  parseSubcategoryNavigation(guideline) {
    const subcategoryNavigationData = [];

    const guidelineSlug = guideline.urlSlug;

    _.each(guideline.categories, (categoryData, categoryKey) => {
      _.each(categoryData.subcategories, (subcategoryData, subcategoryKey) => {
        // Guideline only has a single category
        if (guideline.categories.length === 1) {
          // The subcategory has no siblings
          if (categoryData.subcategories.length === 1) {
            subcategoryNavigationData.push({
              prevSubcategoryTitle: null,
              prevSubcategoryURL: null,
              prevSubcategoryTheme: null,
              nextSubcategoryTitle: null,
              nextSubcategoryURL: null,
              nextSubcategoryTheme: null,
            });
          } else {
            // First subcategory
            if (subcategoryKey === 0) {
              const nextSubcategory =
                categoryData.subcategories[subcategoryKey + 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: null,
                prevSubcategoryURL: null,
                prevSubcategoryTheme: null,
                nextSubcategoryTitle: nextSubcategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${nextSubcategory.urlSlug}`,
                nextSubcategoryTheme: new Subcategory(nextSubcategory)
                  .heroComponentTheme,
              });
            }
            // Last subcategory
            else if (subcategoryKey === categoryData.subcategories.length - 1) {
              const prevSubcategory =
                categoryData.subcategories[subcategoryKey - 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevSubcategory.title,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${prevSubcategory.urlSlug}`,
                prevSubcategoryTheme: new Subcategory(prevSubcategory)
                  .heroComponentTheme,
                nextSubcategoryTitle: null,
                nextSubcategoryURL: null,
                nextSubcategoryTheme: null,
              });
            }
            // Some middle subcategory
            else {
              const nextSubcategory =
                categoryData.subcategories[subcategoryKey + 1];
              const prevSubcategory =
                categoryData.subcategories[subcategoryKey - 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevSubcategory.title,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${prevSubcategory.urlSlug}`,
                prevSubcategoryTheme: new Subcategory(prevSubcategory)
                  .heroComponentTheme,
                nextSubcategoryTitle: nextSubcategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${nextSubcategory.urlSlug}`,
                nextSubcategoryTheme: new Subcategory(nextSubcategory)
                  .heroComponentTheme,
              });
            }
          }
        } else {
          // First category
          if (categoryKey === 0) {
            // The subcategory has no siblings
            if (categoryData.subcategories.length === 1) {
              const nextCategory = guideline.categories[categoryKey + 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: null,
                prevSubcategoryURL: null,
                prevSubcategoryTheme: null,
                nextSubcategoryTitle: nextCategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${nextCategory.urlSlug}/${nextCategory.subcategories[0].urlSlug}`,
                nextSubcategoryTheme: new Subcategory(
                  nextCategory.subcategories[0]
                ).heroComponentTheme,
              });
            }
            // The first subcategory
            else if (subcategoryKey === 0) {
              const nextSubcategory =
                categoryData.subcategories[subcategoryKey + 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: null,
                prevSubcategoryURL: null,
                prevSubcategoryTheme: null,
                nextSubcategoryTitle: nextSubcategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${nextSubcategory.urlSlug}`,
                nextSubcategoryTheme: new Subcategory(nextSubcategory)
                  .heroComponentTheme,
              });
            }
            // The last subcategory
            else if (subcategoryKey === categoryData.subcategories.length - 1) {
              const prevSubcategory =
                categoryData.subcategories[subcategoryKey - 1];
              const nextCategory = guideline.categories[categoryKey + 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevSubcategory.title,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${prevSubcategory.urlSlug}`,
                prevSubcategoryTheme: new Subcategory(prevSubcategory)
                  .heroComponentTheme,
                nextSubcategoryTitle: nextCategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${nextCategory.urlSlug}/${nextCategory.subcategories[0].urlSlug}`,
                nextSubcategoryTheme: new Subcategory(
                  nextCategory.subcategories[0]
                ).heroComponentTheme,
              });
            }
            // Some middle subcategory
            else {
              const prevSubcategory =
                categoryData.subcategories[subcategoryKey - 1];
              const nextSubcategory =
                categoryData.subcategories[subcategoryKey + 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevSubcategory.title,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${prevSubcategory.urlSlug}`,
                prevSubcategoryTheme: new Subcategory(prevSubcategory)
                  .heroComponentTheme,
                nextSubcategoryTitle: nextSubcategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${nextSubcategory.urlSlug}`,
                nextSubcategoryTheme: new Subcategory(nextSubcategory)
                  .heroComponentTheme,
              });
            }
          }
          // Last category
          else if (categoryKey === guideline.categories.length - 1) {
            // The subcategory has no siblings
            if (categoryData.subcategories.length === 1) {
              const prevCategory = guideline.categories[categoryKey - 1];

              let prevTitle =
                prevCategory.subcategories[
                  prevCategory.subcategories.length - 1
                ].title;

              // The previous category has only 1 subcategory
              if (prevCategory.subcategories.length === 1)
                prevTitle = prevCategory.title;

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevTitle,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${
                  prevCategory.urlSlug
                }/${
                  prevCategory.subcategories[
                    prevCategory.subcategories.length - 1
                  ].urlSlug
                }`,
                prevSubcategoryTheme: new Subcategory(
                  prevCategory.subcategories[
                    prevCategory.subcategories.length - 1
                  ]
                ).heroComponentTheme,
                nextSubcategoryTitle: null,
                nextSubcategoryURL: null,
                nextSubcategoryTheme: null,
              });
            }
            // First subcategory
            else if (subcategoryKey === 0) {
              const prevCategory = guideline.categories[categoryKey - 1];
              const nextSubcategory =
                categoryData.subcategories[subcategoryKey + 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle:
                  prevCategory.subcategories[
                    prevCategory.subcategories.length - 1
                  ].title,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${
                  prevCategory.urlSlug
                }/${
                  prevCategory.subcategories[
                    prevCategory.subcategories.length - 1
                  ].urlSlug
                }`,
                prevSubcategoryTheme: new Subcategory(
                  prevCategory.subcategories[
                    prevCategory.subcategories.length - 1
                  ]
                ).heroComponentTheme,
                nextSubcategoryTitle: nextSubcategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${nextSubcategory.urlSlug}`,
                nextSubcategoryTheme: new Subcategory(nextSubcategory)
                  .heroComponentTheme,
              });
            }
            // Last subcategory
            else if (subcategoryKey === categoryData.subcategories.length - 1) {
              const prevSubcategory =
                categoryData.subcategories[subcategoryKey - 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevSubcategory.title,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${prevSubcategory.urlSlug}`,
                prevSubcategoryTheme: new Subcategory(prevSubcategory)
                  .heroComponentTheme,
                nextSubcategoryTitle: null,
                nextSubcategoryURL: null,
                nextSubcategoryTheme: null,
              });
            }
            // Some middle subcategory
            else {
              const prevSubcategory =
                categoryData.subcategories[subcategoryKey - 1];
              const nextSubcategory =
                categoryData.subcategories[subcategoryKey + 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevSubcategory.title,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${prevSubcategory.urlSlug}`,
                prevSubcategoryTheme: new Subcategory(prevSubcategory)
                  .heroComponentTheme,
                nextSubcategoryTitle: nextSubcategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${nextSubcategory.urlSlug}`,
                nextSubcategoryTheme: new Subcategory(nextSubcategory)
                  .heroComponentTheme,
              });
            }
          }
          // Some middle category
          else {
            // The subcategory has no siblings
            if (categoryData.subcategories.length === 1) {
              const prevCategory = guideline.categories[categoryKey - 1];
              const nextCategory = guideline.categories[categoryKey + 1];

              let prevTitle =
                prevCategory.subcategories[
                  prevCategory.subcategories.length - 1
                ].title;

              // The previous category has only 1 subcategory
              if (prevCategory.subcategories.length === 1)
                prevTitle = prevCategory.title;

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevTitle,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${
                  prevCategory.urlSlug
                }/${
                  prevCategory.subcategories[
                    prevCategory.subcategories.length - 1
                  ].urlSlug
                }`,
                prevSubcategoryTheme: new Subcategory(
                  prevCategory.subcategories[
                    prevCategory.subcategories.length - 1
                  ]
                ).heroComponentTheme,
                nextSubcategoryTitle: nextCategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${nextCategory.urlSlug}/${nextCategory.subcategories[0].urlSlug}`,
                nextSubcategoryTheme: new Subcategory(
                  nextCategory.subcategories[0]
                ).heroComponentTheme,
              });
            }
            // First subcategory
            else if (subcategoryKey === 0) {
              const prevCategory = guideline.categories[categoryKey - 1];
              const nextSubcategory =
                categoryData.subcategories[subcategoryKey + 1];

              let prevTitle =
                prevCategory.subcategories[
                  prevCategory.subcategories.length - 1
                ].title;

              // The previous category has only 1 subcategory
              if (prevCategory.subcategories.length === 1)
                prevTitle = prevCategory.title;

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevTitle,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${
                  prevCategory.urlSlug
                }/${
                  prevCategory.subcategories[
                    prevCategory.subcategories.length - 1
                  ].urlSlug
                }`,
                prevSubcategoryTheme: new Subcategory(
                  prevCategory.subcategories[
                    prevCategory.subcategories.length - 1
                  ]
                ).heroComponentTheme,
                nextSubcategoryTitle: nextSubcategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${nextSubcategory.urlSlug}`,
                nextSubcategoryTheme: new Subcategory(nextSubcategory)
                  .heroComponentTheme,
              });
            }
            // Last subcategory
            else if (subcategoryKey === categoryData.subcategories.length - 1) {
              const prevSubcategory =
                categoryData.subcategories[subcategoryKey - 1];
              const nextCategory = guideline.categories[categoryKey + 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevSubcategory.title,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${prevSubcategory.urlSlug}`,
                prevSubcategoryTheme: new Subcategory(prevSubcategory)
                  .heroComponentTheme,
                nextSubcategoryTitle: nextCategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${nextCategory.urlSlug}/${nextCategory.subcategories[0].urlSlug}`,
                nextSubcategoryTheme: new Subcategory(
                  nextCategory.subcategories[0]
                ).heroComponentTheme,
              });
            }
            // Some middle subcategory
            else {
              const prevSubcategory =
                categoryData.subcategories[subcategoryKey - 1];
              const nextSubcategory =
                categoryData.subcategories[subcategoryKey + 1];

              subcategoryNavigationData.push({
                prevSubcategoryTitle: prevSubcategory.title,
                prevSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${prevSubcategory.urlSlug}`,
                prevSubcategoryTheme: new Subcategory(prevSubcategory)
                  .heroComponentTheme,
                nextSubcategoryTitle: nextSubcategory.title,
                nextSubcategoryURL: `/guidelines/details/${guidelineSlug}/${categoryData.urlSlug}/${nextSubcategory.urlSlug}`,
                nextSubcategoryTheme: new Subcategory(nextSubcategory)
                  .heroComponentTheme,
              });
            }
          }
        }
      });
    });

    return subcategoryNavigationData;
  }

  setSelectedGuideline(slugs, cb, destroy) {
    if (destroy) {
      this.selectedGuideline = null;
      this.selectedCategory = null;
      this.selectedSubcategory = null;
      this.guidelineNavigationItems.replace([]);
    } else {
      const guideline = _.filter(this.guidelines, {
        urlSlug: slugs.guidelineSlug,
      })[0];
      
      let category = undefined;
      let subcategory = undefined;
      
      if (guideline) {
        category = _.filter(guideline.categories, {
          urlSlug: slugs.categorySlug,
        })[0];
        
        if (category !== undefined) {
          subcategory = _.filter(category.subcategories, {
            urlSlug: slugs.subcategorySlug,
          })[0];
        }
      }
      
      
      runInAction(() => {
        if (guideline) {
          this.selectedGuideline = guideline;
        }
        if (category) {
          this.selectedCategory = category;
        }
        if (subcategory) {
          this.selectedSubcategory = subcategory;
        }
        
      });

      this.setupGuidelineNavigationItems();

      typeof cb !== "undefined" && cb();
    }
  }

  setupGuidelineNavigationItems() {
    const guidelineNavigationItems = [];

    _.each(this.selectedGuideline.categories, (category) => {
      const subcategories = [];

      _.each(category.subcategories, (subcategory) => {
        subcategories.push(
          new SubcategoryItem({
            id: subcategory.id,
            title: subcategory.title,
            urlSlug: subcategory.urlSlug,
            sidenavSubnavLinks: subcategory.sidenavSubnavLinks,
          })
        );
      });

      guidelineNavigationItems.push(
        new CategoryItem({
          id: category.id,
          title: category.title,
          urlSlug: category.urlSlug,
          subcategories,
        })
      );
    });

    this.guidelineNavigationItems.replace(guidelineNavigationItems);
    
    this.initSideNavigation(this.selectedCategory, this.selectedSubcategory);
  }

  setPrintContent(guidelineSlug, categorySlug) {
    const guideline = _.filter(this.guidelines, { urlSlug: guidelineSlug })[0];

    // DONT GET CATEGORY IF THERE IS NO CATEGORY SLUG
    if (categorySlug) {
      const category = _.filter(guideline.categories, {
        urlSlug: categorySlug,
      })[0];
      this.printContent = _.assign({}, category, {
        guidelineTitle: guideline.title,
      });
    } else {
      // SET TO GUIDELINE IF THERE IS NO CATEGORY SLUG
      this.printContent = guideline;
    }
  }

  setPrintIndexContent() {
    const printIndexContent = [];

    _.each(
      _.filter(rawGuidelinesData, (item) => {
        return item.title !== "Unlimited";
      }),
      (guidelineData) => {
        const categories = [];

        _.each(guidelineData.categories, (categoryData) => {
          const subcategories = [];

          _.each(categoryData.subcategories, (subcategoryData) => {
            subcategories.push({
              id: subcategoryData.id,
              title: subcategoryData.title,
              printURL: `/guidelines/details/${guidelineData.urlSlug}/${categoryData.urlSlug}/${subcategoryData.urlSlug}`,
            });
          });

          categories.push({
            id: categoryData.id,
            title: categoryData.title,
            printURL: `/guidelines/print/${guidelineData.urlSlug}/${categoryData.urlSlug}`,
            subcategories,
          });
        });

        const guideline = {
          id: guidelineData.id,
          title: guidelineData.title,
          printURL: `/guidelines/print/${guidelineData.urlSlug}`,
          categories,
        };

        printIndexContent.push(guideline);
      }
    );

    this.printIndexContent = printIndexContent;
  }

  initSideNavigation(selectedCategory, selectedSubcategory) {
    this.selectedSubnavItemIndex = 0;

    _.each(this.guidelineNavigationItems, (item) => {
      if (item.id === selectedCategory.id) {
        item.selected = item.deepLinked = true;
      }
      else item.selected = item.deepLinked = false;

      _.each(item.subcategories, (subcategory) => {
        if (subcategory.id === selectedSubcategory.id) {
          subcategory.selected = true;
        }
        else subcategory.selected = false;
      });
    });
  }

  toggleSideNavigationCategory(selectedCategory) {
    _.each(this.guidelineNavigationItems, (item) => {
      if (item.id === selectedCategory.id) item.selected = !item.selected;
      else item.selected = item.deepLinked ? true : false;
    });
  }

  loadComponentData(componentID) {
    this.componentPreviewID = componentID;

    DataService.getComponent(this.componentPreviewID).then((componentData) => {
      this.componentPreviewData = componentData;
    });
  }

  handleButtonAction(action) {
    switch (action) {
      case "decisionTree":
        document.body.style.overflow = this.decisionTreeOpen
          ? "auto"
          : "hidden";

        this.decisionTreeOpen = !this.decisionTreeOpen;

        break;
      default:
        break;
    }
  }
}

const store = (window.store = new Store());

export default store;
