import { observable, makeObservable } from 'mobx';

export default class LinkImage {

  id = null;
  type = null;
  image = null;
  title = null;
  titleSize = null;
  description = null;
  url = null;
  linkType = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      image: observable,
      title: observable,
      titleSize: observable,
      description: observable,
      url: observable,
      linkType: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id
    this.type = props.type
    this.image = props.image
    this.title = props.title
    this.titleSize = props.titleSize
    this.description = props.description
    this.url = props.url
    this.linkType = props.linkType
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
  }
}