import { observable, makeObservable } from 'mobx';

export default class Footer {

  id = "";
  items = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      items: observable
    });

    this.id = props.id
    this.items = props.items
  }
}