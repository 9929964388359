import { observable, makeObservable } from 'mobx';

export default class ColorSwatch {
  id = null;
  type = null;
  colorHex = null;
  borderColor = null;
  roundBorders = null;
  squareSwatch = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      colorHex: observable,
      borderColor: observable,
      roundBorders: observable,
      squareSwatch: observable
    });

    this.id = props.id
    this.type = props.type
    this.colorHex = props.colorHex
    this.borderColor = props.borderColor
    this.roundBorders = props.roundBorders
    this.squareSwatch = props.squareSwatch
  }
}
