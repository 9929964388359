import { observable, makeObservable } from "mobx";

export default class CautionHeader {
  id = null;
  type = null;
  title = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      title: observable
    });

    this.id = props.id;
    this.type = props.type;
    this.title = props.title;
  }
}
