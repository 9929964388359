import { observable, makeObservable } from 'mobx';

export default class ContainerInvertThemeToDark {

  id = null;
  type = null;
  components = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      components: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id
    this.type = props.type
    this.components = props.components
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
  }
}