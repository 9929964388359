import { observable, makeObservable } from 'mobx';

export default class ImageGridVersion4 {

  id = null;
  type = null;
  alt = null;
  leftUpperRectangularImage = null;
  leftLowerSquareImages = null;
  rightUpperSquareImages = null;
  rightLowerRectangularImage = null;
  additionalPaddingBelowComponent = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      alt: observable,
      leftUpperRectangularImage: observable,
      leftLowerSquareImages: observable,
      rightUpperSquareImages: observable,
      rightLowerRectangularImage: observable,
      additionalPaddingBelowComponent: observable
    });

    this.id = props.id
    this.type = props.type
    this.alt = props.alt
    this.leftUpperRectangularImage = props.leftUpperRectangularImage
    this.leftLowerSquareImages = props.leftLowerSquareImages
    this.rightUpperSquareImages = props.rightUpperSquareImages
    this.rightLowerRectangularImage = props.rightLowerRectangularImage
    this.additionalPaddingBelowComponent = props.additionalPaddingBelowComponent
  }
} 