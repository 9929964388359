import { observable, makeObservable } from 'mobx';

export default class Guideline {
  id = "";
  title = "";
  sortTitle = "";
  urlSlug = "";
  categories = [];
  theme = null;
  dropdownPriority = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      title: observable,
      sortTitle: observable,
      urlSlug: observable,
      categories: observable,
      theme: observable,
      dropdownPriority: observable
    });

    this.id = props.id
    this.title = props.title
    this.sortTitle = props.title.toLowerCase()
    this.urlSlug = props.urlSlug
    this.categories = props.categories
    this.dropdownPriority = props.dropdownPriority
    this.theme = props.theme
  }
}
