import { observable, makeObservable } from "mobx";

export default class SectionHeader {
  id = null;
  type = null;
  title = null;
  description = null;
  CTAButtons = null;
  anchorLinkTarget = null;

  constructor(props) {
    makeObservable(this, {
      id: observable,
      type: observable,
      title: observable,
      description: observable,
      CTAButtons: observable,
      anchorLinkTarget: observable
    });

    this.id = props.id;
    this.type = props.type;
    this.title = props.title;
    this.description = props.description;
    this.CTAButtons = props.CTAButtons;
    this.anchorLinkTarget = props.anchorLinkTarget;
  }
}
